import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import CreditorLoanModal from "./CreditorLoanModal";
import { useState } from "react";


const LoanList = ({ data , flag , setFlag }) => {
  const [visible, setVisible] = useState(false);
  const [debitAmount, setDebitAmount] = useState();
  const [loansPayableID, setLoansPayableID] = useState("");

  return (
    <>
      <CreditorLoanModal visible={visible} setVisible={setVisible} flag={flag} setFlag={setFlag} data={data} type={"payout"} debitAmount={debitAmount} loansPayableID={loansPayableID}/>
      <CContainer fluid className="p-4 mt-1">
        <CTable bordered className="shadow">
          <CTableHead className="bg-secondary text-center">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">

              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Account Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount Recieved
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Payment Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Promised Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Due Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Status
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data.loansPayableDetails.map((ele, i) => {
              return (
                <CTableRow key={i}>
                  <CTableHeaderCell className="badge bg-primary mt-1" scope="row" onClick={() => {
                    setDebitAmount(ele.amountReceived)
                    setLoansPayableID(ele._id)
                    setVisible(!visible);
                  }} > Pay </CTableHeaderCell>
                  <CTableDataCell>{ele.accountName}</CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.amountReceived}</b>
                  </CTableDataCell>
                  <CTableDataCell>
                    {ele.paymentType}
                  </CTableDataCell>
                  <CTableDataCell>{(new Date(ele.promiseDate)).toLocaleString()}</CTableDataCell>
                  <CTableDataCell>{new Date(100000).toDateString()}</CTableDataCell>
                  <CTableDataCell>
                    pending
                  </CTableDataCell>
                </CTableRow>
              )
            })}
          </CTableBody>
        </CTable>




      </CContainer>
    </>
  );
};

export default LoanList;
