import React, { useState, useContext } from "react";
import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormText,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

function Adjustmentm({ data }) {
  const [visible, setVisible] = useState(false);
  const { showAlert } = useContext(globalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate()
  const [reqtData, setReqtData] = useState({
    fromReqDate: data.Date,
    voucherNo: data.VoucherNo,
    reqRemarks: "",
    reqPaymentType: data.paymentType
  });
  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    try {
      e.preventDefault();
      let res = await axios.post(
        `/api/branchActivity/doAdjustment`,
        reqtData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      await showAlert("Successfully Adjust", "2");
      setTimeout(() => {
        setVisible(false);
        navigate(0);
        setIsSubmitting(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
      setIsSubmitting(false);
    }
    // setIsSubmitting(false);
  };
  let onChangeHandler = (e) => {
    setReqtData({
      ...reqtData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <CButton onClick={() => setVisible(!visible)} className="p-1 ">Adjust</CButton>
      <CModal
        size="xl"
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <CModalHeader>
          <CModalTitle>Adjusment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <legend className="fw-bold">Details</legend>
          <CContainer className="d-flex">
            <CInputGroup className="m-2">
              <CInputGroupText className="fw-bold text-black">Name</CInputGroupText>
              <CFormInput
                type="text"
                className="fw-bold"
                readOnly
                value={data.MemberName}
              />

            </CInputGroup>
            <CInputGroup className="m-2">
              <CInputGroupText className="fw-bold text-black">Product Name</CInputGroupText>
              <CFormInput
                type="text"
                className="fw-bold"
                readOnly
                value={data.ProductName}
              />

            </CInputGroup>

            <CInputGroup className="m-2">
              <CInputGroupText className="fw-bold text-black">Date</CInputGroupText>
              <CFormInput
                type="date"
                className="fw-bold"
                readOnly
                value={new Date(data.Date).toISOString().split("T")[0]}
              />

            </CInputGroup>

          </CContainer>
          <hr />

          <CContainer className="d-flex">
            <CInputGroup className="m-2">
              <CInputGroupText className="fw-bold text-black">Amount</CInputGroupText>
              <CFormInput
                type="number"
                className="fw-bold"
                readOnly
                value={data.amount.toFixed(2)}

              />

            </CInputGroup>
            <CInputGroup className="m-2">
              <CInputGroupText className="fw-bold text-black">Remark</CInputGroupText>
              <CFormInput
                type="text"
                className="fw-bold"
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="m-2">
            </CInputGroup>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton className="fw-bold text-white ps-5 pe-5 me-5" color="success" disabled={isSubmitting} onClick={onSubmitHandler}>Save</CButton>
          <CButton color="danger" className="fw-bold text-white ms-5 ps-5 pe-5" onClick={() => setVisible(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default Adjustmentm;
