import React from "react";
import { CContainer, CFooter, CLink } from "@coreui/react";
function Footer() {
  let date = new Date().getFullYear();
  return (
    // fix the footer to the bottom of the page
    <CFooter
      style={{ backgroundColor: "#2364aa", color: "white" }}
      className="bottom w-100"
    >
      <CContainer fluid>
        <span>   
          {/* {date}  */}
          Powered By </span>
        <CLink
          style={{ color: "#fec601" }}
          className="text-decoration-none"
          href="https://codeforindia.com"
          target="_blank"
        >
          Code For India Foundation
        </CLink>
      </CContainer>
    </CFooter>
  );
}

export default Footer;
