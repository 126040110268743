import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";
import React,{useState} from "react";
const ActivitySummary = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Activity Summary
      </p>
      <CContainer>
        <CRow>
          <h5>Report</h5>
          <hr></hr>

          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="type">
                Report Type
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                // value={}
                // onChange={}
                aria-label="Type"
                options={[
                  { label: "Summary", value: "summary" },
                  { label: "Details", value: "details" },
                ]}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>
          {/* //TODO: variable data in SP  */}
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="cashCollector">
                SP
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                // value={}
                // onChange={}
                aria-label="Transaction Type"
                options={[
                  "Select",

                  { label: "Variable Data", value: "Variable Data" },
                ]}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={12}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton className="text-white " color="success">
                  Show
                </CButton>
                {/* <CButton className="text-white " color="success">
                  Export to Excel
                </CButton> */}
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <hr></hr>
        <h6>From Date: 04-01-2022 To Date: 03-01-2023</h6>

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Cash Collector
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Total Visit Count
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Actual Visit Count
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Withdrawal Count
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Collection Count
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount Withdrawn
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount Collected
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>Abdul Hafeez</CTableDataCell>
              <CTableDataCell>18</CTableDataCell>
              <CTableDataCell>0</CTableDataCell>
              <CTableDataCell>1</CTableDataCell>
              <CTableDataCell>13</CTableDataCell>

              <CTableDataCell>200.00</CTableDataCell>
              <CTableDataCell>21600.00</CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
        <hr></hr>
        <h6>From Date: 04-01-2022 To Date: 03-01-2023</h6>
        {/* Details summary */}
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Membership No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Member Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Date & Time
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Outcome
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Activity Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>111992100001</CTableDataCell>
              <CTableDataCell>0001 Mohd Ashfa</CTableDataCell>
              <CTableDataCell>14/02/2022 03:02:22</CTableDataCell>
              {/* Variable link */}
              <CTableDataCell>
                <CButton type="submit" disabled={isSubmitting} color="link" shape="rounded-0">
                {isSubmitting ? 'Submitting...' : 'Daiily Deposit'}
                </CButton>
              </CTableDataCell>
              <CTableDataCell>Collection</CTableDataCell>
              <CTableDataCell>5000.00</CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default ActivitySummary;
