import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
const DropDownPagination = ({ handleItemsPerPage, num }) => {
  return (
    <CContainer className="d-flex justify-content-end align-middle">
      <CDropdown direction="center shadow">
        <CDropdownToggle color="info" className="text-white">
          Selected Number : <span className="fw-bold">{num} </span>
        </CDropdownToggle>
        <CDropdownMenu className="text-center">
          <CDropdownItem onClick={() => handleItemsPerPage(10)}>
            10
          </CDropdownItem>
          <CDropdownItem onClick={() => handleItemsPerPage(25)}>
            25
          </CDropdownItem>
          <CDropdownItem onClick={() => handleItemsPerPage(50)}>
            50
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </CContainer>
  );
};

export default DropDownPagination;
