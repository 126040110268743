import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,

  CContainer,
  CButton,

  CCard,
  CCardBody,
  CCardImage,
  CCardText,
} from "@coreui/react";
import { useState , useContext, useEffect} from "react";
import axios from "axios";
import globalContext from "../contexts/Global/globalContext";


function PendingMemberModal({
  visible,
  setVisible,
  showData,
  setShowData,
  accept,
  setAccept,
  data,
  setData
}) {
  const { authCheck, showAlert , } = useContext(globalContext);

  const onClickHandler = async () => {
    try {
      let reqData = {
        accept: accept,
        membership_id: showData._id,
      };
      let res = await axios.put("/api/member/decision", reqData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (accept === "accept") {
        showAlert("Successfully approved","2");
      } else {
        showAlert("Successfully rejected","4");
      }
      let memberIndex = data.findIndex(ele => {
        return ele._id == showData._id
      });
      let array = [...data];
      array.splice(memberIndex,1);
      setData(array)
      
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }    }
  };
  const [bussiness, setBusiness] = useState(false);
  useEffect(() => {
    if (showData) {
      if (
        visible &&
        accept === "accept" &&
        showData.pDetails.occupation === "bussiness"
      )
        setBusiness(true);
    }
  }, [visible]);

  if (accept === "accept") {
    return (
      <CModal
        alignment="center"
        className="modal-lg"
        visible={visible}
        onClose={() => {
          setAccept("");
          setVisible(false);
          setShowData({});
        }}
      >
        <CModalHeader className="bg-success">
          <CModalTitle className="text-white">Approve Member</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCard>
            <CContainer
              fluid
              className="d-flex justify-content-center align-content-center"
            >
              <CCardImage
                orientation="top"
                src={`./${showData.memberImage.data}`}
                style={{
                  width: "180px",
                  minHeight: "200px",
                  maxWidth: "280px",
                  maxHeight: "300px",
                }}
                className="m-1 shadow"
              ></CCardImage>
            </CContainer>
            <CCardBody>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 pb-1"
              >
                <CCardText className="m-0">
                  <b>Name:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.memberName}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Adhaar Number:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.adhaarNumber}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Paternal Relation Name:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.fatherHusbandName}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Gender:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.gender}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Date of Birth:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>
                  {new Date(showData.pDetails.dob).toDateString()}
                </CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Caste:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.caste}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Religion:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.religion}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Address:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{`${showData.pAddress.pHouseNo} , ${showData.pAddress.pStreet} , ${showData.pAddress.pCity} , ${showData.pAddress.pState}`}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Occupation:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText> {showData.pDetails.occupation}</CCardText>
              </CContainer>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Gender:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.pDetails.gender}</CCardText>
              </CContainer>
              {bussiness ? (
                <>
                  <CContainer
                    flui
                    className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
                  >
                    <CCardText className="m-0">
                      <b>Business Details:</b>
                    </CCardText>
                    <CCardText>{showData.bussinessType}</CCardText>
                  </CContainer>
                  <CContainer
                    flui
                    className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
                  >
                    <CCardText className="m-0">
                      <b>Goods / Services:</b>
                    </CCardText>
                    <CCardText>{showData.goodsService}</CCardText>
                  </CContainer>
                  <CContainer
                    flui
                    className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
                  >
                    <CCardText className="m-0">
                      <b>Location type:</b>
                    </CCardText>
                    <CCardText>{showData.locationType}</CCardText>
                  </CContainer>
                  <CContainer
                    flui
                    className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
                  >
                    <CCardText className="m-0">
                      <b>Bussiness Description:</b>
                    </CCardText>
                    <CCardText>{showData.bussinessDesc}</CCardText>
                  </CContainer>
                </>
              ) : null}
              <CContainer
                flui
                className="d-flex justify-content-between align-items-center px-5 pt-1"
              >
                <CCardText className="m-0">
                  <b>Annual Income:</b>
                </CCardText>
                <CCardText>{showData.annualIncome}</CCardText>
              </CContainer>
            </CCardBody>
          </CCard>
        </CModalBody>
        <CModalFooter>
          <CButton
            className="text-white"
            color="secondary"
            onClick={() => {
              setAccept("");
              setVisible(false);
            }}
          >
            Close
          </CButton>
          <CButton
            color="success"
            className="text-white"
            onClick={() => {
              onClickHandler();
              setAccept("");
              setVisible(false);
              
            }}
          >
            {/* later change this to onSubmit and revmove setVisible */}
            Approve
          </CButton>
        </CModalFooter>
      </CModal>
    );
  } else if (accept === "reject") {
    return (
      <>
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => {
            setAccept("");
            setVisible(false);
            setShowData({});
          }}
        >
          <CModalHeader>
            <CModalTitle>Reject Member</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>
              <span>Are you sure you want to reject this member request?</span>
            </p>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setAccept("");
                setVisible(false);
              }}
            >
              Close
            </CButton>
            <CButton
              color="danger"
              style={{ color: "#ffffff" }}
              onClick={() => {
                onClickHandler();
                setAccept("");
                setVisible(false);
             
              }}
            >
              {/* later change this to onSubmit and revmove setVisible */}
              Reject
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default PendingMemberModal;
