import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
const AllLoanCsvDownloadDropdown = ({ handleCSVDownload }) => {
  return (
    <CContainer className="d-flex justify-content-center align-middle">
      <CDropdown direction="center shadow">
        <CDropdownToggle color="info" className="text-white">
          Download CSV
        </CDropdownToggle>
        <CDropdownMenu className="">
          <CDropdownItem
            style={{ cursor: "pointer" }}
            onClick={() => handleCSVDownload("Demand Loan")}
          >
            Demand Loan
          </CDropdownItem>
          <CDropdownItem
            style={{ cursor: "pointer" }}
            onClick={() => handleCSVDownload("Business Goods Loan")}
          >
            Business Goods Loan
          </CDropdownItem>
          <CDropdownItem
            style={{ cursor: "pointer" }}
            onClick={() => handleCSVDownload("Consumer Goods Loan")}
          >
            Consumer Goods Loan
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </CContainer>
  );
};

export default AllLoanCsvDownloadDropdown;
