import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormInput,
  CInputGroupText,
  CInputGroup,
} from "@coreui/react";
import Paginate from "./Pagination";
import Paginating from "./Paginating";
import * as XLSX from "xlsx";
import DropDownPagination from "./DropDownPaginate";
import globalContext from "../contexts/Global/globalContext.js";
import axios from "axios";
import { useState, useEffect, useContext, useMemo } from "react";
import AllLoanCsvDownloadDropdown from "./AllLoanCsvDownloadDropdown.jsx";
// const TableSearch = ({ filterValue, currentValue }) => {
//   return (
//     <CContainer fluid className="w-75">
//       <CInputGroup>
//         <CInputGroupText className="bg-info text-white">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="20"
//             height="20"
//             fill="currentColor"
//             className="bi bi-search"
//             viewBox="0 0 16 16"
//           >
//             <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
//           </svg>
//         </CInputGroupText>
//         <CFormInput
//           placeholder="Search"
//           value={currentValue}
//           onChange={filterValue}
//         />
//       </CInputGroup>
//     </CContainer>
//   );
// };

const AllLoansList = () => {
  const { authCheck, showAlert } = useContext(globalContext);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.get("api/loans/all", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(result.data.approvedLoans);
        console.log(result.data.approvedLoans);
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  }, []);

  let filterData = [...data];

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (filterData) {
      return filterData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, filterData]);

  // // to filter the data
  // function filterValue(e) {
  //   setFilter(e.target.value);
  // }

  const handleCSVDownload = (type) => {
    const filterLoanData = data.filter((item) => item.loanName === type);
    handleClick(filterLoanData, type);
  };

  const handleClick = (filterLoanData, type) => {
    let loadData = formatData(filterLoanData);
    const ws = XLSX.utils.json_to_sheet(loadData);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${type}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatData = (data) => {
    return data.map((e, i) => {
      return {
        "S.No": i + 1,
        "Membership No.": `${e.membershipNo}`,
        "Membership Name": e.memberName,
        "Loan Name": e.loanName,
        Amount: e.ammount,
        Frequency: e.frequency,
        "Disbursed Date": new Date(e.disbursedDate).toLocaleDateString(),
        "Max No. of Days": e.maxNoOfDays,
        Status: e.isCompleted ? "Close" : "Open",
      };
    });
  };

  return (
    <div style={{ height: "90vh" }}>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        All Loans List
      </p>
      <CContainer
        fluid
        className="d-flex align-items-center justify-content-around mt-4"
      >
        {/* <TableSearch filterValue={filterValue} currentValue={filter} /> */}
        <CContainer>total result : {filterData.length}</CContainer>
        <AllLoanCsvDownloadDropdown handleCSVDownload={handleCSVDownload} />
        <DropDownPagination handleItemsPerPage={setPageSize} num={pageSize} />
      </CContainer>
      <CContainer fluid className="p-4 my-2">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Membership No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Membership Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Loan Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Frequency
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Disbursed Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Max No. of Days
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Status
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {currentTableData.map((ele, i) => {
              let date = new Date(ele.disbursedDate).toLocaleDateString();
              return (
                <CTableRow key={i}>
                  <CTableDataCell>{ele.membershipNo}</CTableDataCell>
                  <CTableDataCell>{ele.memberName}</CTableDataCell>
                  <CTableDataCell>{ele.loanName}</CTableDataCell>
                  <CTableDataCell>{ele.ammount}</CTableDataCell>
                  <CTableDataCell>{ele.frequency}</CTableDataCell>
                  <CTableDataCell>{date}</CTableDataCell>
                  <CTableDataCell>{ele.maxNoOfDays}</CTableDataCell>
                  <CTableDataCell>
                    {ele.isCompleted ? "Close" : "Open"}
                  </CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
        <Paginating
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filterData ? filterData.length : ""}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </CContainer>
    </div>
  );
};

export default AllLoansList;