import { Outlet, Navigate } from "react-router-dom";
import "../App.css";
import {
    CToaster
} from "@coreui/react";
import Toast from "./Toast";
import { useContext } from "react";
import globalContext from "../contexts/Global/globalContext";



function GlobalRoute() {
    const { alert } = useContext(globalContext);
    return (
        <>
            <CToaster placement="top-start">
                {alert.map((ele, i) => {
                    return (<Toast key={i} msg={ele.msg} type={ele.type} />)
                })}
            </CToaster>
            <Outlet />
        </>
    )
}

export default GlobalRoute;
