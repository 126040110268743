import { useReducer } from "react";
import globalContext from "./globalContext";
import globalReducer from "./globalReducer";
import { SET_LOADING, SHOW_ALERT, LOGGED_IN , REMOVE_ALERT , SET_LOADING_FALSE} from "../types";

import axios from "axios";

function GlobalState(props) {
  const initialState = {
    loading: false,
    alert: [],
    status: false,
    auth: null,
  };

  const [state, dispatch] = useReducer(globalReducer, initialState);

  const setLoadingFalse = () => {
    dispatch({type : SET_LOADING_FALSE})
    return
  }

  const setLoading = () => dispatch({ type: SET_LOADING });

  const showAlert = (msg, type) => {
    dispatch({ type: SHOW_ALERT, payload: { msg, type } });
    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT, payload: {msg, type} });
    }, 5000);
  };

  const authCheck = () => {
    // function to check if user is logged in or not
    async function auth() {
      try {
        const res = await axios.get("/api/auth", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        dispatch({
          type: LOGGED_IN,
          payload: { status: true, data: res.data.payload },
        });
        
      } catch (error) {
        dispatch({ type: LOGGED_IN, payload: { status: false, data: null } });
        console.log(error.response.data);
      }
    }
    // call the function
    auth();
  };

  const loginComplete = (e) =>{
    dispatch({
      type: LOGGED_IN,
      payload: { status: true, data: e},
    })
  }

  return (
    <globalContext.Provider
      value={{
        loading: state.loading,
        alert: state.alert,
        status: state.status,
        auth: state.auth,
        authCheck,
        showAlert,
        setLoading,
        setLoadingFalse,
        loginComplete
      }}
    >
      {props.children}
    </globalContext.Provider>
  );
}

export default GlobalState;