import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../contexts/Global/globalContext";

function CreditorReq() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { alert, showAlert } = useContext(globalContext);

  const [creditorData, setCreditorData] = useState({
    creditorName: "",
    creditorType: "",
    mobileNo: "",
    address: "",
    email: "",
    dob: "",
    age: "",
    nomineeName: "",
    nomineeRel: "",
    nomineeDOB: "",
  });
  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    try {
      //Prevents Refreshing the Form
      e.preventDefault();

      let res = await axios.post("/api/creditors/", creditorData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      await showAlert("Successfully registered", "2");
      setTimeout(() => {
        navigate("/pendingCreditors");
      }, 3000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };
  const onChangeHandler = (e) => {
    if (e.target.name == "mobileNo") {
      let maxNum = 10;
      if (e.target.value.length > maxNum) {
        e.target.value = e.target.value.slice(0, maxNum);
      }
    }

    if (e.target.name == "dob") {
      let today = new Date();
      let birth = new Date(e.target.value);
      const diff = Math.abs(today - birth);

      const calcAge = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

      setCreditorData({
        ...creditorData,
        [e.target.name]: e.target.value,
        age: calcAge,
      });
    } else {
      setCreditorData({
        ...creditorData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <CContainer fluid className="p-0 m-0" style={{ minHeight: "90vh" }}>
      <CForm className="add-card mb-5" onSubmit={onSubmitHandler}>
        <p
          style={{
            background: "#2364aa",
          }}
          className="fw-bold text-white fs-2 text-center p-1"
        >
          Add Creditor Form
        </p>
        <CContainer className="form-container d-flex p-3">
          <CContainer className="form-1 m-2">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Full Name
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                name="creditorName"
                value={creditorData.creditorName}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Phone No.
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                name="mobileNo"
                value={creditorData.mobileNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            {creditorData.creditorType == "Individual" && (
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Date of Birth
                  <span className="text-danger">*</span>
                </CInputGroupText>
                <CFormInput
                  type="date"
                  placeholder="xx/xx/xxxx"
                  aria-label="DOB"
                  aria-describedby="basic-addon1"
                  required
                  name="dob"
                  value={creditorData.dob}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            )}

            <p className="mb-2 text-dark fw-bold">Address </p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Address
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="address"
                aria-label="address"
                aria-describedby="basic-addon1"
                required
                name="address"
                value={creditorData.address}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <hr />
            {creditorData.creditorType == "Individual" && (
              <span>
                <p className="mb-2 text-dark fw-bold">Nominee </p>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Full Name
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormInput
                    type="text"
                    placeholder="Name"
                    aria-label="house"
                    aria-describedby="basic-addon1"
                    required
                    name="nomineeName"
                    value={creditorData.nomineeName}
                    onChange={onChangeHandler}
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Date of Birth
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormInput
                    type="date"
                    placeholder="xx/xx/xxxx"
                    aria-label="DOB"
                    aria-describedby="basic-addon1"
                    required
                    name="nomineeDOB"
                    value={creditorData.nomineeDOB}
                    onChange={onChangeHandler}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3" >
                  <CInputGroupText id="basic-addon1">
                    Nominee Relation
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormInput
                    type="text"
                    placeholder="Relation"
                    aria-label="relation"
                    aria-describedby="basic-addon1"
                    required
                    name="nomineeRel"
                    value={creditorData.nomineeRel}
                    onChange={onChangeHandler}
                  />
                </CInputGroup>
             
              </span>
            )}

            <CContainer fluid className="d-flex justify-content-around mt-5">
              <CButton
                disabled={isSubmitting}
                type="submit"
                size="lg"
                color="success"
                className="m-2 text-white fw-bold"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </CButton>
              <CButton
                type="button"
                size="lg"
                color="danger"
                className="m-2 text-white fw-bold"
                href="/home"
              >
                Cancel
              </CButton>
            </CContainer>
          </CContainer>
          {/* div----Left-------end */}
          {/* div----right-----start */}
          <CContainer className="form-1 m-2">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" required>
                Creditor Type
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="creditorType"
                value={creditorData.creditorType}
                onChange={onChangeHandler}
                required
              >
                <option disabled selected hidden value="">
                  select
                </option>
                <option value="Individual">Individual</option>
                <option value="Assosiate Organization">
                  Assosiate Organization
                </option>

                <option value="Organization">Organization</option>
              </CFormSelect>
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Email</CInputGroupText>
              <CFormInput
                type="email"
                placeholder="example@email.com"
                aria-label="Email"
                aria-describedby="basic-addon1"
                name="email"
                value={creditorData.email}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            {creditorData.creditorType == "Individual" && (
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Age
                  <span className="text-danger">*</span>
                </CInputGroupText>
                <CFormInput
                  placeholder="xx"
                  type="number"
                  aria-label="Number"
                  aria-describedby="basic-addon1"
                  required
                  readOnly
                  name="age"
                  value={creditorData.age}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            )}
          </CContainer>
        </CContainer>
      </CForm>
    </CContainer>
  );
}

export default CreditorReq;
