import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
} from "@coreui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import MemberContext from "../contexts/MemberContext/MemberContext";
import globalContext from "../contexts/Global/globalContext";

const ViewAllMemberTable = ({ data }) => {
  const { setMemberNo, setMemberData } = useContext(MemberContext);
  const { authCheck, showAlert , } = useContext(globalContext);
  let navigate = useNavigate();

  function navigating(e, mem) {
    navigate("/editmember", { state: { e, mem } });
  }

  const onClickHandler = async (membershipNo) =>{
    try {
      Cookies.remove("memberNo");
      await setMemberNo(membershipNo);
      await setMemberData(membershipNo);
      Cookies.set("memberNo", membershipNo);
      navigate("/home");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
  }

  return (
    <CContainer fluid className="p-4 my-2">
      <CTable bordered striped className="shadow">
        <CTableHead className="bg-dark text-center align-middle">
          <CTableRow>
            {/* <CTableHeaderCell scope="col" className="text-white">
              #
            </CTableHeaderCell> */}
            <CTableHeaderCell scope="col" className="text-white">
              Member Name
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Membership No.
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Location
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Gender
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Member Type
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Mobile
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Joining Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Info
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody className="text-center align-middle">
          {data.map((e, index) => {
            return (
              <CTableRow key={index}>
                {/* <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell> */}
                <CTableDataCell
                 onClick={() => navigating(e._id, e)}
                 style={{ cursor: "pointer" }}
                >{e.pDetails.memberName}</CTableDataCell>
                <CTableDataCell onClick={()=>onClickHandler(e.membershipNo)} style={{textDecoration: "underline" , cursor: "pointer"}}>{e.membershipNo}</CTableDataCell>
                {/* <CTableDataCell>{e.address1.state} </CTableDataCell> */}
                {/* This is the Line  :) */}
                <CTableDataCell>{`${e.address.pHouseNo},${e.address.pCity},${e.address.pState}`}</CTableDataCell>
                <CTableDataCell>{e.pDetails.gender} </CTableDataCell>
                <CTableDataCell>{e.memberType}</CTableDataCell>
                <CTableDataCell>{e.mobileNo} </CTableDataCell>
                <CTableDataCell>
                  {e.joiningDate}
                </CTableDataCell>
                <CTableDataCell>
                  <CButton color="info" className="text-white" onClick={()=>onClickHandler(e.membershipNo)} >
                    View
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>
    </CContainer>
  );
};

export default ViewAllMemberTable;
