import { React, useState, useEffect, useContext } from "react";
import {
  CRow,
  CCol,
  CWidgetStatsA,
  CInputGroup,
  CInputGroupText,
  CContainer,
  CFormSelect,
  CFormInput,
  CButton,
  CForm,
} from "@coreui/react";
import { data, bankAccountsCodes, fixedAssetsCodes } from "./options";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

const BranchTransactions = () => {
  const { showAlert } = useContext(globalContext)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionType, setTransactionType] = useState();

  const [empOpt, setEmpOpt] = useState([]);
  const [reqData, setReqData] = useState({
    category: "",
    txnName: "",
    who: "",
    amount: "",
    remarks: "",
    whoId: "",
    date: new Date().toISOString().split("T")[0],
  });

  const [accountData, setAccountData] = useState({});

  let onChangeHandler = (e) => {
    e.preventDefault();
    console.log(
      bankAccountsCodes
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0]
    );

    if (e.target.name == "whoId" && reqData.category == "Expense") {
      console.log(
        bankAccountsCodes
          .filter((f) => f.value == e.target.value)
          .map((e) => e.label)[0]
      );
      let labeldata = bankAccountsCodes
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0];
      setReqData({
        ...reqData,
        whoId: e.target.value,
        who: labeldata,
        // txnName : filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    }
    else if (e.target.name == "whoId" && reqData.category == "FixedAssets") {
      console.log(
        fixedAssetsCodes
          .filter((f) => f.value == e.target.value)
          .map((e) => e.label)[0]
      );
      let labeldata = fixedAssetsCodes
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0];
      setReqData({
        ...reqData,
        whoId: e.target.value,
        who: labeldata,
        // txnName : filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    }

    else if (e.target.name == "whoId" && reqData.category == "loan") {
      console.log(
        fixedAssetsCodes
          .filter((f) => f.value == e.target.value)
          .map((e) => e.label)[0]
      );
      let labeldata = fixedAssetsCodes
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0];
      setReqData({
        ...reqData,
        whoId: e.target.value,
        who: labeldata,
        // txnName : filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    }


    else if (e.target.name == "whoId" && reqData.category == "donation") {
      console.log(
        fixedAssetsCodes
          .filter((f) => f.value == e.target.value)
          .map((e) => e.label)[0]
      );
      let labeldata = fixedAssetsCodes
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0];
      setReqData({
        ...reqData,
        whoId: e.target.value,
        who: labeldata,
        // txnName : filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    }


    else if (
      e.target.name == "whoId" &&
      reqData.category == "Cash_Collector"
    ) {
      console.log(
        empOpt.filter((f) => f.value == e.target.value).map((e) => e.label)[0]
      );
      console.log(
        empOpt.filter((f) => f.value == e.target.value).map((e) => e.label)[0]
      );
      let labeldata2 = empOpt
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0];
      setReqData({
        ...reqData,
        whoId: e.target.value,
        who: labeldata2,
        // txnName : filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name == "whoId" && reqData.category == "Advance") {
      let labeldata2 = empOpt
        .filter((f) => f.value == e.target.value)
        .map((e) => e.label)[0];
      setReqData({
        ...reqData,
        whoId: e.target.value,
        who: labeldata2,
        // txnName : filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name == "category") {
      let selectValue = e.target.value;

      let filteredData = data.filter(
        (singleData) => singleData.name === selectValue
      );
      setTransactionType(filteredData);
      setReqData({
        ...reqData,
        txnName: filteredData[0].value,
        [e.target.name]: e.target.value,
      });
    } else {
      setReqData({
        ...reqData,
        [e.target.name]: e.target.value,
      });
    }

  };

  let onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      console.log(reqData);
      let res = await axios.put(
        "/api/branchActivity/branchTransactions",
        reqData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      showAlert("Transaction Successfull","2");
      setTimeout(()=>{
        window.location.reload();
      },1000)
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    try {
      async function fetchData() {
        const response = await axios.get(
          "/api/cashCollectors/getCashCollectorsBranch",
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        setEmpOpt(response.data.Success);
      }
      async function fetchDataAccInfo() {
        const accInfo = await axios.get("/api/branchActivity/accountsInfo", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setAccountData(accInfo.data.showAccounts);
      }
      fetchData();
      fetchDataAccInfo();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Branch Transactions
      </p>
      <CContainer
        style={{
          minHeight: "85vh",
        }}
      >
        <h5>Current Balance</h5>
        <CRow>
          {accountData[0] && (
            <>
              <CCol sm={4}>
                <CWidgetStatsA
                  className="mb-4"
                  color="primary"
                  value={"₹" + accountData[0].amount.toFixed(2).toLocaleString("en-US")}
                  title="Main Cash"
                />
              </CCol>
              <CCol sm={4}>
                <CWidgetStatsA
                  className="mb-4"
                  color="info"
                  value={"₹" + accountData[1].amount.toFixed(2).toLocaleString("en-US")}
                  title="Bank Account"
                />
              </CCol>
              <CCol sm={4}>
                <CWidgetStatsA
                  className="mb-4"
                  color="warning"
                  value={"₹" + accountData[2].amount.toFixed(2).toLocaleString("en-US")}
                  title="Petty Cash"
                />
              </CCol>
              <CCol sm={4}>
                <CWidgetStatsA
                  className="mb-4"
                  color="warning"
                  value={"₹" + accountData[3].amount.toFixed(2).toLocaleString("en-US")}
                  title="Employee Advance"
                />
              </CCol>
              <CCol sm={4}>
                <CWidgetStatsA
                  className="mb-4"
                  color="danger"
                  value={"₹" + accountData[4].amount.toFixed(2).toLocaleString("en-US")}
                  title="Cash Collector"
                />
              </CCol>
              <CCol sm={4}>
                <CWidgetStatsA
                  className="mb-4"
                  color="primary"
                  value={<>₹0.00</>}
                  title="Credit Balance of Open Money"
                />
              </CCol>
            </>
          )}

          <h5>Details</h5>
          <hr />
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="account_details">
                Select Account
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                // value={}
                // onChange={}
                aria-label="Select Account"
                options={[
                  "Select Account",
                  { label: "Main Cash", value: "Main_Cash" },
                  { label: "Bank Account", value: "Bank_Account" },
                  { label: "Petty Cash", value: "Petty_Cash" },
                ]}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="account_details">
                Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx/xx/xxxx"
                type="date"
                aria-label="Number"
                aria-describedby="account_details"
                required
                name=""
              // value={}
              // onChange={}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={4}>
            <CContainer fluid className="d-flex justify-content-around">
              {/* <CButton
                type="submit"
                disabled={isSubmitting}
                color="success"
                className="text-white  "
              >
                Get PDF
              </CButton> */}
            </CContainer>
          </CCol>
        </CRow>
        <hr />
        <CForm onSubmit={onSubmitHandler}>
          <h5>Branch Transactions</h5>
          <hr />
          <CRow>
            <CCol sm={4}>
              <CInputGroup className="mb-3">
                <CInputGroupText id="transaction_type">
                  Transaction Type
                  <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
                </CInputGroupText>
                <CFormSelect
                  value={reqData.category}
                  onChange={onChangeHandler}
                  aria-label="Transaction Type"
                  name="category"
                  required
                  options={[
                    "Select Transaction Type",
                    // { label: "Loan", value: "Loan" },
                    { label: "Advance", value: "Advance" },
                    { label: "Loan", value: "loan" },
                    { label: "Donation", value: "donation" },
                    { label: "Expense", value: "Expense" },
                    { label: "Fixed Assets", value: "FixedAssets" },
                    { label: "Transfer", value: "Transfer" },
                    { label: "Cash Collector", value: "Cash_Collector" },
                  ]}
                />
              </CInputGroup>
            </CCol>
            <CCol sm={4}>
              <CInputGroup className="mb-3">
                <CInputGroupText id="account_details">
                  Transactions
                  <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
                </CInputGroupText>
                <CFormSelect
                  value={reqData.txnName}
                  name="txnName"
                  required
                  onChange={onChangeHandler}
                  options={transactionType}
                />
              </CInputGroup>
            </CCol>

            {/* TODO: CONDITIONAL RENDERING HAVE TO BE DONE */}

            {reqData.category != "Loan" &&
              reqData.category != "Donation" &&
              reqData.category != "Transfer" &&
              reqData.category != "Expense" &&
              reqData.category != "FixedAssets" &&
              reqData.category != "Cash Collector" && (
                <CCol sm={4}>
                  <CInputGroup className="mb-3">
                    <CInputGroupText id="account_details">
                      Employee
                    </CInputGroupText>
                    <CFormSelect
                      value={reqData.whoId}
                      onChange={onChangeHandler}
                      required
                      aria-label="Select Account"
                      name="whoId"
                      options={["select", ...empOpt]}
                    />
                  </CInputGroup>
                </CCol>
              )}
            {reqData.category == "Advance" && (
              <CCol sm={4}>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="account_details">
                    Date
                    <span style={{ color: "red", fontWeight: "lighter" }}>
                      *
                    </span>
                  </CInputGroupText>
                  <CFormInput
                    placeholder="xx/xx/xxxx"
                    type="date"
                    aria-label="Number"
                    aria-describedby="account_details"
                    required
                    name=""
                  // value={}
                  // onChange={}
                  />
                </CInputGroup>
              </CCol>
            )}
            {reqData.category == "Expense" && (
              <CCol sm={4}>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="account_details">
                    Expense Head
                  </CInputGroupText>
                  <CFormSelect
                    value={reqData.whoId}
                    onChange={onChangeHandler}
                    required
                    aria-label="Select Account"
                    name="whoId"
                    options={["select", ...bankAccountsCodes]}
                  />
                </CInputGroup>
              </CCol>
            )}
            {reqData.category == "FixedAssets" && (
              <CCol sm={4}>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="account_details">
                    Expense Head
                  </CInputGroupText>
                  <CFormSelect
                    value={reqData.whoId}
                    onChange={onChangeHandler}
                    required
                    aria-label="Select Account"
                    name="whoId"
                    options={["select", ...fixedAssetsCodes]}
                  />
                </CInputGroup>
              </CCol>
            )}

            <CCol sm={4}>
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">Amount</CInputGroupText>
                <CFormInput
                  type="number"
                  placeholder="Amount"
                  aria-describedby="amount"
                  required
                  name="amount"
                  value={reqData.amount}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            </CCol>

            <CCol sm={4}>
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">Remarks</CInputGroupText>
                <CFormInput
                  type="text"
                  placeholder="Remarks"
                  aria-label="Remarks"
                  aria-describedby="remarks"
                  required
                  name="remarks"
                  value={reqData.remarks}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            </CCol>
            <CCol sm={12}>
              <CContainer fluid className="d-flex justify-content-around">
                <CButton
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                  className="text-white fw-bold ps-4 pe-4  "
                >
                  {isSubmitting ? "Submitting..." : "Save"}
                </CButton>
              </CContainer>
            </CCol>
          </CRow>
        </CForm>
      </CContainer>
    </>
  );
};

export default BranchTransactions;
