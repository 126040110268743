import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DayBook = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const { showAlert } = useContext(globalContext);
  let onSubmitHandler = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      let res = await axios.get(`/api/branchActivity/dayBook/${date}`, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
          // "Content-Type": "application/json",
        },
      });
      setData(res.data.result);
      showAlert("Fetch Successfull", "2");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    // Section Title: Summary
    doc.setFontSize(14);
    doc.text("Summary", 14, 20);
    // SUMMARY
    // Simulate Summary Data
    const summaryData = [
      {
        date: date,
        balance: "Opening Balance",
        mainCash: data?.mcOpeningBalance?.toFixed(2)?.toLocaleString("en-US"),
        pettyCash: data?.pcOpeningBalance?.toFixed(2)?.toLocaleString("en-US"),
        total: (data?.mcOpeningBalance + data?.pcOpeningBalance)?.toFixed(2),
      },
      {
        date: date,
        balance: "Closing Balance",
        mainCash: data?.mcClosingBalance?.toFixed(2)?.toLocaleString("en-US"),
        pettyCash: data?.pcClosingBalance?.toFixed(2)?.toLocaleString("en-US"),
        total: (data?.mcClosingBalance + data?.pcClosingBalance)
          ?.toFixed(2)
          ?.toLocaleString("en-US"),
      },
    ];
    const summaryHead = [
      ["Date", "Balance", "Main Cash", "Petty Cash", "Total Amount"],
    ];
    const summaryBody = summaryData?.map((item) => [
      item.date,
      item.balance,
      item.mainCash,
      item.pettyCash,
      item.total,
    ]);
    doc.autoTable({
      head: summaryHead,
      body: summaryBody,
      startY: 30,
    });

    let finalY = doc.lastAutoTable.finalY || 40;
    doc.text("Details", 14, finalY + 10);

    const detailsHead = [
      ["S.No", "Account", "Particulars", "Receipt", "Payment", "Balance"],
    ];

    const detailsData = [
      {
        account: "Main Cash",
        particulars: "Opening Balance",
        receipt: "",
        payment: "",
        balance: data?.mcOpeningBalance?.toFixed(2)?.toLocaleString("en-US"),
      },
      {
        account: "Main Cash",
        particulars: "Counter Cash - Receipts",
        receipt: data?.mcReceipts?.toFixed(2)?.toLocaleString("en-US"),
        payment: "",
        balance: "",
      },
      {
        account: "Main Cash",
        particulars: "Counter Cash - Payments",
        receipt: "",
        payment: data?.mcPayments?.toFixed(2)?.toLocaleString("en-US"),
        balance: "",
      },
      {
        account: "Main Cash",
        particulars: "Adjustment",
        receipt: data?.mcAdReceipts?.toFixed(2)?.toLocaleString("en-US"),
        payment: data?.mcAdPayments?.toFixed(2)?.toLocaleString("en-US"),
        balance: "",
      },
      {
        account: "",
        particulars: "Total",
        receipt: data?.totalMCReceipts?.toFixed(2)?.toLocaleString("en-US"),
        payment: data?.totalMCPayments?.toFixed(2)?.toLocaleString("en-US"),
        balance: "",
      },
      {
        account: "",
        particulars: "Closing Balance",
        receipt: "",
        payment: "",
        balance: data?.mcClosingBalance?.toFixed(2)?.toLocaleString("en-US"),
      },
      {
        account: "",
        particulars: "",
        receipt: "",
        payment: "",
        balance: "",
      },
      {
        account: "Petty Cash",
        particulars: "Opening Balance",
        receipt: "",
        payment: "",
        balance: data?.pcOpeningBalance?.toFixed(2)?.toLocaleString("en-US"),
      },
      {
        account: "Petty Cash",
        particulars: "Petty Cash - Receipts",
        receipt: data?.pcReceipts?.toFixed(2)?.toLocaleString("en-US"),
        payment: "",
        balance: "",
      },
      {
        account: "Petty Cash",
        particulars: "Petty Cash - Payments",
        receipt: "",
        payment: data?.pcPayments?.toFixed(2)?.toLocaleString("en-US"),
        balance: "",
      },
      {
        account: "Petty Cash",
        particulars: "Adjustment",
        receipt: data?.pcAdReceipts?.toFixed(2)?.toLocaleString("en-US"),
        payment: data?.pcAdPayments?.toFixed(2)?.toLocaleString("en-US"),
        balance: "",
      },
      {
        account: "",
        particulars: "Total",
        receipt: data?.totalPCReceipts?.toFixed(2)?.toLocaleString("en-US"),
        payment: data?.totalPCPayments?.toFixed(2)?.toLocaleString("en-US"),
        balance: "",
      },
      {
        account: "",
        particulars: "Closing Balance",
        receipt: "",
        payment: "",
        balance: data?.pcClosingBalance?.toFixed(2)?.toLocaleString("en-US"),
      },
    ];

    const detailsBody = detailsData.map((item, index) => [
      index + 1,
      item.account,
      item.particulars,
      item.receipt,
      item.payment,
      item.balance,
    ]);

    doc.autoTable({
      head: detailsHead,
      body: detailsBody,
      startY: finalY + 20,
    });

    doc.save("SummaryAndDetails.pdf");
  };

  return (
    <CContainer fluid className="p-0 m-0" style={{ minHeight: "90vh" }}>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Day Book
      </p>

      <CContainer>
        <CRow>
          <h5>Report</h5>
          <hr></hr>
          <CCol sm={6}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                // max={new Date().toISOString().split("T")[0]}
                required
                name="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={6} className="pe-0">
            <CContainer fluid className="d-flex justify-content-end">
              <CButton
                type="submit"
                onClick={onSubmitHandler}
                disabled={isSubmitting}
                color="success"
                className="text-white  "
              >
                Get Details
              </CButton>
              <CButton
                type="submit"
                disabled={!data || data.length === 0}
                color="success"
                className="text-white ms-2"
                onClick={exportPDF}
              >
                PDF
              </CButton>
            </CContainer>
          </CCol>
          {/* <CCol sm={2}>
            <CContainer
              fluid
              className="d-flex justify-content-around"
            ></CContainer>
          </CCol> */}
          <h5>Summary</h5>
          <hr></hr>
        </CRow>
        {data && (
          <CTable bordered striped className="shadow">
            <CTableHead className="bg-dark text-center align-middle">
              <CTableRow>
                <CTableHeaderCell scope="col" className="text-white">
                  Date
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Balance
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Main Cash
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Petty Cash
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Total Amount
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="text-center align-middle">
              <CTableRow>
                <CTableDataCell>{date}</CTableDataCell>
                <CTableDataCell>Opening Balance</CTableDataCell>
                <CTableDataCell>
                  {data.mcOpeningBalance.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell>
                  {data.pcOpeningBalance.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell>
                  {(data.mcOpeningBalance + data.pcOpeningBalance).toFixed(2)}
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>{date}</CTableDataCell>
                <CTableDataCell>Closing Balance</CTableDataCell>
                <CTableDataCell>
                  {data.mcClosingBalance.toFixed(2).toLocaleString("en-US")}{" "}
                </CTableDataCell>
                <CTableDataCell>
                  {data.pcClosingBalance.toFixed(2).toLocaleString("en-US")}{" "}
                </CTableDataCell>
                <CTableDataCell>
                  {(data.mcClosingBalance + data.pcClosingBalance)
                    .toFixed(2)
                    .toLocaleString("en-US")}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        )}

        <h5>Details</h5>
        <hr></hr>
        {data && (
          <CTable bordered striped className="shadow">
            <CTableHead className="bg-dark text-center align-middle">
              <CTableRow>
                <CTableHeaderCell scope="col" className="text-white">
                  S.No
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Account
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Particulars
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Receipt
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Payment
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Balance
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="text-center align-middle">
              <CTableRow>
                <CTableDataCell>1</CTableDataCell>
                <CTableDataCell>Main Cash</CTableDataCell>
                <CTableDataCell>Opening Balance</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>
                  {data.mcOpeningBalance.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>2</CTableDataCell>
                <CTableDataCell>Main Cash</CTableDataCell>
                <CTableDataCell>Counter Cash - Receipts</CTableDataCell>
                <CTableDataCell>
                  {data.mcReceipts.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>3</CTableDataCell>
                <CTableDataCell>Main Cash</CTableDataCell>
                <CTableDataCell>Counter Cash - Payments</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>
                  {data.mcPayments.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>4</CTableDataCell>
                <CTableDataCell>Main Cash</CTableDataCell>
                <CTableDataCell>Adjustment</CTableDataCell>
                <CTableDataCell>
                  {data.mcAdReceipts.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell>
                  {data.mcAdPayments.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>5</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>Total</CTableDataCell>
                <CTableDataCell>
                  {data.totalMCReceipts.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell>
                  {data.totalMCPayments.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>6</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>Closing Balance</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>
                  {data.mcClosingBalance.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>7</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>8</CTableDataCell>
                <CTableDataCell>Petty Cash</CTableDataCell>
                <CTableDataCell>Opening Balance</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>
                  {data.pcOpeningBalance.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>9</CTableDataCell>
                <CTableDataCell>Petty Cash</CTableDataCell>
                <CTableDataCell>Petty Cash - Receipts</CTableDataCell>
                <CTableDataCell>
                  {data.pcReceipts.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>10</CTableDataCell>
                <CTableDataCell>Petty Cash</CTableDataCell>
                <CTableDataCell>Petty Cash - Payments</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>
                  {data.pcPayments.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>11</CTableDataCell>
                <CTableDataCell>Petty Cash</CTableDataCell>
                <CTableDataCell>Adjustment</CTableDataCell>
                <CTableDataCell>
                  {data.pcAdReceipts.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell>
                  {data.pcAdPayments.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>12</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>Total</CTableDataCell>
                <CTableDataCell>
                  {data.totalPCReceipts.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell>
                  {data.totalPCPayments.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
                <CTableDataCell></CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>13</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>Closing Balance</CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell></CTableDataCell>
                <CTableDataCell>
                  {data.pcClosingBalance.toFixed(2).toLocaleString("en-US")}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        )}
      </CContainer>
    </CContainer>
  );
};

export default DayBook;
