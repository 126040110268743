import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CForm,
  CFormSelect,
} from "@coreui/react";
import { useState, useContext } from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

function CreditorRecieveModal({ visible1, setVisible1, flag, setFlag, data }) {

  const { authCheck, showAlert , } = useContext(globalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [creditorData, setCreditorData] = useState({
    creditAmount: "",
    paymentMode: "",
    dueDate: "",
  });

  const onChangeHandler = (e) => {
    setCreditorData({
      ...creditorData,
      [e.target.name]: e.target.value,
    });
  };

  async function onSubmitHandler(e) {
    setIsSubmitting(true);
    try {
      e.preventDefault();

      let res = await axios.post(
        `/api/creditors/receive/${data.creditorID}`,
        creditorData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      showAlert("Successfully Recieved Amount" , "2");
      setVisible1(!visible1);
      setFlag(!flag);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }    }
      setIsSubmitting(false);
  }

  return (
    <CModal
      visible={visible1}
      onClose={() => {
        setFlag(!flag);
        setVisible1(false);
      }}
    >
      <CModalHeader onClose={() => setVisible1(!visible1)}>
        <CModalTitle>Recieve From Creditor</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm
          className="form-container form-control-plaintext d-flex p-6 shadow-none flex-column  align-items-center"
          onSubmit={onSubmitHandler}
        >
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon2" className="w-50">
              Amount to Pay
            </CInputGroupText>

            <CFormInput
              placeholder="Amount"
              aria-describedby="basic-addon1"
              required
              type="number"
              name="creditAmount"
              value={creditorData.creditAmount}
              onChange={onChangeHandler}
            />
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon2" className="w-50">
              Payment Type
            </CInputGroupText>
            <CFormSelect
              size="sm"
              name="paymentMode"
              value={creditorData.paymentMode}
              onChange={onChangeHandler}
              required
            >
              <option disabled hidden value="">
                select
              </option>
              <option value="Cash">Cash</option>
              <option value="Bank">Bank</option>
            </CFormSelect>
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon2" className="w-50">
              Transaction Date
            </CInputGroupText>
            <CFormInput value={new Date().toLocaleString()} />
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon2" className="w-50">
              Due Date
            </CInputGroupText>
            <CFormInput
                type="date"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                name="dueDate"
                value={creditorData.dueDate}
                onChange={onChangeHandler}/>
          </CInputGroup>

          <CButton type="submit" disabled={isSubmitting} color="success" className="w-25 text-white">
          {isSubmitting ? 'Submitting...' : 'Recieve'}
          </CButton>
        </CForm>
      </CModalBody>
    </CModal>
  );
}

export default CreditorRecieveModal;
