import { Outlet, Navigate } from "react-router-dom";
import Main from "./Main";
import { useContext } from "react";
import globalContext from "../contexts/Global/globalContext";



function PrivateRoutes() {
    const { authCheck, status } = useContext(globalContext);
  let auth = localStorage.getItem("token");
  return auth ? (
    <>
      <Main />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoutes;
