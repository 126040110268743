import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CFormSelect,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Adjustmentm from "../Modal/Adjustmentm";
import globalContext from "../../contexts/Global/globalContext";
const AdjustmentEntry = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showAlert } = useContext(globalContext);
  const [ledgerData, setLedgerData] = useState(null);
  const [empOpt, setEmpOpt] = useState([]);
  const [reqData, setReqData] = useState({
    id: "",
    fromReqDate: new Date().toISOString().split("T")[0],
  });

  let onChangeHandler = (e) => {
   
    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  let onSubmitHandler = async (e) => {
  
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let res = await axios.get(
        `/api/branchActivity/getAdjustments/${reqData.id}/:${reqData.fromReqDate}`,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
            // "Content-Type": "application/json",
          },
        }
      );
      setLedgerData(res.data.mapping);
      showAlert("Adjustments fetched", "2")
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    try {
      async function fetchData() {
        const response = await axios.get(
          "api/cashCollectors/getCashCollectorsAndBranch",
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        
        setEmpOpt(response.data.Success);
      }
      fetchData();
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  }, []);
  return (
    <CContainer fluid className="p-0 m-0" style={{
      minHeight: "90vh"
    }}>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Adjustment
      </p>

      <CContainer>
        <hr></hr>
        <h5>Adjustment</h5>
        <CRow>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText
                id="employee_details"
                className="fw-bold text-black"
              >
                Employee
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormSelect
                value={reqData.id}
                onChange={onChangeHandler}
                aria-label="Employee"
                name="id"
                options={["select", ...empOpt]}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText
                id="employee_details"
                className="fw-bold text-black"
              >
                Date
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx/xx/xxxx"
                type="date"
                aria-label="Number"
                aria-describedby="employee_details"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButton
                // type="submit"
                onClick={onSubmitHandler}
                disabled={isSubmitting}
                color="success"
                className="text-white "
              >
                {isSubmitting ? "Processing..." : "Get Details"}
              </CButton>
            </CContainer>
          </CCol>
        </CRow>

        <hr></hr>

        {ledgerData && (
          <>
            <h5>Details</h5>
            <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    Date
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Voucher No.
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Member Name
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Product Name
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Amount
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Remarks
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Payment Type
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Adjust
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text-center align-middle">
                {ledgerData &&
                  ledgerData.map((ele, i) => {
                    return (
                      <CTableRow key={i}>
                        <CTableDataCell>
                          {new Date(ele.Date).toLocaleDateString()}
                        </CTableDataCell>

                        <CTableDataCell>{ele.VoucherNo}</CTableDataCell>
                        <CTableDataCell>{ele.MembershipNo}</CTableDataCell>
                        <CTableDataCell>{ele.ProductName}</CTableDataCell>
                        <CTableDataCell>{ele.amount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{ele.Remarks}</CTableDataCell>
                        <CTableDataCell>{ele.paymentType}</CTableDataCell>
                        <CTableDataCell>
                          <Adjustmentm data={ele} />
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })}
              </CTableBody>
            </CTable>
          </>
        )}
      </CContainer>
    </CContainer>
  );
};

export default AdjustmentEntry;
