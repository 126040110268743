import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";

const LastActivity = ({ data }) => {


  return (
    <CContainer fluid className="p-4 mt-1">
      <CTable bordered striped className="shadow">
        <CTableHead className="bg-secondary text-center">
          <CTableRow>

            <CTableHeaderCell scope="col" className="text-white">
              Voucher No.
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Account Name
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Credit Amount
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Debit Amount
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Balance Amount
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Transaction Date
            </CTableHeaderCell>

          </CTableRow>
        </CTableHead>
        <CTableBody className="text-center">
          {data.transactionDetails.slice(0).reverse().map((ele,i)=>{

            return (
               <CTableRow key={i}>
            <CTableDataCell scope="row">{ele.voucherNo}</CTableDataCell>
            <CTableDataCell>{ele.accountName}</CTableDataCell>
            <CTableDataCell>{ele.creditAmount}</CTableDataCell>
            <CTableDataCell>{ele.debitAmount}</CTableDataCell>
            <CTableDataCell>{ele.balanceAmount}</CTableDataCell>
            <CTableDataCell>{(new Date(ele.transactionDate)).toLocaleDateString()}</CTableDataCell>
          </CTableRow>
            )
          })}

        </CTableBody>
      </CTable>
    </CContainer>
  );
};

export default LastActivity;
