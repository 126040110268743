import React from "react";
import {
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CForm,
  CContainer,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext, useState } from "react";
import globalContext from "../contexts/Global/globalContext";
import Cookies from "js-cookie"

// TODO : Change the divs to CContainer and use bootstrap instead of css
function Login() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { alert, showAlert , loginComplete} = useContext(globalContext);

  let navigate = useNavigate();
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });

  const onChangeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);

    try {
      //Prevents Refreshing the Form
      e.preventDefault();
      
      let res = await axios.post("/api/login", userData);
      localStorage.setItem(
        "token",
        JSON.stringify({ token: res.data.token, role: res.data.role })
      );
      if (res.data.role) {
        loginComplete(res.data.payload)
        Cookies.set("role",res.data.role)
        Cookies.remove(`memberData`)
        Cookies.remove(`memberNo`)
        Cookies.set("username" , res.data.payload.username)
        Cookies.set("branchName",res.data.payload.branchName)
        // Cookies.set("members",JSON.stringify(res.data.members))
        localStorage.setItem("members", JSON.stringify(res.data.members));
        navigate("/home");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
    setIsSubmitting(false);
  };

  return (
    <CContainer fluid className="p-0">

      <div className="login-flex background-radial-gradient" style={{ minHeight: "95vh" }}>
        <div className="text-login">
          <h1 id="logo" style={{ color: "hsl(218, 81%, 95%)" }}>
            {" "}
            SAHULAT
            <br />
            <span style={{ color: "hsl(218, 81%, 75%)" }}>Micro-Finance</span>
          </h1>
          <p style={{ color: "hsl(218, 81%, 85%)" }}>
            SAHULAT MICROFINANCE SOCIETY (Regd. under the Societies Registration
            Act, 1860) is a voluntary non-political, non-profit making social
            service organization established in 2010. Sahulat was formed for the
            sole purpose of promoting interest-free microfinance (IFMF)
            activities in India. It establishes IFMF institutions under credit
            cooperative format as a sector. Currently there are 42 legal
            entities and 87 branches who are affiliated with Sahulat through a
            memorandum of cooperation. These legal entities are registered under
            various cooperative acts enacted by state and central government.
          </p>
        </div>

        <div className="form-card">
          <h3
            style={{
              color: "hsl(218, 81%, 75%)",
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "38px",
            }}
          >
            Login
          </h3>

          <CForm onSubmit={onSubmitHandler}>
            <CInputGroup className="login-form " style={{width:"90%"}}>
              <CInputGroupText
                style={{
                  border: "black 1px solid",
                  borderRadius: "8px 0px 0px 8px ",
                }}
                id="basic-addon1"
              >
                👤
              </CInputGroupText>
              <CFormInput
                style={{
                  border: "black 1px solid",
                  borderRadius: "0px 8px 8px 0px",
                  width: "100%",
                }}
                id="floatingInputInvalid"
                placeholder="User name"
                floatingLabel="User Name"
                name="username"
                autoComplete="off"
                value={userData.username}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="login-form" style={{width:"90%"}}>
              <CInputGroupText
                style={{
                  border: "black 1px solid",
                  borderRadius: "8px 0px 0px 8px",
                }}
                id="basic-addon1"
              >
                🔑
              </CInputGroupText>
              <CFormInput
                style={{
                  border: "black 1px solid",
                  borderRadius: "0px 8px 8px 0px",
                  width: "100%",
                }}
                id="floatingInputInvalid"
                placeholder="Password"
                floatingLabel="Password"
                type="password"
                name="password"
                autoComplete="off"
                value={userData.password}
                onChange={onChangeHandler}
              />
              <br />
            </CInputGroup>
            <center>
              {" "}
              <CButton
              disabled={isSubmitting}
                component="input"
                type="submit"
                style={{
                  background: "#2364aa",
                  margin: "0px 0px 0px 0px",
                  padding: "8px 30px",
                  borderRadius: "8px",
                  fontWeight: "bolder",
                }}
                value={isSubmitting ? 'Authenticating...' : 'Login'}
              />
            </center>
          </CForm>
          <div
            id="radius-shape-1"
            className="position-absolute rounded-circle shadow-5-strong"
          ></div>
          <div
            id="radius-shape-2"
            className="position-absolute shadow-5-strong"
          ></div>
        </div>
      </div>
    </CContainer>
  );
}

export default Login;
