import React, { useContext, useState } from "react";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";

import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";
import { useNavigate } from "react-router-dom";

const Wmodal = ({ setwModalOpen, WmodalOpen, membershipNo, type }) => {
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const [isLoading, setIsLoading] = useState(false);
  async function MemberShipFeePay() {
    setIsLoading(true);
    try {
      let res = await axios.patch(
        `/api/member/${type}/${membershipNo}`,
        membershipNo,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      showAlert("Payment Successfull", "2");
      setwModalOpen(false);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  return (
    <>
      <CModal
        className="modal-m"
        visible={WmodalOpen}
        onClose={() => setwModalOpen(false)}
      >
        <CModalHeader>
          <CModalTitle>Payment Confirmation</CModalTitle>
        </CModalHeader>
        <CModalBody className="mt-5 text-center ">
          Do you want to continue with the payment?
        </CModalBody>
        <CModalBody className="d-flex justify-content-center align-items-center">
          <CButton
            color="success"
            variant="outline"
            className="mb-5"
            onClick={() => MemberShipFeePay()}
            disabled={isLoading}
          >
            Pay
          </CButton>
          <CButton
            // style={{ marginLeft: "10px" }}
            className="mb-5 ms-2"
            color="danger"
            variant="outline"
            onClick={() => setwModalOpen(false)}
          >
            Cancel
          </CButton>
        </CModalBody>
      </CModal>
    </>
  );
};

export default Wmodal;
