import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
} from "@coreui/react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

import globalContext from "../../contexts/Global/globalContext";

const NewExpenseRequest = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { auth, showAlert } = useContext(globalContext);

  useEffect(() => {
    try {
      const getData = async () => {
        const res = await axios.get(
          `/api/branchActivity/allExpenseRequests`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        setData(res.data.success);
        return;
      };
      getData();
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
      return;
    }
  }, []);

  async function approveExpense(details) {
    try {
      // For amount below 5000, only manage and committee can approve
      if (Number(details.amount) > 20000) {
        // Checking role of the user
        if (auth.role === "admin") {
          const res = await axios.post(
            "/api/branchActivity/expenseRequest/approve",
            details,
            {
              headers: {
                "auth-token": JSON.parse(localStorage.getItem("token")).token,
              },
            }
          );
          showAlert("Request Approved","2")
          setTimeout(()=>{
            navigate(0);
          },1000)
        } else {
          return showAlert("You are not authorized to approve this expense","3");
        }
      }
      else if (Number(details.amount) > 2000) {
        // For amount above 5000, only committee can approve
        if (auth.role === "committee") {
          const res = await axios.post(
            "/api/branchActivity/expenseRequest/approve",
            details,
            {
              headers: {
                "auth-token": JSON.parse(localStorage.getItem("token")).token,
              },
            }
          );
          showAlert("Request Approved","2")
          setTimeout(()=>{
            navigate(0);
          },1000)
        } else {
          return showAlert("You are not authorized to approve this expense","3");
        }
      }

      else if (Number(details.amount) > 1000) {
        // For amount above 5000, only committee can approve
        if (auth.role === "manager") {
          const res = await axios.post(
            "api/branchActivity/expenseRequest/approve",
            details,
            {
              headers: {
                "auth-token": JSON.parse(localStorage.getItem("token")).token,
              },
            }
          );
          showAlert("Request Approved","2")
          setTimeout(()=>{
            navigate(0);
          },1000)
        } else {
          return alert("You are not authorized to approve this expense");
        }
      }

    } catch (e) {
      console.log(e);
      return;
    }
  }

  async function rejectExpense(details) {
    try {
      if (Number(details.amount) <= 5000) {
        // Checking role of the user
        if (auth.role === "manager" || auth.role === "committee") {
          const res = await axios.post(
            "api/branchActivity/expenseRequest/reject",
            details,
            {
              headers: {
                "auth-token": JSON.parse(localStorage.getItem("token")).token,
              },
            }
          );
          showAlert("Request Rejected","1")
          setTimeout(()=>{
            navigate(0);
          },1000)
        } else {
          return showAlert("You are not authorized to reject this expense","3");
        }
      } else {
        // For amount above 5000, only committee can approve
        if (auth.role === "committee") {
          const res = await axios.post(
            "api/branchActivity/expenseRequest/reject",
            details,
            {
              headers: {
                "auth-token": JSON.parse(localStorage.getItem("token")).token,
              },
            }
          );
          showAlert("Request Approved","1")
          setTimeout(()=>{
            navigate(0);
          },1000)
        } else {
          return showAlert("You are not authorized to reject this expense","3");
        }
      }
    } catch (e) {
      console.log(e);
      return;
    }
  }
  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        New Expense Request
      </p>
      <CContainer fluid className="mt-3 px-5" style={{ minHeight: "80vh" }}>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Transaction
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Expense Head
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Status
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Requested Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Action Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Action
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data &&
              data.map((item, index) => (
                <CTableRow key={index}>
                  <CTableDataCell>Expense</CTableDataCell>
                  <CTableDataCell>{item.expenseHead}</CTableDataCell>
                  <CTableDataCell>{Number(item.amount).toFixed(2)}</CTableDataCell>
                  <CTableDataCell>{item.status}</CTableDataCell>
                  <CTableDataCell>
                    {new Date(item.date).toDateString()}
                  </CTableDataCell>
                  <CTableDataCell>
                    {item.approvedDate
                      ? new Date(item.approvedDate).toDateString()
                      : "-"}
                  </CTableDataCell>
                  <CTableDataCell
                    style={{
                      width: "15%",
                    }}
                  >
                    {item.status !== "Pending" ? (
                      item.status
                    ) : (
                      <CContainer
                        fluid
                        className="d-flex justify-content-around p-0 m-0"
                      >
                        <CButton
                          color="success"
                          variant="outline"
                          onClick={() => approveExpense(item)}
                        >
                          Approve
                        </CButton>
                        <CButton
                          color="danger"
                          variant="outline"
                          onClick={() => rejectExpense(item)}
                        >
                          Reject
                        </CButton>
                      </CContainer>
                    )}
                  </CTableDataCell>
                </CTableRow>
              ))}
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default NewExpenseRequest;
