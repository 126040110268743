import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import { useContext} from "react";
import axios from "axios";
import globalContext from "../contexts/Global/globalContext";



function PendingLoansModal({
  visible,
  setVisible,
  showData,
  setShowData,
  accept,
  setAccept,
}) {
  const { alert, showAlert } = useContext(globalContext);

  const onClickHandler = async () => {
    try {
      let reqData = {
        accept: accept,
      };
      let res = await axios.put(`/api/loans/decision/${showData.membershipNo}/${showData.loanId}`, reqData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (accept === "accept") {
        showAlert("Successfully Approved Loan" , "2");

      } else {
        showAlert("Successfully Rejected Loan" , "2");

      }
      setTimeout(()=>{
        window.location.reload()
      },1000)

    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
  };


  if (accept === "accept") {
    return (
      <CModal
        alignment="center"
        className="modal-m"
        visible={visible}
        onClose={() => {
          setAccept("");
          setVisible(false);
        }}
      >
        <CModalHeader className="bg-success">
          <CModalTitle className="text-white">Approve Loan</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>
            <span>Are you sure you want to approve this loan request?</span>
          </p>
        </CModalBody>
        <CModalFooter>
          <CButton
            className="text-white"
            color="secondary"
            onClick={() => {
              setAccept("");
              setVisible(false);
            }}
          >
            Close
          </CButton>
          <CButton
            color="success"
            className="text-white"
            onClick={() => {
              onClickHandler();
              setAccept("");
              setVisible(false);

            }}
          >
            {/* later change this to onSubmit and revmove setVisible */}
            Approve
          </CButton>
        </CModalFooter>
      </CModal>
    );
  } else if (accept === "reject") {
    return (
      <>
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => {
            setAccept("");
            setVisible(false);
          }}
        >
          <CModalHeader>
            <CModalTitle>Reject Member</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>
              <span>Are you sure you want to reject this member request?</span>
            </p>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setAccept("");
                setVisible(false);
              }}
            >
              Close
            </CButton>
            <CButton
              color="danger"
              style={{ color: "#ffffff" }}
              onClick={() => {
                onClickHandler();
                setAccept("");
                setVisible(false);

              }}
            >
              Reject
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }

}

export default PendingLoansModal