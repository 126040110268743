import { CListGroup, CListGroupItem, CContainer } from "@coreui/react";


// TODO: Send props to this component to render the actual data
// const CreditorListGroup = ({ data }) => {
const CreditorListGroup = ({ data }) => {
  // const [bussiness, setBusiness] = useState(false);
  // useEffect(() => {
  //   if (data) {
  //     if (data.pDetails.occupation === "bussiness") setBusiness(true);
  //   }
  // }, []);

  return (
    <>
      <CListGroup>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Age</b>
            </div>
            {/* <div>{data.pDetails.fatherHusbandName}</div> */}
            <div>18</div>
          </CContainer>
        </CListGroupItem>

        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Data of Birth</b>
            </div>
            <div>some birth</div>
            {/* <div>{new Date(data.pDetails.dob).toLocaleDateString()}</div> */}
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Address</b>
            </div>
            <div>some address</div>
            {/* <div>{`${data.pAddress.pHouseNo} , ${data.pAddress.pStreet} , ${data.pAddress.pCity} , ${data.pAddress.pState}`}</div> */}
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Nominee Name</b>
            </div>
            <div>Random name</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Nominee Relation</b>
            </div>
            <div>Some relation</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Nominee Address</b>
            </div>
            <div>some address</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Nominee Date of Birth</b>
            </div>
            <div>some dob</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Nominee Aadhar / Passport No</b>
            </div>
            <div>some number again</div>
            {/* <div>{data.requestedDate}</div> */}
          </CContainer>
        </CListGroupItem>
      </CListGroup>
    </>
  );
};

export default CreditorListGroup;