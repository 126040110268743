import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";
import * as XLSX from "xlsx";

const AccountBalances = () => {
  const names = [
    {
      category: "Current Asset",
      name: "Demand Loan",
      accName: "Qard-e-Hasna",
      accNo: 10101,
    },
    {
      category: "Current Asset",
      name: "Consumer Goods Loan",
      accName: "Murabaha Consumer Loan",
      accNo: 10102,
    },
    {
      category: "Current Asset",
      accName: "Air Cooler Loan",
      name: "Air Cooler Loan",
      accNo: 10104,
    },
    {
      category: "Current Asset",
      accName: "Special Qarz Loan",
      name: "Special Qarz Loan",
      accNo: 10105,
    },
    {
      category: "Current Asset",
      accName: "Virtual Bank Account",
      name: "Virtual Bank Account",
      accNo: 30110,
    },
    {
      category: "Current Asset",
      name: "Business Goods Loan",
      accName: "Murabaha Business Loan",
      accNo: 10103,
    },
    {
      category: "Current Asset",
      name: "Main Cash",
      accName: "Main Cash",
      accNo: 30101,
    },
    {
      category: "Current Asset",
      name: "Branch Bank Account",
      accName: "CASH IN BANKS",
      accNo: 30102,
    },
    {
      category: "Current Asset",
      name: "Petty Cash",
      accName: "Petty Cash",
      accNo: 30103,
    },
    {
      category: "Current Asset",
      name: "Advance Salary",
      accName: "Advance Salary",
      accNo: 30104,
    },
    {
      category: "Current Asset",
      name: "Cash Collector",
      accName: "Cash Collector",
      accNo: 30105,
    },
    {
      category: "Non-Current Asset",
      name: "DEPOSIT FOR LOCKER",
      accName: "DEPOSIT FOR LOCKER A/c",
      accNo: 30107,
    },
    {
      category: "Non-Current Asset",
      name: "Rental Deposit",
      accName: "RENTAL DEPOSIT A/c",
      accNo: 30108,
    },
    {
      category: "Liability",
      name: "Daily Deposit",
      accName: "Daily Deposit",
      accNo: 20101,
    },
    {
      category: "Liability",
      name: "Savings Deposit",
      accName: "Savings Deposit",
      accNo: 20102,
    },
    {
      category: "Liability",
      name: "Term Deposit",
      accName: "Term Deposit",
      accNo: 20103,
    },
    {
      category: "Liability",
      name: "Umrah Deposits-Members",
      accName: "Umrah Deposits-Members",
      accNo: 20104,
    },
    {
      category: "Liability",
      name: "Share Certificate Fee",
      accName: "Share Capital",
      accNo: 50103,
    },
    {
      category: "Income",
      name: "Membership Fee",
      accName: "Income- Membership Fee",
      accNo: 50101,
    },
    {
      category: "Income",
      name: "Annual Fee",
      accName: "Income- Yearly Fee",
      accNo: 50102,
    },
    {
      category: "Income",
      name: "INCOME FROM DONATIONS A/c",
      accName: "INCOME FROM DONATION A/c",
      accNo: 50107,
    },
    {
      category: "Income",
      name: "Demand Loan Service Charge",
      accName: "Income- Qard-e-Hasna",
      accNo: 60101,
    },
    {
      category: "Income",
      name: "Consumer Goods Loan Service Charge",
      accName: "Income- Murabaha Consumer Loan",
      accNo: 60102,
    },
    {
      category: "Income",
      name: "Business Goods Loan Service Charge",
      accName: "Income- Murabaha Business Loan",
      accNo: 60103,
    },
    {
      category: "Liability",
      name: "Loans Payable",
      accName: "LOANS PAYABLE A/c",
      accNo: 20114,
    },
    {
      category: "Non-Current Asset",
      name: "Loans Receivable",
      accName: "LOANS RECEIVABLE A/c",
      accNo: 30106,
    },
    {
      category: "Expense Account",
      accName: "Expense Accounts",
      name: "Expense Accounts",
      accNo: 701,
    },
    {
      category: "Expenditure",
      accName: "Hypto Charges",
      name: "Hypto Charges",
      accNo: 70123,
    },
    {
      category: "Expenditure",
      name: "Salaries , Allowances",
      accName: "Salaries & Allowances",
      accNo: 70101,
    },
    {
      category: "Expenditure",
      name: "Employee Benefits",
      accName: "Employee Benefits",
      accNo: 70102,
    },
    {
      category: "Expenditure",
      name: "Medical Expenses",
      accName: "Medical Expenses",
      accNo: 70103,
    },
    {
      category: "Expenditure",
      name: "Office Expenses",
      accName: "Office Expenses",
      accNo: 70104,
    },
    {
      category: "Expenditure",
      name: "Staff Accommodation",
      accName: "Staff Accommodation",
      accNo: 70105,
    },
    {
      category: "Expenditure",
      name: "Telephone & Internet",
      accName: "Communication Charges",
      accNo: 70106,
    },
    {
      category: "Expenditure",
      name: "TA, DA & Fuel",
      accName: "TA, DA & Fuel Charges",
      accNo: 70107,
    },
    {
      category: "Expenditure",
      name: "Electricity Charges",
      accName: "Utility Charges",
      accNo: 70108,
    },
    {
      category: "Expenditure",
      name: "Office Rental",
      accName: "Office Rent",
      accNo: 70109,
    },
    {
      category: "Expenditure",
      name: "Operating Expenses",
      accName: "Operating Expenses",
      accNo: 70110,
    },
    {
      category: "Expenditure",
      name: "Bank Charges",
      accName: "Bank Charges",
      accNo: 70112,
    },
    {
      category: "Expenditure",
      name: "Misc. Expenses",
      accName: "Miscellaneous Expenses",
      accNo: 70113,
    },
    {
      category: "Expenditure",
      name: "Printing & Stationary",
      accName: "Printing & Stationery Charges",
      accNo: 70116,
    },
    {
      category: "Expenditure",
      name: "Suspense",
      accName: "Suspense Account",
      accNo: 70117,
    },
    {
      category: "Expenditure",
      name: "Management Fee",
      accName: "Management Fee",
      accNo: 70118,
    },
    {
      category: "Expenditure",
      name: "TDS",
      accName: "TDS",
      accNo: 70119,
    },
    {
      category: "Expenditure",
      name: "DONATION A/c",
      accName: "DONATION A/c",
      accNo: 70120,
    },
    {
      category: "Expenditure",
      name: "Depreciation Expenses",
      accName: "DEPRECIATION EXPENSES A/c",
      accNo: 70122,
    },
    {
      category: "Fixed Assets",
      name: "Furniture & Fixtures",
      accName: "FURNITURE & FIXTURES A/c",
      accNo: 80101,
    },
    {
      category: "Fixed Assets",
      name: "Office Appliance",
      accName: "OFFICE APPLIANCE A/c",
      accNo: 80102,
    },
    {
      category: "Fixed Assets",
      name: "Office Equipment",
      accName: "OFFICE EQUIPMENT A/c",
      accNo: 80103,
    },
    {
      category: "Fixed Assets",
      name: "Computer",
      accName: "COMPUTER A/c",
      accNo: 80104,
    },
    {
      category: "Fixed Assets",
      name: "Inverter",
      accName: "INVERTER A/c",
      accNo: 80105,
    },
    {
      category: "Fixed Assets",
      name: "Electrical Goods",
      accName: "ELECTRICAL GOODS A/c",
      accNo: 80106,
    },
    {
      category: "Fixed Assets",
      name: "Land & Building",
      accName: "LAND & BUILDING A/c",
      accNo: 80107,
    },
    {
      category: "Fixed Assets",
      name: "Plant & Machinery",
      accName: "PLANT & MACHINERY A/c",
      accNo: 80108,
    },
  ];
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  const [credit, setCredit] = useState(null);
  const [debit, setDebit] = useState(null);
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });

  let onSubmitHandler = () => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `api/accounts/accountBalances/${reqData.fromReqDate}/${reqData.toReqDate}`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );

        let bal = res.data.balances;
        let newData = [];
        names.forEach((e) => {
          let temp = bal.find((ele) => ele.accName === e.accName);
          newData.push(temp);
        });

        setDebit(res.data.totalDebitSide);
        setCredit(res.data.totalCreditSide);
        setData(newData);
      } catch (error) {
        // console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  };

  let onChangeHandler = (e) => {
    // e.preventDefault();

    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let newData = formatData(names);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `AccountBalance.csv`);
  };

  const formatData = (namesData) => {
    return namesData.map((e, i) => {
      return {
        "S.No": i + 1,
        Type: e.category,
        AccountName: e.name,
        AccountNo: e.accNo,
        Name: e.accName,
        Debit: data[i].debitSide.toFixed(2).toLocaleString("en-US"),
        Credit: data[i].creditSide.toFixed(2).toLocaleString("en-US"),
      };
    });
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Account Balances
      </p>
      <CContainer
        style={{
          minHeight: "80vh",
        }}
      >
        <CRow className="mt-5">
          <CCol sm={4}>
            <CInputGroup className="mb-5">
              <CInputGroupText id="From_date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="To_date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={reqData.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={3}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white"
                  color="success"
                  onClick={onSubmitHandler}
                >
                  Get Details
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                  disabled={data && data.length === 0}
                >
                  Export To CSV
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <h5>Balance Sheet </h5>
        <hr></hr>

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                AccountName
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                AccountNo{" "}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Debit
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Credit
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data
              ? names.map((e, i) => (
                  <>
                    <CTableRow key={i}>
                      <CTableDataCell>{e.category}</CTableDataCell>
                      <CTableDataCell>{e.name}</CTableDataCell>
                      <CTableDataCell>{e.accNo}</CTableDataCell>
                      <CTableDataCell>{e.accName}</CTableDataCell>
                      <CTableDataCell>
                        {data[i].debitSide.toFixed(2).toLocaleString("en-US")}
                      </CTableDataCell>
                      <CTableDataCell>
                        {data[i].creditSide.toFixed(2).toLocaleString("en-US")}
                      </CTableDataCell>
                    </CTableRow>
                  </>
                ))
              : ""}
            {data ? (
              <>
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell>
                    {debit.toFixed(2).toLocaleString("en-US")}
                  </CTableDataCell>
                  <CTableDataCell>
                    {credit.toFixed(2).toLocaleString("en-US")}
                  </CTableDataCell>
                </CTableRow>
              </>
            ) : (
              ""
            )}
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default AccountBalances;
