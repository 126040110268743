import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";
import axios from "axios";
import { useState, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const OpeningBalances = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  // const [otherAmount, setOtherAmount] = useState(null);
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });
  let onSubmitHandler = () => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `api/accounts/openingBalances/${reqData.fromReqDate}/${reqData.toReqDate}`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        setData(res.data.openingBalances);
      } catch (error) {
        // console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  };

  let onChangeHandler = (e) => {
    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let newData = formatData(data);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `OpeningBalances.csv`);
  };

  function formatData(data) {
    return data?.map((item) => ({
      Date: new Date(item.date).toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      Balance: "Opening Balance", // Assuming this is static as shown in your JSX
      "Main Cash": item.mainCash.toFixed(2), // Ensures two decimal places
      "Petty Cash": item.pettyCash.toFixed(2),
      Bank: item.bankAccount.toFixed(2),
      "Total Amount": item.totalAmount.toFixed(2),
    }));
  }

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text(`Summary`, 14, 16);

    if (data && data.length > 0) {
      doc.autoTable({
        head: [
          [
            "Date",
            "Balance",
            "Main Cash",
            "Petty Cash",
            "Bank",
            "Total Amount",
          ],
        ],
        body: data.map((ele, i) => [
          new Date(ele.date).toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
          "Opening Balance", // Assuming this is static for opening balance
          ele.mainCash,
          ele.pettyCash,
          ele.bankAccount,
          ele.totalAmount,
        ]),
        startY: 30,
      });
    }

    doc.save("OpeningBalance.pdf");
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Opening Balances
      </p>
      <CContainer>
        <CRow>
          <h5>Reports</h5>
          <hr></hr>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="From_date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="To_date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={reqData.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>
          {/* <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="fieldteamactivity">
                Accounts
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                // value={}
                // onChange={}
                aria-label="account"
                options={[
                  "All",

                  { label: "Main cash", value: "mainCash" },
                  { label: "Petty cash", value: "pettycash" },
                  { label: "Bank", value: "bank" },
                ]}
              />
            </CInputGroup>
          </CCol> */}
          <CCol sm={12}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white "
                  color="success"
                  onClick={onSubmitHandler}
                >
                  Get Details
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={exportPDF}
                  disabled={!data || data?.length === 0}
                >
                  PDF
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                  disabled={!data || data?.length === 0}
                >
                  Export to CSV
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <h5>Summary</h5>
        <hr></hr>

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Main Cash
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Petty Cash
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Bank
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Total Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data ? (
              data.map((ele, i) => {
                let date = new Date(ele.date).toLocaleDateString("en-IN", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                });
                return (
                  <CTableRow key={i}>
                    <CTableDataCell>{date}</CTableDataCell>
                    <CTableDataCell>Opening Balance</CTableDataCell>
                    <CTableDataCell>{ele.mainCash}</CTableDataCell>
                    <CTableDataCell>{ele.pettyCash}</CTableDataCell>
                    <CTableDataCell> {ele.bankAccount}</CTableDataCell>
                    <CTableDataCell>{ele.totalAmount}</CTableDataCell>
                  </CTableRow>
                );
              })
            ) : (
              <></>
            )}
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default OpeningBalances;
