import {
    CModal, CModalHeader, CModalTitle, CModalBody,CButton, CFormInput, CInputGroup, CInputGroupText, CForm, CFormSelect
} from "@coreui/react"
import { useState, useContext } from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";


function CreditorLoanModal({ visible, setVisible, flag, setFlag, data , type , debitAmount , loansPayableID}) {
    const { authCheck, showAlert , } = useContext(globalContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [creditorData, setCreditorData] = useState({
        debitAmount: "",
        paymentMode: "",
    });
 

    const onChangeHandler = (e) => {
        setCreditorData({
            ...creditorData,
            [e.target.name]: e.target.value,
        });
    };
    

    async function onSubmitHandler(e) {
        setIsSubmitting(true);
        try {
            e.preventDefault();
            if(type == "payout"){
                let bodyData = {
                    debitAmount: debitAmount,
                    paymentMode: creditorData.paymentMode,
                }
                let res = await axios.post(
                    `/api/creditors/pay/${loansPayableID}/${data.creditorID}`,
                    bodyData,
                    {
                        headers: {
                            "auth-token": JSON.parse(localStorage.getItem("token")).token,
                        },
                    }
                );
            }else{
                let res = await axios.post(
                    `/api/creditors/loansPayable/${data.creditorID}`,
                    creditorData,
                    {
                        headers: {
                            "auth-token": JSON.parse(localStorage.getItem("token")).token,
                        },
                    }
                );
            }
            
            showAlert("Successfully Updated" , "2");
            setVisible(!visible)
            setFlag(!flag)
        } catch (error) {
            console.log(error);
            if (error.response.data.errors) {
              error.response.data.errors.forEach((ele) => {
                showAlert(ele.msg, "3")
              });
            } else {
              showAlert(error.response.data.error, "3")
            }        }
            setIsSubmitting(false);
    }



    return (
        <CModal visible={visible} onClose={() => {
            setFlag(!flag)
            setVisible(false)
        }
        }>
            <CModalHeader onClose={() => setVisible(false)}>
                {type=="payout" ? <><CModalTitle>Loans Payout</CModalTitle></> : <><CModalTitle>Loans Payable Request</CModalTitle></>}
                
            </CModalHeader>
            <CModalBody>
                <CForm className="form-container form-control-plaintext d-flex p-6 shadow-none flex-column  align-items-center" onSubmit={onSubmitHandler}>
                    <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon2" className="w-50">
                            Amount to Pay
                        </CInputGroupText>
                        {type=="payout" ?  <CFormInput
                            placeholder="Amount"
                            aria-describedby="basic-addon1"
                            required
                            type="number"
                            name="debitAmount"
                            value={debitAmount}
                        /> 
                        
                        : 
                        <><CFormInput
                        placeholder="Amount"
                        aria-describedby="basic-addon1"
                        required
                        type="number"
                        name="debitAmount"
                        value={creditorData.debitAmount}
                        onChange={onChangeHandler}
                    /> </>}
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon2" className="w-50">
                            Payment Type
                        </CInputGroupText>
                        <CFormSelect
                            size="sm"
                            name="paymentMode"
                            value={creditorData.paymentMode}
                            onChange={onChangeHandler}
                            required
                            
                        >
                            <option disabled  hidden value="">select</option>
                            <option value="Cash">Cash</option>
                            <option value="Bank">Bank</option>
                        </CFormSelect>
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon2" className="w-50">
                            Transaction Date
                        </CInputGroupText>
                        <CFormInput
                            value={(new Date().toLocaleString())}
                        />
                    </CInputGroup>
                    
                    <CButton type="submit" disabled={isSubmitting} color="success" className="w-25 text-white">
                    {isSubmitting ? 'Submitting...' : 'Pay'}
                        </CButton>
                </CForm>
            </CModalBody>

        </CModal>
    )
}

export default CreditorLoanModal