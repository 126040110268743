import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CContainer,
  CButton,
} from "@coreui/react";
import { useLocation } from "react-router-dom";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";



// * XLButton component to download data as excel
const XLButton = ({ data , memberDetails }) => {
  // * formatData function to format data to be exported
  const formatData = (data) => {
    return data.map((e, i) => {
      return {
        "S.No": i + 1,
        "Voucher No.": e.voucherNo,
        Date: new Date(e.date).toLocaleString(),
        Deposit: (e.deposit ? e.deposit.toFixed(2) : ""),
        Withdrawal: (e.withdrawal ? e.withdrawal.toFixed(2) : ""),
        Balance: (e.balance ? e.balance.toFixed(2) : ""),
        Employee: e.emp,
      };
    });
  };
 
  const handleClick = () => {
    data = formatData(data);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `data.csv`);
  };

  const exportPDF = () => {

  const doc = new jsPDF();
  doc.setFontSize(14);
  doc.text(`Deposit Details`, 14, 16);
  doc.text(`Member Name: ${memberDetails[0]} | Membership No: ${memberDetails[1]} `,14 , 24 ,  { fontSize: 14 });

  doc.autoTable({
    head: [["#", "Voucher No", "Date", "Deposit", "Withdrawal", "Balance", "Emp"]],
    body: data.map((ele, i) => [
      i + 1,
      ele.voucherNo,
      new Date(ele.date).toLocaleString(),
      (ele.deposit ? ele.deposit.toFixed(2) : "-"),
      (ele.withdrawal ? ele.withdrawal.toFixed(2) : "-"),
      (ele.balance ? ele.balance.toFixed(2) : "-"),
      ele.emp,
    ]),
    startY : 30,
  });
  doc.save("depositDetails.pdf");
  };



  return (
    <>
    <CContainer fluid className="d-flex align-items-center justify-content-start">
      <b>Member Name : {memberDetails[1]} <br /> Membership Number : {memberDetails[0]}</b>
    </CContainer>
      <CContainer
        fluid
        className="d-flex align-items-center justify-content-end"
      >
        <CButton
          color="info"
          className="text-white fw-bold shadow"
          onClick={handleClick}
        >
          Download CSV
        </CButton>
        <CButton
          color="info"
          className="text-white fw-bold shadow ms-5"
          onClick={exportPDF}
        >
          Export PDF
        </CButton>
      </CContainer>
    </>
  );
};



const DepositDetails = () => {
  const { state } = useLocation();

  return (
    <>
      <CContainer fluid className="p-4 mb-5" style={{ minHeight: "80vmin" }}>
        <CContainer fluid className="p-1 m-2 ms-0">
          <XLButton data={state.ele.details} memberDetails={state.memberDetails}/>
        </CContainer>

        <CTable bordered className="shadow  ">
          <CTableHead className="bg-dark text-white  text-center">
            <CTableRow>
              {/* Added this for later */}

              {/* <CTableHeaderCell scope="col">#</CTableHeaderCell> */}
              <CTableHeaderCell scope="col">#</CTableHeaderCell>
              <CTableHeaderCell scope="col">Voucher No</CTableHeaderCell>
              <CTableHeaderCell scope="col">Date</CTableHeaderCell>
              <CTableHeaderCell scope="col">Deposit</CTableHeaderCell>
              <CTableHeaderCell scope="col">Withdrawal</CTableHeaderCell>
              <CTableHeaderCell scope="col">Balance</CTableHeaderCell>
              <CTableHeaderCell scope="col">Emp</CTableHeaderCell>
              <CTableHeaderCell scope="col">Narration</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="bg-light">
            { state.ele.details.map((ele, i, arr) => {
              let date = new Date(ele.date).toLocaleString();

              const sum = (arr, end)=>{
                let cumulative=0;
                for(let idx=0; idx<end; idx++){
                  cumulative += Number(arr[idx].deposit) - Number(arr[idx].withdrawal);
                }
                return cumulative?.toFixed(2);
              }

              return (
                <CTableRow className="text-center" key={i}>
                  <CTableDataCell>{i + 1}</CTableDataCell>
                  <CTableDataCell>{ele.voucherNo} </CTableDataCell>
                  <CTableDataCell>{date} </CTableDataCell>
                  <CTableDataCell>{ele.deposit?.toFixed(2) || "-"}</CTableDataCell>
                  <CTableDataCell>{ele.withdrawal?.toFixed(2) || "-"}</CTableDataCell>
                  <CTableDataCell>{sum(arr,i+1)}</CTableDataCell>
                  <CTableDataCell>{ele.emp}</CTableDataCell>
                  <CTableDataCell>{ele.narration}</CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default DepositDetails;
