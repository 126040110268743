import {
  CContainer,
  CRow,
  CCol,
  CButton,
  CImage,
  CCard,
  CCardBody,
  CCardText,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";

import MemberScroll from "./MemberScroll";
import MemberStatusModal from "../../components/Modal/MemberStatusModal";
import { useEffect, useState, useContext } from "react";

import MemberContext from "../../contexts/MemberContext/MemberContext";
import globalContext from "../../contexts/Global/globalContext";
import Loading from "../Loading";
import Cookies from 'js-cookie'

const MemberCard = () => {
  const { authCheck, status , loading} = useContext(globalContext);
  let { setMemberData , memberData  } = useContext(MemberContext);
  let navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [memberStatusModal, setMemberStatusModal] = useState(false);
  useEffect(()=>{
    try{
      authCheck();
      const memberNo = Cookies.get("memberNo");
      if(memberNo) setMemberData(memberNo);  
    }
    catch(e){
      console.log(e);
      console.log("Error in Searching Member");
    }
  },[isStatusChanged,flag])

  return (
    <>
      {!memberData ? (
        loading ? (<Loading className="d-flex justify-content-center" />) : <></>
      ) : (
        <>
          <MemberStatusModal
            setMemberStatusModalOpen={setMemberStatusModal}
            setIsStatusChanged={setIsStatusChanged}
            isStatusChanged={isStatusChanged}
            memberStatusModalOpen={memberStatusModal}
          />
          <CContainer fluid className="p-0 mt-2" style={{ minHeight: "100vh" }}>
            <CContainer fluid>
              <CContainer>
                <CCard>
                  <CCardBody style={{ backgroundColor: "#f5f5f5" }}>
                    <CRow>
                      <CCol className="d-flex justify-content-around align-items-stretch">
                        <CContainer className="d-flex">
                          <CContainer className="d-flex flex-column justify-content-around align-items-center">
                            <CContainer
                              className="d-flex justify-content-between rounded shadow bg-white pt-2 pb-2 m-2"
                              style={{ minWidth: "250px" }}
                            >
                              <CCardText className="m-0">
                                <b>Member Name:</b>
                              </CCardText>
                              <CCardText>
                                {memberData.pDetails.memberName}
                              </CCardText>
                            </CContainer>
                            <CContainer className="d-flex justify-content-between  rounded shadow bg-white  pt-2 pb-2 m-2">
                              <CCardText className="m-0">
                                <b>Member Type:</b>
                              </CCardText>
                              <CCardText>{memberData.memberType}</CCardText>
                            </CContainer>
                            <CContainer className="d-flex justify-content-between  rounded shadow bg-white  pt-2 pb-2 m-2">
                              <CCardText className="m-0">
                                <b>Member ID:</b>
                              </CCardText>
                              <CCardText>{memberData.membershipNo}</CCardText>
                            </CContainer>
                            <CContainer className="d-flex justify-content-between rounded shadow bg-white  pt-2 pb-2 m-2">
                              <CCardText className="m-0">
                                <b>Adhaar Number:</b>
                              </CCardText>
                              <CCardText>
                                {memberData.pDetails.adhaarNumber}
                              </CCardText>
                            </CContainer>
                            <CContainer className="d-flex justify-content-between rounded shadow bg-white  pt-2 pb-2 m-2">
                              <CCardText className="m-0">
                                <b>Email ID:</b>
                              </CCardText>
                              <CCardText>{memberData.pDetails.email}</CCardText>
                            </CContainer>
                            <CContainer className="d-flex justify-content-between  rounded shadow bg-white  pt-2 pb-2 m-2">
                              <CCardText className="m-0">
                                <b>Phone Number:</b>
                              </CCardText>
                              <CCardText>{memberData.mobileNo}</CCardText>
                            </CContainer>
                          </CContainer>
                        </CContainer>
                        <CContainer className="d-flex flex-column align-items-center justify-content-around">
                          <CContainer className="d-flex justify-content-center">
                            <CImage
                              src={`./${memberData.memberImage.data}`}
                              style={{
                                width: "200px",
                                minHeight: "220px",
                                maxWidth: "300px",
                                maxHeight: "320px",
                              }}
                              className="shadow mb-2"
                            ></CImage>
                          </CContainer>
                          <CButton
                            className="btn shadow  btn-success "
                            onClick={() => {
                              setMemberStatusModal(true);
                            }}
                            style={{ width: "40%", color: "white" }}
                          >
                            <b>Status: {memberData.memberStatus} </b>
                          </CButton>
                        </CContainer>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CContainer>
              <CContainer>
                <MemberScroll setFlag={setFlag} flag={flag} />
              </CContainer>
            </CContainer>
          </CContainer>
        </>
      )}
    </>
  );
};

export default MemberCard;
