import React from "react";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CForm,
  CModalFooter,
} from "@coreui/react";
import { useState, useContext } from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

import { useNavigate } from "react-router-dom";

const DepositModal = ({
  setDepositModalOpen,
  DepositModalOpen,
  data,
  depositId,
}) => {
  const navigate = useNavigate();
  const { alert, showAlert } = useContext(globalContext);
  const [isLoading, setIsLoading] = useState(false);

  const [depositAmount, setdepositAmount] = useState({
    depositAmount: "",
  });

  const onChangeHandler = (e) => {
    setdepositAmount({
      ...depositAmount,
      [e.target.name]: e.target.value,
    });
  };

  async function onSubmitHandler(e) {
    setIsLoading(true);
    try {
      e.preventDefault();
      let res = await axios.post(
        `/api/member/deposits/addAmount/${data.membershipNo}/${depositId}`,
        depositAmount,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      await showAlert("Successfully Deposited", "2");

      setTimeout(() => {
        setDepositModalOpen(false);
        navigate(0);
      }, 2000);
    } catch (error) {
      console.error(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  return (
    <>
      <CModal
        className="modal"
        visible={DepositModalOpen}
        onClose={() => setDepositModalOpen(false)}
      >
        <CModalHeader onClose={() => setDepositModalOpen(false)}>
          <CModalTitle>Recieve from Member</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm className="form-container form-control-plaintext d-flex p-6 shadow-none flex-column  align-items-center">
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon2" className="w-50">
                Amount to be Deposited
              </CInputGroupText>
              <CFormInput
                placeholder="Deposit Amount"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                type="number"
                name="depositAmount"
                className="text-center"
                value={depositAmount.depositAmount}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon2" className="w-50">
                Date of deposit
              </CInputGroupText>
              <CFormInput value={new Date().toLocaleString()} />
            </CInputGroup>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            variant="outline"
            onClick={() => setDepositModalOpen(false)}
          >
            Close
          </CButton>
          <CButton
            className="w-25"
            color="success"
            variant="outline"
            onClick={onSubmitHandler}
            disabled={isLoading}
          >
            Pay
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default DepositModal;
