import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CContainer,
  CButton,
  CRow,
  CCol,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import globalContext from "../contexts/Global/globalContext";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { useEffect, useState, useContext } from "react";
import PendingMemberModal from "./PendingMemberModal";
import DropDownPagination from "./DropDownPaginate";
import Paginate from "./Pagination";

const TableSearch = ({ filterValue, currentValue }) => {
  return (
    <CContainer fluid className="w-75">
      <CInputGroup>
        <CInputGroupText className="bg-info text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </CInputGroupText>
        <CFormInput
          placeholder="Search"
          value={currentValue}
          onChange={filterValue}
        />
      </CInputGroup>
    </CContainer>
  );
};

const PendingMembers = () => {
  const { authCheck, showAlert } = useContext(globalContext);

  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);

  const [accept, setAccept] = useState("");
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  let filterData = [...data];

  const filterString = filter.toLowerCase();
  filterData = filterData.filter((item) => {
    return [
      item.pDetails.memberName,
      item.pAddress.pState,
      item.pAddress.pCity,
      item.pAddress.pHouseNo,
      item.mobileNo,
      item.pDetails.gender,
      item.memberType,
    ]
      .map((e) => e.toLowerCase())
      .some((property) => property.includes(filterString));
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);

  // to set new page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // to set to previous page wont return anything beyond first page
  const prevPage = () =>
    setCurrentPage((nextPage) => {
      if (nextPage > 1) {
        return nextPage - 1;
      }
      return nextPage;
    });
  // to set to next page wont return anything after the final page
  const nextPage = () =>
    setCurrentPage((prevPage) => {
      if (prevPage < Math.ceil(data.length / itemsPerPage)) {
        return prevPage + 1;
      }
      return prevPage;
    });

  function handleItemsPerPage(value) {
    setItemsPerPage(value);
    setCurrentPage(1);
  }

  function filterValue(e) {
    setFilter(e.target.value);
  }

  useEffect(() => {
    try {
      async function fetchData() {
        const response = await axios.get(`/api/member/pendingmembers`, {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(response.data.allData);
      }
      fetchData();
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  }, []);

  function navigating(e, mem) {
    navigate("/editmember", { state: { e, mem } });
  }

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Pending Members List
      </p>
      <CContainer fluid className="p-4 mb-5" style={{ minHeight: "90vmin" }}>
        <CContainer
          fluid
          className="d-flex align-items-center justify-content-around my-4"
        >
          <TableSearch filterValue={filterValue} currentValue={filter} />
          <DropDownPagination
            handleItemsPerPage={handleItemsPerPage}
            num={itemsPerPage}
          />
        </CContainer>
        <CTable bordered striped className="shadow align-middle">
          <CTableHead className="bg-dark text-center text-white">
            <CTableRow>
              <CTableHeaderCell scope="col">Member Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Gender</CTableHeaderCell>
              <CTableHeaderCell scope="col">Member Type</CTableHeaderCell>
              <CTableHeaderCell scope="col">DOB</CTableHeaderCell>
              <CTableHeaderCell scope="col">Address</CTableHeaderCell>
              <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
              <CTableHeaderCell scope="col">Requested Date</CTableHeaderCell>
              <CTableHeaderCell scope="col">Status</CTableHeaderCell>
              <CTableHeaderCell scope="col">Approve/Reject</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {currentItems.map((item, index) => (
              <CTableRow key={index} className="text-center">
                <CTableDataCell
                  onClick={() => navigating(item._id, item)}
                  style={{ cursor: "pointer" }}
                >
                  {item.pDetails.memberName}
                </CTableDataCell>
                <CTableDataCell>{item.pDetails.gender}</CTableDataCell>
                <CTableDataCell>{item.memberType}</CTableDataCell>
                <CTableDataCell>
                  {new Date(item.pDetails.dob).toDateString()}
                </CTableDataCell>
                <CTableDataCell>
                  {`${item.pAddress.pHouseNo},${item.pAddress.pCity},${item.pAddress.pState}`}
                </CTableDataCell>
                <CTableDataCell>{item.mobileNo}</CTableDataCell>
                <CTableDataCell>
                  {new Date(item.requestedDate).toDateString()}
                </CTableDataCell>
                <CTableDataCell>Pending</CTableDataCell>
                <CTableDataCell>
                  <CRow className="d-grid align-items-center gy-2">
                    <CCol>
                      <CButton
                        className="btn btn-success w-75 text-white"
                        onClick={() => {
                          setAccept("accept");
                          setVisible(!visible);
                          setShowData(currentItems[index]);
                        }}
                      >
                        Approve
                      </CButton>
                    </CCol>
                    <CCol>
                      <CButton
                        className="btn border-danger text-danger w-75"
                        style={{ backgroundColor: "#f5f5f5" }}
                        onClick={() => {
                          setAccept("reject");
                          setVisible(!visible);
                          setShowData(currentItems[index]);
                        }}
                      >
                        Reject
                      </CButton>
                    </CCol>
                  </CRow>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <PendingMemberModal
          accept={accept}
          setAccept={setAccept}
          setVisible={setVisible}
          visible={visible}
          showData={showData}
          setShowData={setShowData}
          data={data}
          setData={setData}
        />
        <Paginate
          itemsPerPage={itemsPerPage}
          totalItems={data.length}
          paginate={paginate}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </CContainer>
    </>
  );
};

export default PendingMembers;
