import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";
import * as XLSX from "xlsx";

const MemberHistory = () => {
  const rawData = [
    {
      date: "05/09/2015",
      voucherNo: "1516-QHD-TEST-44",
      remarks: "20,800.00",
      disbursement: "0.00",
      repayment: "Not Visited",
      balance: "20,800.00",
      emp: "",
    },
    // More data objects...
  ];

  function formatData(data) {
    return data.map((item, index) => ({
      S_No: index + 1,
      Date: item.date,
      "Voucher No.": item.voucherNo,
      Remarks: item.remarks,
      Disbursement: item.disbursement,
      Repayment: item.repayment,
      Balance: item.balance,
      Emp: item.emp,
    }));
  }

  const handleClick = () => {
    let newData = formatData(rawData);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `MemberHistory.csv`);
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Member History
      </p>
      <CContainer>
        <CRow>
          <h5>Report</h5>
          <hr></hr>
          {/* Member Number variable data */}
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="memberNo">Member No</CInputGroupText>
              <CFormInput
                aria-describedby="memberNo"
                name=""
                // value=""
                // onChange={}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>

          {/* //TODO: variable data in caterogy  */}
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="productName">
                Product Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                // value={}
                // onChange={}
                aria-label="Transaction Type"
                options={[
                  "Select",

                  { label: "Long Term Deposit", value: "longtermdeposit" },
                  { label: "Demand Loan", value: "demandloan" },
                  { label: "Consumer Goods Loan", value: "consumergoodsloan" },
                  { label: "Business Goods Loan", value: "businessgoodsloan" },
                  { label: "Coolers Loan", value: "coolersloan" },
                  { label: "Daily Deposit", value: "dailydeposit" },
                  { label: "Savings Deposit", value: "savingsdeposit" },
                ]}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={12}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton className="text-white " color="success">
                  Get Details
                </CButton>
                {/* <CButton className="text-white " color="success">
                  Print PDF
                </CButton> */}
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                >
                  Export to CSV
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <h5>Field Activity Details</h5>
        <hr></hr>

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                S_No
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Voucher No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Remarks
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Disbursement
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Repayment
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Emp
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>1</CTableDataCell>
              <CTableDataCell>05/09/2015</CTableDataCell>
              <CTableDataCell>1516-QHD-TEST-44</CTableDataCell>
              <CTableDataCell>20,800.00</CTableDataCell>
              <CTableDataCell>0.00</CTableDataCell>
              <CTableDataCell>Not Visited</CTableDataCell>
              <CTableDataCell>20,800.00</CTableDataCell>
              <CTableDataCell></CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default MemberHistory;
