import { Outlet, Navigate } from "react-router-dom";

import Cookies from "js-cookie";

function SuperAdminRoutes() {
  // const { authCheck, status , auth } = useContext(globalContext);
  let outlet = false;
  let role = Cookies.get("role");
  if (role == "admin" || role == "ho" || role == "adminView"){
    outlet = true;
  }
  return outlet ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default SuperAdminRoutes;
