import { useState, useEffect } from "react";
import {
  CButton,
  CContainer,

  CFormSelect,

  CInputGroup,
  CInputGroupText,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormCheck,
} from "@coreui/react";
import axios from "axios";
// import { MultiSelect } from "react-multi-select-component";
// import Paginate from "./Pagination";
// import DropDownPagination from "./DropDownPaginate";

// const TableSearch = ({ filterValue, currentValue }) => {
//   return (
//     <CContainer fluid className="w-100">
//       <CInputGroup>
//         <CInputGroupText className="bg-info text-white">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="20"
//             height="20"
//             fill="currentColor"
//             className="bi bi-search"
//             viewBox="0 0 16 16"
//           >
//             <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
//           </svg>
//         </CInputGroupText>
//         <CFormInput
//           placeholder="Search"
//           // value={currentValue}
//           // onChange={filterValue}
//         />
//       </CInputGroup>
//     </CContainer>
//   );
// };

// const options = [
//   { label: "person 1", value: "person 1" },
//   { label: "person 2", value: "person 2" },
//   { label: "person 3", value: "person 3" },
// ];
// function CashCollectorMember() {
//   const [selected, setSelected] = useState([]);

//   return (
//     <>
//       <p
//         style={{ background: "#2364aa" }}
//         className="fw-bold text-white fs-2 text-center p-1"
//       >
//         Cash Collector Member
//       </p>
//       <CContainer>
//         <legend>Cash Collector Beat</legend>
//         <CForm className="w-75 d-flex ">
//           <CInputGroup className="mb-3 ">
//             <CInputGroupText className="fw-bold">
//               Cash Collector
//             </CInputGroupText>
//             <CFormSelect
//               size="sm"
//               required
//               //   onChange={onChangeHandler}
//             >
//               <option disabled selected hidden value="">
//                 Select Type
//               </option>
//               <option value="Person1">Person1</option>
//               <option value="Person2">Person2</option>
//               <option value="Person3">Person3</option>
//               <option value="Person4">Person4</option>
//             </CFormSelect>
//           </CInputGroup>
//           <CContainer className="ml-auto">
//             {/* <legend>Details</legend> */}
//             <CButton
//               // variant="outline"
//               type="submit"
//               color="success"
//               className=" fw-bold me-4 text-white"
//             >
//               Add member
//             </CButton>
//             <CButton
//               // variant="outline"
//               // type="submit"
//               color="warning"
//               className=" fw-bold text-white"
//             >
//               Clear
//             </CButton>
//           </CContainer>
//         </CForm>
//       </CContainer>

//       <CContainer>
//         <CForm className="d-flex">
//           <CInputGroup className="mb-3 w-25">
//             <CInputGroupText className="fw-bold">Beat Name</CInputGroupText>
//             <CFormSelect
//               size="sm"
//               required
//               //   onChange={onChangeHandler}
//             >
//               <option disabled selected hidden value="">
//                 Select Type
//               </option>
//               <option value="Beat 1">Beat 1</option>
//               <option value="Beat 2">Beat 2</option>
//               <option value="Beat 3">Beat 3</option>
//               <option value="Beat 4">Beat 4</option>
//             </CFormSelect>
//           </CInputGroup>
//           <CInputGroup className="mb-3 w-25 ms-4">
//             <CInputGroupText className="fw-bold">Member Name</CInputGroupText>
//             {/* <CFormSelect
//               size="sm"
//               required
//               className="chosen-select"
//               //   onChange={onChangeHandler}
//             >
//               <option disabled selected hidden value="">
//                 Select Type
//               </option>
//               <option value="Member 1">Member 1</option>
//               <option value="Member 2 ">Member 2 </option>
//             </CFormSelect> */}
//             <MultiSelect
//               className="w-50"
//               options={options}
//               value={selected}
//               onChange={setSelected}
//               labelledBy="Select"
//             />
//           </CInputGroup>
//           <CInputGroup className="mb-3 ms-4 w-25">
//             <CInputGroupText className="fw-bold">Start Date</CInputGroupText>
//             <CFormInput
//               type="date"
//               placeholder="xx/xx/xxxx"
//               aria-label="DOB"
//               aria-describedby="basic-addon1"
//               required
//             />
//           </CInputGroup>

//           <CInputGroup className="mb-3 w-25 ms-4">
//             <CInputGroupText className="fw-bold">Day of week</CInputGroupText>
//             <CFormSelect
//               size="sm"
//               required
//               //   onChange={onChangeHandler}
//             >
//               <option disabled selected hidden value="">
//                 Select Type
//               </option>
//               <option value="Monday">Monday</option>
//               <option value="Tuesday ">Tuesday </option>
//               <option value="Wednesday">Wednesday</option>
//               <option value="Thursday ">Thursday </option>
//               <option value="Friday">Friday</option>
//               <option value="Saturday ">Saturday </option>
//             </CFormSelect>
//           </CInputGroup>
//         </CForm>
//       </CContainer>

//       <hr />

//       <CContainer className="d-flex mt-3">
//         <CInputGroup className="mb-3 w-75">
//           <CInputGroupText className="fw-bold">Search by</CInputGroupText>
//           <CFormSelect size="sm" required>
//             <option disabled selected hidden value="">
//               Select Type
//             </option>
//             {/*  */}
//             <option value="customername">Member Name</option>
//             <option value="beatname">Beat Name</option>
//             <option value="OMobile">Mobile</option>
//             <option value="OLocation">Location</option>
//             <option value="customercode">Member Code</option>
//             <option value="ODayOfWeek">Day</option>
//           </CFormSelect>
//         </CInputGroup>
//         <TableSearch filterValue={{}} currentValue={{}} />
//         <CButton color="warning" className=" fw-bold text-white me-3 mb-3 w-50">
//           Remove Selected
//         </CButton>
//         <CButton color="success" className=" fw-bold text-white mb-3  w-50">
//           Export to excel
//         </CButton>
//         <DropDownPagination
//         // handleItemsPerPage={handleItemsPerPage}
//         // num={itemsPerPage}
//         />
//       </CContainer>

//       <CContainer fluid className="p-4 my-2 ms-5">
//         <CTable bordered striped className="shadow w-75 ms-5">
//           <CTableHead className="bg-dark text-center align-middle">
//             <CTableRow>
//               {/* <CTableHeaderCell scope="col" className="text-white">
//               #
//             </CTableHeaderCell> */}
//               <CTableHeaderCell scope="col" className="text-white">
//                 <CFormCheck id="flexCheckDefault" label="" />
//               </CTableHeaderCell>
//               <CTableHeaderCell scope="col" className="text-white">
//                 Member Name
//               </CTableHeaderCell>
//               <CTableHeaderCell scope="col" className="text-white">
//                 Membership No.
//               </CTableHeaderCell>
//               <CTableHeaderCell scope="col" className="text-white">
//                 Visit Day
//               </CTableHeaderCell>
//               <CTableHeaderCell scope="col" className="text-white">
//                 Mobile
//               </CTableHeaderCell>
//               <CTableHeaderCell scope="col" className="text-white">
//                 Location
//               </CTableHeaderCell>
//               <CTableHeaderCell scope="col" className="text-white">
//                 Added On
//               </CTableHeaderCell>
//             </CTableRow>
//           </CTableHead>
//           <CTableBody className="text-center align-middle">
//             <CTableRow>
//               <CTableDataCell>
//                 <CFormCheck id="flexCheckDefault" label="" />
//               </CTableDataCell>
//               <CTableDataCell>Person 1</CTableDataCell>
//               <CTableDataCell>001</CTableDataCell>
//               <CTableDataCell>10/2/2000</CTableDataCell>
//               <CTableDataCell>99778126</CTableDataCell>
//               <CTableDataCell>hyd</CTableDataCell>
//               <CTableDataCell>10/2/2000</CTableDataCell>
//             </CTableRow>
//             <CTableRow>
//               <CTableDataCell>
//                 <CFormCheck id="flexCheckDefault" label="" />
//               </CTableDataCell>
//               <CTableDataCell>Person 2</CTableDataCell>
//               <CTableDataCell>002</CTableDataCell>
//               <CTableDataCell>10/2/2000</CTableDataCell>
//               <CTableDataCell>99778126</CTableDataCell>
//               <CTableDataCell>hyd</CTableDataCell>
//               <CTableDataCell>10/2/2000</CTableDataCell>
//             </CTableRow>
//             <CTableRow>
//               <CTableDataCell>
//                 <CFormCheck id="flexCheckDefault" label="" />
//               </CTableDataCell>
//               <CTableDataCell>Person 2</CTableDataCell>
//               <CTableDataCell>002</CTableDataCell>
//               <CTableDataCell>10/2/2000</CTableDataCell>
//               <CTableDataCell>99778126</CTableDataCell>
//               <CTableDataCell>hyd</CTableDataCell>
//               <CTableDataCell>10/2/2000</CTableDataCell>
//             </CTableRow>
//           </CTableBody>
//         </CTable>
//         <Paginate
//         //   itemsPerPage={itemsPerPage}
//         //   totalItems={data.length}
//         //   paginate={paginate}
//         //   prevPage={prevPage}
//         //   nextPage={nextPage}
//         />
//       </CContainer>
//     </>
//   );
// }

const CashCollectorMember = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [input, setInput] = useState({
    beat: "",
  });
  const [beatName, setBeatName] = useState([]);
  const [members, setMembers] = useState([]);
  const [sendMembers, setSendMembers] = useState([]);
  const [removeMember, setRemoveMember] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(
          "/api/cashCollectors/allbeats",
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        let newBeat = res.data.everyBeats.map((beat) => {
          return {
            label: beat.beats.beatName,
            value: beat._id,
          };
        });
        setBeatName(newBeat);
        const result = await axios.get("api/cashCollectors/getUnassignedMembers", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setMembers(result.data.everymember);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function onSubmitHandler() {
    setIsSubmitting(true);
    if (input.beat === "") {
      return alert("Please Select Beat");
    }
    try {
     
      if (!removeMember) {
        const res = await axios.put(
          `/api/cashCollectors/addMembers/${input.beat}`,
          {
            membersList: sendMembers,
          },
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
   
        setIsSubmitting(false);
      } else {
        const res = await axios.put(
          `/api/cashCollectors/removeMembers/${input.beat}`,
          {
            membersList: sendMembers,
          },
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
   
      }
      setTimeout(()=>{
      window.location.reload();
      },1000)

    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  }

  return (
    <CContainer
      fluid
      className="p-0 m-0 "
      style={{
        minHeight: "90vh",
      }}
    >
      <p
        style={{ background: "#2364aa" }}
        className="fw-bold text-white fs-2 text-center p-1"
      >
        Cash Collector Member
      </p>
      <CContainer className="d-flex justify-content-between align-items-center border rounded shadow p-3">
        <CContainer className="p-0 m-0">
          <CInputGroup>
            <CInputGroupText className="fw-bold text-primary">
              Select Beat:
            </CInputGroupText>
            <CFormSelect
              size="sm"
              required
              value={input.beatName}
              onChange={(e) => setInput({ ...input, beat: e.target.value })}
              aria-label="Transaction Type"
              options={["Select", ...beatName]}
              className="text-primary"
            ></CFormSelect>
          </CInputGroup>
        </CContainer>
        <CContainer className="p-0 m-0 d-flex justify-content-end align-items-center">
          <CFormCheck
            label="Remove Member"
            className="border py-2 pe-2 rounded ps-5 border-danger text-danger"
            onChange={(e) => {
              e.target.checked ? setRemoveMember(true) : setRemoveMember(false);
            }}
          />
          <CButton
            color="success"
            variant="outline"
            className="ms-5 p-2"
            onClick={onSubmitHandler}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit to Beat'}
            {/* Submit to Beat */}
          </CButton>
        </CContainer>
      </CContainer>
      <CContainer className="p-0 mt-3">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                {/* <CFormCheck
                  label="All"
                  onChange={(e) => {
                    e.target.checked ? setSelectAll(true) : setSelectAll(false);
                  }}
                /> */}
                Select
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Member Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Membership No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Mobile
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Address
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                is Assigned
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Beat Name
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {members.map((member, index) => {
              return (
                <CTableRow key={index} >
                  <CTableDataCell>
                    <CFormCheck
                      label=""
                      onChange={(e) => {
                     
                        e.target.checked
                          ? setSendMembers([...sendMembers, member._id])
                          : setSendMembers(
                              sendMembers.filter((m) => m !== member._id)
                            );
                      }}
                    />
                  </CTableDataCell >
                  <CTableDataCell>{member.memberName}</CTableDataCell>
                  <CTableDataCell>{member.membershipNo}</CTableDataCell>
                  <CTableDataCell>{member.mobileNo}</CTableDataCell>
                  <CTableDataCell>{member.address}</CTableDataCell>
                  <CTableDataCell >
                    {member.isBeatAssigned? "Assigned":"Not Assigned"}
                  </CTableDataCell>
                  <CTableDataCell>{member.name || "---"}</CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </CContainer>
    </CContainer>
  );
};

export default CashCollectorMember;
