import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";

import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import globalContext from "../../contexts/Global/globalContext";
import * as XLSX from "xlsx";

const CashCollectorActivity = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState({});
  const [cashCollector, setCashCollector] = useState([]);
  const [display, setDisplay] = useState(false);
  const [input, setInput] = useState({
    cashCollectorId: "",
    date: new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    )
      .toLocaleString("sv", {
        timeZone: "Asia/Kolkata",
        dateStyle: "short",
        timeStyle: "medium",
      })
      .split(" ")[0],
  });

  useEffect(() => {
    async function fetchCashCollectors() {
      try {
        const res = await axios.get(
          `/api/cashCollectors/getCashCollectorsBranch`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        return setCashCollector(res.data.Success);
      } catch (e) {
        console.log(e);
      }
    }
    fetchCashCollectors();
  }, []);

  async function fetchCashCollectorsDate() {
    setIsSubmitting(true);
    try {
      const res = await axios.get(
        `/api/cashCollectors/activity/${input.cashCollectorId}/${input.date}`,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      res.data.success.ledger.sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
      });
      setDisplay(true);
      setIsSubmitting(false);
      showAlert("Fetched Successfully" < "2");
      return setData(res.data.success);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  }

  const handleClick = () => {
    let newData = formatData(data.ledger);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `CashCollector.csv`);
  };

  const formatData = (ledgerData) => {
    return ledgerData?.map((item, index) => {
      return {
        S_No: index + 1,
        "Membership No.": `${item.membershipNo}`,
        "Member Name": item.memberName,
        "Member Address": item.address,
        Transaction: item.transaction,
        "Product Name": item.productName,
        Time: new Date(item.time).toLocaleString(),
        "Activity Type": item.activityType,
        Amount: item.amount.toFixed(2),
      };
    });
  };

  return (
    <CContainer
      fluid
      className="p-0 m-0"
      style={{
        minHeight: "90vh",
      }}
    >
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Cash Collector Activity
      </p>
      <CContainer>
        <CRow>
          <h5>Cash Collector Activity</h5>
          <hr></hr>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="type">
                Type
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormSelect aria-label="Type" options={["All"]} />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="cashCollector">
                Cash Collector
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormSelect
                value={input.cashCollectorId}
                onChange={(e) =>
                  setInput({ ...input, cashCollectorId: e.target.value })
                }
                aria-label="Transaction Type"
                options={["Select", ...cashCollector]}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                value={input.date}
                onChange={(e) => setInput({ ...input, date: e.target.value })}
                max={
                  new Date(
                    new Date().toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    })
                  )
                    .toLocaleString("sv", {
                      timeZone: "Asia/Kolkata",
                      dateStyle: "short",
                      timeStyle: "medium",
                    })
                    .split(" ")[0]
                }
              />
            </CInputGroup>
          </CCol>
          <CCol sm={12}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white "
                  disabled={isSubmitting}
                  color="success"
                  onClick={() => {
                    fetchCashCollectorsDate();
                  }}
                >
                  Show
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                >
                  Export to CSV
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>
        {display && (
          <>
            <h5>Field Activity Summary</h5>
            <hr></hr>
            <CRow>
              <CCol sm={4}>
                <h6 className="px-1">{data.cashCollectorName}</h6>
                <CTable bordered striped className="shadow">
                  <CTableHead className=" align-middle">
                    <CTableRow>
                      <CTableHeaderCell scope="col">Total</CTableHeaderCell>
                      <CTableHeaderCell scope="col">{`${data.uniqueMembers}/${data.membersAssigned}`}</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody className=" align-middle">
                    <CTableRow>
                      <CTableDataCell>
                        Minimum Members to be covered/Day
                      </CTableDataCell>
                      <CTableDataCell>{data.membersAssigned}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Members covered</CTableDataCell>
                      <CTableDataCell>{data.uniqueMembers}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Members not visited</CTableDataCell>
                      <CTableDataCell>
                        {data.membersAssigned - data.uniqueMembers}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Activities Logged</CTableDataCell>
                      <CTableDataCell>{data.activityLogged}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Total Advance</CTableDataCell>
                      <>{}</>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Total Collections (Cash)</CTableDataCell>
                      <CTableDataCell>{`₹ ${data.collectionTotal.toFixed(
                        2
                      )}`}</CTableDataCell>
                    </CTableRow>
                    {/* <CTableRow>
                      <CTableDataCell>
                        Total Collections (Cheque)
                      </CTableDataCell>
                      <CTableDataCell>0.00/0</CTableDataCell>
                    </CTableRow> */}
                  </CTableBody>
                </CTable>
              </CCol>
              <CCol sm={8}>
                <h6>Collection OutComes</h6>
                <CTable bordered striped className="shadow">
                  <CTableHead className="bg-dark text-center align-middle">
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="text-white">
                        OutCome
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Members
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Collection
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Withdrawal
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody className="text-center align-middle">
                    <CTableRow>
                      <CTableDataCell>Total</CTableDataCell>
                      <CTableDataCell>{data.uniqueMembers}</CTableDataCell>
                      <CTableDataCell>
                        {data.collectionTotal.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell>
                        {data.withdrawalTotal.toFixed(2)}
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>
            <h5>Field Activity Details</h5>
            <hr></hr>
            <CRow>
              <CCol sm={8}>
                <CButton
                  type="submit"
                  color="link"
                  disabled={isSubmitting}
                  shape="rounded-0"
                  onClick={() => navigate("/cashCollectorActivity/maps")}
                >
                  Show Map
                </CButton>
                <CButton
                  type="submit"
                  disabled={isSubmitting}
                  color="link"
                  shape="rounded-0"
                >
                  Multiple Activities
                </CButton>
              </CCol>
              <CCol sm={2}>
                <CInputGroup className="mb-3">
                  <CFormSelect
                    // value={}
                    // onChange={}
                    aria-label="Transaction Type"
                    options={[
                      "All",

                      { label: "Total", value: "total" },
                      { label: "Not Visited", value: "notVisited" },
                    ]}
                  />
                </CInputGroup>
              </CCol>
              <CCol sm={2}>
                <CInputGroup className="mb-3">
                  <CFormSelect
                    // value={}
                    // onChange={}
                    aria-label="Transaction Type"
                    options={[
                      "All",

                      { label: "Collection", value: "collection" },
                      { label: "Withdrawel", value: "wtihdrawel" },
                    ]}
                  />
                </CInputGroup>
              </CCol>
            </CRow>

            <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    S_No
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Membership No.
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Member Name
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Member Address
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Transaction
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Product Name
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Time
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Activity Type
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Amount
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text-center align-middle">
                {data.ledger.map((item, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{item.membershipNo}</CTableDataCell>
                    <CTableDataCell>{item.memberName}</CTableDataCell>
                    <CTableDataCell>{item.address}</CTableDataCell>
                    <CTableDataCell>{item.productName}</CTableDataCell>
                    <CTableDataCell>{item.productName}</CTableDataCell>
                    <CTableDataCell>
                      {new Date(item.time).toLocaleString()}
                    </CTableDataCell>
                    <CTableDataCell>{item.activityType}</CTableDataCell>
                    <CTableDataCell>{item.amount.toFixed(2)}</CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </>
        )}
      </CContainer>
    </CContainer>
  );
};

export default CashCollectorActivity;
