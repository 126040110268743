import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
} from "@coreui/react";
import axios from "axios"
import Wmodal from "../../components/Modal/Wmodal";
import Smodal from "../../components/Modal/Smodal";

import { useState, useContext , useEffect} from "react";

import MemberContext from "../../contexts/MemberContext/MemberContext";

const FeeDetails = ({ setFlag, flag }) => {
  let { memberData: data } = useContext(MemberContext);
  const [feeDetails, setFeeDetails] = useState({
    membershipFee: data.feeDetails.membershipFee.isMemberShipPaid,
    annualFee: data.feeDetails.annualFee.isAnnualFeePaid,
  });
  const [WmodalOpen, setwModalOpen] = useState(false);
  const [SmodalOpen, setsModalOpen] = useState(false);

  const [type, setType] = useState("");

  useEffect(()=>{
    async function fetchFees(){
      try {
        const res = await axios.get(`/api/member/feeDetails`, {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        })
      } catch (error) {
        console.log(error);
      }
    }
  }, [])


  return (
    <>
      <Wmodal
        setwModalOpen={setwModalOpen}
        WmodalOpen={WmodalOpen}
        membershipNo={data.membershipNo}
        setFlag={setFlag}
        flag={flag}
        type={type}
      />
  {/* {console.log(data)} */}
      <Smodal
        setsModalOpen={setsModalOpen}
        SmodalOpen={SmodalOpen}
        membershipNo={data.membershipNo}
      />

      <CContainer fluid className="p-3">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-secondary text-center">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                #
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Payment
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="row">1</CTableHeaderCell>
              {/* use conditional rendering to check whether to pay or paid */}
              <CTableDataCell>
                {feeDetails.membershipFee ? (
                  <CButton
                    color="success"
                    disabled
                    className="text-white w-50 "
                  >
                    Paid
                  </CButton>
                ) : (
                  <CButton
                    onClick={() => {
                      setType("membershipFee");
                      setwModalOpen(true);
                    }}
                    color="warning"
                    className="text-white w-50"
                  >
                    Pay
                  </CButton>
                )}
              </CTableDataCell>
              <CTableDataCell>MemberShip Fee</CTableDataCell>
              <CTableDataCell>
                <b>₹{data.membershipFee ? data.membershipFee : "0"}.00</b>
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableHeaderCell scope="row">2</CTableHeaderCell>
              {/* use conditional rendering to check whether to pay or paid */}
              <CTableDataCell>
                {feeDetails.annualFee ? (
                  <CButton
                    color="success"
                    disabled
                    className="text-white w-50 "
                  >
                    Paid
                  </CButton>
                ) : (
                  <CButton
                    onClick={() => {
                      setType("annualFee");
                      setwModalOpen(true);
                    }}
                    color="warning"
                    className="text-white w-50"
                  >
                    Pay
                  </CButton>
                )}
              </CTableDataCell>
              <CTableDataCell>Annual Fee</CTableDataCell>
              <CTableDataCell>
                <b>₹{data.annualFees ? data.annualFees : "0"}.00</b>
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableHeaderCell scope="row">3</CTableHeaderCell>
              {/* use conditional rendering to check whether to pay or paid */}
              <CTableDataCell>
                {false ? (
                  <CButton
                    color="success"
                    disabled
                    className="text-white w-50 "
                  >
                    Add
                  </CButton>
                ) : (
                  <CButton
                    onClick={() => {
                      setsModalOpen(true);
                    }}
                    color="warning"
                    className="text-white w-50"
                  >
                    Pay
                  </CButton>
                )}
              </CTableDataCell>
              <CTableDataCell>Primary ShareCertificate Fee</CTableDataCell>
              <CTableDataCell>
                <b>₹{data.feeDetails.shareCertificate.totalAmount.toFixed(2)}</b>
              </CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default FeeDetails;
