import axios from "axios";
import { useState, useEffect, useContext } from "react";
import {
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";

import ViewAllCreditorTable from "./ViewAllCreditorsTable";
import Paginate from "../Pagination";
import DropDownPagination from "../DropDownPaginate";
import globalContext from "../../contexts/Global/globalContext";


// Search Component
const TableSearch = ({ filterValue, currentValue }) => {
  return (
    <CContainer fluid className="w-75">
      <CInputGroup>
        <CInputGroupText className="bg-info text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </CInputGroupText>
        <CFormInput
          placeholder="Search with Name"
          value={currentValue}
          onChange={filterValue}
        />
      </CInputGroup>
    </CContainer>
  );
};

const ViewAllCreditor = () => {
  const { authCheck, showAlert , } = useContext(globalContext);

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.get(`api/creditors/allCreditors`, {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(result.data.everyCreditors);
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3")
          });
        } else {
          showAlert(error.response.data.error, "3")
        }
      }
    }
    fetchData();
  }, []);

  // to filter the data

  let filterData = [...data];

  filterData = filterData.map((e) => {
    return {
      creditorName: e.creditorName,
      creditorNumber: e.creditorID,
      email: e.email,
      mobileNumber: e.mobileNo,
      address: e.address,
      date: e.requestedDate,
    };
  });

  const filterString = filter.toLowerCase();
  filterData = filterData.filter((item) => {
    return [
      item.creditorName.toLowerCase(),
      item.creditorNumber,
      item.address.toLowerCase(),
      item.mobileNumber,
      item.email.toLowerCase(),
      item.date.toLowerCase(),
    ].some((property) => property.includes(filterString));
  });
  // to get the current items to be displayed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);

  // to set new page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // to set to previous page wont return anything beyond first page
  const prevPage = () =>
    setCurrentPage((nextPage) => {
      if (nextPage > 1) {
        return nextPage - 1;
      }
      return nextPage;
    });

  // to set to next page wont return anything after the final page
  const nextPage = () =>
    setCurrentPage((prevPage) => {
      if (prevPage < Math.ceil(data.length / itemsPerPage)) {
        return prevPage + 1;
      }
      return prevPage;
    });

  // to set the number of items per page
  function handleItemsPerPage(value) {
    setItemsPerPage(value);
    setCurrentPage(1);
  }

  // to filter the data
  function filterValue(e) {
    setFilter(e.target.value);
  }

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        All Creditors List
      </p>
      
      <CContainer fluid style={{ minHeight: "90vh" }}>
        <CContainer
          fluid
          className="d-flex align-items-center justify-content-around mt-4"
        >
          <TableSearch filterValue={filterValue} currentValue={filter} />
          <DropDownPagination
            handleItemsPerPage={handleItemsPerPage}
            num={itemsPerPage}
          />
        </CContainer>
        <ViewAllCreditorTable data={currentItems} />
        <Paginate
          itemsPerPage={itemsPerPage}
          totalItems={data.length}
          paginate={paginate}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </CContainer>
      
    </>
  );
};

export default ViewAllCreditor;
