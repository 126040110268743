import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CFormSelect,
  CButtonGroup,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CFormInput,
  CModalFooter,
  CModalBody,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";
import axios from "axios";
import Cookies from "js-cookie";

const BranchChange = () => {
  let navigate = useNavigate();
  const { showAlert, loginComplete } = useContext(globalContext);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function fetchBranches() {
      try {
        const res = await axios.get("api/superAdmin/branches", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        let { branches } = res.data;
        let data = branches.map((branch) => {
          return {
            label: branch.branch,
            value: branch.branch,
          };
        });
        setBranch(data[0].value);
        setBranches(data);

      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchBranches();
  }, []);

  async function onClickHandler() {
    try {
      const res = await axios.get(`api/superAdmin/changeBranch/${branch}`, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      showAlert(`Successfully Changed Branch to ${branch}`, "2");
      localStorage.setItem(
        "token",
        JSON.stringify({ token: res.data.token, role: res.data.role })
      );
      Cookies.remove("branchName")
      Cookies.remove(`memberData`);
      Cookies.remove(`memberNo`);
      Cookies.set("branchName",branch)
      navigate("/home");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  }

  return (
    <CContainer
      fluid
      className="p-0 m-0"
      style={{
        minHeight: "87vh",
      }}
    >
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Branch Change
      </p>
      <NewBranchModal visible={visible} setVisible={setVisible} />
      <CContainer className="mt-5 " style={{ minHeight: "70vh" }}>
        <CRow>
          <CCol sm={6}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="newExpeseRequest">
                Branch Select
              </CInputGroupText>
              <CFormSelect
                value={branch}
                aria-label="Transaction Type"
                options={branches}
                onChange={(e) => {
                  setBranch(e.target.value);
                }}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={2}>
            <CContainer fluid className="d-flex">
              <CButtonGroup role="group" className="me-5">
                <CButton
                  className="text-white me-5 rounded"
                  style={{ width: "200px" }}
                  color="success"
                  onClick={onClickHandler}
                >
                  Change Branch
                </CButton>
                <CButton
                  className="text-white ms-3 rounded "
                  style={{ width: "200px" }}
                  color="info"
                  onClick={() => setVisible(!visible)}
                >
                  Create New Branch
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  );
};

function NewBranchModal({ visible, setVisible }) {
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const [branchData, setBranchData] = useState({
    branch: "",
    branchCode: "",
    branchVoucherName: "",
    societyName: "",
  });

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();

      let res = await axios.post("api/superAdmin/newBranch", branchData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      await showAlert("Successfully Added new Collector", "2");
      setTimeout(() => {
        navigate(0);
      });
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  };

  const onChangeHandler = (e) => {
    setBranchData({
      ...branchData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CModal visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader onClose={() => setVisible(false)}>
        <CModalTitle>Add New Branch</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="form-1 m-3">
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Branch Name
              <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter the name of the Branch"
              required
              type="text"
              name="branch"
              value={branchData.branch}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Branch code
              <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter Branch Code"
              required
              type="text"
              name="branchCode"
              value={branchData.branchCode}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Branch Voucher
              <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter The Branch Voucher"
              required
              type="text"
              name="branchVoucherName"
              value={branchData.branchVoucherName}
              onChange={onChangeHandler}
            />
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Society Name
              <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter The Society Name"
              required
              type="text"
              name="societyName"
              value={branchData.societyName}
              onChange={onChangeHandler}
            />
          </CInputGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler}>
          Add
        </CButton>
      </CModalFooter>
    </CModal>
  );
}

export default BranchChange;
