import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CContainer,
  CButton,
  CCard,
  CCardBody,
  CCardText,
} from "@coreui/react";
import {useContext} from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";


function PendingCreditorModal({
  visible,
  setVisible,
  showData,
  setShowData,
  accept,
  setAccept,
  data,
  setData
}) {
  const {showAlert , } = useContext(globalContext);

  const onClickHandler = async () => {
    try {

      let reqData = {
        accept: accept,
        creditor_id: showData._id,
      };
      let res = await axios.put("/api/creditors/creditorstatus", reqData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (accept === "accept") {
        showAlert("Successfully Approved Creditor" , "2");
      } else {
        showAlert("Successfully Rejected Creditor" , "2");
      }
      let memberIndex = data.findIndex(ele => {
        return ele._id == showData._id
      });
      let array = [...data];
      array.splice(memberIndex, 1);
      setData(array)

    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }    }
  };


  if (accept === "accept") {
    return (
      <CModal
        alignment="center"
        className="modal-lg"
        visible={visible}
        onClose={() => {
          setAccept("");
          setVisible(false);
          setShowData({});
        }}
      >
        <CModalHeader className="bg-success">
          <CModalTitle className="text-white">Approve Creditor</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCard>
            <CContainer
              fluid
              className="d-flex justify-content-center align-content-center"
            >
            </CContainer>
            <CCardBody>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 pb-1"
              >
                <CCardText className="m-0">
                  <b>Name:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.creditorName}</CCardText>
              </CContainer>

              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Date of Birth:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>
                  {new Date(showData.dob).toDateString()}
                </CCardText>
              </CContainer>

              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 pb-1"
              >
                <CCardText className="m-0">
                  <b>Mobile Number:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.mobileNo}</CCardText>
              </CContainer>

              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 pb-1"
              >
                <CCardText className="m-0">
                  <b>Email:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                <CCardText>{showData.email}</CCardText>
              </CContainer>

              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 pb-1"
              >
                <CCardText className="m-0">
                  <b>Creditor Type:</b>
                </CCardText>
                <CCardText>{showData.creditorType}</CCardText>
              </CContainer>   

              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center border-bottom  px-5 py-1"
              >
                <CCardText className="m-0">
                  <b>Address:</b>
                </CCardText>
                {/* <CCardText>{showData.pDetails.memberName}</CCardText> */}
                {/* <CCardText>{`${showData.address.HouseNo} , ${showData.address.Street} , ${showData.address.City} , ${showData.address.State}`}</CCardText> */}
                <CCardText>{`${showData.address}`}</CCardText>
              </CContainer>




            </CCardBody>
          </CCard>
        </CModalBody>
        <CModalFooter>
          <CButton
            className="text-white"
            color="secondary"
            onClick={() => {
              setAccept("");
              setVisible(false);
            }}
          >
            Close
          </CButton>
          <CButton
            color="success"
            className="text-white"
            onClick={() => {
              onClickHandler();
              setAccept("");
              setVisible(false);

            }}
          >
            {/* later change this to onSubmit and revmove setVisible */}
            Approve
          </CButton>
        </CModalFooter>
      </CModal>
    );
  } else if (accept === "reject") {
    return (
      <>
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => {
            setAccept("");
            setVisible(false);
            setShowData({});
          }}
        >
          <CModalHeader>
            <CModalTitle>Reject Creditor</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>
              <span>Are you sure you want to reject this Creditor request?</span>
            </p>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setAccept("");
                setVisible(false);
              }}
            >
              Close
            </CButton>
            <CButton
              color="danger"
              style={{ color: "#ffffff" }}
              onClick={() => {
                onClickHandler();
                setAccept("");
                setVisible(false);

              }}
            >
              {/* later change this to onSubmit and revmove setVisible */}
              Reject
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default PendingCreditorModal;
