import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButtonGroup,
} from "@coreui/react";
import * as XLSX from "xlsx";

import axios from "axios";
import { useState, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

const RP_Accounts = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  // const [otherAmount, setOtherAmount] = useState(null);
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });
  let onSubmitHandler = () => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `api/accounts/receiptsAndPayments/${reqData.fromReqDate}/${reqData.toReqDate}`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        setData(res.data.balances);
        console.log(res.data.balances);
      } catch (error) {
        // console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  };

  let onChangeHandler = (e) => {
    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let newData = formatData();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `RP_Accounts.csv`);
  };

  const RP_data = [
    {
      Receipts: "To Opening Balance",
      Amount: "",
      Payments: "By Salaries, Allowances",
      PaymentsAmount:
        data && data["Salaries & Allowances"]
          ? data["Salaries & Allowances"].totalDebit -
            data["Salaries & Allowances"].totalCredit
          : "0",
    },
    {
      Receipts: "Cash In Hand",
      Amount: data ? data.cashInHand : "0",
      Payments: "By Telephone & Internet",
      PaymentsAmount:
        data && data["Telephone And Internet"]
          ? data["Telephone And Internet"].totalDebit -
            data["Telephone And Internet"].totalCredit
          : "0",
    },
    {
      Receipts: "Cash At Bank",
      Amount: data ? data.cashAtBank : "0",
      Payments: "By TA, DA & Fuel",
      PaymentsAmount:
        data && data["TA, DA & Fuel Charges"]
          ? data["TA, DA & Fuel Charges"].totalDebit -
            data["TA, DA & Fuel Charges"].totalCredit
          : "0",
    },
    {
      Receipts: "To Income from Demand Loan",
      Amount:
        data && data["Income- Qard-e-Hasna"]
          ? data["Income- Qard-e-Hasna"].totalCredit -
            data["Income- Qard-e-Hasna"].totalDebit
          : "0",
      Payments: "By Electricity Charges",
      PaymentsAmount:
        data && data["Utility Charges"]
          ? data["Utility Charges"].totalDebit -
            data["Utility Charges"].totalCredit
          : "0",
    },
    {
      Receipts: "To Income from Business Goods Loan",
      Amount:
        data && data["Income- Murabaha Business Loan"]
          ? data["Income- Murabaha Business Loan"].totalCredit -
            data["Income- Murabaha Business Loan"].totalDebit
          : "0",
      Payments: "By Printing & Stationery",
      PaymentsAmount:
        data && data["Printing & Stationery Charges"]
          ? data["Printing & Stationery Charges"].totalDebit -
            data["Printing & Stationery Charges"].totalCredit
          : "0",
    },
    {
      Receipts: "To Income from Consumer Goods Loan",
      Amount:
        data && data["Income- Murabaha Consumer Loan"]
          ? data["Income- Murabaha Consumer Loan"].totalCredit -
            data["Income- Murabaha Consumer Loan"].totalDebit
          : "0",
      Payments: "By Bank Charges",
      PaymentsAmount:
        data && data["Bank Charges"]
          ? data["Bank Charges"].totalDebit - data["Bank Charges"].totalCredit
          : "0",
    },
    {
      Receipts: "To Demand Loan",
      Amount:
        data && data["Qard-e-Hasna"] ? data["Qard-e-Hasna"].totalCredit : "0",
      Payments: "By Demand Loan",
      PaymentsAmount:
        data && data["Qard-e-Hasna"] ? data["Qard-e-Hasna"].totalDebit : "0",
    },
    {
      Receipts: "To Consumer Goods Loan",
      Amount:
        data && data["Murabaha Consumer Loan"]
          ? data["Murabaha Consumer Loan"].totalCredit
          : "0",
      Payments: "By Consumer Goods Loan",
      PaymentsAmount:
        data && data["Murabaha Consumer Loan"]
          ? data["Murabaha Consumer Loan"].totalDebit
          : "0",
    },
    {
      Receipts: "To Business Goods Loan",
      Amount:
        data && data["Murabaha Business Loan"]
          ? data["Murabaha Business Loan"].totalCredit
          : "0",
      Payments: "By Business Goods Loan",
      PaymentsAmount:
        data && data["Murabaha Business Loan"]
          ? data["Murabaha Business Loan"].totalDebit
          : "0",
    },
    {
      Receipts: "To Daily Deposit",
      Amount:
        data && data["Daily Deposit"] ? data["Daily Deposit"].totalCredit : "0",
      Payments: "By Daily Deposit",
      PaymentsAmount:
        data && data["Daily Deposit"] ? data["Daily Deposit"].totalDebit : "0",
    },
    {
      Receipts: "To Savings Deposit",
      Amount:
        data && data["Savings Deposit"]
          ? data["Savings Deposit"].totalCredit
          : "0",
      Payments: "By Savings Deposit",
      PaymentsAmount:
        data && data["Savings Deposit"]
          ? data["Savings Deposit"].totalDebit
          : "0",
    },
    {
      Receipts: "To Term Deposit",
      Amount:
        data && data["Term Deposit"] ? data["Term Deposit"].totalCredit : "0",
      Payments: "By Term Deposit",
      PaymentsAmount:
        data && data["Term Deposit"] ? data["Term Deposit"].totalDebit : "0",
    },
    {
      Receipts: "To Advance Salary",
      Amount:
        data && data["Advance Salary"]
          ? data["Advance Salary"].totalCredit
          : "0",
      Payments: "By Advance Salary",
      PaymentsAmount:
        data && data["Advance Salary"]
          ? data["Advance Salary"].totalDebit
          : "0",
    },
    {
      Receipts: "To Cash Collector",
      Amount:
        data && data["Cash Collector"]
          ? data["Cash Collector"].totalCredit
          : "0",
      Payments: "By Cash Collector",
      PaymentsAmount:
        data && data["Cash Collector"]
          ? data["Cash Collector"].totalDebit
          : "0",
    },
    {
      Receipts: "To Membership Fee",
      Amount:
        data && data["Income- Membership Fee"]
          ? data["Income- Membership Fee"].totalCredit -
            data["Income- Membership Fee"].totalDebit
          : "0",
      Payments: "",
      PaymentsAmount: "",
    },
    {
      Receipts: "To Annual Fee",
      Amount:
        data && data["Income- Yearly Fee"]
          ? data["Income- Yearly Fee"].totalCredit -
            data["Income- Yearly Fee"].totalDebit
          : "0",
      Payments: "By FURNITURE & FIXTURES",
      PaymentsAmount:
        data && data["FURNITURE & FIXTURES A/c"]
          ? data["FURNITURE & FIXTURES A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "To Computer",
      Amount:
        data && data["COMPUTER A/c"] ? data["COMPUTER A/c"].totalCredit : "0",
      Payments: "By Employee Benefits",
      PaymentsAmount:
        data && data["Employee Benefits"]
          ? data["Employee Benefits"].totalDebit -
            data["Employee Benefits"].totalCredit
          : "0",
    },
    {
      Receipts: "To Primary: ShareCertificate Fee",
      Amount:
        data && data["Share Capital"] ? data["Share Capital"].totalCredit : "0",
      Payments: "By Primary: ShareCertificate Fee",
      PaymentsAmount:
        data && data["Share Capital"] ? data["Share Capital"].totalDebit : "0",
    },
    {
      Receipts: "To OFFICE EQUIPMENT",
      Amount:
        data && data["OFFICE EQUIPMENT A/c"]
          ? data["OFFICE EQUIPMENT A/c"].totalCredit
          : "0",
      Payments: "By OFFICE EQUIPMENT",
      PaymentsAmount:
        data && data["OFFICE EQUIPMENT A/c"]
          ? data["OFFICE EQUIPMENT A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "To OFFICE APPLIANCE",
      Amount:
        data && data["OFFICE APPLIANCE A/c"]
          ? data["OFFICE APPLIANCE A/c"].totalCredit
          : "0",
      Payments: "By OFFICE APPLIANCE",
      PaymentsAmount:
        data && data["OFFICE APPLIANCE A/c"]
          ? data["OFFICE APPLIANCE A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "To Loans Receivable",
      Amount:
        data && data["LOANS RECEIVABLE A/c"]
          ? data["LOANS RECEIVABLE A/c"].totalCredit
          : "0",
      Payments: "By Loans Receivable",
      PaymentsAmount:
        data && data["LOANS RECEIVABLE A/c"]
          ? data["LOANS RECEIVABLE A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "To Loans Payable",
      Amount:
        data && data["LOANS PAYABLE A/c"]
          ? data["LOANS PAYABLE A/c"].totalCredit
          : "0",
      Payments: "By Loans Payable",
      PaymentsAmount:
        data && data["LOANS PAYABLE A/c"]
          ? data["LOANS PAYABLE A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "To INCOME FROM DONATION A/c",
      Amount:
        data && data["INCOME FROM DONATION A/c"]
          ? data["INCOME FROM DONATION A/c"].totalCredit -
            data["INCOME FROM DONATION A/c"].totalDebit
          : "0",
      Payments: "By DONATION A/c",
      PaymentsAmount:
        data && data["DONATION A/c"]
          ? data["DONATION A/c"].totalDebit - data["DONATION A/c"].totalCredit
          : "0",
    },
    {
      Receipts: "To Rental Deposit",
      Amount:
        data && data["RENTAL DEPOSIT A/c"]
          ? data["RENTAL DEPOSIT A/c"].totalCredit
          : "0",
      Payments: "By Rental Deposit",
      PaymentsAmount:
        data && data["RENTAL DEPOSIT A/c"]
          ? data["RENTAL DEPOSIT A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "To Deposit for Locker",
      Amount:
        data && data["DEPOSIT FOR LOCKER A/c"]
          ? data["DEPOSIT FOR LOCKER A/c"].totalCredit
          : "0",
      Payments: "By Deposit for Locker",
      PaymentsAmount:
        data && data["DEPOSIT FOR LOCKER A/c"]
          ? data["DEPOSIT FOR LOCKER A/c"].totalDebit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Operating Expenses",
      PaymentsAmount:
        data && data["Operating Expenses"]
          ? data["Operating Expenses"].totalDebit -
            data["Operating Expenses"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By INVERTER",
      PaymentsAmount:
        data && data["INVERTER A/c"]
          ? data["INVERTER A/c"].totalDebit - data["INVERTER A/c"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Office Rental",
      PaymentsAmount:
        data && data["Office Rent"]
          ? data["Office Rent"].totalDebit - data["Office Rent"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Audit Fee",
      PaymentsAmount:
        data && data["Audit Fee"]
          ? data["Audit Fee"].totalDebit - data["Audit Fee"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Management Fee",
      PaymentsAmount:
        data && data["Management Fee"]
          ? data["Management Fee"].totalDebit -
            data["Management Fee"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By TDS",
      PaymentsAmount:
        data && data["TDS"]
          ? data["TDS"].totalDebit - data["TDS"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Miscellaneous Expenses",
      PaymentsAmount:
        data && data["Miscellaneous Expenses"]
          ? data["Miscellaneous Expenses"].totalDebit -
            data["Miscellaneous Expenses"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Staff Accommodation",
      PaymentsAmount:
        data && data["Staff Accommodation"]
          ? data["Staff Accommodation"].totalDebit -
            data["Staff Accommodation"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Office Expenses",
      PaymentsAmount:
        data && data["Office Expenses"]
          ? data["Office Expenses"].totalDebit -
            data["Office Expenses"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Computer",
      PaymentsAmount:
        data && data["COMPUTER A/c"] ? data["COMPUTER A/c"].totalDebit : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Medical Expenses",
      PaymentsAmount:
        data && data["Medical Expenses"]
          ? data["Medical Expenses"].totalDebit -
            data["Medical Expenses"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Plant & Machinery",
      PaymentsAmount:
        data && data["PLANT & MACHINERY A/c"]
          ? data["PLANT & MACHINERY A/c"].totalDebit -
            data["PLANT & MACHINERY A/c"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Land & Building",
      PaymentsAmount:
        data && data["LAND & BUILDING A/c"]
          ? data["LAND & BUILDING A/c"].totalDebit -
            data["LAND & BUILDING A/c"].totalCredit
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Electrical Goods",
      PaymentsAmount:
        data && data["ELECTRICAL GOODS A/c"]
          ? (
              data["ELECTRICAL GOODS A/c"].totalDebit -
              data["ELECTRICAL GOODS A/c"].totalCredit
            ).toString()
          : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "By Closing Balance",
      PaymentsAmount: "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "Cash In Hand",
      PaymentsAmount: data ? data.closingCash.toString() : "0",
    },
    {
      Receipts: "",
      Amount: "",
      Payments: "Cash At Bank",
      PaymentsAmount: data ? data.closingBank.toString() : "0",
    },
    {
      Receipts: "Total",
      Amount: data ? data.receiptsTotal.toString() : "0",
      Payments: "Total",
      PaymentsAmount: data ? data.paymentsTotal.toString() : "0",
    },
  ];

  const formatData = () => {
    return RP_data?.map((e, i) => {
      return {
        Receipts: e.Receipts,
        Amount: e.Amount,
        Payments: e.Payments,
        PaymentsAmount: e.PaymentsAmount,
      };
    });
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text(`Receipts & Payments Account`, 14, 16);

    doc.autoTable({
      head: [["#", "Receipts", "Amount", "Payments", "Amount"]],
      body: RP_data.map((e, i) => [
        i + 1,
        e.Receipts,
        e.Amount,
        e.Payments,
        e.PaymentsAmount,
      ]),
      startY: 30,
    });
    doc.save("RP_Accounts.pdf");
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Receipts & Payments Account
      </p>
      <CContainer>
        <CRow>
          <h5> Receipts & Payments Account </h5>
          <hr></hr>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="From_date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="To_date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={reqData.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white "
                  color="success"
                  onClick={onSubmitHandler}
                >
                  Get Details
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                  // disabled={!data || data?.length === 0}
                >
                  Export To CSV
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={exportPDF}
                  // disabled={!data || data?.length === 0}
                >
                  Print PDF
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <h5>Receipts & Payments Account</h5>
        <hr></hr>

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Receipts
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Payments
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className=" align-middle">
            <CTableRow>
              <CTableDataCell>To Opening Balance</CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Salaries, Allowances</CTableDataCell>
              <CTableDataCell>
                {data && data["Salaries & Allowances"]
                  ? data["Salaries & Allowances"].totalDebit -
                    data["Salaries & Allowances"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Cash In Hand</CTableDataCell>
              <CTableDataCell>{data ? data.cashInHand : "0"}</CTableDataCell>
              <CTableDataCell>By Telephone & Internet </CTableDataCell>
              <CTableDataCell>
                {data && data["Telephone And Internet"]
                  ? data["Telephone And Internet"].totalDebit -
                    data["Telephone And Internet"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Cash At Bank </CTableDataCell>
              <CTableDataCell>{data ? data.cashAtBank : "0"}</CTableDataCell>
              <CTableDataCell>By TA, DA & Fuel </CTableDataCell>
              <CTableDataCell>
                {data && data["TA, DA & Fuel Charges"]
                  ? data["TA, DA & Fuel Charges"].totalDebit -
                    data["TA, DA & Fuel Charges"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Income from Demand Loan </CTableDataCell>
              <CTableDataCell>
                {data && data["Income- Qard-e-Hasna"]
                  ? data["Income- Qard-e-Hasna"].totalCredit -
                    data["Income- Qard-e-Hasna"].totalDebit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Electricity Charges </CTableDataCell>
              <CTableDataCell>
                {data && data["Utility Charges"]
                  ? data["Utility Charges"].totalDebit -
                    data["Utility Charges"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>

            <CTableRow>
              <CTableDataCell>
                To Income from Business Goods Loan{" "}
              </CTableDataCell>
              <CTableDataCell>
                {data && data["Income- Murabaha Business Loan"]
                  ? data["Income- Murabaha Business Loan"].totalCredit -
                    data["Income- Murabaha Business Loan"].totalDebit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Printing & Stationery</CTableDataCell>
              <CTableDataCell>
                {data && data["Printing & Stationery Charges"]
                  ? data["Printing & Stationery Charges"].totalDebit -
                    data["Printing & Stationery Charges"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>
                To Income from Consumer Goods Loan{" "}
              </CTableDataCell>
              <CTableDataCell>
                {data && data["Income- Murabaha Consumer Loan"]
                  ? data["Income- Murabaha Consumer Loan"].totalCredit -
                    data["Income- Murabaha Consumer Loan"].totalDebit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Bank Charges</CTableDataCell>
              <CTableDataCell>
                {data && data["Bank Charges"]
                  ? data["Bank Charges"].totalDebit -
                    data["Bank Charges"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Demand Loan</CTableDataCell>
              <CTableDataCell>
                {data && data["Qard-e-Hasna"]
                  ? data["Qard-e-Hasna"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Demand Loan </CTableDataCell>
              <CTableDataCell>
                {data && data["Qard-e-Hasna"]
                  ? data["Qard-e-Hasna"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Consumer Goods Loan</CTableDataCell>
              <CTableDataCell>
                {data && data["Murabaha Consumer Loan"]
                  ? data["Murabaha Consumer Loan"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Consumer Goods Loan </CTableDataCell>
              <CTableDataCell>
                {data && data["Murabaha Consumer Loan"]
                  ? data["Murabaha Consumer Loan"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Business Goods Loan </CTableDataCell>
              <CTableDataCell>
                {data && data["Murabaha Business Loan"]
                  ? data["Murabaha Business Loan"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Business Goods Loan </CTableDataCell>
              <CTableDataCell>
                {data && data["Murabaha Business Loan"]
                  ? data["Murabaha Business Loan"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Daily Deposit </CTableDataCell>
              <CTableDataCell>
                {data && data["Daily Deposit"]
                  ? data["Daily Deposit"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Daily Deposit </CTableDataCell>
              <CTableDataCell>
                {data && data["Daily Deposit"]
                  ? data["Daily Deposit"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Savings Deposit </CTableDataCell>
              <CTableDataCell>
                {data && data["Savings Deposit"]
                  ? data["Savings Deposit"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Savings Deposit </CTableDataCell>
              <CTableDataCell>
                {data && data["Savings Deposit"]
                  ? data["Savings Deposit"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Term Deposit </CTableDataCell>
              <CTableDataCell>
                {data && data["Term Deposit"]
                  ? data["Term Deposit"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Term Deposit</CTableDataCell>
              <CTableDataCell>
                {data && data["Term Deposit"]
                  ? data["Term Deposit"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Advance Salary </CTableDataCell>
              <CTableDataCell>
                {data && data["Advance Salary"]
                  ? data["Advance Salary"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Advance Salary </CTableDataCell>
              <CTableDataCell>
                {data && data["Advance Salary"]
                  ? data["Advance Salary"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Cash Collector </CTableDataCell>
              <CTableDataCell>
                {data && data["Cash Collector"]
                  ? data["Cash Collector"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Cash Collector </CTableDataCell>
              <CTableDataCell>
                {data && data["Cash Collector"]
                  ? data["Cash Collector"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To MemberShip Fee </CTableDataCell>
              <CTableDataCell>
                {data && data["Income- Membership Fee"]
                  ? data["Income- Membership Fee"].totalCredit -
                    data["Income- Membership Fee"].totalDebit
                  : "0"}
              </CTableDataCell>
              {/* <CTableDataCell>By loan received from head office </CTableDataCell>
              <CTableDataCell>{(data && data['Cash Collector']) ? data['Cash Collector'].totalDebit : "0"}</CTableDataCell> */}
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Annual Fee</CTableDataCell>
              <CTableDataCell>
                {data && data["Income- Yearly Fee"]
                  ? data["Income- Yearly Fee"].totalCredit -
                    data["Income- Yearly Fee"].totalDebit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By FURNITURE & FIXTURES </CTableDataCell>
              <CTableDataCell>
                {data && data["FURNITURE & FIXTURES A/c"]
                  ? data["FURNITURE & FIXTURES A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Computer</CTableDataCell>
              <CTableDataCell>
                {data && data["COMPUTER A/c"]
                  ? data["COMPUTER A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Employee Benefits</CTableDataCell>
              <CTableDataCell>
                {data && data["Employee Benefits"]
                  ? data["Employee Benefits"].totalDebit -
                    data["Employee Benefits"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Primary: ShareCertificate Fee </CTableDataCell>
              <CTableDataCell>
                {data && data["Share Capital"]
                  ? data["Share Capital"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Primary: ShareCertificate Fee </CTableDataCell>
              <CTableDataCell>
                {data && data["Share Capital"]
                  ? data["Share Capital"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To OFFICE EQUIPMENT </CTableDataCell>
              <CTableDataCell>
                {data && data["OFFICE EQUIPMENT A/c"]
                  ? data["OFFICE EQUIPMENT A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By OFFICE EQUIPMENT </CTableDataCell>
              <CTableDataCell>
                {data && data["OFFICE EQUIPMENT A/c"]
                  ? data["OFFICE EQUIPMENT A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To OFFICE APPLIANCE </CTableDataCell>
              <CTableDataCell>
                {data && data["OFFICE APPLIANCE A/c"]
                  ? data["OFFICE APPLIANCE A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By OFFICE APPLIANCE </CTableDataCell>
              <CTableDataCell>
                {data && data["OFFICE APPLIANCE A/c"]
                  ? data["OFFICE APPLIANCE A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Loans Recievable</CTableDataCell>
              <CTableDataCell>
                {data && data["LOANS RECEIVABLE A/c"]
                  ? data["LOANS RECEIVABLE A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Loans Recievable</CTableDataCell>
              <CTableDataCell>
                {data && data["LOANS RECEIVABLE A/c"]
                  ? data["LOANS RECEIVABLE A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Loans Payable</CTableDataCell>
              <CTableDataCell>
                {data && data["LOANS PAYABLE A/c"]
                  ? data["LOANS PAYABLE A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Loans Payable</CTableDataCell>
              <CTableDataCell>
                {data && data["LOANS PAYABLE A/c"]
                  ? data["LOANS PAYABLE A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To INCOME FROM DONATION A/c</CTableDataCell>
              <CTableDataCell>
                {data && data["INCOME FROM DONATION A/c"]
                  ? data["INCOME FROM DONATION A/c"].totalCredit -
                    data["INCOME FROM DONATION A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By DONATION A/c</CTableDataCell>
              <CTableDataCell>
                {data && data["DONATION A/c"]
                  ? data["DONATION A/c"].totalDebit -
                    data["DONATION A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Rental Deposit</CTableDataCell>
              <CTableDataCell>
                {data && data["RENTAL DEPOSIT A/c"]
                  ? data["RENTAL DEPOSIT A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Rental Deposit</CTableDataCell>
              <CTableDataCell>
                {data && data["RENTAL DEPOSIT A/c"]
                  ? data["RENTAL DEPOSIT A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>To Deposit for Locker</CTableDataCell>
              <CTableDataCell>
                {data && data["DEPOSIT FOR LOCKER A/c"]
                  ? data["DEPOSIT FOR LOCKER A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
              <CTableDataCell>By Deposit for Locker</CTableDataCell>
              <CTableDataCell>
                {data && data["DEPOSIT FOR LOCKER A/c"]
                  ? data["DEPOSIT FOR LOCKER A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Operating Expenses</CTableDataCell>
              <CTableDataCell>
                {data && data["Operating Expenses"]
                  ? data["Operating Expenses"].totalDebit -
                    data["Operating Expenses"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By INVERTER</CTableDataCell>
              <CTableDataCell>
                {data && data["INVERTER A/c"]
                  ? data["INVERTER A/c"].totalDebit -
                    data["INVERTER A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Office Rental</CTableDataCell>
              <CTableDataCell>
                {data && data["Office Rent"]
                  ? data["Office Rent"].totalDebit -
                    data["Office Rent"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Audit Fee</CTableDataCell>
              <CTableDataCell>
                {data && data["Audit Fee"]
                  ? data["Audit Fee"].totalDebit - data["Audit Fee"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Management Fee</CTableDataCell>
              <CTableDataCell>
                {data && data["Management Fee"]
                  ? data["Management Fee"].totalDebit -
                    data["Management Fee"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By TDS</CTableDataCell>
              <CTableDataCell>
                {data && data["TDS"]
                  ? data["TDS"].totalDebit - data["TDS"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Miscellaneous Expenses</CTableDataCell>
              <CTableDataCell>
                {data && data["Miscellaneous Expenses"]
                  ? data["Miscellaneous Expenses"].totalDebit -
                    data["Miscellaneous Expenses"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Staff Accomodation</CTableDataCell>
              <CTableDataCell>
                {data && data["Staff Accommodation"]
                  ? data["Staff Accommodation"].totalDebit -
                    data["Staff Accommodation"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Office Expenses</CTableDataCell>
              <CTableDataCell>
                {data && data["Office Expenses"]
                  ? data["Office Expenses"]?.totalDebit -
                    data["Office Expenses"]?.totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Computer</CTableDataCell>
              <CTableDataCell>
                {data && data["COMPUTER A/c"]
                  ? data["COMPUTER A/c"].totalDebit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Medical Expenses</CTableDataCell>
              <CTableDataCell>
                {data && data["Medical Expenses"]
                  ? data["Medical Expenses"].totalDebit -
                    data["Medical Expenses"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Plant & Machinery</CTableDataCell>
              <CTableDataCell>
                {data && data["PLANT & MACHINERY A/c"]
                  ? data["PLANT & MACHINERY A/c"].totalDebit -
                    data["PLANT & MACHINERY A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Land & Building</CTableDataCell>
              <CTableDataCell>
                {data && data["LAND & BUILDING A/c"]
                  ? data["LAND & BUILDING A/c"].totalDebit -
                    data["LAND & BUILDING A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>By Electrical Goods</CTableDataCell>
              <CTableDataCell>
                {data && data["ELECTRICAL GOODS A/c"]
                  ? data["ELECTRICAL GOODS A/c"].totalDebit -
                    data["ELECTRICAL GOODS A/c"].totalCredit
                  : "0"}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell> </CTableDataCell>
              <CTableDataCell>By Closing Balance</CTableDataCell>
              <CTableDataCell> </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell> </CTableDataCell>
              <CTableDataCell>Cash In Hand</CTableDataCell>
              <CTableDataCell> {data ? data.closingCash : "0"}</CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell> Cash At Bank </CTableDataCell>
              <CTableDataCell>{data ? data.closingBank : "0"}</CTableDataCell>
            </CTableRow>

            <CTableRow>
              <CTableDataCell>
                <u>Total</u>
              </CTableDataCell>
              <CTableDataCell>{data ? data.receiptsTotal : 0}</CTableDataCell>
              <CTableDataCell>
                <u>Total</u>
              </CTableDataCell>
              <CTableDataCell>{data ? data.paymentsTotal : 0}</CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default RP_Accounts;
