import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormText,
  CImage,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import React from "react";
import globalContext from "../contexts/Global/globalContext";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';

function Editmember() {
  const { authCheck, showAlert, } = useContext(globalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  function cancelButton() {
    navigate("/home");
  }

  const { state } = useLocation();
  const [editmemberData, setEditMemberData] = useState({
    memberType: "",
    memberName: "",
    fatherHusbandName: "",
    adhaarNumber: "",
    gender: "",
    age: "",
    dob: "",
    email: "",
    caste: "",
    religion: "",
    occupation: "",
    mobileNo: "",
    bussinessType: "",
    goodsService: "",
    locationType: "",
    bussinessDesc: "",
    pHouseNo: "",
    pCity: "",
    pStreet: "",
    pCountry: "",
    pState: "",
    annualIncome: "",
    nomineeName: "",
    nomineeRel: "",
    nomineeDOB: "",
    nHouseNo: "",
    nCity: "",
    nStreet: "",
    nCountry: "",
    nState: "",
    iName: "",
    iMembershipID: "",
    iMobileNo: "",
    memberImage: "",
    signatureImage: "",
    image1: "",
    image2: "",
    image3: "",
  });


  useEffect(() => {
    async function fetchUserData() {
      try {
        const res = await axios.get(`/api/member/edit/${state.e}`, {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        })
        const userData = res.data.member

        setEditMemberData({
          memberType: userData.memberType,
          memberName: userData.pDetails.memberName,
          fatherHusbandName: userData.pDetails.fatherHusbandName,
          adhaarNumber: userData.pDetails.adhaarNumber,
          gender: userData.pDetails.gender,
          age: userData.pDetails.age,
          dob: userData.pDetails.dob,
          email: userData.pDetails.email,
          caste: userData.pDetails.caste,
          religion: userData.pDetails.religion,
          occupation: userData.pDetails.occupation,
          mobileNo: userData.mobileNo,
          bussinessType: userData.bussinessType,
          goodsService: userData.goodsService,
          locationType: userData.locationType,
          bussinessDesc: userData.bussinessDesc,
          pHouseNo: userData.pAddress.pHouseNo,
          pCity: userData.pAddress.pCity,
          pStreet: userData.pAddress.pStreet,
          pCountry: userData.pAddress.pCountry,
          pState: userData.pAddress.pState,
          annualIncome: userData.annualIncome,
          nomineeName: userData.nominee?.nomineeName,
          nomineeRel: userData.nominee?.nomineeRel || "",
          nomineeDOB: userData.nominee?.nomineeDOB || "",
          nHouseNo: userData.nominee?.nomineeAddress.nHouseNo,
          nCity: userData.nominee?.nomineeAddress.nCity,
          nStreet: userData.nominee?.nomineeAddress.nStreet,
          nCountry: userData.nominee?.nomineeAddress.nCountry,
          nState: userData.nominee?.nomineeAddress.nState,
          iName: userData.introducer?.iName,
          iMembershipID: userData.introducer?.iMembershipID,
          iMobileNo: userData.introducer?.iMobileNo,
          memberImage: userData.memberImage?.data,
          signatureImage: userData.signatureImage?.data,
          image1: userData.establishmentImages?.image1?.data,
          image2: userData.establishmentImages?.image2?.data,
          image3: userData.establishmentImages?.image3?.data,
        });

      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3")
          });
        } else {
          showAlert(error.response.data.error, "3")
        }
      }
    }
    fetchUserData()

  }, [])

  // const [editmemberData, setEditMemberData] = useState({
  //   memberType: state.mem.memberType,
  //   memberName: state.mem.pDetails.memberName,
  //   fatherHusbandName: state.mem.pDetails.fatherHusbandName,
  //   adhaarNumber: state.mem.pDetails.adhaarNumber,
  //   gender: state.mem.pDetails.gender,
  //   age: state.mem.pDetails.age,
  //   dob: state.mem.pDetails.dob,
  //   email: state.mem.pDetails.email,
  //   caste: state.mem.pDetails.caste,
  //   religion: state.mem.pDetails.religion,
  //   occupation: state.mem.pDetails.occupation,
  //   mobileNo: state.mem.mobileNo,
  //   bussinessType: state.mem.bussinessType,
  //   goodsService: state.mem.goodsService,
  //   locationType: state.mem.locationType,
  //   bussinessDesc: state.mem.bussinessDesc,

  //   // bHouseNo: state.mem.bAddress.bHouseNo,
  //   // bCity: state.mem.bAddress.bCity,
  //   // bStreet: state.mem.bAddress.bStreet,
  //   // bCountry: state.mem.bAddress.bCountry,
  //   // bState: state.mem.bAddress.bState,

  //   pHouseNo: state.mem.address.pHouseNo,
  //   pCity: state.mem.address.pCity,
  //   pStreet: state.mem.address.pStreet,
  //   pCountry: state.mem.address.pCountry,
  //   pState: state.mem.address.pState,
  //   annualIncome: state.mem.annualIncome,

  //   nomineeName: state.mem.nominee?.nomineeName,
  //   nomineeRel: state.mem.nominee?.nomineeRel || "",
  //   nomineeDOB: state.mem.nominee?.nomineeDOB || "",
  //   nHouseNo: state.mem.nominee?.nomineeAddress.nHouseNo,
  //   nCity: state.mem.nominee?.nomineeAddress.nCity,
  //   nStreet: state.mem.nominee?.nomineeAddress.nStreet,
  //   nCountry: state.mem.nominee?.nomineeAddress.nCountry,
  //   nState: state.mem.nominee?.nomineeAddress.nState,

  //   iName: state.mem.introducer?.iName,
  //   iMembershipID: state.mem.introducer?.iMembershipID,
  //   iMobileNo: state.mem.introducer?.iMobileNo,

  //   memberImage: state.mem.memberImage?.data,
  //   signatureImage: state.mem.signatureImage?.data,
  //   image1: state.mem.establishmentImages?.image1.data,
  //   image2: state.mem.establishmentImages?.image2.data,
  //   image3: state.mem.establishmentImages?.image3.data,
  // });

  const [previewImages, setPreviewImages] = useState([].fill(null));
  const [show, setShow] = useState({});
  const [bmargin, setBmargin] = useState({});

  async function fileSelectedHandler(e, index) {
    let file = e.target.files[0];
    const options = {
      maxSizeMB: 0.097,
      maxWidthOrHeight: 1920
    }
    // *
    const newPreviewImages = [...previewImages];
    try {
      const compressedFile = await imageCompression(file, options);

      var file_blob = new File([compressedFile], `${compressedFile.name}`, { type: compressedFile.type });
      setEditMemberData({
        ...editmemberData,
        [e.target.name]: {
          data: file_blob,
          contentType: file_blob.type,
        },
      });
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }

    const imageSrc = URL.createObjectURL(e.target.files[0]);
    if (newPreviewImages[index] === null) {
      /* insert image at the index of the array */
      newPreviewImages.splice(index, 0, imageSrc);
    } else {
      /* replace the previous image at index */
      newPreviewImages[index] = imageSrc;
    }
    setPreviewImages(newPreviewImages);
  }

  const onChangeHandler = (e) => {
    if (e.target.name === "occupation" && e.target.value !== "Business") {
      setShow({ display: "none" });
      setBmargin({ marginTop: "190px" });
    } else if (
      e.target.name === "occupation" &&
      e.target.value === "Business"
    ) {
      setShow({});
      setBmargin({ marginTop: "85px" });
    }

    if (e.target.name == "dob") {
      let today = new Date();
      let birth = new Date(e.target.value);
      const diff = Math.abs(today - birth);

      const calcAge = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

      setEditMemberData({
        ...editmemberData,
        [e.target.name]: e.target.value,
        age: calcAge,
      });

    } else {
      setEditMemberData({
        ...editmemberData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    try {
      e.preventDefault();

      let res = await axios.put(
        `/api/member/${state.e}`,
        editmemberData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      showAlert("Successfully edited", "2")
      navigate("/home");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <CForm className="add-card mb-5" onSubmit={onSubmitHandler}>
        <p
          style={{
            background: "#2364aa",
          }}
          className="fw-bold text-white fs-2 text-center p-1"
        >
          Edit Member Form
        </p>
        <CContainer className="form-container d-flex p-3">
          <CContainer className="form-1 m-2">
            <p>User Details </p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Full Name
                <span style={{ fontWeight: "lighter" }} className="text-danger">
                  *
                </span>
              </CInputGroupText>
              <CFormInput
                placeholder="Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                name="memberName"
                value={editmemberData.memberName}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Paternal Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="Father/Husband name"
                aria-label="Father name"
                aria-describedby="basic-addon1"
                required
                name="fatherHusbandName"
                value={editmemberData.fatherHusbandName}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" required>
                Gender
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="gender"
                value={editmemberData.gender}
                onChange={onChangeHandler}
              >
                <option>select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>

                <option value="Other">Other</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Date of Birth
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx/xx/xxxx"
                type="date"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                name="dob"
                value={
                  editmemberData.dob && new Date(editmemberData.dob).toISOString().split("T")[0]
                } onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" required>
                Religion
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="religion"
                value={editmemberData.religion}
                onChange={onChangeHandler}
              >
                <option>Select</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Muslim">Muslim</option>
                <option value="Christanity">Christanity</option>
                <option value="Shikhism">Shikhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Buddhisum">Buddhisum</option>
                <option value="Others">Others</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Email</CInputGroupText>
              <CFormInput
                type="email"
                placeholder="example@email.com"
                aria-label="Email"
                aria-describedby="basic-addon1"
                name="email"
                value={editmemberData.email}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <hr />

            {/* <p className="mb-2">Address *</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="houseNo"
                value={editmemberData.houseNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                required
                name="city"
                value={editmemberData.city}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                required
                name="country"
                value={editmemberData.country}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <hr /> */}

            <p className="mb-2">Permanent Address *</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="pHouseNo"
                value={editmemberData.pHouseNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                required
                name="pCity"
                value={editmemberData.pCity}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                required
                name="pCountry"
                value={editmemberData.pCountry}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <hr />

            <p className="mb-2">Business *</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Occupation
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                required
                size="sm"
                name="occupation"
                value={editmemberData.occupation}
                onChange={onChangeHandler}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Business">Business</option>
                <option value="Trust">Trust</option>
                <option value="NGO">NGO</option>
                <option value="Society">Society</option>
                <option value="Selfhelp Group">Selfhelp Group</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Govt. Service">Govt. Service</option>
                <option value="Pvt. Service">Pvt. Service</option>
                <option value="Professional">Professional</option>
                <option value="Student">Student</option>
                <option value="Retired">Retired</option>
              </CFormSelect>
            </CInputGroup>
            <CContainer className="business" style={show}>
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Good Services
                  {/* <span style={{ color: "red", fontWeight: "lighter" }}>*</span> */}
                </CInputGroupText>
                <CFormSelect
                  size="sm"
                  name="goodsService"
                  value={editmemberData.goodsService}
                  onChange={onChangeHandler}
                >
                  <option>Select</option>
                  <option value="1">Goods</option>

                  <option value="3">Service</option>
                </CFormSelect>
              </CInputGroup>

              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Location Type
                  {/* <span style={{ color: "red", fontWeight: "lighter" }}>*</span> */}
                </CInputGroupText>
                <CFormSelect
                  size="sm"
                  // required
                  name="locationType"
                  value={editmemberData.locationType}
                  onChange={onChangeHandler}
                >
                  <option>Select Type</option>
                  <option value="Fixed">Fixed</option>
                  <option value="Moving">Moving</option>
                </CFormSelect>
              </CInputGroup>
            </CContainer>

            <hr />

            <p className="mb-2">Business Address *</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                // required
                name="bHouseNo"
                value={editmemberData.bHouseNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                // required
                name="bCity"
                value={editmemberData.bCity}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                // required
                name="bCountry"
                value={editmemberData.bCountry}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <hr />
            <p className="mb-2">Nominee *</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                required
                name="nomineeName"
                value={editmemberData.nomineeName}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Date of Birth
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="DOB"
                aria-describedby="basic-addon1"
                required
                name="nomineeDOB"
                value={
                  editmemberData.nomineeDOB
                    ? new Date(editmemberData.nomineeDOB).toISOString().split("T")[0]
                    : ""
                }
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <p className="mb-2">Nominee Address *</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="nHouseNo"
                value={editmemberData.nHouseNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                required
                name="nCity"
                value={editmemberData.nCity}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                required
                name="nCountry"
                value={editmemberData.nCountry}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <hr />
            <p className="mb-2">Introducer</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Member Id</CInputGroupText>
              <CFormInput
                type="number"
                placeholder="xxxxx"
                aria-label="DOB"
                aria-describedby="basic-addon1"
                name="iMembershipID"
                value={editmemberData.iMembershipID}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Phone No </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="xxxxxxxxx"
                aria-label="Name"
                aria-describedby="basic-addon1"
                name="iMobileNo"
                value={editmemberData.iMobileNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <hr />

            {/* <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Share purchased
              </CInputGroupText>
              <CFormInput
                placeholder="Date"
                aria-label="Name"
                aria-describedby="basic-addon1"
                name="sharePurchaseDate"
                value={editmemberData.sharePurchaseDate}
                onChange={onChangeHandler}
              />
            </CInputGroup> */}
            {/* <hr /> */}
            <p className="mb-2">Images</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Member Image </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 0)}
                name="memberImage"
                accept="image/*"
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Establishment: 1
              </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 2)}
                name="image1"
                accept="image/*"
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Establishment: 3
              </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 4)}
                name="image3"
                accept="image/*"
              />
            </CInputGroup>
            {/* <CFormInput
              type="submit"
              className="m-2"
              style={{
                display: "inline",
                width: "30%",
                background: "green",
                color: "white",
                fontWeight: "bolder",
              }}
            /> */}
            {/* <CFormInput
              type="button"
              className="m-2"
              value="Cancel"
              style={{
                display: "inline",
                width: "30%",
                background: "red",
                color: "white",
                fontWeight: "bolder",
              }}
            /> */}
            <CContainer fluid className="d-flex justify-content-around">
              <CButton
                disabled={isSubmitting}
                type="submit"
                size="lg"
                color="success"
                className="m-2 text-white fw-bold"
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </CButton>
              <CButton
                type="button"
                size="lg"
                color="danger"
                className="m-2 text-white fw-bold"
                onClick={cancelButton}
              >
                Cancel
              </CButton>
            </CContainer>
            {/* {alert !== null && <h3 id="err-msg" className={`alert-${alert.type}`}>{alert.msg}</h3>} */}
          </CContainer>

          {/* -------------------Right CContainer------------------------------------------------------------------------------ */}
          <CContainer className="form-2 m-2">
            <p>
              <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              Fields are mandatory
            </p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" required>
                Member Type
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="memberType"
                value={editmemberData.memberType}
                onChange={onChangeHandler}
              >
                <option>Select</option>
                <option value="Primary">Primary</option>
                <option value="Assosiate Organization">
                  Assosiate Organization
                </option>
                <option value="Assosiate InCContaineridual">
                  Assosiate InCContaineridual
                </option>
                <option value="Nominal">Nominal</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Adhaar Number
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xxxxxxxxxxxx"
                type="number"
                aria-label="Adhaar Number"
                aria-describedby="basic-addon1"
                required
                name="adhaarNumber"
                value={editmemberData.adhaarNumber}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Age
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                readOnly
                name="age"
                value={editmemberData.age}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Caste</CInputGroupText>
              <CFormSelect
                size="sm"
                name="caste"
                value={editmemberData.caste}
                onChange={onChangeHandler}
              >
                <option>Select</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
                <option value="OBC">OBC</option>
                <option value="SBC">SBC</option>
                <option value="Other">Other</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Annual Income
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                name="annualIncome"
                value={editmemberData.annualIncome}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Phone No.
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                name="mobileNo"
                value={editmemberData.mobileNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <hr />

            {/* <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" style={{ marginTop: "33px" }}>
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                style={{ marginTop: "33px" }}
                required
                name="street"
                value={editmemberData.street}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                required
                name="state"
                value={editmemberData.state}
                onChange={onChangeHandler}
              />
            </CInputGroup> */}

            <CInputGroup className="mb-3" style={{ marginTop: "48px" }}>
              <CInputGroupText id="basic-addon1">
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="pStreet"
                value={editmemberData.pStreet}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                required
                name="pState"
                value={editmemberData.pState}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            {/* <CInputGroup className="mb-3" style={{ marginTop: "119px" }}>
              <CInputGroupText id="basic-addon1">Credit Limit</CInputGroupText>
              <CFormInput
                type="number"
                placeholder="Credit"
                aria-label="Credit"
                aria-describedby="basic-addon1"
              />
            </CInputGroup> */}
            <CContainer className="business" style={show}>
              <CInputGroup className="mb-3" style={{ marginTop: "119px" }}>
                <CInputGroupText id="basic-addon1">
                  Business Type
                </CInputGroupText>
                <CFormSelect
                  size="sm"
                  name="bussinessType"
                  value={editmemberData.bussinessType}
                  onChange={onChangeHandler}
                >
                  <option value="">--Select--</option>
                  <option value="Acid Business">Acid Business</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Air Conditioner and Fridge Repair">
                    Air Conditioner and Fridge Repair
                  </option>
                  <option value="Artificial Jewellery">
                    Artificial Jewellery
                  </option>
                  <option value="Attar Shop">Attar Shop</option>
                  <option value="Auto Consultancy">Auto Consultancy</option>
                  <option value="Auto Electrician">Auto Electrician</option>
                  <option value="Auto Mobile Spare Parts">
                    Auto Mobile Spare Parts
                  </option>
                  <option value="Automobile Mechanic">
                    Automobile Mechanic
                  </option>
                  <option value="Automobile Washing Centre">
                    Automobile Washing Centre
                  </option>
                  <option value="Baby Store">Baby Store</option>
                  <option value="Bags Business">Bags Business</option>
                  <option value="Bakery &amp; Sweets">
                    Bakery &amp; Sweets
                  </option>
                  <option value="Band set &amp; Musical Instruments">
                    Band set &amp; Musical Instruments
                  </option>
                  <option value="Bangle Store">Bangle Store</option>
                  <option value="Battery Shop">Battery Shop</option>
                  <option value="Bearing Works">Bearing Works</option>
                  <option value="Beauty parlour">Beauty parlour</option>
                  <option value="Bedding &amp; Mattresses">
                    Bedding &amp; Mattresses
                  </option>
                  <option value="Beedi Works">Beedi Works</option>
                  <option value="Building Contractor">
                    Building Contractor
                  </option>
                  <option value="Building Material Store">
                    Building Material Store
                  </option>
                  <option value="Cable Network Operator">
                    Cable Network Operator
                  </option>
                  <option value="Caps">Caps</option>
                  <option value="Car Decorator">Car Decorator</option>
                  <option value="Carpenter">Carpenter</option>
                  <option value="Catering">Catering</option>
                  <option value="Cattle business">Cattle business</option>
                  <option value="Cattle Food">Cattle Food</option>
                  <option value="CCTV Works">CCTV Works</option>
                  <option value="Cell phone Repairing &amp; Sales">
                    Cell phone Repairing &amp; Sales
                  </option>
                  <option value="Cement Rings Manufacturing">
                    Cement Rings Manufacturing
                  </option>
                  <option value="Centring Material">Centring Material</option>
                  <option value="Centring Material Or steel Or wood">
                    Centring Material Or steel Or wood
                  </option>
                  <option value="Chair Repair Works">Chair Repair Works</option>
                  <option value="Charcoal Business">Charcoal Business</option>
                  <option value="Chicken Centre or Mutton">
                    Chicken Centre or Mutton
                  </option>
                  <option value="Chicken, Mutton &amp; Fish Centre">
                    Chicken, Mutton &amp; Fish Centre
                  </option>
                  <option value="Coconut Business">Coconut Business</option>
                  <option value="Computer Sales &amp; Service">
                    Computer Sales &amp; Service
                  </option>
                  <option value="Confectionary Shop">Confectionary Shop</option>
                  <option value="Cool Drink and Juice Shop">
                    Cool Drink and Juice Shop
                  </option>
                  <option value="Cotton Dealer">Cotton Dealer</option>
                  <option value="Crane Works">Crane Works</option>
                  <option value="Crockery Shop">Crockery Shop</option>
                  <option value="Cycle Mechanic">Cycle Mechanic</option>
                  <option value="Cycle Store">Cycle Store</option>
                  <option value="Dairy">Dairy</option>
                  <option value="Dental Clinic">Dental Clinic</option>
                  <option value="Denting Works">Denting Works</option>
                  <option value="Dry Cleaning">Dry Cleaning</option>
                  <option value="DTP Xerox Internet Cafe">
                    DTP Xerox Internet Cafe
                  </option>
                  <option value="Earth Movers Business">
                    Earth Movers Business
                  </option>
                  <option value="Eggs Business">Eggs Business</option>
                  <option value="Electrical  Shop">Electrical Shop</option>
                  <option value="Electrical Works">Electrical Works</option>
                  <option value="Electronics &amp; Electricals Servicing">
                    Electronics &amp; Electricals Servicing
                  </option>
                  <option value="Electronics &amp; Home Appliances Shop">
                    Electronics &amp; Home Appliances Shop
                  </option>
                  <option value="Fish Business">Fish Business</option>
                  <option value="Florist">Florist</option>
                  <option value="Flour Mill or Wet Grinder">
                    Flour Mill or Wet Grinder
                  </option>
                  <option value="Foot Wear">Foot Wear</option>
                  <option value="Fruits and Vegetables Business">
                    Fruits and Vegetables Business
                  </option>
                  <option value="Function Hall">Function Hall</option>
                  <option value="Furniture">Furniture</option>
                  <option value="Garments">Garments</option>
                  <option value="Gas Stove Business">Gas Stove Business</option>
                  <option value="General and Fancy Store">
                    General and Fancy Store
                  </option>
                  <option value="General Engineering Works">
                    General Engineering Works
                  </option>
                  <option value="Gifts and Article Shops">
                    Gifts and Article Shops
                  </option>
                  <option value="Glass and Aluminium">
                    Glass and Aluminium
                  </option>
                  <option value="Glass and Aluminium Dealer">
                    Glass and Aluminium Dealer
                  </option>
                  <option value="Gold Smith and Silver Makers">
                    Gold Smith and Silver Makers
                  </option>
                  <option value="Gunny Bags Business">
                    Gunny Bags Business
                  </option>
                  <option value="Gym">Gym</option>
                  <option value="Hair cutting Saloon">
                    Hair cutting Saloon
                  </option>
                  <option value="Handicraft">Handicraft</option>
                  <option value="Hard Ware Shop">Hard Ware Shop</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Ice Cream">Ice Cream</option>
                  <option value="Ice Factory">Ice Factory</option>
                  <option value="Interior Designing">Interior Designing</option>
                  <option value="Jewellery">Jewellery</option>
                  <option value="Key Repair Works">Key Repair Works</option>
                  <option value="Kirana">Kirana</option>
                  <option value="Kirana Store">Kirana Store</option>
                  <option value="Ladies Emporium">Ladies Emporium</option>
                  <option value="Leather Works">Leather Works</option>
                  <option value="Lighting and Decoration">
                    Lighting and Decoration
                  </option>
                  <option value="Lodge">Lodge</option>
                  <option value="Matrimony">Matrimony</option>
                  <option value="Medical Lab">Medical Lab</option>
                  <option value="Medical Shop">Medical Shop</option>
                  <option value="Mineral Water">Mineral Water</option>
                  <option value="Mineral Water Plant">
                    Mineral Water Plant
                  </option>
                  <option value="Motor Rewinding and Repair">
                    Motor Rewinding and Repair
                  </option>
                  <option value="Nursery">Nursery</option>
                  <option value="Oil Mill">Oil Mill</option>
                  <option value="Optical Shop">Optical Shop</option>
                  <option value="Others">Others</option>
                  <option value="Pan Shop">Pan Shop</option>
                  <option value="Parking Stand">Parking Stand</option>
                  <option value="Pesticides &amp; Fertilizers Shop">
                    Pesticides &amp; Fertilizers Shop
                  </option>
                  <option value="Photo Studio or Videography">
                    Photo Studio or Videography
                  </option>
                  <option value="Physiotherapy Centre">
                    Physiotherapy Centre
                  </option>
                  <option value="Pickles">Pickles</option>
                  <option value="Plastic items">Plastic items</option>
                  <option value="Plumbing Works">Plumbing Works</option>
                  <option value="POP (Interior Designing Works)">
                    POP (Interior Designing Works)
                  </option>
                  <option value="Popcorn">Popcorn</option>
                  <option value="Printers">Printers</option>
                  <option value="Radium &amp; Stickers">
                    Radium &amp; Stickers
                  </option>
                  <option value="Rexine Works">Rexine Works</option>
                  <option value="Rice Business">Rice Business</option>
                  <option value="Rice Trader">Rice Trader</option>
                  <option value="Rubber Stamp Works">Rubber Stamp Works</option>
                  <option value="Sanitary Marts">Sanitary Marts</option>
                  <option value="Saree Business">Saree Business</option>
                  <option value="Saw Mill">Saw Mill</option>
                  <option value="School/College">School/College</option>
                  <option value="Scrap">Scrap</option>
                  <option value="Spices">Spices</option>
                  <option value="Spray Painting and Tinkering Shop">
                    Spray Painting and Tinkering Shop
                  </option>
                  <option value="Stamp Paper Vendor">Stamp Paper Vendor</option>
                  <option value="Stationary">Stationary</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Tailoring and Embroidery">
                    Tailoring and Embroidery
                  </option>
                  <option value="Taxi Car or Cabs">Taxi Car or Cabs</option>
                  <option value="Tent House">Tent House</option>
                  <option value="Tiffin and Tea Stall">
                    Tiffin and Tea Stall
                  </option>
                  <option value="Timber Depot">Timber Depot</option>
                  <option value="Tours &amp; Travels">
                    Tours &amp; Travels
                  </option>
                  <option value="Transport">Transport</option>
                  <option value="Tyre Repair or Changing or Air Compressor">
                    Tyre Repair or Changing or Air Compressor
                  </option>
                  <option value="Watch Sales &amp; Service">
                    Watch Sales &amp; Service
                  </option>
                  <option value="Welding Shop">Welding Shop</option>
                </CFormSelect>
              </CInputGroup>

              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Business Description
                </CInputGroupText>
                <CFormInput
                  type="text"
                  placeholder="Optional..."
                  aria-label="Description"
                  aria-describedby="basic-addon1"
                  name="bussinessDesc"
                  value={editmemberData.bussinessDesc}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            </CContainer>

            <CInputGroup className="mb-3" style={bmargin}>
              <CInputGroupText id="basic-addon1" style={{ marginTop: "33px" }}>
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                style={{ marginTop: "33px" }}
                // required
                name="bStreet"
                value={editmemberData.bStreet}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>
                  *
                </span>{" "}
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                // required
                name="bState"
                value={editmemberData.bState}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "118px" }}>
              <CInputGroupText id="basic-addon1">
                Relationship
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Relation name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                required
                name="nomineeRel"
                value={editmemberData.nomineeRel}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "101px" }}>
              <CInputGroupText id="basic-addon1">
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="nStreet"
                value={editmemberData.nStreet}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                required
                name="nState"
                value={editmemberData.nState}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "119px" }}>
              <CInputGroupText id="basic-addon1">Name </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                name="iName"
                value={editmemberData.iName}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "120px" }}>
              <CInputGroupText id="basic-addon1">Sign Image</CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 1)}
                name="signatureImage"
                accept="image/*"
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Establishment: 2
              </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 3)}
                name="image2"
                accept="image/*"
              />
            </CInputGroup>
            <CContainer className="m-2 border border-primary border-3 rounded p-2">
              <CFormText className="fw-bold">New Images</CFormText>
              {previewImages.map((previewImage) =>
                (previewImage !== null) & (previewImage !== undefined) ? (
                  //if previewImage is not null
                  <img
                    src={previewImage}
                    alt="preview"
                    className="image-input"
                  />
                ) : null
              )}
            </CContainer>

            <CContainer className="m-2 border border-primary border-3 rounded p-2">
              <CContainer
                fluid
                className="d-flex align-items-center justify-content-between p-0"
              >
                <CFormText className="fw-bold m-0">Prev Images</CFormText>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#FF0000"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </CContainer>
              <CImage
                className="image-input"
                src={`./${state.mem.memberImage?.data}`}
              />
              <CImage
                className="image-input"
                src={`./${state.mem.signatureImage?.data}`}
              />
              <CImage
                className="image-input"
                src={`./${state.mem.establishmentImages?.image1.data}`}
              />
              <CImage
                className="image-input"
                src={`./${state.mem.establishmentImages?.image2.data}`}
              />
              <CImage
                className="image-input"
                src={`./${state.mem.establishmentImages?.image3.data}`}
              />
            </CContainer>
          </CContainer>
        </CContainer>
      </CForm>
    </>
  );
}

export default Editmember;
