import React from 'react'
import {
    CContainer,
    CInputGroup,
    CInputGroupText,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CForm,
    CFormSelect
  } from "@coreui/react";

function Userlicense() {
  return (
    <>
    <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        User Licence
      </p>
      
      <CContainer fluid className='w-50 m-3 '>
          <legend className='ms-5'>User Licence Details</legend>
        <CForm className='d-flex ms-5'>
            <CInputGroup className='ms-5 w-50 text-center'>
                <CInputGroupText className='fw-bold w-25 ' >Year</CInputGroupText>
                <CFormSelect
                size="sm"
                name="memberType"
                
              >
                <option>------Select-----</option>
                <option value="2014">2014</option>
                <option value="2015">
                  2015
                </option>
                <option value="2016">
                  2016
                </option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2017">2017</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
              </CFormSelect>
            </CInputGroup>
            <CButton type='submit' size='lg' color='info' className='ms-5 text-white fw-bold'>Show</CButton>
        </CForm>
        </CContainer>
       <hr/>
      <CContainer className=' '>
      <CTable bordered striped className="shadow w-50">
  <CTableHead className="bg-dark text-center text-white">
    <CTableRow>
      <CTableHeaderCell scope="col">Month</CTableHeaderCell>
      <CTableHeaderCell scope="col">Mobile Users Count</CTableHeaderCell>
    </CTableRow>
  </CTableHead>
  <CTableBody>
    <CTableRow className='text-center align-middle'>
      <CTableHeaderCell scope="row">January</CTableHeaderCell>
      <CTableDataCell>5</CTableDataCell>
      
    </CTableRow>
   
  </CTableBody>
</CTable>
      </CContainer>
      
    </>
  )
}

export default Userlicense