import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";

const ViewAllCreditorTable = ({ data }) => {
  return (
    <CContainer fluid className="p-4 my-2">
      <CTable bordered striped className="shadow">
        <CTableHead className="bg-dark text-center align-middle">
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-white">
              Creditor Name
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Creditor Number
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Mobile No.
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Email address
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Address
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Aadhar No.
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Date Added
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody className="text-center align-middle">
          {data.map((e, index) => {
            return (
              <CTableRow key={index}>
                <CTableDataCell>{e.creditorName}</CTableDataCell>
                <CTableDataCell>{e.creditorNumber}</CTableDataCell>
                <CTableDataCell>{e.mobileNumber} </CTableDataCell>
                <CTableDataCell>{e.email} </CTableDataCell>
                <CTableDataCell>
                  {e.address}
                </CTableDataCell>
                <CTableDataCell>{`7898 8567 8975 2356`}</CTableDataCell>
                <CTableDataCell>
                  {new Date(e.date).toDateString()}
                </CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>
    </CContainer>
  );
};

export default ViewAllCreditorTable;
