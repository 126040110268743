import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CContainer,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CCarousel,
  CCarouselItem,
  CImage
} from "@coreui/react";
import { useState , useContext } from "react";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import MemberListGroup from "./MemberListGroup";
import FeeDetails from "./FeeDetails";
import DepositList from "./DepositList";
import LoanList from "./LoanList";
import LastActivity from "./LastActivity";
import MemberContext from "../../contexts/MemberContext/MemberContext";


const MemberScroll = ({ setFlag, flag }) => {
  const { memberData } = useContext(MemberContext);
  const [visible, setVisible] = useState(false)
  return (
    <CAccordion alwaysOpen>
      <CAccordionItem itemKey={1}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>View Details</strong>
        </CAccordionHeader>
        <CAccordionBody style={{ backgroundColor: "#f5f5f5f5" }}>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Member Images</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CCarousel controls indicators>
                <CCarouselItem>
                  <CImage className="d-block w-100" src={`./${memberData.memberImage.data}`} alt="slide 1" />
                </CCarouselItem>
                <CCarouselItem>
                  <CImage className="d-block w-100" src={`./${memberData.signatureImage.data}`} alt="slide 1" />
                </CCarouselItem>
                <CCarouselItem>
                  <CImage className="d-block w-100" src={`./${memberData.establishmentImages.image1.data}`} alt="slide 1" />
                </CCarouselItem>
                <CCarouselItem>
                  <CImage className="d-block w-100" src={`./${memberData.establishmentImages.image2.data}`} alt="slide 1" />
                </CCarouselItem>
                <CCarouselItem>
                  <CImage className="d-block w-100" src={`./${memberData.establishmentImages.image3.data}`} alt="slide 1" />
                </CCarouselItem>
              </CCarousel>
            </CModalBody>
          </CModal>
          <MemberListGroup />
          <CContainer className="w-100 d-flex justify-content-center align-content-center px-4 mt-2">
            <CButton style={{ width: "16%" }} onClick={() => setVisible(!visible)} >Images</CButton>
          </CContainer>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={2}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>Last Activity</strong>
        </CAccordionHeader>
        <CAccordionBody style={{ backgroundColor: "#f5f5f5f5" }}>
          <LastActivity />
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={3}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>Loans</strong>
        </CAccordionHeader>
        <CAccordionBody
          // style={{ backgroundColor: "#f5f5f5f5" }}
          className="p-0"
        >
          <LoanList />
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={4}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>Deposits</strong>
        </CAccordionHeader>
        <CAccordionBody
          style={{ backgroundColor: "#f5f5f5f5" }}
          className="p-0"
        >
          <DepositList setFlag={setFlag} flag={flag} />
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={5}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>Fee Details</strong>
        </CAccordionHeader>
        <CAccordionBody
          style={{ backgroundColor: "#f5f5f5f5" }}
          className="p-0"
        >
          <FeeDetails flag={flag} setFlag={setFlag} />
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  );
};

export default MemberScroll;
