import {
  CContainer,
  CRow,
  CCol,
  CButton,
  CImage,
  CCard,
  CCardBody,
  CCardText,
  CForm,
  CFormInput,

} from "@coreui/react";
import { useNavigate } from "react-router-dom"

import CreditorScroll from "./CreditorScroll";
import { useEffect, useRef, useState , useContext} from 'react';
import axios from 'axios';
import Logo from "../../assets/memberdefault.jpeg";
import CreditorLoanModal from "./CreditorLoanModal";
import CreditorRecieveModal from "./CreditorRecieveModal";
import globalContext from "../../contexts/Global/globalContext";

const CreditorCard = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { authCheck, showAlert , } = useContext(globalContext);
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  let navigate = useNavigate();
  const [data, setData] = useState(null)
  const [creditorID, setCreditorID] = useState(null)
  const [flag, setFlag] = useState(true)
  const isMounted = useRef(false);


  useEffect(() => {

    if (isMounted.current) {
      isMounted.current = false;
    }
    else {
      fetchData();
    }
  }, [flag])

  async function fetchData() {
    try {
      let res = await axios.get(`/api/creditors/${creditorID}`, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      setData(res.data.details)

    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
  }

  const onChangeHandler = (e) => {
    setCreditorID(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      fetchData()
    } catch (e) {
      console.log(e);
      // showAlert("Please Enter a Valid Creditor ID");
      console.log("Error in Searching Creditor");
    }
  };

  
  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Creditor Card
      </p>



      <CContainer fluid className="m-1 mt-4 " >
        <CForm className="d-flex align-items-center justify-content-center" onSubmit={onSubmitHandler} >
          <CFormInput
            className="w-25 rounded-0 shadow mb-4"
            type="text"
            // pattern="^[0-9]*$"
            //TODO fix a proper pattern for membership ID
            placeholder="Enter Creditor Id here"
            onChange={onChangeHandler}
            value={creditorID}

          ></CFormInput>
          <CButton type="submit" disabled={isSubmitting} color="info" className="text-white rounded-0 mb-4">
          {isSubmitting ? 'Searching...' : 'Search'}
          </CButton>
        </CForm>
      </CContainer>
      <CContainer fluid className="p-0 mt-2" style={{ minHeight: "100vh" }}>
        {data && <CContainer fluid>
          <CContainer>
            <CCard>
              <CCardBody style={{ backgroundColor: "#f5f5f5" }}>
                <CRow>
                  <CCol className="d-flex justify-content-around align-items-stretch">
                    <CContainer className="d-flex flex-column justify-content-around align-items-center text-center rounded p-1">
                      {/* <CContainer
                        className=" rounded shadow text-primary  bg-white m-0 w-50 h5  py-1"
                        style={{ minWidth: "145px" }}
                      >
                        <CCardText className="m-0 pb-1">
                          <strong>Credit</strong>
                        </CCardText>
                        <CCardText className="pb-1">0.00</CCardText>
                      </CContainer>
                      <CContainer
                        className="rounded shadow text-success my-2 bg-white mx-0 w-50 h5 py-1"
                        style={{ minWidth: "145px" }}
                      >
                        <CCardText className="m-0 pb-1">
                          <strong>Balance</strong>
                        </CCardText>
                        <CCardText className="pb-1">₹100000</CCardText>
                      </CContainer>
                      <CContainer
                        className="rounded shadow text-warning bg-white m-0 w-50 h5 py-1"
                        style={{ minWidth: "145px" }}
                      >
                        <CCardText className="m-0 pb-1">
                          <strong>Loan</strong>
                        </CCardText>
                        <CCardText className="pb-1">₹50000</CCardText>
                      </CContainer> */}
                    </CContainer>
                    <CContainer className="d-flex">
                      <CContainer className="d-flex flex-column justify-content-around align-items-center">
                        <CContainer
                          className="d-flex justify-content-between rounded shadow bg-white pt-2 pb-2 m-2"
                          style={{ minWidth: "250px" }}
                        >
                          <CCardText className="m-0">
                            <b>Creditor Name:</b>
                          </CCardText>
                          <CCardText>{data.creditorName}</CCardText>
                        </CContainer>
                        <CContainer className="d-flex justify-content-between  rounded shadow bg-white  pt-2 pb-2 m-2">
                          <CCardText className="m-0">
                            <b>Creditor Type:</b>
                          </CCardText>
                          <CCardText>{data.creditorType}</CCardText>
                        </CContainer>
                        <CContainer className="d-flex justify-content-between  rounded shadow bg-white  pt-2 pb-2 m-2">
                          <CCardText className="m-0">
                            <b>Creditor ID:</b>
                          </CCardText>
                          <CCardText>{data.creditorID}</CCardText>
                        </CContainer>
                        <CContainer className="d-flex justify-content-between rounded shadow bg-white  pt-2 pb-2 m-2">
                          <CCardText className="m-0">
                            <b>Email ID:</b>
                          </CCardText>
                          <CCardText>{data.email}</CCardText>
                        </CContainer>
                        <CContainer className="d-flex justify-content-between  rounded shadow bg-white  pt-2 pb-2 m-2">
                          <CCardText className="m-0">
                            <b>Phone Number:</b>
                          </CCardText>
                          <CCardText>{data.mobileNo}</CCardText>
                        </CContainer>

                      </CContainer>
                    </CContainer>
                    <CContainer className="d-flex flex-column align-items-center justify-content-around">
                      <CContainer className="d-flex justify-content-center">
                        <CImage
                          src={Logo}
                          style={{
                            width: "200px",
                            minHeight: "220px",
                            maxWidth: "300px",
                            maxHeight: "320px",
                          }}
                          className="shadow mb-2"
                        ></CImage>
                      </CContainer>
                    </CContainer>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
          <CContainer>
            <CreditorScroll data={data} flag={flag} setFlag={setFlag} />
          </CContainer>
          <CContainer className="d-flex justify-content-around rounded my-4 px-5">
            <CButton className="btn shadow" onClick={() => setVisible(!visible)}>New Loans Payable Request</CButton>

            <CButton
              className="btn shadow"
              style={{ width: "20%" }}
              onClick={() => setVisible1(!visible1)}
            >
              Recieve from Creditor
            </CButton>
          </CContainer>
        </CContainer>}
      </CContainer>
      <CreditorLoanModal visible={visible} setVisible={setVisible} flag={flag} setFlag={setFlag} data={data} />
      <CreditorRecieveModal visible1={visible1} setVisible1={setVisible1} flag={flag} setFlag={setFlag} data={data}/>
    </>
  );
};

export default CreditorCard;
