import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";

import {useContext} from 'react'

import MemberContext from "../../contexts/MemberContext/MemberContext";

const LastActivity = () => {
let {memberData:data} = useContext(MemberContext);
  return (
    <CContainer fluid className="p-4 mt-1">
      <CTable bordered striped className="shadow">
        <CTableHead className="bg-secondary text-center">
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-white">
              #
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Activity At
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Date Since Last Activity
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Product/ Outcome
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Activity
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Amount
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              EMP
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody className="text-center">
        {
          data.lastActivityAtbranch.activity  && (
            <CTableRow>
        <CTableDataCell>1</CTableDataCell>
        <CTableDataCell>Branch</CTableDataCell>
        <CTableDataCell>{new Date(data.lastActivityAtbranch.date).toISOString().split("T")[0]}</CTableDataCell>
        <CTableDataCell>{Math.floor((new Date() - new Date(data.lastActivityAtbranch.date)) / 1000 / 60 / 60 / 24)}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtbranch.outcome}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtbranch.activity}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtbranch.amount.toFixed(2)}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtbranch.emp}</CTableDataCell>
        </CTableRow>
       
          )

        }
        {
          data.lastActivityAtfield.activity  && (
            <CTableRow>
        <CTableDataCell>2</CTableDataCell>
        <CTableDataCell>Field</CTableDataCell>
        <CTableDataCell>{new Date(data.lastActivityAtfield.date).toISOString().split("T")[0]}</CTableDataCell>
        <CTableDataCell>{Math.floor((new Date() - new Date(data.lastActivityAtfield.date)) / 1000 / 60 / 60 / 24)}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtfield.outcome}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtfield.activity}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtfield.amount.toFixed(2)}</CTableDataCell>
        <CTableDataCell>{data.lastActivityAtfield.emp}</CTableDataCell>
        </CTableRow>
       
          )

        }
         </CTableBody>
      </CTable>
    </CContainer>
  );
};

export default LastActivity;
