import { CContainer } from "@coreui/react";

import { useNavigate } from "react-router-dom";
const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <CContainer
      fluid
      className="d-flex justify-content-center align-items-center "
      style={{
        height: "100vh",
      }}
    >
      <h1
        className="text-danger border border-danger p-5 rounded"
        onClick={() => navigate(-1)}
      >
        404 - Page Not Found
      </h1>
    </CContainer>
  );
};

export default ErrorPage;
