import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalState from "./contexts/Global/globalState";
import MemberGlobalState from "./contexts/MemberContext/MemberState";
import App from "./App";
// import "./custom.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <GlobalState>
      <MemberGlobalState>
        <App />
      </MemberGlobalState>
    </GlobalState>
  </Router>
);
