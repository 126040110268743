import {
  CContainer,
  CDropdown,
  CButton,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";

import { useState } from "react";

const CashCollectorMaps = () => {
  //   useEffect(() => {}, []);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [display, setDisplay] = useState(false);
  return (
    <>
      <CContainer
        fluid
        className="d-flex flex-column align-items-center px-5 pt-3 mb-3"
        style={{
          minHeight: "90vh",
        }}
      >
        <CContainer fluid className="p-3 mb-4 border shadow ">
          <CContainer
            fluid
            className="d-flex justify-content-between p-2 mb-2 border rounded shadow-sm"
          >
            <CContainer
              fluid
              className="d-flex justify-content-center align-items-center"
            >
              <h4 className="p-0 m-0 align-middle">Select a Date: </h4>
              <form className="px-3">
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className=" border rounded shadow-sm p-2"
                />
              </form>
            </CContainer>
            <CContainer
              fluid
              className="d-flex justify-content-center align-items-center"
            >
              <CDropdown direction="dropend">
                <CDropdownToggle color="info" className="text-white">
                  Select a Type
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem>All</CDropdownItem>
                  <CDropdownItem>Transaction</CDropdownItem>
                  <CDropdownItem>No Transaction</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CContainer>
          </CContainer>
          {/* Button Code */}
          <CContainer fluid>
            <CContainer fluid className="d-flex justify-content-center">
              <CButton color="success" className="text-white border shadow-sm">
                Submit
              </CButton>
            </CContainer>
          </CContainer>
        </CContainer>

        <CContainer
          fluid
          className="d-flex p-0 m-0 border border-dark rounded "
        >
          <CContainer
            fluid
            className="d-flex justify-content-center align-items-center"
          >
            Map in here
          </CContainer>
          <CContainer fluid className="p-3 border-start w-25">
            <CContainer className="text-center">
              # Name CashCollector
            </CContainer>
            <CContainer>
              <ul
                className="border rounded py-0  mt-2"
                style={{
                  listStyleType: "none",
                  height: "600px",
                  overflow: "auto",
                }}
              >
                <li className="border-bottom px-2">1</li>
                <li className="border-bottom px-2">2</li>
                <li className="border-bottom px-2">3</li>
                <li className="border-bottom px-2">4</li>
                <li className="border-bottom px-2">5</li>
                <li className="border-bottom px-2">6</li>
                <li className="border-bottom px-2">7</li>
              </ul>
            </CContainer>
          </CContainer>
        </CContainer>
      </CContainer>
    </>
  );
};

export default CashCollectorMaps;
