import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";

import "@coreui/coreui/dist/css/coreui.min.css";
import Footer from "./components/Footer";

import Addmember from "./components/Addmember";
import PendingMembers from "./components/PendingMembers";
import { useState } from "react";
import AllMembers from "./components/ViewAllMembers";
import Editmember from "./components/Editmember";
import PrivateRoutes from "./components/PrivateRoutes";
import SuperAdminRoutes from "./components/SuperAdminRoutes";

// * Temporary change when view all Members page is done.
import MemberCard from "./components/MemberCard/MemberCard";
import Transactions from "./components/Transactions";
import DepositDetails from "./components/Deposits/DepositDetails";
import PendingLoanList from "./components/PendingLoanList";
import AllLoansList from "./components/AllLoans";
import Home from "./components/Home";
import CreditorCard from "./components/CreditorCard/CreditorCard";
import PendingCreditors from "./components/CreditorLists/PendingCreditors";
import CreditorReq from "./components/CreditorReq";
import ViewAllCreditors from "./components/CreditorLists/ViewAllCreditors";
import BranchTransactions from "./components/BranchActivity/BranchTransactions";
import NewExpenseRequest from "./components/BranchActivity/NewExpenseRequest";
import CashCollector from "./components/BranchActivity/CashCollector";
import DayBook from "./components/BranchActivity/DayBook";
import AdjustmentLog from "./components/BranchActivity/AdjustmentLog";
import CashBankBook from "./components/BranchActivity/CashBankBook";
import DepositsList from "./components/Deposits/DepositsList";

import Ledger from "./components/Ledger";
import NewBeat from "./components/NewBeat";
import Userlicense from "./components/Userlicense";
// import Beatuplode from "./components/Beatuplode";
import CashCollectorBeat from "./components/CashCollectorBeat";
import AdjustmentEntry from "./components/BranchActivity/AdjustmentEntry";
import CashCollectorActivity from "./components/FieldActivity/CashCollectorActivity";
import FieldTeamActivity from "./components/FieldActivity/FieldTeamActivity";

import MemberActivity from "./components/FieldActivity/MemberActivity";
import Dashboard from "./components/Dashboard";

import CashCollectorMember from "./components/CashCollectorMember";
import AccountBalances from "./components/Accounts/AccountBalances";
import OpeningBalances from "./components/Accounts/OpeningBalances";
import BalanceSheet from "./components/Accounts/BalanceSheet";
import MemberHistory from "./components/Accounts/MemberHistory";
import PAndLAccounts from "./components/Accounts/PAndLAccounts";
import RP_Accounts from "./components/Accounts/RP_Accounts";

import CreditorLedger from "./components/CreditorLedger";

import ActivitySummary from "./components/FieldActivity/ActivitySummary";
import PasswordReset from "./components/PasswordReset";
import GlobalRoute from "./components/GlobalRoute";
import LoanTrack from "./components/LoanTrack";
import CashCollectorMaps from "./components/FieldActivity/cashCollectorMaps";
import ErrorPage from "./components/Error";

import BranchChange from "./components/SuperAdminPages/BranchChange";
import MemberFeeDetails from "./components/SuperAdminPages/MemberFeeDetails";
import DepositsLoans from "./components/SuperAdminPages/DepositsLoans";
import PasswordChange from "./components/SuperAdminPages/PasswordChange";
import PortalUsers from "./components/SuperAdminPages/PortalUsers";
import CashCollectorsList from "./components/SuperAdminPages/CashCollectors";

function App() {
  const [alert, setAlert] = useState(null);
  const showAlert = (data) => {
    setAlert({
      type: data.type,
      msg: data.msg,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };
  return (
    <>
      <Routes>
        <Route element={<GlobalRoute />}>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<Home />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route element={<SuperAdminRoutes />}>
              <Route path="/branchChange" element={<BranchChange />} />
              <Route path="/passwordChange" element={<PasswordChange />} />
              <Route path="/portalUsers" element={<PortalUsers />} />
            </Route>

            <Route path="/cashCollectors" element={<CashCollectorsList />} />
            <Route path="/FeeDetails" element={<MemberFeeDetails />} />
            <Route path="/depositsLoans" element={<DepositsLoans />} />
            <Route
              path="/addmember"
              element={<Addmember alert={alert} showAlert={showAlert} />}
            />
            <Route
              path="/deposits"
              element={<DepositsList />}
            />

            <Route path="passwordreset" element={<PasswordReset />} />

            {/* TODO: change to using nested routes later */}
            <Route path="/pendingMemberList" element={<PendingMembers />} />
            <Route path="/allmembers" element={<AllMembers />} />
            <Route path="/pendingLoans" element={<PendingLoanList />} />
            <Route path="/allLoans" element={<AllLoansList />} />
            <Route path="/addcreditor" element={<CreditorReq />} />

            <Route path="/memberCard" element={<MemberCard />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/DepositDetails" element={<DepositDetails />} />

            <Route path="/editmember" element={<Editmember />} />

            <Route path="/LoanTracker" element={<LoanTrack />} />


            <Route path="creditorCard" element={<CreditorCard />} />
            <Route path="/creditorList" element={<ViewAllCreditors />} />
            <Route path="/pendingCreditors" element={<PendingCreditors />} />
            <Route path="/branchTransactions" element={<BranchTransactions />} />
            <Route path="/newExpenseRequest" element={<NewExpenseRequest />} />
            <Route path="/cashCollector" element={<CashCollector />} />
            <Route path="/adjustmentLog" element={<AdjustmentLog />} />
            <Route path="/dayBook" element={<DayBook />} />
            <Route path="/cashBankBook" element={<CashBankBook />} />
            <Route path="/creditorLedger" element={<CreditorLedger />} />

            <Route path="/ledger" element={<Ledger />} />
            <Route path="/newbeat" element={<NewBeat />} />
            {/* <Route path="/beatuplode" element={<Beatuplode />} /> */}
            <Route path="/userlicense" element={<Userlicense />} />
            <Route path="/cashcollectorbeat" element={<CashCollectorBeat />} />
            <Route
              path="cashcollectormember"
              element={<CashCollectorMember />}
            />
            <Route path="/adjustmentEntry" element={<AdjustmentEntry />} />
            <Route
              path="/cashCollectorActivity"
              element={<CashCollectorActivity />}
            />
            <Route path="/FieldTeamActivity" element={<FieldTeamActivity />} />

            <Route path="/MemberActivity" element={<MemberActivity />} />

            {/* <Route path="/cashCollectorAdnvance" element={<CashCollectorAdnvance />} /> */}

            <Route path="/accountBalances" element={<AccountBalances />} />
            <Route path="/OpeningBalances" element={<OpeningBalances />} />
            <Route path="/BalanceSheet" element={<BalanceSheet />} />
            <Route path="/MemberHistory" element={<MemberHistory />} />
            <Route path="/PAndLAccounts" element={<PAndLAccounts />} />
            <Route path="/RP_Accounts" element={<RP_Accounts />} />

            <Route path="/activitySummary" element={<ActivitySummary />} />

            <Route
              path="/cashCollectorActivity/maps"
              element={<CashCollectorMaps />}
            />

            <Route path="/loantracker" element={<LoanTrack />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
