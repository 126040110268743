import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import React, { useState , useContext } from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

const AdjustmentLog = () => {
  const {showAlert} = useContext(globalContext)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ledgerData, setLedgerData] = useState();
  const [date, setDate] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });

  let onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      let res = await axios.get(
        `/api/branchActivity/adjustmentLedger/${date.fromReqDate}/${date.toReqDate}`,

        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
            "Content-Type": "application/json",
          },
        }
      );
      setLedgerData(res.data.result);
      showAlert("Successfully Fetched Data","2")
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  let onChangeHandler = (e) => {
    // e.preventDefault();
    setDate({
      ...date,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <CContainer fluid className="p-0 m-0" style={{minHeight:"90vh"}}>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Adjustment Log
      </p>
      <CContainer>
        <CRow>
          <h5 className="mt-3 fw-bold text-black">Adjustment Log</h5>
          <hr></hr>
          <CCol sm={5}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date" className="text-black fw-bold">
                From Date
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={date.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={5}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date" className="fw-bold text-black">
                To Date
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={date.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={2}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButton
                type="submit"
                disabled={isSubmitting}
                color="success"
                className="text-white fw-bold ps-4 pe-4 "
                onClick={onSubmitHandler}
              >
                {isSubmitting ? "Processing ..." : "Get Details"}
              </CButton>
            </CContainer>
          </CCol>
        </CRow>
        <h5 className="fw-bold mt-3 text-black">Adjustment Log Report</h5>
        <hr></hr>
        {ledgerData && (
                  <CTable bordered striped className="shadow ">
                  <CTableHead className="bg-dark text-center align-middle">
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="text-white">
                        Membership No#
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Date
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Voucher No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Member Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Product Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Original Voucher No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Original Voucher Date
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Amount
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Remarks
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Payment Type
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-white">
                        Employee
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody className="text-center align-middle">
                    {ledgerData &&
                      ledgerData.map((ele, i) => {
                        return (
                          <CTableRow key={i}>
                            {/* <CTableDataCell>{new Date(ele.originalVoucherDate).toLocaleDateString()}</CTableDataCell> */}
        
                            <CTableDataCell>{ele.membershipNo}</CTableDataCell>
                            <CTableDataCell>
                              {new Date(ele.date).toLocaleDateString()}
                            </CTableDataCell>
                            <CTableDataCell>{ele.voucherNo}</CTableDataCell>
                            <CTableDataCell>{ele.memberName}</CTableDataCell>
                            <CTableDataCell>{ele.productName}</CTableDataCell>
                            <CTableDataCell>{ele.originalVoucherNo}</CTableDataCell>
                            <CTableDataCell>
                              {new Date(ele.originalVoucherDate).toLocaleDateString()}
                            </CTableDataCell>
                            <CTableDataCell>{ele.amount.toFixed(2)}</CTableDataCell>
                            <CTableDataCell>{ele.remarks}</CTableDataCell>
                            <CTableDataCell>{ele.paymentType}</CTableDataCell>
                            <CTableDataCell>{ele.emp}</CTableDataCell>
                          </CTableRow>
                        );
                      })}
                  </CTableBody>
                </CTable>
        )}
      </CContainer>
    </CContainer>
  );
};

export default AdjustmentLog;
