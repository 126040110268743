import { useReducer, useContext } from "react";
import MemberContext from "./MemberContext";
import MemberReducer from "./MemberReducer";
import axios from "axios";
import { SET_MEMBER, SET_MEMBERNO , RESET_MEMBER} from "../types";
import globalContext from "../Global/globalContext";

// import { usePersistState } from '../../hooks/usePersistState';

function MemberGlobalState(props) {
  const { setLoading, showAlert } = useContext(globalContext);
  const initialState = {
    memberNo: null,
    memberData: null,
  };

  const [state, dispatch] = useReducer(MemberReducer, initialState);

  const resetMember = () =>{
    dispatch({
      type : RESET_MEMBER
    })
  }

  //  Setting memberNo here
  const setMemberNo = async (e) => {
    try {
      setLoading(true);
      dispatch({
        type: SET_MEMBERNO,
        payload: e,
      });
    } catch (error) {
      if (error.response.data.errors) {
        let errorString = "";
        error.response.data.errors.forEach((ele) => {
          errorString += ele.msg;
        });
        showAlert(errorString, "3");
      }
      console.log(error.response.data.error);
    }
  };

  // Setting memberData here
  const setMemberData = async (e) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/member/${e}`, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      console.log(res.data);
      dispatch({
        type: SET_MEMBER,
        payload: res.data.Member,
      });
    } catch (error) {
      if (error.response.data.errors) {
        let errorString = "";
        error.response.data.errors.forEach((ele) => {
          errorString += ele.msg;
        });
        showAlert(errorString, "3");
      }
      console.log(error.response.data.error);
      throw error.response.data.error;
    }
  };
  return (
    <MemberContext.Provider
      value={{
        memberData: state.memberData,
        memberNo: state.memberNo,
        setMemberNo,
        setMemberData,
        resetMember
      }}
    >
      {props.children}
    </MemberContext.Provider>
  );
}

export default MemberGlobalState;
