import React, { useState, useContext } from "react";
import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CTable,
  CTableRow,
  CTableHeaderCell,
  CTableHead,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import axios from "axios";
import LoanRepay from "./LoanRepay";
import globalContext from "../../contexts/Global/globalContext";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Cookies from "js-cookie";
import "jspdf-autotable";
import MemberContext from "../../contexts/MemberContext/MemberContext";

function LoanDetails(data) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { setMemberNo, setMemberData } = useContext(MemberContext);
  const { showAlert } = useContext(globalContext);

  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState("Details");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [dataFc, setDataFc] = useState(new Date().toISOString().split("T")[0]);

  let loanData = data.data.loans.loans[data.indx];
  const onForeClose = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let token = JSON.parse(localStorage.getItem("token")).token;

      let foreclosures = await axios.put(
        `/api/loans/forecloseAndPay/${data.data.membershipNo}/${loanData._id}/pay`,
        { date },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  const onSubmitforeclose = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let fcRes = await axios.put(
        `/api/loans/foreclose/${data.data.membershipNo}/${loanData._id}`,
        { date },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      setDataFc(fcRes);
      // showAlert("Successfully Foreclosed" , "2");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  const dateChange = (e) => {
    if (e.target.name == "date") {
      setDate(e.target.value);
    }
  };

  const onSubmitDisburse = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let amount = loanData.loanAmount;
    try {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let res = await axios.put(
        `/api/loans/disburse/${data.data.membershipNo}/${loanData._id}`,
        { date },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      showAlert("Successfully Disbursed Loan", "2");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  const handleClick = () => {
    data = formatData(loanData.details);
    // const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.json_to_sheet(data);
    // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // XLSX.writeFile(wb, `data.xlsx`);
    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `LoanDetails.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatData = (data) => {
    return data.map((e, i) => {
      return {
        "S.No": i + 1,
        "Voucher No.": e.voucherNo,
        Date: new Date(e.date).toLocaleString(),
        Disbursement: e.disbursement.toFixed(2),
        Repayment: e.repayment.toFixed(2),
        Balance: e.balance.toFixed(2),
        Employee: e.emp,
        Remarks: e.remarks,
      };
    });
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Loan Details`, 14, 16);
    doc.text(
      `Member Name: ${data.data.pDetails.memberName} | Membership No: ${data.data.membershipNo} `,
      14,
      24,
      { fontSize: 14 }
    );
    doc.autoTable({
      head: [
        [
          "#",
          "Voucher No",
          "Date",
          "Disbursement",
          "Repayment",
          "Balance",
          "Emp",
          "Remarks",
        ],
      ],
      body: loanData.details.map((ele, i) => [
        i + 1,
        ele.voucherNo,
        new Date(ele.date).toLocaleString(),
        ele.disbursement.toFixed(2) || "-",
        ele.repayment.toFixed(2) || "-",
        ele.balance.toFixed(2),
        ele.emp,
        ele.remarks,
      ]),
      startY: 30,
    });
    doc.save("loanDetails.pdf");
  };

  const onClickHandler = async (membershipNo) => {
    try {
      Cookies.remove("memberNo");
      await setMemberNo(membershipNo);
      await setMemberData(membershipNo);
      Cookies.set("memberNo", membershipNo);
      navigate("/home");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  };

  return (
    <>
      <CButton onClick={() => setVisible(!visible)}>Details</CButton>
      <CModal
        scrollable
        size="xl"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <CModalHeader>
          <CModalTitle className="me-5">Details</CModalTitle>
          <CButton
            className="ms-5 w-25 text-white fw-bold"
            color="info"
            onClick={() => setSelected("Repay")}
            disabled={loanData.isCompleted}
          >
            Repay
          </CButton>
          <CButton
            className="ms-3 w-25 text-white fw-bold"
            color="info"
            onClick={() => setSelected("Disburse")}
            disabled={loanData.disbursedDate}
          >
            Disburse
          </CButton>
          <CButton
            className="ms-3 w-25 text-white fw-bold"
            color="info"
            onClick={() => setSelected("Fore Close")}
            disabled={loanData.isCompleted}
          >
            Fore Close
          </CButton>
          <CButton
            className="ms-3 w-25 text-white fw-bold"
            color="info"
            onClick={() => setSelected("Details")}
          >
            Details
          </CButton>
        </CModalHeader>
        <CModalBody>
          {/* <legend>Details</legend> */}
          <CTable bordered striped className="shadow">
            <CTableHead className="bg-secondary text-center">
              <CTableRow>
                <CTableHeaderCell scope="col" className="text-white">
                  Loan Name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Frequency
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Max Days
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Loan Amount
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Status
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-white">
                  Service Charge%
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="text-center align-middle">
              <CTableRow>
                <CTableDataCell>{loanData.loanName}</CTableDataCell>
                <CTableDataCell>{loanData.frequency}</CTableDataCell>
                <CTableDataCell>{loanData.maxNoOfDays}</CTableDataCell>
                <CTableDataCell>
                  {loanData.loanAmount.toFixed(2)}
                </CTableDataCell>
                <CTableDataCell>{loanData.loanRequestStatus}</CTableDataCell>
                <CTableDataCell>{loanData.serviceCharge}</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          <hr />

          {/* collateral Table */}
          {/* SHOW TABLE ONLY IF HAVE Linked Account */}
          {loanData.collateral === "Linked Account" && (
            <CContainer>
              <h5>Collateral Details</h5>
              <CTable bordered striped className="shadow">
                <CTableHead className="bg-secondary text-center">
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="text-white">
                      Loan Id
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Linked Membership No
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Collateral
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Linked Deposit Name
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody className="text-center align-middle">
                  {/* {loanData.details.map((ele, i, arr) => {
                return ( */}
                  <CTableRow>
                    <CTableDataCell>{loanData.loanId}</CTableDataCell>
                    <CTableDataCell
                      onClick={() =>
                        onClickHandler(loanData.linkedMembershipNo)
                      }
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {loanData.linkedMembershipNo}
                    </CTableDataCell>
                    <CTableDataCell>{loanData.collateral}</CTableDataCell>
                    <CTableDataCell>
                      {loanData.linkedDepositName}
                    </CTableDataCell>
                  </CTableRow>
                  {/* );
              })} */}
                </CTableBody>
              </CTable>
            </CContainer>
          )}

          {selected === "Details" && (
            <CContainer>
              <h5>
                Loan Details{" "}
                <CButton
                  color="info"
                  className="text-white fw-bold shadow ms-5"
                  onClick={handleClick}
                  disabled={
                    !loanData?.details || loanData?.details?.length === 0
                  }
                >
                  Download CSV
                </CButton>
                <CButton
                  color="info"
                  className="text-white fw-bold shadow ms-5"
                  onClick={exportPDF}
                  disabled={
                    !loanData?.details || loanData?.details?.length === 0
                  }
                >
                  Export PDF
                </CButton>
              </h5>

              <CTable bordered striped className="shadow">
                <CTableHead className="bg-secondary text-center">
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="text-white">
                      Voucher No
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Date
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Disbursement
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Repayment
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Balance
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Emp
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Remarks
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Narration
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody className="text-center align-middle">
                  {loanData.details.map((ele, i, arr) => {
                    const sum = (arr, end) => {
                      let cumulative = 0;
                      for (let idx = 0; idx < end; idx++) {
                        cumulative +=
                          Number(arr[idx].disbursement) -
                          Number(arr[idx].repayment);
                      }
                      return cumulative?.toFixed(2);
                    };

                    return (
                      <CTableRow key={i}>
                        <CTableDataCell>{ele.voucherNo}</CTableDataCell>
                        {/* <CTableDataCell>{ele.date}</CTableDataCell> */}
                        <CTableDataCell>
                          {new Date(ele.date).toISOString().split("T")[0]}
                        </CTableDataCell>
                        <CTableDataCell>
                          {ele.disbursement.toFixed(2)}
                        </CTableDataCell>
                        <CTableDataCell>
                          {ele.repayment.toFixed(2)}
                        </CTableDataCell>
                        <CTableDataCell>{sum(arr, i + 1)}</CTableDataCell>
                        <CTableDataCell>{ele.emp}</CTableDataCell>
                        <CTableDataCell>{ele.remarks}</CTableDataCell>
                        <CTableDataCell>{ele.narration}</CTableDataCell>
                      </CTableRow>
                    );
                  })}
                </CTableBody>
              </CTable>
            </CContainer>
          )}

          {selected === "Fore Close" && (
            <CContainer>
              <CForm className=" d-flex fw-bold" onSubmit={onSubmitforeclose}>
                <CInputGroup className="w-50">
                  <CInputGroupText className="fw-bold ">
                    Select Closing Date
                  </CInputGroupText>
                  <CFormInput
                    type="date"
                    value={date}
                    name="date"
                    onChange={dateChange}
                  />
                </CInputGroup>
                <CButton
                  type="submit"
                  disabled={isSubmitting}
                  color="info"
                  className="ms-3 me-5 text-white fw-bold w-25"
                >
                  {isSubmitting ? "Calculating..." : "Calculate"}
                </CButton>
                <CButton
                  color="warning"
                  className="ms-3 text-white fw-bold w-25"
                  onClick={onForeClose}
                  disabled={isSubmitting}
                >
                  Fore Close
                </CButton>
              </CForm>
              <hr />
              <h5>Account Details</h5>
              <CTable bordered striped className="shadow">
                <CTableHead className="bg-secondary text-center">
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="text-white">
                      No of Days
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Service Charges %
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Service Charges Amount
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Loan Amount
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Total Repaid
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-white">
                      Balance Payable to Forecolose
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                {dataFc.data && (
                  <CTableBody className="text-center align-middle">
                    <CTableRow>
                      <CTableDataCell>{dataFc.data.noOfDays}</CTableDataCell>
                      <CTableDataCell>
                        {dataFc.data.serviceCharge}
                      </CTableDataCell>
                      <CTableDataCell>
                        {dataFc.data.serviceChargeAmount}
                      </CTableDataCell>
                      <CTableDataCell>{dataFc.data.loanAmount}</CTableDataCell>
                      <CTableDataCell>{dataFc.data.totalRepaid}</CTableDataCell>
                      <CTableDataCell>
                        {dataFc.data.balancePayableToForeclose}
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                )}
              </CTable>
            </CContainer>
          )}

          {/* ----------------------------------------------loan repayment----------------------- */}
          {selected === "Repay" && <LoanRepay data={data} />}

          {/* ------------------------------------loan disburse--------------------------------------- */}

          {selected === "Disburse" && (
            <CContainer>
              <h5>Amount Disburse</h5>
              <CForm className="w-75 d-flex" onSubmit={onSubmitDisburse}>
                <CInputGroup className="w-50">
                  <CInputGroupText className="fw-bold">Amount</CInputGroupText>
                  <CFormInput
                    type="number"
                    value={loanData.loanAmount}
                    name="amount"
                    readOnly
                    className="overflow-hidden"
                    required
                  />
                </CInputGroup>
                <CInputGroup className="w-50 ms-4">
                  <CInputGroupText className="fw-bold w-25">
                    Date
                  </CInputGroupText>
                  <CFormInput
                    type="datetime-local"
                    name="date"
                    value={date}
                    onChange={dateChange}
                  />
                </CInputGroup>
                <CButton
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                  className="w-50 text-white fw-bold ms-5"
                >
                  {isSubmitting ? "Submitting..." : "Release Amount"}
                </CButton>
              </CForm>
            </CContainer>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            className="fw-bold text-white w-25"
            onClick={() => setVisible(false)}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default LoanDetails;
