// let handleBranchTransactions = (e) => {
//     e.preventDefault();

//     let selectValue = e.target.value;

    let data = [
      "Select Transaction",
      {
        label: "SELECT",
        name: "SELECT",
        value: "",
      },
      {
        label: "MainCash To Fixed Assets",
        name: "FixedAssets",
        value: "MainCashToFixedAssets",
      },
      {
        label: "Bank Account To Fixed Assets",
        name: "FixedAssets",
        value: "BankAccountToFixedAssets",
      },
      {
        label: "Depreciation From Fixed Assets",
        name: "FixedAssets",
        value: "DepFromFixedAssets",
      },
    //   {
    //     label: "HO Loan To Main Cash",
    //     name: "Loan",
    //     value: "HOLoanToMainCash",
    //   },

      {
        label: "Main Cash To Employee Advance",
        value: "MainCashToEmployeeAdvance",
        name: "Advance",
      },
      {
        label: "Employee Advance To Main Cash",
        value: "EmployeeAdvanceToMainCash",
        name: "Advance",
      },
      {
        label: "Main Cash To Deposit Locker ",
        value: "MainCashToDepositLocker",
        name: "Advance",
      },
      {
        label: "Deposit Locker To Main Cash",
        value: "DepositLockerToMainCash",
        name: "Advance",
      },
      {
        label: "Main Cash To Rental Deposit",
        value: "MainCashToRentalDeposit",
        name: "Advance",
      },
      {
        label: "Rental Deposit To Main Cash",
        value: "RentalToMainCash",
        name: "Advance",
      },
      {
        label: "Bank Account To Deposit Locker",
        value: "BankAccountToDepositLocker",
        name: "Advance",
      },
      {
        label: "Deposit Locker To Bank Account",
        value: "DepositLockerToBankAccount",
        name: "Advance",
      },
      {
        label: "Bank Account To Rental Deposit",
        value: "BankAccountToRentalDeposit",
        name: "Advance",
      },
      {
        label: "Rental Deposit To Bank Account",
        value: "RentalDepositToBankAccount",
        name: "Advance",
      },
      {
        label: "Petty Cash To  Employee Advance",
        value: "PettyCashToEmployeeAdvance",
        name: "Advance",
      },
    //   {
    //     label: "Employee Advance To  Petty Cash",
    //     value: "EmployeeAdvanceToPettyCash",
    //     name: "Advance",
    //   },


      {
        label: "Main Cash to Loans Receivable",
        name: "loan",
        value: "MainCashtoLoansReceivable ",
      },
      {
        label: "Bank Account to Loans Receivable",
        name: "loan",
        value: "BankAccounttoLoansReceivable",
      },
      {
        label: "Loans Receivable to Main Cash",
        name: "loan",
        value: "LoansReceivabletoMainCash",
      },
      {
        label: "Loans Receivable to Bank Account",
        name: "loan",
        value: "LoansReceivabletoBankAccount",
      },
      {
        label: "Loans Payable to Main Cash",
        name: "loan",
        value: "LoansPayabletoMainCash",
      },
      {
        label: "Loans Payable to Bank Account",
        name: "loan",
        value: "LoansPayabletoBankAccount",
      },
      {
        label: "Main Cash to Loans Payable",
        name: "loan",
        value: "MainCashtoLoansPayable",
      },
      {
        label: "Bank Account to Loans Payable",
        name: "loan",
        value: "BankAccounttoLoansPayable",
      },


      {
        label: "Donation received in Main Cash",
        name: "donation",
        value: "DonationreceivedinMainCash",
      },
      {
        label: "Donation received in Bank Account",
        name: "donation",
        value: "DonationreceivedinBankAccount",
      },
      {
        label: "Donation given from Main Cash",
        name: "donation",
        value: "DonationgivenfromMainCash",
      },
      {
        label: "Donation given from Bank Account",
        name: "donation",
        value: "DonationgivenfromBankAccount",
      },
    //   {
    //     label: "Zakat received in Main Cash",
    //     name: "donation",
    //     value: "ZakatreceivedinMainCash",
    //   },
    //   {
    //     label: "Zakat received in Back Account",
    //     name: "donation",
    //     value: "ZakatreceivedinBackAccount",
    //   },



      {
        label: "Expense From Main Cash",
        name: "Expense",
        value: "ExpenseFromMainCash",
      },
      {
        label: "Expense From Petty Cash",
        name: "Expense",
        value: "ExpenseFromPettyCash",
      },
      {
        label: "Expense From Bank Account",
        name: "Expense",
        value: "ExpenseFromBankAccount",
      },
    //   {
    //     label: "Main Cash To Printing & Stationery",
    //     name: "Expense",
    //     value: "MainCashToPrinting&Stationery",
    //   },

    //   {
    //     label: "HO Donation To Main Cash",
    //     name: "Donation",
    //     value: "HODonationToMainCash",
    //   },
    //   {
    //     label: "HO Donation To Bank Account",
    //     name: "Donation",
    //     value: "HODonationToBankAccount",
    //   },

      {
        label: "Main Cash To Bank Account ",
        name: "Transfer",
        value: "MainCashToBankAccount",
      },
      {
        label: "Bank Account To MainCash ",
        name: "Transfer",
        value: "BankAccountToMainCash",
      },
      {
        label: "MainCash To PettyCash ",
        name: "Transfer",
        value: "MainCashToPettyCash",
      },
      {
        label: "PrintingAndStationery To MainCash",
        name: "Transfer",
        value: "PrintingAndStationeryToMainCash",
      },
    //   {
    //     label: "Suspense To Main Cash",
    //     name: "Transfer",
    //     value: "SuspenseToMainCash",
    //   },
    //   {
    //     label: "Main Cash To Suspense",
    //     name: "Transfer",
    //     value: "MainCashToSuspense",
    //   },
    //   {
    //     label: "Annual Fee To Main Cash",
    //     name: "Transfer",
    //     value: "AnnualFeeToMainCash",
    //   },
    //   {
    //     label: "Shar CertificateFee To Main Cash",
    //     name: "Transfer",
    //     value: "ShareCertificateFeeToMainCash",
    //   },

      {
        label: "Main Cash To Cash Collector",
        name: "Cash_Collector",
        value: "MainCashToCashCollector",
      },
      {
        label: "Cash CollectorTo Main Cash",
        name: "Cash_Collector",
        value: "CashCollectorToMainCash",
      },
    ];
//     let filteredData = data.filter(
//       (singleData) => singleData.name === selectValue
//     );
//     setTransactionType(filteredData);
//   };


const bankAccountsCodes = [
	// {
	// 	name: "demandLoan",
	// 	label: "Qard-e-Hasna",
	// 	value: 10101,
	// },
	// {
	// 	name: "consumerGoodsLoan",
	// 	label: "Murabaha Consumer Loan",
	// 	value: 10102,
	// },
	// {
	// 	name: "businessGoodsLoan",
	// 	label: "Murabaha Business Loan",
	// 	value: 10103,
	// },
	// {
	// 	name: "mainCash",
	// 	label: "Main Cash",
	// 	value: 30101,
	// },
	// {
	// 	name: "branchBankAccount",
	// 	label: "CASH IN BANKS",
	// 	value: 30102,
	// },
	// {
	// 	name: "pettyCash",
	// 	label: "Petty Cash",
	// 	value: 30103,
	// },
	// {
	// 	name: "advanceSalary",
	// 	label: "Advance Salary",
	// 	value: 30104,
	// },
	// {
	// 	name: "cashCollector",
	// 	label: "Cash Collector",
	// 	value: 30105,
	// },
	// {
	// 	name: "rentalDeposit",
	// 	label: "RENTAL DEPOSIT A/c",
	// 	value: 30108,
	// },
	// {
	// 	name: "virtualBankAccount",
	// 	label: "VIRTUAL BANK ACCOUNT",
	// 	value: 30110,
	// },
	// {
	// 	name: "dailyDeposit",
	// 	label: "Daily Deposit",
	// 	value: 20101,
	// },
	// {
	// 	name: "savingsDeposit",
	// 	label: "Savings Deposit",
	// 	value: 20102,
	// },
	// {
	// 	name: "termDeposit",
	// 	label: "Term Deposit",
	// 	value: 20103,
	// },
	// {
	// 	name: "umrahDeposit",
	// 	label: "Umrah Deposits-Members",
	// 	value: 20104,
	// },
	// {
	// 	name: "shareCertificateFeeAcc",
	// 	label: "Share Capital",
	// 	value: 50103,
	// },
	// {
	// 	name: "membershipFeeAcc",
	// 	label: "Income- Membership Fee",
	// 	value: 50101,
	// },
	// {
	// 	name: "annualFee",
	// 	label: "Income-Yearly Fee",
	// 	value: 50102,
	// },
	// {
	// 	name: "incomeFromDonation",
	// 	label: "INCOME FROM DONATION A/c",
	// 	value: 50107,
	// },
	// {
	// 	name: "demandLoanServiceCharge",
	// 	label: "Income- Qard-e-Hasna",
	// 	value: 60101,
	// },
	// {
	// 	name: "consumerGoodsLoanServiceCharge",
	// 	label: "Income- Murabaha Consumer Loan",
	// 	value: 60102,
	// },
	// {
	// 	name: "businessGoodsLoanServiceCharge",
	// 	label: "Income- Murabaha Business Loan",
	// 	value: 60103,
	// },
	// {
	// 	name: "loansReceivable",
	// 	label: "LOANS RECEIVABLE A/c",
	// 	value: 30106,
	// },
	{
		name: "salariesAndAllowances",
		label: "Salaries & Allowances",
		value: 70101,
	},
	{
		name: "employeeBenefits",
		label: "Employee Benefits",
		value: 70102,
	},
	{
		name: "medicalExpenses",
		label: "Medical Expenses",
		value: 70103,
	},
	{
		name: "officeExpenses",
		label: "Office Expenses",
		value: 70104,
	},
	{
		name: "staffAccomodation",
		label: "Staff Accommodation",
		value: 70105,
	},
	{
		name: "telephoneAndInternet",
		label: "Telephone And Internet ",
		value: 70106,
	},
	{
		name: "taDaAndFuel",
		label: "TA, DA & Fuel Charges",
		value: 70107,
	},
	{
		name: "electricCharges",
		label: "Utility Charges",
		value: 70108,
	},
	{
		name: "officeRental",
		label: "Office Rent",
		value: 70109,
	},
	{
		name: "operatingExpenses",
		label: "Operating Expenses",
		value: 70110,
	},
	{
		name: "bankCharges",
		label: "Bank Charges",
		value: 70112,
	},
	{
		name: "miscExpenses",
		label: "Miscellaneous Expenses",
		value: 70113,
	},
	{
		name: "auditFee",
		label: "Audit Fee",
		value: 70114,
	},
	{
		name: "depreciation",
		label: "Depreciation ",
		value: 70115,
	},
	{
		name: "printingAndStationery",
		label: "Printing & Stationery Charges",
		value: 70116,
	},
	{
		name: "suspense",
		label: "Suspense Account",
		value: 70117,
	},
	{
		name: "managementFee",
		label: "Management Fee",
		value: 70118,
	},
	{
		name: "tds",
		label: "TDS",
		value: 70119,
	},
	// {
	// 	name: "hyptoCharges",
	// 	label: "Hypto Charges",
	// 	value: 70123,
	// },
	// {
	// 	name: "depreciationExpenses",
	// 	label: "DEPRECIATION EXPENSES A/c",
	// 	value: 70122,
	// },
	// {
	// 	name: "dividendReserve",
	// 	label: "DIVIDEND RESERVE A/c",
	// 	value: 20113,
	// },
];

const fixedAssetsCodes = [
	{
		name: "furnitureAndFixtures",
		label:"FURNITURE & FIXTURES A/c",
		value: 80101,
	},
	{
		name: "officeAppliance",
		label: "OFFICE APPLIANCE A/c",
		value: 80102,
	},
	{
		name: "officeEquipment",
		label: "OFFICE EQUIPMENT A/c",
		value: 80103,
	},
	{
		name: "computer",
		label: "COMPUTER A/c",
		value: 80104,
	},
	{
		name: "inverter",
		label: "INVERTER A/c",
		value: 80105,
	},
	{
		name: "electricalGoods",
		label: "ELECTRICAL GOODS A/c",
		value: 80106,
	},
	{
		name: "landAndBuilding",
		label: "LAND & BUILDING A/c",
		value: 80107,
	},
	{
		name: "plantAndMachinery",
		label: "PLANT & MACHINERY A/c",
		value: 80108,
	},
]

  export  {data,bankAccountsCodes,fixedAssetsCodes};