import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import globalContext from "../contexts/Global/globalContext";
import MemberContext from "../contexts/MemberContext/MemberContext";
import Cookies from "js-cookie";
import {
  CNavbar,
  CContainer,
  CNavbarNav,
  CNavbarToggler,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CNavbarText,
  CNavbarBrand,
  CNavItem,
  CAccordion,
  CAccordionItem,
  CAccordionBody,
  CAccordionHeader,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";

function Main() {
  const { authCheck, auth } = useContext(globalContext);
  useEffect(() => {
    authCheck();
  }, []);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const username = Cookies.get("username");
  const role = Cookies.get("role");
  let { resetMember } = useContext(MemberContext);
  // To call the authCheck function after log out to update the state
  function logout() {
    Cookies.remove("memberNo");
    Cookies.remove("username");
    Cookies.remove("memberData");
    Cookies.remove("role");
    localStorage.removeItem("members");
    Cookies.remove("branchName")
    resetMember();
    localStorage.removeItem("token");
    navigate("/");
  }
  // let date = new Date().getDate();
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let realdate = `${date}/${month}/${year}`;

  return (
    <CNavbar
      colorScheme="light"
      style={{ backgroundColor: "#a4d0ff" }}
      className="navbar navbar-light "
    >
      <CContainer fluid className="flex-nowrap align-items-center">
        <CNavbarToggler
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          // className="bg-white"
          onClick={() => setVisible(!visible)}
        />
        <COffcanvas
          id="offcanvasNavbar"
          placement="start"
          portal={false}
          visible={visible}
        >
          <COffcanvasHeader className="py-1 text-primary">
            <COffcanvasTitle className="h1 ">Menu</COffcanvasTitle>
            <CCloseButton onClick={() => setVisible(false)} />
          </COffcanvasHeader>
          <COffcanvasBody>
            <CNavbarNav>
              <CAccordion flush className="mt-3">
                {(role == "admin" || role == "ho" || role == "adminView") ? (
                  <CAccordionItem>
                    <CAccordionHeader itemkey={1}>
                      Branch Change
                    </CAccordionHeader>
                    <CAccordionBody>
                      <CListGroup flush className="border-0">
                        <Link to="branchChange" className="text-decoration-none">
                          <CListGroupItem className="border-0 sidebar_hover">
                            Change Current Branch
                          </CListGroupItem>
                        </Link>
                      </CListGroup>
                    </CAccordionBody>
                  </CAccordionItem>
                ) : (
                  <></>
                )}
                <CAccordionItem>
                  <CAccordionHeader itemkey={1}>
                    Member Transaction
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      <Link to="/home" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover" onClick={() => setVisible(false)} >
                          Home
                        </CListGroupItem>
                      </Link>
                      <Link to="/addmember" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Add New Member
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="/pendingMemberList"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Member Request List
                        </CListGroupItem>
                      </Link>
                      <Link to="/allmembers" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          All Members
                        </CListGroupItem>
                      </Link>
                      <Link to="/allLoans" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          All Loans
                        </CListGroupItem>
                      </Link>
                      <Link to="/pendingLoans" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Pending Loans
                        </CListGroupItem>
                      </Link>
                      {/* <Link to="/deposits" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          All Deposits
                        </CListGroupItem>
                      </Link> */}
                      <Link to="#" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Member Branch Change
                        </CListGroupItem>
                      </Link>
                      <Link to="/Dashboard" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover" onClick={() => setVisible(false)} >
                          Dashboard
                        </CListGroupItem>
                      </Link>
                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem>
                  <CAccordionHeader itemkey={3}>
                    Creditor Transactions
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      <Link to="/creditorCard" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Creditor Search
                        </CListGroupItem>
                      </Link>
                      <Link to="/addcreditor" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          New Creditor Request
                        </CListGroupItem>
                      </Link>
                      <Link to="creditorList" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Creditor List
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="pendingCreditors"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Pending Creditors List
                        </CListGroupItem>
                      </Link>
                      {/* <Link
                        to="creditorTransactions"
                        className="text-decoration-none"
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          All Transactions
                        </CListGroupItem>
                      </Link> */}
                      <Link
                        to="creditorLedger"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Ledger
                        </CListGroupItem>
                      </Link>
                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem>
                  <CAccordionHeader itemkey={4}>
                    Branch Activity
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      <Link
                        to="/branchTransactions"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Branch Transactions
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="/newExpenseRequest"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          New Expense Request
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="/cashCollector"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash Collector
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="/adjustmentEntry"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Adjustment Entry
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="/adjustmentLog"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Adjustment Log
                        </CListGroupItem>
                      </Link>
                      <Link to="/dayBook" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Day Book
                        </CListGroupItem>
                      </Link>
                      <Link to="/cashBankBook" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash / Bank Book
                        </CListGroupItem>
                      </Link>
                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem>
                  <CAccordionHeader itemkey={5}>
                    Field Activity
                  </CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      <Link
                        to="cashCollectorActivity"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash Collector Activity
                        </CListGroupItem>
                      </Link>

                      {/* <Link
                        to="/cashCollectorAdnvance"
                        className="text-decoration-none"
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash Collector Advance
                        </CListGroupItem>
                      </Link> */}
                      <Link
                        to="FieldTeamActivity"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                      </Link>

                      <Link to="FieldTeamActivity" className="text-decoration-none" onClick={() => setVisible(false)} >

                        <CListGroupItem className="border-0 sidebar_hover">
                          Field Team Activity
                        </CListGroupItem>
                      </Link>

                      <Link to="activitySummary" className="text-decoration-none" ></Link>

                      {/* <Link to="#" className="text-decoration-none">

                        <CListGroupItem className="border-0 sidebar_hover">
                          Activity Summary
                        </CListGroupItem>
                      </Link> */}
                      <Link
                        to="MemberActivity"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                      </Link>
                      {/* <Link to="MemberActivity" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          Member Activity
                        </CListGroupItem>
                      </Link> */}
                      <Link to="/newbeat" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          New Beats
                        </CListGroupItem>
                      </Link>
                      {/* <Link to="userlicense" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          User License
                        </CListGroupItem>
                      </Link> */}
                      {/* <Link to="beatuplode" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          Beat Upload
                        </CListGroupItem>
                      </Link> */}
                      <Link
                        to="cashcollectorbeat"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash Collector Beats
                        </CListGroupItem>
                      </Link>
                      <Link
                        to="cashcollectormember"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash Collector - Members
                        </CListGroupItem>
                      </Link>
                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem>
                <CAccordionItem>
                  <CAccordionHeader itemkey={6}>Accounts</CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      <Link
                        to="/accountBalances"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Account Balances
                        </CListGroupItem>
                      </Link>

                      <Link
                        to="OpeningBalances"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Opening Balances
                        </CListGroupItem>
                      </Link>
                      <Link to="BalanceSheet" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Balance Sheet
                        </CListGroupItem>
                      </Link>
                      <Link to="ledger" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Ledger
                        </CListGroupItem>
                      </Link>
                      <Link to="PAndLAccounts" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          P&L Accounts
                        </CListGroupItem>
                      </Link>
                      <Link to="RP_Accounts" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          R&P Accounts
                        </CListGroupItem>
                      </Link>

                      <Link
                        to="/LoanTracker"
                        className="text-decoration-none"
                        onClick={() => setVisible(false)}
                      >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Loan Tracker
                        </CListGroupItem>
                      </Link>


                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem>
                {/* <CAccordionItem>
                  <CAccordionHeader itemkey={7}>Analytics</CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      <Link to="loantracker" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          Loan Tracker
                        </CListGroupItem>
                      </Link>
                      <Link to="#" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          Deposit Tracker
                        </CListGroupItem>
                      </Link>
                      <Link to="#" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          Business Outlook
                        </CListGroupItem>
                      </Link>
                      <Link to="#" className="text-decoration-none">
                        <CListGroupItem className="border-0 sidebar_hover">
                          Dashboard
                        </CListGroupItem>
                      </Link>
                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem> */}
                <CAccordionItem>
                  <CAccordionHeader itemkey={8}>Settings</CAccordionHeader>
                  <CAccordionBody>
                    <CListGroup flush className="border-0">
                      {(role == "admin" || role == "ho" || role == "adminView") ? (
                        <>
                          <Link to="/portalUsers" className="text-decoration-none" onClick={() => setVisible(false)} >
                            <CListGroupItem className="border-0 sidebar_hover">
                              Portal Users
                            </CListGroupItem>
                          </Link>
                          <Link to="/passwordChange" className="text-decoration-none" onClick={() => setVisible(false)} >
                            <CListGroupItem className="border-0 sidebar_hover">
                              Change Password
                            </CListGroupItem>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                      <Link to="/cashCollectors" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Cash Collectors
                        </CListGroupItem>
                      </Link>
                      <Link to="/FeeDetails" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Fee Details
                        </CListGroupItem>
                      </Link>
                      <Link to="/depositsLoans" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Deposits & Loans
                        </CListGroupItem>
                      </Link>
                      <Link to="/passwordreset" className="text-decoration-none" onClick={() => setVisible(false)} >
                        <CListGroupItem className="border-0 sidebar_hover">
                          Password Change
                        </CListGroupItem>
                      </Link>
                    </CListGroup>
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            </CNavbarNav>
          </COffcanvasBody>
        </COffcanvas>
        <CNavbarBrand
          href="/home"
          className=" fs-2 fw-bolder m-0 px-4 text-black"
        >
          SAHULAT
        </CNavbarBrand>
        <CNavItem className="d-flex justify-content-end align-items-center w-100">
          <CNavbarText className="fw-bold fs-5 px-1 text-black ">
            {Cookies.get("branchName")} Branch ||
          </CNavbarText>
          <CNavbarText className="fw-bold fs-5 px-1 text-black ">
            {Cookies.get("username")} user
          </CNavbarText>
          <CNavbarText className="fw-bold fs-5 px-5 text-black">
            {realdate}
          </CNavbarText>
          <CNavbarText
            className="fw-bold fs-5  px-4 text-white bg-warning rounded-pill"
            onClick={logout}
            style={{ cursor: "pointer" }}
          >
            Logout
          </CNavbarText>
        </CNavItem>
      </CContainer>
    </CNavbar>
  );
}

export default Main;
