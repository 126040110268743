import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormText,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useContext } from "react";
import imageCompression from "browser-image-compression";
import globalContext from "../../contexts/Global/globalContext";

function LoanRequestModal({ visible, setVisible, data }) {
  const [checking, setChecking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const [sum, setSum] = useState(0);
  const [input1, setInput1] = useState(0);
  const [input2, setInput2] = useState(0);
  const [days, setDays] = useState(0);

  const [installs, setInstalls] = useState(0);

  const [loanData, setLoanData] = useState({
    membershipNo: data.membershipNo,
    memberName: data.pDetails.memberName,
    loanName: "",
    frequency: "",
    maxNoOfDays: "",
    loanAmount: "",
    collateral: "",
    linkedMembershipNo: "",
    linkedDepositName: "",
    repayment: "",
    image1: null,
    image2: null,
    image3: null,
  });

  const [isCalPaymentBtnDisable, setIsCalPaymentBtnDisable] = useState(false);
  const handleInput1Change = (e) => {
    if (e.target.value == "Consumer Goods Loan") {
      setInput1(10);
      setLoanData({
        ...loanData,
        [e.target.name]: e.target.value,
      });
    } else {
      {
        setInput1(4);
        setLoanData({
          ...loanData,
          [e.target.name]: e.target.value,
        });
      }
    }
    setLoanData({
      ...loanData,
      [e.target.name]: e.target.value,
    });
  };
  const handleInput2Change = (e) => {
    setInput2(e.target.value);
    setLoanData({
      ...loanData,
      [e.target.name]: e.target.value,
    });
  };
  const calculateSum = () => {
    setSum(Number(input2) + Number(input2) * (Number(input1) / 100));
    setInstalls(Number(input2) / days);
  };
  const [previewImages, setPreviewImages] = useState([].fill(null));
  async function fileSelectedHandler(e, index) {
    let file = e.target.files[0];
    const options = {
      maxSizeMB: 0.097,
      maxWidthOrHeight: 1920,
    };
    const newPreviewImages = [...previewImages];

    try {
      const compressedFile = await imageCompression(file, options);

      var file_blob = new File([compressedFile], `${compressedFile.name}`, {
        type: compressedFile.type,
      });
      setLoanData({
        ...loanData,
        [e.target.name]: {
          data: file_blob,
          contentType: file_blob.type,
        },
      });
    } catch (error) {
      console.log(error);
    }
    const imageSrc = URL.createObjectURL(e.target.files[0]);
    if (newPreviewImages[index] === null) {
      /* insert image at the index of the array */
      newPreviewImages.splice(index, 0, imageSrc);
    } else {
      /* replace the previous image at index */
      newPreviewImages[index] = imageSrc;
    }
    setPreviewImages(newPreviewImages);
  }
  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    try {
      //Prevents Refreshing the Form
      e.preventDefault();

      let res = await axios.post(`/api/loans/${data.membershipNo}`, loanData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
          "Content-Type": "multipart/form-data",
        },
      });
      showAlert("Successfully Loan Requested", "2");
      setTimeout(() => {
        setVisible(false);
        navigate(0);
      }, 2000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  const onChangeHandler = (e) => {
    if (e.target.name == "maxNoOfDays") {
      setDays(e.target.value);
      setLoanData({
        ...loanData,
        [e.target.name]: e.target.value,
      });
    } else {
      setLoanData({
        ...loanData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const checkBalance = async (e) => {
    try {
      e.preventDefault();
      setChecking(true);
      if (loanData.linkedMembershipNo == data.membershipNo) {
        showAlert("linked account cannot be same as loan request account", "3");
        setChecking(false);
        return;
      }
      let res = await axios.post(
        `/api/loans/checkdeposit/${loanData.linkedMembershipNo}`,
        {
          depositName: loanData.linkedDepositName,
          totalRepayment: sum,
        },
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      showAlert(res.data.success, "2");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setChecking(false);
  };

  // CHECK REPAYMENT BUTTON HAVE DATA
  useEffect(() => {
    const { loanName, loanAmount, maxNoOfDays } = loanData;
    // Check if all values are truthy (not null, undefined, empty string, etc.)
    const isDisabled = !loanName || loanAmount <= 0 || !maxNoOfDays;
    setIsCalPaymentBtnDisable(isDisabled);
  }, [loanData]);

  const HandleCalulateRepayment = async () => {
    const payload = {
      loanName: loanData.loanName,
      loanAmount: loanData.loanAmount,
      days: loanData.maxNoOfDays,
    };

    try {
      let res = await axios.post(`/api/loans/calculateRepayment`, payload, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
          "Content-Type": "application/json",
        },
      });
      showAlert("Successfully Calculate Repayment", "2");

      if (res) {
        if (res.status === 200 && res.data.response) {
          const { repaymentAmount, installmentPerDay } = res?.data?.response;
          setInstalls(installmentPerDay?.toFixed(2));
          setSum(repaymentAmount);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  };
  return (
    <>
      <CModal
        size="xl"
        visible={visible}
        onClose={() => {
          setLoanData({
            membershipNo: data.membershipNo,
            memberName: data.pDetails.memberName,
            loanName: "",
            frequency: "",
            maxNoOfDays: "",
            loanAmount: "",
            collateral: "",
            linkedMembershipNo: "",
            linkedDepositName: "",
            repayment: "",
            image1: null,
            image2: null,
            image3: null,
          });
          setSum(0);
          setInstalls(0);
          setVisible(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>Loan Request</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm id="loan-form" onSubmit={onSubmitHandler}>
            <CContainer className="form-container d-flex p-3">
              <CContainer className="form-1 m-2">
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Membership Number
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Member id"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    required
                    type="number"
                    name="membershipNo"
                    value={loanData.membershipNo}
                    readOnly
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Loan Name
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormSelect
                    required
                    size="sm"
                    name="loanName"
                    value={loanData.loanName}
                    onChange={handleInput1Change}
                  >
                    <option disabled selected hidden value="">
                      Select
                    </option>
                    <option value="Demand Loan">Demand Loan</option>
                    <option value="Consumer Goods Loan">
                      Consumer Goods Loan
                    </option>
                    <option value="Business Goods Loan">
                      Business Goods Loan
                    </option>
                  </CFormSelect>
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Loan Duration
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  {loanData.loanName == "" && (
                    <CFormSelect
                      required
                      size="sm"
                      name="maxNoOfDays"
                      // className="w-25"
                      value={loanData.maxNoOfDays}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="300">300</option>
                      <option value="100">100</option>
                    </CFormSelect>
                  )}
                  {(loanData.loanName == "Business Goods Loan" ||
                    loanData.loanName == "Demand Loan") && (
                    <CFormSelect
                      required
                      size="sm"
                      name="maxNoOfDays"
                      value={loanData.maxNoOfDays}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="100">100</option>
                    </CFormSelect>
                  )}
                  {loanData.loanName == "Consumer Goods Loan" && (
                    <CFormSelect
                      required
                      size="sm"
                      name="maxNoOfDays"
                      value={loanData.maxNoOfDays}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="300">300</option>
                    </CFormSelect>
                  )}
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Collateral
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  {loanData.loanName == "" && (
                    <CFormSelect
                      required
                      size="sm"
                      name="collateral"
                      className=""
                      value={loanData.collateral}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="Hypothecation">Hypothecation</option>
                      {/* <option value="None">None</option> */}
                      <option value="Cheque">Cheque</option>
                      <option value="Linked Account">Linked Account</option>
                      <option value="Gold">Gold</option>
                    </CFormSelect>
                  )}
                  {loanData.loanName == "Demand Loan" && (
                    <CFormSelect
                      required
                      size="sm"
                      name="collateral"
                      className=""
                      value={loanData.collateral}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="Linked Account">Linked Account</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Gold">Gold</option>
                    </CFormSelect>
                  )}
                  {loanData.loanName == "Consumer Goods Loan" && (
                    <CFormSelect
                      required
                      size="sm"
                      name="collateral"
                      className=""
                      value={loanData.collateral}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="Hypothecation"> Hypothecation</option>
                    </CFormSelect>
                  )}
                  {loanData.loanName == "Business Goods Loan" && (
                    <CFormSelect
                      required
                      size="sm"
                      name="collateral"
                      className=""
                      value={loanData.collateral}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>

                      <option value="Cheque">Cheque</option>
                      <option value="Linked Account">Linked Account</option>
                      <option value="Gold">Gold</option>
                    </CFormSelect>
                  )}
                </CInputGroup>
                <hr />
                {/* -------Linked member--------- */}
                {loanData.collateral == "Linked Account" && (
                  <CInputGroup className="mb-3">
                    <CInputGroupText id="basic-addon1">
                      Link Membership Number
                      <span className="text-danger">*</span>
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Link Member Id"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="linkedMembershipNo"
                      required
                      type="number"
                      value={loanData.linkedMembershipNo}
                      onChange={onChangeHandler}
                    />
                  </CInputGroup>
                )}
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Total Repayment
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Total Repayment"
                    aria-label="Repayment"
                    aria-describedby="basic-addon1"
                    name="repayment"
                    type="number"
                    readOnly
                    value={sum}
                    // onChange={onChangeHandler}
                  />
                </CInputGroup>
                <hr />
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Quotation 1
                  </CInputGroupText>
                  <CFormInput
                    type="file"
                    onChange={(e) => fileSelectedHandler(e, 0)}
                    name="image1"
                    accept="image/*"
                    required
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Quotation 2
                  </CInputGroupText>
                  <CFormInput
                    type="file"
                    onChange={(e) => fileSelectedHandler(e, 1)}
                    name="image2"
                    accept="image/*"
                    required
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Quotation 3
                  </CInputGroupText>
                  <CFormInput
                    type="file"
                    onChange={(e) => fileSelectedHandler(e, 3)}
                    name="image3"
                    accept="image/*"
                    required
                  />
                </CInputGroup>
              </CContainer>
              {/* -------------------2------------------------ */}
              <CContainer className="form-2 m-2">
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Member Name
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Member name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    required
                    value={loanData.memberName}
                    name="memberName"
                    readOnly
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Loan Amount
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Amount"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    required
                    name="loanAmount"
                    type="number"
                    value={loanData.loanAmount}
                    onChange={handleInput2Change}
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Frequency
                    <span className="text-danger">*</span>
                  </CInputGroupText>
                  <CFormSelect
                    required
                    size="sm"
                    name="frequency"
                    value={loanData.frequency}
                    onChange={onChangeHandler}
                  >
                    <option disabled selected hidden value="">
                      Select
                    </option>
                    {loanData.loanName == "Demand Loan" ||
                    loanData.loanName == "Consumer Goods Loan" ? (
                      <>
                        <option value="Monthly">Monthly</option>
                      </>
                    ) : (
                      <option value="Daily">Daily</option>
                    )}
                  </CFormSelect>
                </CInputGroup>
                <hr style={{ marginTop: "70px" }} />
                {loanData.collateral == "Linked Account" && (
                  <CInputGroup className="mb-3">
                    <CInputGroupText id="basic-addon1">
                      Linked Deposit Name
                      <span className="text-danger">*</span>
                    </CInputGroupText>
                    <CFormSelect
                      size="sm"
                      required
                      name="linkedDepositName"
                      value={loanData.linkedDepositName}
                      onChange={onChangeHandler}
                    >
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="Daily Deposit">Daily Deposit</option>
                      <option value="Savings Deposit">Savings Deposit</option>
                      <option value="Term Deposit">Term Deposit</option>
                    </CFormSelect>
                  </CInputGroup>
                )}
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1" className="">
                    Installment / Day
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Installation"
                    aria-label="installation"
                    aria-describedby="basic-addon1"
                    name="installation"
                    type="number"
                    readOnly
                    // onChange={onChangeHandler}
                    value={installs}
                  />
                </CInputGroup>
                <CContainer className="form-image-cont ">
                  <CFormText>Images</CFormText>
                  {previewImages.map((previewImage) => (
                    <img
                      src={previewImage}
                      className="image-input"
                      alt="preview"
                    />
                  ))}
                </CContainer>
              </CContainer>
            </CContainer>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CContainer fluid className="d-flex justify-content-around">
            <CButton
              type="submit"
              disabled={isSubmitting || checking}
              color="success"
              form="loan-form"
              className="m-2 text-white fw-bold"
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </CButton>
            {loanData.collateral == "Linked Account" && (
              <CButton
                disabled={isSubmitting || checking}
                color="info"
                className="m-2 text-white fw-bold"
                onClick={checkBalance}
              >
                {checking ? "Checking..." : "Check Balance"}
              </CButton>
            )}
            <CButton
              // onClick={calculateSum}
              onClick={HandleCalulateRepayment}
              color="info"
              className="m-2 text-white fw-bold"
              disabled={isCalPaymentBtnDisable}
            >
              Calculate Repayment
            </CButton>
            <CButton
              type="button"
              color="danger"
              onClick={() => {
                setLoanData({
                  membershipNo: data.membershipNo,
                  memberName: data.pDetails.memberName,
                  loanName: "",
                  frequency: "",
                  maxNoOfDays: "",
                  loanAmount: "",
                  collateral: "",
                  linkedMembershipNo: "",
                  linkedDepositName: "",
                  repayment: "",
                  image1: null,
                  image2: null,
                  image3: null,
                });
                setSum(0);
                setInstalls(0);
                setVisible(false);
              }}
              className="m-2 text-white fw-bold"
            >
              Cancel
            </CButton>
          </CContainer>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default LoanRequestModal;
