import {
    CContainer,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHeaderCell,
    CTableHead,
    CTableRow,
    CButton,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios"

const Dashboard = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({
        first: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        second: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        third: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        fourth: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        fifth: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        sixth: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        seventh: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        eight: {
            noOfLoans: 0,
            balanceAmount: 0,
        },
        total: {
            loans: 0,
            balanceAmount: 0
        }
    })
    const [fixData, setFixData] = useState({
        first: {
            percentLoans: 0,
            percentBalance: 0,
        },
        second: {
            percentLoans: 0,
            percentBalance: 0,
        },
        third: {
            percentLoans: 0,
            percentBalance: 0,
        },
        fourth: {
            percentLoans: 0,
            percentBalance: 0,
        },
        fifth: {
            percentLoans: 0,
            percentBalance: 0,
        },
        sixth: {
            percentLoans: 0,
            percentBalance: 0,
        },
        seventh: {
            percentLoans: 0,
            percentBalance: 0,
        },
        eight: {
            percentLoans: 0,
            percentBalance: 0,
        },
    })
    useEffect(() => {
        async function fetchDashboard() {
            try {
                const { data } = await axios.get("api/loans/dashboard", {
                    headers: {
                        "auth-token": JSON.parse(localStorage.getItem("token")).token,
                    },
                });
                setDashboardData(data.dashboardData)

                let fix = {
                    first: {
                        percentLoans: ((data.dashboardData.first.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.first.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    second: {
                        percentLoans: ((data.dashboardData.second.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.second.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    third: {
                        percentLoans: ((data.dashboardData.third.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.third.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    fourth: {
                        percentLoans: ((data.dashboardData.fourth.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.fourth.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    fifth: {
                        percentLoans: ((data.dashboardData.fifth.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.fifth.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    sixth: {
                        percentLoans: ((data.dashboardData.sixth.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.sixth.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    seventh: {
                        percentLoans: ((data.dashboardData.seventh.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.seventh.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                    eight: {
                        percentLoans: ((data.dashboardData.eight.noOfLoans / data.dashboardData.total.loans) * 100),
                        percentBalance: ((data.dashboardData.eight.balanceAmount / data.dashboardData.total.balanceAmount) * 100),
                    },
                }
                setFixData(fix)

            } catch (error) {
                console.log(error)
            }
        }
        fetchDashboard();
    }, []);
    return (

        <CContainer fluid className="p-4 my-2">
            <CTable bordered striped className="shadow">
                <CTableHead className="bg-dark text-center align-middle">
                    <CTableRow>
                        <CTableHeaderCell scope="col" className="text-white">
                            S.No.
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            Loan Maturity
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            No. Of Loans
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            % No. Of Loans
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            Balance Amount
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            % Balance
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            Avg. Balance per Loan
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            Remarks
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-white">
                            Details
                        </CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody className="text-center">
                    <CTableRow style={{ backgroundColor: "#00D100" }}>
                        <CTableDataCell>1</CTableDataCell>
                        <CTableDataCell>New Loan, Active Member, Overdue Up To 25%</CTableDataCell>
                        <CTableDataCell>{dashboardData.first.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.first.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.first.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.first.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.first.balanceAmount / dashboardData.first.noOfLoans) ? (dashboardData.first.balanceAmount / dashboardData.first.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Good Health</CTableDataCell>
                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "new",
                                    lastPaymentDate: "active",
                                    repaidValue : "All",
                                    overduePercentage: "upto"
                                }
                            })
                        }}>Details</CButton>
                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#8AFF8A" }}>
                        <CTableDataCell>2</CTableDataCell>
                        <CTableDataCell>New Loan, Active Member, Overdue Above 25%</CTableDataCell>
                        <CTableDataCell>{dashboardData.second.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.second.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.second.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.second.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.second.balanceAmount / dashboardData.second.noOfLoans) ? (dashboardData.second.balanceAmount / dashboardData.second.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Bad Health
                        </CTableDataCell>
                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "new",
                                    lastPaymentDate: "active",
                                    repaidValue : "All",
                                    overduePercentage: "above"
                                }
                            })
                        }}>Details</CButton>
                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#FFFF5C" }}>
                        <CTableDataCell>3</CTableDataCell>
                        <CTableDataCell>New Loan, Inactive Member, Partial Repayment</CTableDataCell>
                        <CTableDataCell>{dashboardData.third.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.third.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.third.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.third.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.third.balanceAmount / dashboardData.third.noOfLoans) ? (dashboardData.third.balanceAmount / dashboardData.third.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Low Risk
                        </CTableDataCell>
                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "new",
                                    lastPaymentDate: "inactive",
                                    repaidValue : "partialPayment",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>
                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#FFA500" }}>
                        <CTableDataCell>4</CTableDataCell>
                        <CTableDataCell>New Loan, Inactive Member, Zero Repayment</CTableDataCell>
                        <CTableDataCell>{dashboardData.fourth.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.fourth.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.fourth.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.fourth.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.fourth.balanceAmount / dashboardData.fourth.noOfLoans) ? (dashboardData.fourth.balanceAmount / dashboardData.fourth.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>High Risk
                        </CTableDataCell>

                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "new",
                                    lastPaymentDate: "inactive",
                                    repaidValue : "noPayment",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>
                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#FF5C5C", color: "#FFFFFF" }}>
                        <CTableDataCell>5</CTableDataCell>
                        <CTableDataCell>Matured Loan, Active Member</CTableDataCell>
                        <CTableDataCell>{dashboardData.fifth.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.fifth.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.fifth.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.fifth.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.fifth.balanceAmount / dashboardData.fifth.noOfLoans) ? (dashboardData.fifth.balanceAmount / dashboardData.fifth.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Defaulter - Revived
                        </CTableDataCell>
                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "matured",
                                    lastPaymentDate: "active",
                                    repaidValue : "All",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>
                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}>
                        <CTableDataCell>6</CTableDataCell>
                        <CTableDataCell>Matured Loan, Inactive Member</CTableDataCell>
                        <CTableDataCell>{dashboardData.sixth.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.sixth.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.sixth.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.sixth.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.sixth.balanceAmount / dashboardData.sixth.noOfLoans) ? (dashboardData.sixth.balanceAmount / dashboardData.sixth.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Defaulter
                        </CTableDataCell>

                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "matured",
                                    lastPaymentDate: "inactive",
                                    repaidValue : "All",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>

                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#808080", color: "#FFFFFF" }}>
                        <CTableDataCell>7</CTableDataCell>
                        <CTableDataCell>Old Loan, Active Member</CTableDataCell>
                        <CTableDataCell>{dashboardData.seventh.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.seventh.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.seventh.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.seventh.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.seventh.balanceAmount / dashboardData.seventh.noOfLoans) ? (dashboardData.seventh.balanceAmount / dashboardData.seventh.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Bad Debt - Revived
                        </CTableDataCell>

                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "old",
                                    lastPaymentDate: "active",
                                    repaidValue : "All",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>

                    </CTableRow>

                    <CTableRow style={{ backgroundColor: "#000000", color: "#FFFFFF" }}>
                        <CTableDataCell>8</CTableDataCell>
                        <CTableDataCell>Old Loan, Inactive Member</CTableDataCell>
                        <CTableDataCell>{dashboardData.eight.noOfLoans}</CTableDataCell>
                        <CTableDataCell>{fixData.eight.percentLoans.toFixed(2)}%</CTableDataCell>
                        <CTableDataCell>{dashboardData.eight.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{fixData.eight.percentBalance.toFixed(2)}</CTableDataCell>
                        <CTableDataCell>{(dashboardData.eight.balanceAmount / dashboardData.eight.noOfLoans) ? (dashboardData.eight.balanceAmount / dashboardData.eight.noOfLoans).toFixed(2) : "-"}</CTableDataCell>
                        <CTableDataCell>Bad Debt
                        </CTableDataCell>

                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "old",
                                    lastPaymentDate: "inactive",
                                    repaidValue : "All",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>

                    </CTableRow>
                    <CTableRow>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell>Total</CTableDataCell>
                        <CTableDataCell>{dashboardData.total.loans}</CTableDataCell>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell>{dashboardData.total.balanceAmount.toFixed(2)}</CTableDataCell>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell></CTableDataCell>
                        <CButton onClick={() => {
                            navigate("/LoanTracker", {
                                state: {
                                    loanName : "All",
                                    issueDate : "All",
                                    lastPaymentDate: "All",
                                    repaidValue : "All",
                                    overduePercentage: "All"
                                }
                            })
                        }}>Details</CButton>
                    </CTableRow>

                </CTableBody>
            </CTable>
        </CContainer>
    );
};

export default Dashboard;
