import React, { useState , useContext} from "react";
import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,

} from "@coreui/react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";
import { useNavigate } from "react-router-dom";

function LoanRepay(data) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate()
    let loanData = data.data.data.loans.loans[data.data.indx];
    const { showAlert , } = useContext(globalContext);
    const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
    const [amountPay, setRepayAmount] =useState(null)
    
    const onRepayment = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
          let token = JSON.parse(localStorage.getItem("token")).token;
          let  payRes  = await axios.put(
            `/api/loans/pay/${data.data.data.membershipNo}/${loanData._id}`,
            {amountPay,date},
            {
              headers: {
                "auth-token": token,
              },
            }
          );
          showAlert(`${amountPay} Ammount paid successfully`,"2")
          setTimeout(()=>{
            window.location.reload()
          },1000)
        } catch (error) {
          console.log(error);
          if (error.response.data.errors) {
            error.response.data.errors.forEach((ele) => {
              showAlert(ele.msg, "3")
            });
          } else {
            showAlert(error.response.data.error, "3")
          }        }
          setIsSubmitting(false);
      };

      const dateChange = (e) =>{
        if(e.target.name == "date"){ 
        setDate(e.target.value)}
        if(e.target.name== "amountPay"){
          setRepayAmount(e.target.value)
        }
      }

  return (
    <>
    <CContainer>
              <h5>Amount Receive</h5>
              <CForm className="w-75 d-flex" onSubmit={onRepayment}>
                <CInputGroup className="w-50">
                  <CInputGroupText className="fw-bold">Amount</CInputGroupText>
                  <CFormInput
                    type="number"
                    className="overflow-hidden"
                    value={amountPay}
                    name="amountPay"
                    onChange={dateChange}
                    required
                  />
                </CInputGroup>
                <CInputGroup className="w-50 ms-4">
                  <CInputGroupText className="fw-bold w-25">
                    Date
                  </CInputGroupText>
                  <CFormInput type="date" 
                  name="date"
                   value={date} 
                   onChange={dateChange}
                   />
                </CInputGroup>
                <CButton
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                  className="w-25 text-white fw-bold ms-5"
                >
                  {isSubmitting ? 'Submitting...' : 'Add Payment'}
                </CButton>
              </CForm>
            </CContainer>
    </>
  )
}

export default LoanRepay