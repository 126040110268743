import React, { useState } from "react";
import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import axios from "axios";
import Paginate from "./Pagination";
import DropDownPagination from "./DropDownPaginate";
// import xlsx library to convert json to excel
import * as XLSX from "xlsx";

function CreditorLedger() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [incomingData, setIncomingData] = useState();
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });

  let onSubmitHandler = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      let res = await axios.get(
        // `api/branchActivity/cashBook/${reqData.fromReqDate}/${reqData.toReqDate}/${reqData.account}/${reqData.category}`,
        `api/creditors/ledger/${reqData.fromReqDate}/${reqData.toReqDate}`,

        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
            "Content-Type": "application/json",
          },
        }
      );

      setIncomingData(res.data.creditorData);

      // alert("Transaction Successfull");
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  let onChangeHandler = (e) => {
    // e.preventDefault();

    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };
  const formatData = (incomingData) => {
    return incomingData.map((e, i) => {
      return {
        "S.No": i + 1,

        Creditor_ID: e.creditorID,
        Creditor_Name: e.creditorName,
        Voucher_No: e.voucherNo,
        Account_Name: e.accountName,
        Credit_Amount: e.creditAmount,
        Debit_Amount: e.debitAmount,
        Balance_Amount: e.balanceAmount,
        Date: new Date(e.transactionDate).toLocaleString(),
      };
    });
  };
  const handleClick = () => {
    let newData = formatData(incomingData);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `CreditorLedger.csv`);
  };

  return (
    <CContainer fluid className="p-0 m-0" style={{ minHeight: "90vh" }}>
      <p
        style={{ background: "#2364aa" }}
        className="fw-bold text-white fs-2 text-center p-1"
      >
        Creditor Ledger
      </p>
      <CContainer className=" mt-2">
        <legend>Details</legend>
        <CForm onSubmit={onSubmitHandler} className=" d-flex ">
          <CInputGroup className=" w-75">
            <CInputGroupText className="fw-bold">From Date</CInputGroupText>
            <CFormInput
              value={reqData.fromReqDate}
              onChange={onChangeHandler}
              placeholder="xx/xx/xxxx"
              className="me-5"
              type="date"
              required
              name="fromReqDate"
              max={new Date().toISOString().split("T")[0]}
            />

            <CInputGroupText className="fw-bold ms-4">To Date</CInputGroupText>
            <CFormInput
              value={reqData.toReqDate}
              onChange={onChangeHandler}
              placeholder="xx/xx/xxxx"
              type="date"
              required
              name="toReqDate"
              max={new Date().toISOString().split("T")[0]}
            />
          </CInputGroup>

          <CButton
            disabled={isSubmitting}
            type="submit"
            // size="sm"
            color="info"
            className="ms-4 text-white fw-bold"
          >
            {isSubmitting ? "Submitting..." : "Get Details"}
          </CButton>
          <CButton
            onClick={handleClick}
            // size="sm"
            color="success"
            className="ms-4 text-white fw-bold"
          >
            Export to CSV
          </CButton>
        </CForm>
      </CContainer>

      <CContainer className=" mt-4">
        <hr />

        {/* <DropDownPagination
      // handleItemsPerPage={handleItemsPerPage}
      // num={itemsPerPage}
      /> */}

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              {/* <CTableHeaderCell scope="col" className="text-white">
              #
            </CTableHeaderCell> */}
              <CTableHeaderCell scope="col" className="text-white">
                Deposit
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Creditor No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Creditor Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Voucher No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Account Name
              </CTableHeaderCell>

              <CTableHeaderCell scope="col" className="text-white">
                Credit Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Debit Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Transaction Date
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {incomingData &&
              incomingData.map((ele, i) => {
                return (
                  <CTableRow key={i}>
                    <CTableDataCell></CTableDataCell>
                    <CTableDataCell>{ele.creditorID}</CTableDataCell>
                    <CTableDataCell>{ele.creditorName}</CTableDataCell>
                    <CTableDataCell>{ele.voucherNo}</CTableDataCell>
                    <CTableDataCell>{ele.accountName}</CTableDataCell>
                    <CTableDataCell>
                      {ele.creditAmount.toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {ele.debitAmount.toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {ele.balanceAmount.toFixed(2)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {new Date(ele.transactionDate).toLocaleDateString()}
                    </CTableDataCell>
                  </CTableRow>
                );
              })}
          </CTableBody>
        </CTable>
        <Paginate
        //   itemsPerPage={itemsPerPage}
        //   totalItems={data.length}
        //   paginate={paginate}
        //   prevPage={prevPage}
        //   nextPage={nextPage}
        />
      </CContainer>
    </CContainer>
  );
}

export default CreditorLedger;
