import {
	CTable,
	CTableHead,
	CTableRow,
	CTableHeaderCell,
	CTableBody,
	CTableDataCell,
	CContainer,
} from "@coreui/react";

import axios from "axios";

import { useEffect, useState } from "react";

const Transactions = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		try {
			async function fetchData() {
				const response = await axios.get(`/api/member/deposits`, {
					headers: {
						"auth-token": JSON.parse(localStorage.getItem("token")).token,
					},
				});
				setData(response.data.allData);
			}
			fetchData();
		} catch (error) {
			console.error(error);
		}
	}, []);


	return (
		<>
			<CContainer fluid className="p-4 mb-5" style={{ minHeight: "80vmin" }}>
				<CContainer fluid className="p-1 m-2 ms-0"></CContainer>

				<CTable bordered striped className="shadow">
					<CTableHead className="bg-secondary text-center">
						<CTableRow>
							{/* Added this for later */}

							{/* <CTableHeaderCell scope="col">#</CTableHeaderCell> */}

							<CTableHeaderCell scope="col">Deposit Name</CTableHeaderCell>
							<CTableHeaderCell scope="col">Deposit Amount</CTableHeaderCell>
							<CTableHeaderCell scope="col">Withdraw Amount</CTableHeaderCell>
							<CTableHeaderCell scope="col">Balance Amount</CTableHeaderCell>
							<CTableHeaderCell scope="col">Frequency</CTableHeaderCell>
							<CTableHeaderCell scope="col">Status</CTableHeaderCell>
						</CTableRow>
					</CTableHead>
					<CTableBody>
						<CTableRow className="text-center">
							<CTableDataCell>Monthly</CTableDataCell>
							<CTableDataCell>50000</CTableDataCell>
							<CTableDataCell>10000</CTableDataCell>
							<CTableDataCell>40000</CTableDataCell>
							<CTableDataCell>Daily</CTableDataCell>
							<CTableDataCell>{true ? "Active" : "Inactive"}</CTableDataCell>
						</CTableRow>
					</CTableBody>
				</CTable>
			</CContainer>
		</>
	);
};

export default Transactions;
