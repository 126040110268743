import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import bookOpt from "./bookOpt.js";
import * as XLSX from "xlsx";
import globalContext from "../../contexts/Global/globalContext.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CashBankBook = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
    account: "",
    category: "all",
  });

  let onSubmitHandler = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      if (reqData.account === "select" || reqData.account === "") {
        alert("Please select account");
        setIsSubmitting(false);
        return;
      }
      let res = await axios.get(
        `api/branchActivity/cashBook/${reqData.fromReqDate}/${reqData.toReqDate}/${reqData.account}/${reqData.category}`,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
            "Content-Type": "application/json",
          },
        }
      );
      setData(res.data);
      showAlert("Fetch Successfull", "2");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  let onChangeHandler = (e) => {
    // e.preventDefault();

    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let newData = formatData(data);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `CashBankBook.csv`);
  };

  const formatData = (data) => {
    const openingBalanceRow = {
      "S.No": "",
      Date: "",
      "Voucher No.": "",
      Account: "",
      Category: "",
      Particulars: "Opening Balance",
      Receipt: "",
      Payment: "",
      Balance: data.opBalance.toFixed(2).toLocaleString("en-US"),
    };

    // Data Rows
    const dataRows = data?.result.map((e, i) => {
      return {
        "S.No": i + 1,
        Date: new Date(e.date).toLocaleDateString(),
        "Voucher No.": e.voucherNo,
        Account: e.account,
        Category: e.category,
        Particulars: e.particulars,
        Receipt: e.receipt.toFixed(2).toLocaleString("en-US"),
        Payment: e.payments.toFixed(2).toLocaleString("en-US"),
        Balance: e.balance.toFixed(2).toLocaleString("en-US"),
      };
    });

    // Total Row
    const totalRow = {
      "S.No": "",
      Date: "",
      "Voucher No.": "",
      Account: "",
      Category: "",
      Particulars: "Total",
      Receipt: data.totalReceipts.toFixed(2).toLocaleString("en-US"),
      Payment: data.totalPayments.toFixed(2).toLocaleString("en-US"),
      Balance: "",
    };

    // Concatenate all rows
    return [openingBalanceRow, ...dataRows, totalRow];
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text("Profit and Loss Account", 12, 16);

    doc.setFontSize(7);
    // Define the head of the table
    const head = [
      [
        "S_No",
        "Date",
        "Voucher No.",
        "Account",
        "Category",
        "Particulars",
        "Receipt",
        "Payment",
        "Balance",
      ],
    ];

    // Start with the opening balance row
    let body = [
      [
        "",
        "",
        "",
        "",
        "",
        "Opening Balance",
        "",
        "",
        `${data?.opBalance?.toFixed(2)}`,
      ],
    ];

    // Append the transaction rows to the body
    body = body.concat(
      data?.result?.map((ele, i) => [
        i + 1,
        new Date(ele.date)?.toLocaleDateString(),
        ele.voucherNo,
        ele.account,
        ele.category,
        ele.particulars,
        ele.receipt ? `${ele.receipt?.toFixed(2)}` : "-",
        ele.payments ? `${ele.payments?.toFixed(2)}` : "-",
        ele.balance ? `${ele.balance?.toFixed(2)}` : "-",
      ])
    );

    // Append the total row at the end
    body.push([
      "",
      "",
      "",
      "",
      "",
      "Total",
      `${data.totalReceipts?.toFixed(2)}`,
      `${data.totalPayments?.toFixed(2)}`,
      "",
    ]);

    // Add the table to the document
    doc.autoTable({
      head,
      body,
      startY: 30,
      styles: { fontSize: 7},
      headStyles: { fontSize: 8 },
    });

    // Save the document
    doc.save("CashBankBook.pdf");
  };

  return (
    <CContainer fluid className="p-0 m-0" style={{ minHeight: "90vh" }}>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Cash / Bank Book
      </p>
      <CContainer>
        <CRow>
          <h5>Report</h5>
          <hr></hr>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={reqData.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="transaction_type">
                Account
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                name="account"
                value={reqData.account}
                onChange={onChangeHandler}
                aria-label="Transaction Type"
                options={[
                  { label: "select" },
                  { label: "All", value: "all" },
                  { label: "Main Cash", value: "mc" },
                  { label: "Petty Cash", value: "pc" },
                  { label: "Bank", value: "ba" },
                ]}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="transaction_type">
                Category
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                name="category"
                value={reqData.category}
                onChange={onChangeHandler}
                aria-label="Transaction Type"
                // options={[{ label: "All", value: "all" }]}
                options={[...bookOpt]}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={12}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white "
                  color="success"
                  onClick={onSubmitHandler}
                  disabled={isSubmitting}
                >
                  Get Details
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={exportPDF}
                  disabled={!data?.result || data?.result?.length === 0}
                >
                  PDF
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                  disabled={!data || (data && data.result.length === 0)}
                >
                  Export to CSV
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>
        <h5>Details</h5>
        <hr></hr>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                S_No
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Voucher No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Account
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Category
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Particulars
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Receipt
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Payment
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data && (
              <>
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Opening Balance</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    {data.opBalance.toFixed(2).toLocaleString("en-US")}
                  </CTableDataCell>
                </CTableRow>
              </>
            )}
            {data &&
              data.result.map((e, i) => (
                <>
                  <CTableRow key={i}>
                    <CTableDataCell>{i + 1}</CTableDataCell>
                    <CTableDataCell>
                      {new Date(e.date).toLocaleDateString()}
                    </CTableDataCell>
                    <CTableDataCell>{e.voucherNo}</CTableDataCell>
                    <CTableDataCell>{e.account}</CTableDataCell>
                    <CTableDataCell>{e.category}</CTableDataCell>
                    <CTableDataCell>{e.particulars}</CTableDataCell>
                    <CTableDataCell>
                      {e.receipt.toFixed(2).toLocaleString("en-US")}
                    </CTableDataCell>
                    <CTableDataCell>
                      {e.payments.toFixed(2).toLocaleString("en-US")}
                    </CTableDataCell>
                    <CTableDataCell>
                      {e.balance.toFixed(2).toLocaleString("en-US")}
                    </CTableDataCell>
                  </CTableRow>
                </>
              ))}
            {data && (
              <>
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell>
                    {data.totalReceipts.toFixed(2).toLocaleString("en-US")}
                  </CTableDataCell>
                  <CTableDataCell>
                    {data.totalPayments.toFixed(2).toLocaleString("en-US")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
              </>
            )}
          </CTableBody>
        </CTable>
      </CContainer>
    </CContainer>
  );
};

export default CashBankBook;
