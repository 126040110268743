import {
  CCard,
  CCardBody,
  CCardTitle,
  CContainer,
  CForm,
  CFormInput,
  CButton,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import globalContext from "../contexts/Global/globalContext";
import MemberContext from "../contexts/MemberContext/MemberContext";
import Cookies from "js-cookie";
import MemberCard from "./MemberCard/MemberCard";

function Home() {
  const navigate = useNavigate();
  const [membershipNo, setmembershipNo] = useState("");
  const { authCheck, alert, showAlert, setLoadingFalse, loading } =
    useContext(globalContext);
  const { setMemberNo, setMemberData } = useContext(MemberContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchResults, setSearchResults] = useState([])

  // let members = JSON.parse(Cookies.get("members"))
  let members = JSON.parse(localStorage.getItem("members"))

  useEffect(() => {
    authCheck();
    setLoadingFalse();
  }, []);

  const onChangeHandler = (e) => {
    setmembershipNo(e.target.value);
    getSearchResults(e.target.value)
  };

  const getSearchResults = (value) => {
    // Replace this with your actual search logic
    const filteredMembers = members.filter(option => {
      return option.toString().includes(value)
    });
    setSearchResults(filteredMembers);
  };



  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();

    try {
      Cookies.remove("memberNo");
      await setMemberNo(membershipNo);
      await setMemberData(membershipNo);
      Cookies.set("memberNo", membershipNo);
    } catch (e) {
      console.log(e);
      showAlert("Please Enter a Valid Membership Number", "3");
      setLoadingFalse();
      console.log("Error in Searching Member");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Member Details
      </p>
      <CContainer
        fluid
        style={{ minHeight: "100vh" }}
        className="d-flex justify-content-start align-items-start p-5 flex-column"
      >
        <CContainer className=" d-flex justify-content-center mb-5">
          <CCard style={{ width: "40rem" }} className="shadow">
            <CCardBody>
              <CCardTitle className="text-center h4">Search Member</CCardTitle>
              <CContainer fluid>
                <CForm
                  className="d-flex align-items-center justify-content-center"
                  onSubmit={onSubmitHandler}
                >
                  <CFormInput
                    className="w-75 rounded-0"
                    type="text"
                    // pattern="^[0-9]*$"
                    //TODO fix a proper pattern for membership ID
                    placeholder="Enter Membership Number here"
                    onChange={onChangeHandler}
                    value={membershipNo}
                    list="options"
                    autoComplete="off"
                  ></CFormInput>
                  <datalist id="options">
                  {searchResults.map((ele,i)=>{
                    return (
                      <option value={ele} key={i}/>
                    )
                  })}
                  </datalist>
                  <CButton
                    disabled={isSubmitting}
                    type="submit"
                    color="info"
                    className="text-white rounded-0"
                  >
                    {isSubmitting ? "Searching..." : "Search"}
                  </CButton>
                </CForm>
              </CContainer>
            </CCardBody>
          </CCard>
        </CContainer>
        <MemberCard />
      </CContainer>
    </>
  );
}

export default Home;
