import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CModalFooter,
  CModalBody,
} from "@coreui/react";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import globalContext from "../../contexts/Global/globalContext.js";
import axios from "axios";
import Cookies from "js-cookie";

const MemberFeeDetails = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState({
    name: "",
    amount: 0,
    sharePrice: 0,
    minQuant: 0,
  });
  const [loanDetailVisible, setLoanDetailVisible] = useState(false);
  const [loanModalData, setLoanModalData] = useState({
    name: "",
    loanServiceCharge: 0,
    loanMinimumAmount: 0,
    loanMaximumAmount: 0,
  });

  const role = Cookies.get("role");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get("api/superAdmin/feeDetails", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(res.data.branchData);
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Member Fee Details
      </p>
      <CContainer className="mt-4" style={{ minHeight: "80vh" }}>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Account No
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Share Price
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Minimum Qty
              </CTableHeaderCell>
              {role == "admin" ? (
                <>
                  <CTableHeaderCell scope="col" className="text-white">
                    Update
                  </CTableHeaderCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>Membership Fee</CTableDataCell>
              <CTableDataCell>{data ? data.membershipFee : ""}</CTableDataCell>
              <CTableDataCell>50101</CTableDataCell>
              <CTableDataCell>0</CTableDataCell>
              <CTableDataCell>0</CTableDataCell>
              {role == "admin" ? (
                <>
                  <CTableDataCell>
                    <CButton
                      className="btn btn-primary w-40 text-white"
                      onClick={() => {
                        setModalData({
                          name: "membershipFee",
                          amount: data.membershipFee,
                        });
                        setVisible(!visible);
                      }}
                    >
                      Update
                    </CButton>{" "}
                  </CTableDataCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Annual Fee</CTableDataCell>
              <CTableDataCell>{data ? data.annualFees : ""}</CTableDataCell>
              <CTableDataCell>50102</CTableDataCell>
              <CTableDataCell>0</CTableDataCell>
              <CTableDataCell>0</CTableDataCell>
              {role == "admin" ? (
                <>
                  <CTableDataCell>
                    <CButton
                      className="btn btn-primary w-40 text-white"
                      onClick={() => {
                        setModalData({
                          name: "annualFee",
                          amount: data.annualFees,
                        });
                        setVisible(!visible);
                      }}
                    >
                      Update
                    </CButton>{" "}
                  </CTableDataCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Share Certificate Fee</CTableDataCell>
              <CTableDataCell>
                {data
                  ? data.shareCertificateCost * data.minShareCertificate
                  : ""}
              </CTableDataCell>
              <CTableDataCell>50103</CTableDataCell>
              <CTableDataCell>
                {data ? data.shareCertificateCost : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.minShareCertificate : ""}
              </CTableDataCell>
              {role == "admin" ? (
                <>
                  <CTableDataCell>
                    <CButton
                      className="btn btn-primary w-40 text-white"
                      onClick={() => {
                        setModalData({
                          name: "shareCertificateFee",
                          sharePrice: data.shareCertificateCost,
                          minQuant: data.minShareCertificate,
                        });
                        console.log(modalData);
                        setVisible(!visible);
                      }}
                    >
                      Update
                    </CButton>{" "}
                  </CTableDataCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
          </CTableBody>
        </CTable>

        {/*  */}
        <p
          style={{
            background: "#2364aa",
          }}
          className="fw-bold text-white fs-2 text-center p-1 m-0 mb-4"
        >
          Loans Details
        </p>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Service Charge
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Minimum Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Maximum Amount
              </CTableHeaderCell>
              {role == "admin" ? (
                <>
                  <CTableHeaderCell scope="col" className="text-white">
                    Update
                  </CTableHeaderCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>Business Goods Loan</CTableDataCell>
              <CTableDataCell>
                {data ? data.loanServiceCharge : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.loanMinimumAmount : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.loanMaximumAmount : ""}
              </CTableDataCell>
              {role == "admin" ? (
                <>
                  <CTableDataCell>
                    <CButton
                      className="btn btn-primary w-40 text-white"
                      onClick={() => {
                        setLoanModalData({
                          name: "goodsLoan",
                          loanServiceCharge: data.loanServiceCharge,
                          loanMinimumAmount: data.loanMinimumAmount,
                          loanMaximumAmount: data.loanMaximumAmount,
                        });
                        setLoanDetailVisible(!loanDetailVisible);
                      }}
                    >
                      Update
                    </CButton>{" "}
                  </CTableDataCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Demand Loan</CTableDataCell>
              <CTableDataCell>
                {data ? data.demandLoanServiceCharge : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.demandLoanMinimumAmount : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.demandLoanMaximumAmount : ""}
              </CTableDataCell>
              {role == "admin" ? (
                <>
                  <CTableDataCell>
                    <CButton
                      className="btn btn-primary w-40 text-white"
                      onClick={() => {
                        setLoanModalData({
                          name: "demandLoan",
                          loanServiceCharge: data.demandLoanServiceCharge,
                          loanMinimumAmount: data.demandLoanMinimumAmount,
                          loanMaximumAmount: data.demandLoanMaximumAmount,
                        });
                        setLoanDetailVisible(!loanDetailVisible);
                      }}
                    >
                      Update
                    </CButton>{" "}
                  </CTableDataCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Consumer Goods Loan</CTableDataCell>
              <CTableDataCell>
                {data ? data.consumerLoanServiceCharge : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.consumerLoanMinimumAmount : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.consumerLoanMaximumAmount : ""}
              </CTableDataCell>
              {role == "admin" ? (
                <>
                  <CTableDataCell>
                    <CButton
                      className="btn btn-primary w-40 text-white"
                      onClick={() => {
                        setLoanModalData({
                          name: "consumerLoan",
                          loanServiceCharge: data.consumerLoanServiceCharge,
                          loanMinimumAmount: data.consumerLoanMinimumAmount,
                          loanMaximumAmount: data.consumerLoanMaximumAmount,
                        });
                        setLoanDetailVisible(!loanDetailVisible);
                      }}
                    >
                      Update
                    </CButton>{" "}
                  </CTableDataCell>
                </>
              ) : (
                <></>
              )}
            </CTableRow>
          </CTableBody>
        </CTable>
        {/*  */}
      </CContainer>
      {/* {console.log(modalData)} */}
      <FeeModal
        visible={visible}
        setVisible={setVisible}
        modalData={modalData}
        setModalData={setModalData}
      />
      <LoanDetailModal
        loanDetailVisible={loanDetailVisible}
        setLoanDetailVisible={setLoanDetailVisible}
        loanModalData={loanModalData}
        setLoanModalData={setLoanModalData}
      />
    </>
  );
};

function FeeModal({ visible, setVisible, modalData, setModalData }) {
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.patch("api/superAdmin/feeDetails", modalData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      await showAlert("Successfully updated details", "2");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  };

  const onChangeHandler = (e) => {
    setModalData({
      ...modalData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CModal
      visible={visible}
      onClose={() => {
        setVisible(false);
        setModalData({
          name: "",
          amount: 0,
          sharePrice: 0,
          minQuant: 0,
        });
      }}
    >
      <CModalHeader
        onClose={() => {
          setVisible(false);
          setModalData({
            name: "",
            amount: 0,
            sharePrice: 0,
            minQuant: 0,
          });
        }}
      >
        <CModalTitle>Update {modalData.name} Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="form-1 m-3">
          {modalData.name !== "shareCertificateFee" ? (
            <>
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">Amount</CInputGroupText>
                <CFormInput
                  placeholder="Amount"
                  required
                  type="text"
                  name="amount"
                  value={modalData.amount}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            </>
          ) : (
            <>
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">Share Price</CInputGroupText>
                <CFormInput
                  placeholder="sharePrice"
                  required
                  type="text"
                  name="sharePrice"
                  value={modalData.sharePrice}
                  onChange={onChangeHandler}
                />
              </CInputGroup>

              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Minimum Quantity
                </CInputGroupText>
                <CFormInput
                  placeholder="minQuant"
                  required
                  type="text"
                  name="minQuant"
                  value={modalData.minQuant}
                  onChange={onChangeHandler}
                />
              </CInputGroup>
            </>
          )}

          {/* <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Password
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter password here"
              required
              type="text"
              name="password"
              value={userData.password}
              onChange={onChangeHandler}

            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Role
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="role"
              value={userData.role}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="manager">manager</option>
              <option value="committee">committee</option>
              <option value="branch">branch</option>
              <option value="management">management</option>
            </CFormSelect>
          </CInputGroup> */}
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => {
            setVisible(false);
            setModalData({
              name: "",
              amount: 0,
              sharePrice: 0,
              minQuant: 0,
            });
          }}
        >
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler}>
          Add
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
function LoanDetailModal({
  loanDetailVisible,
  setLoanDetailVisible,
  loanModalData,
  setLoanModalData,
}) {
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.patch("api/superAdmin/feeDetails", loanModalData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      await showAlert("Successfully updated details", "2");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  };

  const onChangeHandler = (e) => {
    setLoanModalData({
      ...loanModalData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CModal
      visible={loanDetailVisible}
      onClose={() => {
        setLoanDetailVisible(false);
        setLoanModalData({
          name: "",
          loanServiceCharge: 0,
          loanMinimumAmount: 0,
          loanMaximumAmount: 0,
        });
      }}
    >
      <CModalHeader
        onClose={() => {
          setLoanDetailVisible(false);
          setLoanModalData({
            name: "",
            loanServiceCharge: 0,
            loanMinimumAmount: 0,
            loanMaximumAmount: 0,
          });
        }}
      >
        <CModalTitle>
          Update {loanModalData.name === "goodsLoan" && "Business Goods Loan"}
          {loanModalData.name === "demandLoan" && "Demand Loan"}
          {loanModalData.name === "consumerLoan" && "Consumer Goods Loan"}{" "}
          Details
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="form-1 m-3">
          <>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Service Charge
              </CInputGroupText>
              <CFormInput
                placeholder="Service Charge"
                required
                type="text"
                name={"loanServiceCharge"}
                value={loanModalData.loanServiceCharge}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Minimum Amount
              </CInputGroupText>
              <CFormInput
                placeholder="Minimum Amount"
                required
                type="text"
                name="loanMinimumAmount"
                value={loanModalData.loanMinimumAmount}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Maximum Amount
              </CInputGroupText>
              <CFormInput
                placeholder="Maximum Amount"
                required
                type="text"
                name="loanMaximumAmount"
                value={loanModalData.loanMaximumAmount}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => {
            setLoanDetailVisible(false);
            setLoanModalData({
              name: "",
              loanServiceCharge: 0,
              loanMinimumAmount: 0,
              loanMaximumAmount: 0,
            });
          }}
        >
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler}>
          Update
        </CButton>
      </CModalFooter>
    </CModal>
  );
}

export default MemberFeeDetails;
