import React from "react";
import {
  CButton,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
} from "@coreui/react";

import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

function BeatModal({ visible, setVisible }) {
  const { showAlert } = useContext(globalContext);
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);
    try {
      let data = {
        beatName: text,
      };
      const res = await axios.post("/api/cashCollectors/addBeat", data, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      showAlert("Beat Added", "2");
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsLoading(false);
  }

  return (
    <CModal size="lg" visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader>
        <CModalTitle>Add New Beat</CModalTitle>
      </CModalHeader>
      <CForm className="m-5">
        <CInputGroup className="mb-3">
          <CInputGroupText className="fw-bold"> Beat Name</CInputGroupText>
          <CFormInput
            type="text"
            required
            name="beatname"
            placeholder="type name"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </CInputGroup>
        <CInputGroup>
          <CInputGroupText className="fw-bold"> Beat Type</CInputGroupText>
          <CFormSelect size="sm" name="beatType">
            <option>Select</option>
          </CFormSelect>
        </CInputGroup>
      </CForm>
      <CModalFooter>
        <CButton
          color="danger"
          size="lg"
          className="fw-bold text-white me-5"
          onClick={() => setVisible(false)}
        >
          Close
        </CButton>
        <CButton
          color="success"
          size="lg"
          className="fw-bold text-white ms-5"
          onClick={() => {
            handleSubmit();
            setVisible(false);
            navigate(0);
          }}
          disabled={isLoading}
        >
          Submit
        </CButton>
      </CModalFooter>
    </CModal>
  );
}

export default BeatModal;
