import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormText,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useContext } from "react";
import imageCompression from "browser-image-compression";
import globalContext from "../contexts/Global/globalContext";

function Addmember({ }) {
  const { alert, showAlert } = useContext(globalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //alert,showAlert

  let navigate = useNavigate();
  const [memberData, setMemberData] = useState({
    memberType: "Primary",
    memberName: "",
    fatherHusbandName: "",
    adhaarNumber: "",
    gender: "",
    age: "",
    dob: "",
    email: "",
    caste: "",
    religion: "",
    occupation: "",
    mobileNo: "",
    bussinessType: "",
    goodsService: "",
    locationType: "",
    bussinessDesc: "",
    bHouseNo: "",
    bCity: "",
    bStreet: "",
    bCountry: "India",
    pState: "",
    pHouseNo: "",
    pCity: "",
    pStreet: "",
    pCountry: "India",
    pState: "",
    annualIncome: "",
    nomineeName: "",
    nomineeRel: "",
    nomineeDOB: "",
    nHouseNo: "",
    nCity: "",
    nStreet: "",
    nCountry: "India",
    nState: "",
    iName: "",
    iMembershipID: "",
    iMobileNo: "",
    memberImage: null,
    signatureImage: null,
    image1: null,
    image2: null,
    image3: null,
  });
  const [previewImages, setPreviewImages] = useState([].fill(null));

  async function fileSelectedHandler(e, index) {
    let file = e.target.files[0];
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
    };
    const newPreviewImages = [...previewImages];
    try {
      const compressedFile = await imageCompression(file, options);

      var file_blob = new File([compressedFile], `${compressedFile.name}`, {
        type: compressedFile.type,
      });
      setMemberData({
        ...memberData,
        [e.target.name]: {
          data: file_blob,
          contentType: file_blob.type,
        },
      });
    } catch (error) {
      console.log(error);
    }

    const imageSrc = URL.createObjectURL(e.target.files[0]);

    if (newPreviewImages[index] === null) {
      /* insert image at the index of the array */
      newPreviewImages.splice(index, 0, imageSrc);
    } else {
      /* replace the previous image at index */
      newPreviewImages[index] = imageSrc;
    }
    setPreviewImages(newPreviewImages);
  }

  const [show, setShow] = useState({});
  const [bmargin, setBmargin] = useState({});

  const onChangeHandler = (e) => {
    if (e.target.name === "occupation" && e.target.value !== "Business") {
      setShow({ display: "none" });
      setBmargin({ marginTop: "190px" });
    } else if (
      e.target.name === "occupation" &&
      e.target.value === "Business"
    ) {
      setShow({});
      setBmargin({ marginTop: "85px" });
    }

    if (e.target.name == "dob") {
      let today = new Date();
      let birth = new Date(e.target.value);
      const diff = Math.abs(today - birth);

      const calcAge = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

      setMemberData({
        ...memberData,
        [e.target.name]: e.target.value,
        age: calcAge,
      });
    } else {
      setMemberData({
        ...memberData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    try {
      //Prevents Refreshing the Form
      e.preventDefault();

      let res = await axios.post("/api/member/", memberData, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
          "Content-Type": "multipart/form-data",
        },
      });
      showAlert("Successfully registered", "2");
      setTimeout(() => {
        navigate("/pendingMemberList");
      }, 2000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  const resetForm = () => {
    setMemberData({
      memberType: "Primary",
      memberName: "",
      fatherHusbandName: "",
      adhaarNumber: "",
      gender: "",
      age: "",
      dob: "",
      email: "",
      caste: "",
      religion: "",
      occupation: "",
      mobileNo: "",
      bussinessType: "",
      goodsService: "",
      locationType: "",
      bussinessDesc: "",
      bHouseNo: "",
      bCity: "",
      bStreet: "",
      bCountry: "India",
      pState: "",
      pHouseNo: "",
      pCity: "",
      pStreet: "",
      pCountry: "India",
      pState: "",
      annualIncome: "",
      nomineeName: "",
      nomineeRel: "",
      nomineeDOB: "",
      nHouseNo: "",
      nCity: "",
      nStreet: "",
      nCountry: "India",
      nState: "",
      iName: "",
      iMembershipID: "",
      iMobileNo: "",
      memberImage: null,
      signatureImage: null,
      image1: null,
      image2: null,
      image3: null,
    });
    setPreviewImages([].fill(null));
  };

  return (
    <>
      <CForm className="add-card mb-5" onSubmit={onSubmitHandler}>
        <p
          style={{
            background: "#2364aa",
          }}
          className="fw-bold text-white fs-2 text-center p-1"
        >
          Add Member Form
        </p>
        <CContainer className="form-container d-flex p-3">
          <CContainer className="form-1 m-2">
            <p>User Details </p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Full Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                name="memberName"
                value={memberData.memberName}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Paternal Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="Father/Husband name"
                aria-label="Father name"
                aria-describedby="basic-addon1"
                required
                name="fatherHusbandName"
                value={memberData.fatherHusbandName}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" required>
                Gender
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="gender"
                value={memberData.gender}
                onChange={onChangeHandler}
                required
              >
                <option disabled selected hidden value="">
                  select
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>

                <option value="Other">Other</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Date of Birth
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx/xx/xxxx"
                type="date"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                max={new Date().toISOString().split("T")[0]}
                name="dob"
                value={memberData.dob}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Religion
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="religion"
                value={memberData.religion}
                onChange={onChangeHandler}
                required
              >
                <option disabled selected hidden value="">
                  Select
                </option>
                <option value="Hinduism">Hinduism</option>
                <option value="Muslim">Muslim</option>
                <option value="Christanity">Christanity</option>
                <option value="Shikhism">Shikhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Buddhisum">Buddhisum</option>
                <option value="Others">Others</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Email</CInputGroupText>
              <CFormInput
                type="email"
                placeholder="example@email.com"
                aria-label="Email"
                aria-describedby="basic-addon1"
                name="email"
                value={memberData.email}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>
            <hr />
            <p className="mb-2">Permanent Address *</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="pHouseNo"
                value={memberData.pHouseNo}
                onChange={onChangeHandler}
                maxLength={64}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                required
                name="pCity"
                value={memberData.pCity}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                required
                name="pCountry"
                value={memberData.pCountry}
                // onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <hr />

            <p className="mb-2">Occupation *</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Occupation
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                required
                size="sm"
                name="occupation"
                value={memberData.occupation}
                onChange={onChangeHandler}
              >
                <option disabled selected hidden value="">
                  Select
                </option>
                <option value="Business">Business</option>
                <option value="Trust">Trust</option>
                <option value="NGO">NGO</option>
                <option value="Society">Society</option>
                <option value="Selfhelp Group">Selfhelp Group</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Govt. Service">Govt. Service</option>
                <option value="Pvt. Service">Pvt. Service</option>
                <option value="Professional">Professional</option>
                <option value="Student">Student</option>
                <option value="Retired">Retired</option>
              </CFormSelect>
            </CInputGroup>
            <div className="business" style={show}>
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Good Services
                  <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
                </CInputGroupText>
                <CFormSelect
                  size="sm"
                  name="goodsService"
                  value={memberData.goodsService}
                  onChange={onChangeHandler}

                // required
                >
                  <option disabled selected hidden value="">
                    Select
                  </option>
                  <option value="1">Goods</option>

                  <option value="3">Service</option>
                </CFormSelect>
              </CInputGroup>

              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Location Type
                  <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
                </CInputGroupText>
                <CFormSelect
                  size="sm"
                  // required
                  name="locationType"
                  value={memberData.locationType}
                  onChange={onChangeHandler}
                >
                  <option disabled selected hidden value="">
                    Select Type
                  </option>
                  <option value="Fixed">Fixed</option>
                  <option value="Moving">Moving</option>
                </CFormSelect>
              </CInputGroup>
            </div>

            <hr />
            <p className="mb-2" style={show}>Bussiness Address *</p>
            <CInputGroup className="mb-3" style={show}>
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                // required
                name="bHouseNo"
                value={memberData.bHouseNo}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={show}>
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                // required
                name="bCity"
                value={memberData.bCity}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={show}>
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                // required
                name="bCountry"
                value={memberData.bCountry}
                // onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <hr style={show} />

            <p className="mb-2">Nominee *</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                required
                name="nomineeName"
                value={memberData.nomineeName}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Date of Birth
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="DOB"
                aria-describedby="basic-addon1"
                required
                max={new Date().toISOString().split("T")[0]}
                name="nomineeDOB"
                value={memberData.nomineeDOB}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <p className="mb-2">Nominee Address *</p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                House no
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="House no"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                name="nHouseNo"
                value={memberData.nHouseNo}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                City
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="City"
                aria-label="City"
                aria-describedby="basic-addon1"
                required
                name="nCity"
                value={memberData.nCity}
                onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Country
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Country"
                aria-label="Country"
                aria-describedby="basic-addon1"
                required
                name="nCountry"
                value={memberData.nCountry}
                // onChange={onChangeHandler}
                maxLength={32}
              />
            </CInputGroup>
            <hr />
            <p className="mb-2">Introducer</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Member Id</CInputGroupText>
              <CFormInput
                type="number"
                placeholder="xxxxx"
                aria-label="DOB"
                aria-describedby="basic-addon1"
                name="iMembershipID"
                value={memberData.iMembershipID}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Phone No </CInputGroupText>
              <CFormInput
                type="number"
                placeholder="xxxxxxxxx"
                aria-label="Name"
                aria-describedby="basic-addon1"
                name="iMobileNo"
                value={memberData.iMobileNo}
                onChange={onChangeHandler}
                maxLength={13}
              />
            </CInputGroup>
            <hr />

            {/* ------ */}
            <p className="mb-2">Images</p>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">Member Image </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 0)}
                name="memberImage"
                accept="image/*"
                required
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Establishment: 1
              </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 2)}
                name="image1"
                accept="image/*"
                required
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Establishment: 3
              </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 4)}
                name="image3"
                accept="image/*"
                required
              />
            </CInputGroup>

            <CContainer fluid className="d-flex justify-content-around">
              <CButton
                disabled={isSubmitting}
                type="submit"
                size="lg"
                color="success"
                className="m-2 text-white fw-bold"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </CButton>
              <CButton
                type="button"
                size="lg"
                color="danger"
                className="m-2 text-white fw-bold"
                onClick={() => resetForm()}
              >
                Cancel
              </CButton>
            </CContainer>

            {/* {alert !== null && <h3 id="err-msg" className={`alert-${alert.type}`}>{alert.msg}</h3>} */}
          </CContainer>

          {/* -------------------Right div------------------------------------------------------------------------------ */}
          <CContainer className="form-2 m-2">
            <p>
              <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              Fields are mandatory
            </p>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Member Type{" "}
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                size="sm"
                name="memberType"
                value={memberData.memberType}
                onChange={onChangeHandler}
                required
                disabled
              />
              {/* <option disabled  hidden value="">Select</option> */}
              {/* <option selected defaultValue={"Primary"} value="Primary">
                  Primary
                </option> */}
              {/* <option value="Assosiate Organization">
                  Assosiate Organization
                </option>
                <option value="Assosiate Individual">
                  Assosiate Individual
                </option>
                <option value="Nominal">Nominal</option> */}
              {/* </CFormSelect> */}
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Adhaar Number
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xxxx xxxx xxxx"
                type="text"
                aria-label="Adhaar Number"
                aria-describedby="basic-addon1"
                required
                name="adhaarNumber"
                maxLength={12}
                value={memberData.adhaarNumber}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Age
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                readOnly
                name="age"
                value={memberData.age}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Caste
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                size="sm"
                name="caste"
                value={memberData.caste}
                onChange={onChangeHandler}
                required
              >
                <option disabled selected hidden value="">
                  Select
                </option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
                <option value="OBC">OBC</option>
                <option value="SBC">SBC</option>
                <option value="Other">Other</option>
              </CFormSelect>
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Annual Income
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                name="annualIncome"
                value={memberData.annualIncome}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Phone No.
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                placeholder="xx"
                type="number"
                aria-label="Number"
                aria-describedby="basic-addon1"
                required
                maxLength={14}
                name="mobileNo"
                value={memberData.mobileNo}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            <hr />

            {/* <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1" style={{ marginTop: "33px" }}>
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                style={{ marginTop: "33px" }}
                required
                name="street"
                value={memberData.street}
                onChange={onChangeHandler}
              />
            </CInputGroup> */}

            {/* <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>
                  *
                </span>{" "}
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                required
                name="state"
                value={memberData.state}
                onChange={onChangeHandler}
              />
            </CInputGroup> */}

            <CInputGroup className="mb-3" style={{ marginTop: "70px" }}>
              <CInputGroupText id="basic-addon1">
                Street{" "}
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                maxLength={32}
                name="pStreet"
                value={memberData.pStreet}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>
                  *
                </span>{" "}
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                required
                maxLength={32}
                name="pState"
                value={memberData.pState}
                onChange={onChangeHandler}
              />
            </CInputGroup>
            {/* 
            <CInputGroup className="mb-3" style={{ marginTop: "119px" }}>
              <CInputGroupText id="basic-addon1">Credit Limit</CInputGroupText>
              <CFormInput
                type="number"
                placeholder="Credit"
                aria-label="Credit"
                aria-describedby="basic-addon1"
              />
            </CInputGroup> */}
            <div className="business" style={show}>
              <CInputGroup className="mb-3" style={{ marginTop: "119px" }}>
                <CInputGroupText id="basic-addon1">
                  Business Type
                  <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
                </CInputGroupText>
                <CFormSelect
                  size="sm"
                  name="bussinessType"
                  value={memberData.bussinessType}
                  onChange={onChangeHandler}
                // required
                >
                  <option disabled selected hidden value="">
                    --Select--
                  </option>
                  <option value="Acid Business">Acid Business</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Air Conditioner and Fridge Repair">
                    Air Conditioner and Fridge Repair
                  </option>
                  <option value="Artificial Jewellery">
                    Artificial Jewellery
                  </option>
                  <option value="Attar Shop">Attar Shop</option>
                  <option value="Auto Consultancy">Auto Consultancy</option>
                  <option value="Auto Electrician">Auto Electrician</option>
                  <option value="Auto Mobile Spare Parts">
                    Auto Mobile Spare Parts
                  </option>
                  <option value="Automobile Mechanic">
                    Automobile Mechanic
                  </option>
                  <option value="Automobile Washing Centre">
                    Automobile Washing Centre
                  </option>
                  <option value="Baby Store">Baby Store</option>
                  <option value="Bags Business">Bags Business</option>
                  <option value="Bakery &amp; Sweets">
                    Bakery &amp; Sweets
                  </option>
                  <option value="Band set &amp; Musical Instruments">
                    Band set &amp; Musical Instruments
                  </option>
                  <option value="Bangle Store">Bangle Store</option>
                  <option value="Battery Shop">Battery Shop</option>
                  <option value="Bearing Works">Bearing Works</option>
                  <option value="Beauty parlour">Beauty parlour</option>
                  <option value="Bedding &amp; Mattresses">
                    Bedding &amp; Mattresses
                  </option>
                  <option value="Beedi Works">Beedi Works</option>
                  <option value="Building Contractor">
                    Building Contractor
                  </option>
                  <option value="Building Material Store">
                    Building Material Store
                  </option>
                  <option value="Cable Network Operator">
                    Cable Network Operator
                  </option>
                  <option value="Caps">Caps</option>
                  <option value="Car Decorator">Car Decorator</option>
                  <option value="Carpenter">Carpenter</option>
                  <option value="Catering">Catering</option>
                  <option value="Cattle business">Cattle business</option>
                  <option value="Cattle Food">Cattle Food</option>
                  <option value="CCTV Works">CCTV Works</option>
                  <option value="Cell phone Repairing &amp; Sales">
                    Cell phone Repairing &amp; Sales
                  </option>
                  <option value="Cement Rings Manufacturing">
                    Cement Rings Manufacturing
                  </option>
                  <option value="Centring Material">Centring Material</option>
                  <option value="Centring Material Or steel Or wood">
                    Centring Material Or steel Or wood
                  </option>
                  <option value="Chair Repair Works">Chair Repair Works</option>
                  <option value="Charcoal Business">Charcoal Business</option>
                  <option value="Chicken Centre or Mutton">
                    Chicken Centre or Mutton
                  </option>
                  <option value="Chicken, Mutton &amp; Fish Centre">
                    Chicken, Mutton &amp; Fish Centre
                  </option>
                  <option value="Coconut Business">Coconut Business</option>
                  <option value="Computer Sales &amp; Service">
                    Computer Sales &amp; Service
                  </option>
                  <option value="Confectionary Shop">Confectionary Shop</option>
                  <option value="Cool Drink and Juice Shop">
                    Cool Drink and Juice Shop
                  </option>
                  <option value="Cotton Dealer">Cotton Dealer</option>
                  <option value="Crane Works">Crane Works</option>
                  <option value="Crockery Shop">Crockery Shop</option>
                  <option value="Cycle Mechanic">Cycle Mechanic</option>
                  <option value="Cycle Store">Cycle Store</option>
                  <option value="Dairy">Dairy</option>
                  <option value="Dental Clinic">Dental Clinic</option>
                  <option value="Denting Works">Denting Works</option>
                  <option value="Dry Cleaning">Dry Cleaning</option>
                  <option value="DTP Xerox Internet Cafe">
                    DTP Xerox Internet Cafe
                  </option>
                  <option value="Earth Movers Business">
                    Earth Movers Business
                  </option>
                  <option value="Eggs Business">Eggs Business</option>
                  <option value="Electrical  Shop">Electrical Shop</option>
                  <option value="Electrical Works">Electrical Works</option>
                  <option value="Electronics &amp; Electricals Servicing">
                    Electronics &amp; Electricals Servicing
                  </option>
                  <option value="Electronics &amp; Home Appliances Shop">
                    Electronics &amp; Home Appliances Shop
                  </option>
                  <option value="Fish Business">Fish Business</option>
                  <option value="Florist">Florist</option>
                  <option value="Flour Mill or Wet Grinder">
                    Flour Mill or Wet Grinder
                  </option>
                  <option value="Foot Wear">Foot Wear</option>
                  <option value="Fruits and Vegetables Business">
                    Fruits and Vegetables Business
                  </option>
                  <option value="Function Hall">Function Hall</option>
                  <option value="Furniture">Furniture</option>
                  <option value="Garments">Garments</option>
                  <option value="Gas Stove Business">Gas Stove Business</option>
                  <option value="General and Fancy Store">
                    General and Fancy Store
                  </option>
                  <option value="General Engineering Works">
                    General Engineering Works
                  </option>
                  <option value="Gifts and Article Shops">
                    Gifts and Article Shops
                  </option>
                  <option value="Glass and Aluminium">
                    Glass and Aluminium
                  </option>
                  <option value="Glass and Aluminium Dealer">
                    Glass and Aluminium Dealer
                  </option>
                  <option value="Gold Smith and Silver Makers">
                    Gold Smith and Silver Makers
                  </option>
                  <option value="Gunny Bags Business">
                    Gunny Bags Business
                  </option>
                  <option value="Gym">Gym</option>
                  <option value="Hair cutting Saloon">
                    Hair cutting Saloon
                  </option>
                  <option value="Handicraft">Handicraft</option>
                  <option value="Hard Ware Shop">Hard Ware Shop</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Ice Cream">Ice Cream</option>
                  <option value="Ice Factory">Ice Factory</option>
                  <option value="Interior Designing">Interior Designing</option>
                  <option value="Jewellery">Jewellery</option>
                  <option value="Key Repair Works">Key Repair Works</option>
                  <option value="Kirana">Kirana</option>
                  <option value="Kirana Store">Kirana Store</option>
                  <option value="Ladies Emporium">Ladies Emporium</option>
                  <option value="Leather Works">Leather Works</option>
                  <option value="Lighting and Decoration">
                    Lighting and Decoration
                  </option>
                  <option value="Lodge">Lodge</option>
                  <option value="Matrimony">Matrimony</option>
                  <option value="Medical Lab">Medical Lab</option>
                  <option value="Medical Shop">Medical Shop</option>
                  <option value="Mineral Water">Mineral Water</option>
                  <option value="Mineral Water Plant">
                    Mineral Water Plant
                  </option>
                  <option value="Motor Rewinding and Repair">
                    Motor Rewinding and Repair
                  </option>
                  <option value="Nursery">Nursery</option>
                  <option value="Oil Mill">Oil Mill</option>
                  <option value="Optical Shop">Optical Shop</option>
                  <option value="Others">Others</option>
                  <option value="Pan Shop">Pan Shop</option>
                  <option value="Parking Stand">Parking Stand</option>
                  <option value="Pesticides &amp; Fertilizers Shop">
                    Pesticides &amp; Fertilizers Shop
                  </option>
                  <option value="Photo Studio or Videography">
                    Photo Studio or Videography
                  </option>
                  <option value="Physiotherapy Centre">
                    Physiotherapy Centre
                  </option>
                  <option value="Pickles">Pickles</option>
                  <option value="Plastic items">Plastic items</option>
                  <option value="Plumbing Works">Plumbing Works</option>
                  <option value="POP (Interior Designing Works)">
                    POP (Interior Designing Works)
                  </option>
                  <option value="Popcorn">Popcorn</option>
                  <option value="Printers">Printers</option>
                  <option value="Radium &amp; Stickers">
                    Radium &amp; Stickers
                  </option>
                  <option value="Rexine Works">Rexine Works</option>
                  <option value="Rice Business">Rice Business</option>
                  <option value="Rice Trader">Rice Trader</option>
                  <option value="Rubber Stamp Works">Rubber Stamp Works</option>
                  <option value="Sanitary Marts">Sanitary Marts</option>
                  <option value="Saree Business">Saree Business</option>
                  <option value="Saw Mill">Saw Mill</option>
                  <option value="School/College">School/College</option>
                  <option value="Scrap">Scrap</option>
                  <option value="Spices">Spices</option>
                  <option value="Spray Painting and Tinkering Shop">
                    Spray Painting and Tinkering Shop
                  </option>
                  <option value="Stamp Paper Vendor">Stamp Paper Vendor</option>
                  <option value="Stationary">Stationary</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Tailoring and Embroidery">
                    Tailoring and Embroidery
                  </option>
                  <option value="Taxi Car or Cabs">Taxi Car or Cabs</option>
                  <option value="Tent House">Tent House</option>
                  <option value="Tiffin and Tea Stall">
                    Tiffin and Tea Stall
                  </option>
                  <option value="Timber Depot">Timber Depot</option>
                  <option value="Tours &amp; Travels">
                    Tours &amp; Travels
                  </option>
                  <option value="Transport">Transport</option>
                  <option value="Tyre Repair or Changing or Air Compressor">
                    Tyre Repair or Changing or Air Compressor
                  </option>
                  <option value="Watch Sales &amp; Service">
                    Watch Sales &amp; Service
                  </option>
                  <option value="Welding Shop">Welding Shop</option>
                </CFormSelect>
              </CInputGroup>

              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  Business Description
                </CInputGroupText>
                <CFormInput
                  type="text"
                  placeholder="Optional..."
                  aria-label="Description"
                  aria-describedby="basic-addon1"
                  name="bussinessDesc"
                  value={memberData.bussinessDesc}
                  onChange={onChangeHandler}
                  maxLength={32}
                />
              </CInputGroup>
            </div>

            <div style={show}>  <CInputGroup className="mb-3" style={bmargin}>
              <CInputGroupText id="basic-addon1" style={{ marginTop: "33px" }}>
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                style={{ marginTop: "33px" }}
                // required
                maxLength={32}
                name="bStreet"
                value={memberData.bStreet}
                onChange={onChangeHandler}
              />
            </CInputGroup></div>

            <CInputGroup className="mb-3" style={show}>
              <CInputGroupText id="basic-addon1">
                State
                <span style={{ color: "red", fontWeight: "lighter" }}>
                  *
                </span>{" "}
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                maxLength={32}
                name="bState"
                value={memberData.bState}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "209px" }}>
              <CInputGroupText id="basic-addon1">
                Relationship
                <span style={{ color: "red", fontWeight: "lighter" }}>
                  *
                </span>{" "}
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Relation name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                required
                maxLength={32}
                name="nomineeRel"
                value={memberData.nomineeRel}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "101px" }}>
              <CInputGroupText id="basic-addon1">
                Street
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Street"
                aria-label="house"
                aria-describedby="basic-addon1"
                required
                maxLength={32}
                name="nStreet"
                value={memberData.nStreet}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                State{" "}
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="State"
                aria-label="State"
                aria-describedby="basic-addon1"
                required
                maxLength={32}
                name="nState"
                value={memberData.nState}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "119px" }}>
              <CInputGroupText id="basic-addon1">Name </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Name"
                aria-label="Name"
                maxLength={32}
                aria-describedby="basic-addon1"
                name="iName"
                value={memberData.iName}
                onChange={onChangeHandler}
              />
            </CInputGroup>

            <CInputGroup className="mb-3" style={{ marginTop: "120px" }}>
              <CInputGroupText id="basic-addon1">Sign Image</CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 1)}
                name="signatureImage"
                accept="image/*"
                required
              />
            </CInputGroup>

            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Establishment: 2
              </CInputGroupText>
              <CFormInput
                type="file"
                onChange={(e) => fileSelectedHandler(e, 3)}
                name="image2"
                accept="image/*"
                required
              />
            </CInputGroup>
            <CContainer className="form-image-cont ">
              <CFormText>Images</CFormText>

              {previewImages.map((previewImage) => (
                <img src={previewImage} className="image-input" alt="preview" />
              ))}
            </CContainer>
          </CContainer>
        </CContainer>
      </CForm>
    </>
  );
}

export default Addmember;
