import React from "react";
import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";

const MemberActivity = () => {
  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Reports - Member Activity
      </p>
      <CContainer>
        <CRow>
          <h5>Member Activity</h5>
          <hr></hr>

          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="basic-addon1">
                Membership No.
              </CInputGroupText>
              <CFormInput
                type="text"
                placeholder="Membership No."
                aria-label="Membership No."
                aria-describedby="Membership No."
                required
                name="membershipNo."
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="select date"
                // value={}
                // onChange={}
              />
            </CInputGroup>
          </CCol>
          <CCol sm={3}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="cashCollector">
                Product Name
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                // value={}
                // onChange={}
                aria-label="Product Name"
                options={[
                  "All",

                  { label: "Long Term Deposits", value: "LongTermDeposits" },
                  { label: "Daily Deposits", value: "DailyDeposits" },
                  { label: "Umrah Deposits", value: "UmrahDeposits" },
                  { label: "Term Deposits", value: "TermDeposits" },
                  { label: "Savings Deposits", value: "SavingsDeposits" },
                  { label: "Special Qarz", value: "SpecialQarz" },
                  {
                    label: "Member Not Available",
                    value: "MemberNotAvailable",
                  },
                ]}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={12}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton className="text-white " color="success">
                  Show
                </CButton>
                {/* <CButton className="text-white " color="success">
                  Export to Excel
                </CButton> */}
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>
        {/* <h5>Summary</h5> */}
        <hr></hr>
        <CRow>
          <CCol sm={6}>
            <h6>Summary</h6>

            <CTable bordered striped className="shadow">
              <CTableBody className=" align-middle">
                <CTableRow>
                  <CTableDataCell>Cash Collector Visits</CTableDataCell>
                  <CTableDataCell>1</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Field Activities </CTableDataCell>
                  <CTableDataCell>0</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Branch Activities </CTableDataCell>
                  <CTableDataCell>0</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CCol>
          <CCol sm={6}>
            <h6> Cash Collection OutComes</h6>
            <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    OutCome
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    No. of Times
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Amount
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text-center align-middle">
                <CTableRow>
                  <CTableDataCell>No Money</CTableDataCell>
                  <CTableDataCell>1</CTableDataCell>
                  <CTableDataCell>0</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell>1</CTableDataCell>
                  <CTableDataCell>0</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>
        <h5>Member Activity</h5>
        <hr></hr>
    

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                S_No
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
              Date 
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
              Product / Outcome 
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
              Activity 
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
              Amount 	
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
              Emp 
              </CTableHeaderCell>
    

            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>1</CTableDataCell>
              <CTableDataCell>14/02/2022 03:49 PM </CTableDataCell>
              <CTableDataCell>Cash Collection </CTableDataCell>
              <CTableDataCell>HYD</CTableDataCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell>Abdul Hafeez</CTableDataCell>
       
            </CTableRow>
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default MemberActivity;
