import { CToast, CToastHeader, CToastBody, CToastClose } from "@coreui/react";

const Toast = ({ msg, type }) => {
  let colorSet;
  switch (type) {
    case "1":
      colorSet = "#0d6efd";
      break;
    case "2":
      colorSet = "#198754";
      break;
    case "3":
      colorSet = "#dc3545";
      break;
    case "4":
      colorSet = "#ffc107";
      break;
    default:
      colorSet = "#0d6efd";
      break;
  }
  return (

    <CToast autohide={false} visible={true}>
      <CToastHeader
        className="text-white"
        style={{ backgroundColor: `${colorSet}` }}
      >
        <strong className="me-auto">Alert</strong><CToastClose className="me-2" white />
      </CToastHeader>
      <CToastBody>{msg}</CToastBody>
    </CToast>
  );
};

export default Toast;
