import { SET_LOADING, SHOW_ALERT, LOGGED_IN , REMOVE_ALERT , SET_LOADING_FALSE} from "../types";
const reducerFunction = (state, action) => {
  switch (action.type) {
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SHOW_ALERT:
      
      let changedAlert = state.alert
      changedAlert.push(action.payload)
      return {
        ...state,
        alert: changedAlert,
      };
    case REMOVE_ALERT: 
      let alertIndex = state.alert.findIndex(ele => ele.msg == action.payload.msg);
      let removingAlert = state.alert;
      removingAlert.splice(alertIndex, 1);
      return {
        ...state ,
        alert : removingAlert
      }
    case LOGGED_IN:
      return {
        ...state,
        status: action.payload.status,
        auth: action.payload.data,
      };
    default:
      return state;
  }
};
export default reducerFunction;
