import React from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import { useState, useContext } from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";
import { useNavigate } from "react-router-dom";

const Dmodal = ({ setModalOpen, modalOpen, data, setFlag, flag }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate()
  const { alert, showAlert } = useContext(globalContext);
  const [depositData, setDepositData] = useState({
    depositName: "",
    frequency: "",
  });

  const onChangeHandler = (e) => {
    setDepositData({
      ...depositData,
      [e.target.name]: e.target.value,
    });
  };

  if (depositData.depositName == "Daily Deposit") {
    depositData.frequency = "Daily";
  } else if (depositData.depositName == "Savings Deposit") {
    depositData.frequency = "None";
  } else if (depositData.depositName == "Term Deposit") {
    depositData.frequency = "Half Yearly";
  }

  const onSubmitHandler = async (e) => {
    setIsSubmitting(true);
    try {
      //Prevents Refreshing the Form
      e.preventDefault();

      let res = await axios.post(
        `/api/member/deposits/${data.membershipNo}`,
        depositData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      // setFlag(!flag);
      // alert("Successfully Created Deposit Account");
      await showAlert(`Successfully Created ${depositData.depositName} Account`, "2");
      setTimeout(() => {
        setModalOpen(false)
        navigate(0)
      }, 2000)
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <CModal
        className="modal-lg"
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <CModalHeader>
          <CModalTitle>New Deposit Account</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm id="loan-form" onSubmit={onSubmitHandler}>
            <CContainer className="form-container form-control-plaintext d-flex p-6 shadow-none flex-column">
              <CContainer className="form-1 m-3">
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Membership No
                    <span style={{ color: "red", fontWeight: "lighter" }}>
                      *
                    </span>
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Membership No"
                    required
                    type="number"
                    name="membershipNo"
                    value={data.membershipNo}
                    readOnly
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Member Name
                    <span style={{ color: "red", fontWeight: "lighter" }}>
                      *
                    </span>
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Membership No"
                    required
                    type="text"
                    name="memberName"
                    value={data.pDetails.memberName}
                    readOnly
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Deposit Name
                    <span style={{ color: "red", fontWeight: "lighter" }}>
                      *
                    </span>
                  </CInputGroupText>
                  <CFormSelect
                    required
                    size="sm"
                    name="depositName"
                    value={depositData.depositName}
                    onChange={onChangeHandler}
                  >
                    <option disabled selected hidden value="">
                      Select
                    </option>
                    <option value="Daily Deposit">Daily Deposit</option>
                    <option value="Savings Deposit">Savings Deposit</option>
                    <option value="Term Deposit">Term Deposit</option>
                  </CFormSelect>
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">
                    Frequency
                    <span style={{ color: "red", fontWeight: "lighter" }}>
                      *
                    </span>
                  </CInputGroupText>
                  <CFormSelect
                    required
                    size="sm"
                    name="frequency"
                    className=""
                    value={depositData.frequency}
                  >
                    <option disabled selected hidden value="">
                      Select
                    </option>
                    <option value="None">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Half Yearly">Half Yearly</option>
                  </CFormSelect>
                </CInputGroup>
              </CContainer>
              <CContainer className="d-flex justify-content-center">
                <CButton
                  type="submit"
                  disabled={isSubmitting}
                  size="lg"
                  color="success"
                  form="loan-form"
                  className="m-2 text-white fw-bold"
                >
                  Create
                </CButton>
              </CContainer>
            </CContainer>
          </CForm>
        </CModalBody>
      </CModal>
    </>
  );
};

export default Dmodal;
