import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButtonGroup,
} from "@coreui/react";

import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const PAndLAccounts = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  const [missingValues, setMissingValues] = useState([]);
  const [otherAmount, setOtherAmount] = useState(null);
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });

  let onSubmitHandler = () => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `api/accounts/profitAndLoss/${reqData.fromReqDate}/${reqData.toReqDate}`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        let bal = res.data.balances;
        let otherAmount = {
          totalIncome: res.data.totalIncome,
          totalExpense: res.data.totalExpense,
          finalTotal: res.data.finalTotal,
          IncomeAdjust: res.data.IncomeAdjust,
          ExpenseAdjust: res.data.ExpenseAdjust,
        };
        setOtherAmount(otherAmount);
        setData(bal);
        setMissingValues(res.data.missingValues);
      } catch (error) {
        // console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  };

  let onChangeHandler = (e) => {
    // e.preventDefault();

    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  function getAmount(name) {
    let found = data?.find((e) => e.accName === name);
    return found?.amount || 0;
  }

  function getMissingAmount(name) {
    let found = missingValues.find((ele) => ele.name == name);
    return found?.DebitSide || 0;
  }

  const handleClick = () => {
    let newData = formatData();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `PandAccount.csv`);
  };

  const pandAccountData = [
    {
      Expenditure: "To Salaries, Allowances ",
      Amount: getAmount("Salaries & Allowances"),
      Income: "By Income from Business Partnership",
      IncomeAmount: "0.00",
    },
    {
      Expenditure: "To Employees Benefits",
      Amount: getAmount("Employee Benefits"),
      Income: "By Income from Business Goods Loan",
      IncomeAmount: getAmount("Income- Murabaha Business Loan"),
    },
    {
      Expenditure: "To Medical Expenses",
      Amount: getAmount("Medical Expenses"),
      Income: "By Income from Consumer Goods Loan",
      IncomeAmount: getAmount("Income- Murabaha Consumer Loan"),
    },
    {
      Expenditure: "To Office Expenses",
      Amount: getAmount("Office Expenses"),
      Income: "By Income from Demand Loan",
      IncomeAmount: getAmount("Income- Qard-e-Hasna"),
    },
    {
      Expenditure: "To Staff Accomodation",
      Amount: getAmount("Staff Accommodation"),
      Income: "By Annual Fee",
      IncomeAmount: getAmount("Income- Yearly Fee"),
    },
    {
      Expenditure: "To Telephone & Internet",
      Amount: getAmount("Telephone And Internet "),
      Income: "By MemberShip Fee",
      IncomeAmount: getAmount("Income- Membership Fee"),
    },
    {
      Expenditure: "To TA, DA & Fuel",
      Amount: getAmount("TA, DA & Fuel Charges"),
      Income: "By Income from Donation",
      IncomeAmount: getAmount("INCOME FROM DONATION A/c"),
    },
    {
      Expenditure: "To Electricity Charges",
      Amount: getAmount("Utility Charges"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Operating Expenses",
      Amount: getAmount("Operating Expenses"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Staff Training",
      Amount: 0.0,
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Bank Charges",
      Amount: getAmount("Bank Charges"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Audit Fee ",
      Amount: getAmount("Audit Fee"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Management Fee",
      Amount: getAmount("Management Fee"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Office Rental",
      Amount: getAmount("Office Rent"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Misc. Expenses",
      Amount: getAmount("Miscellaneous Expenses"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Printing & Stationery",
      Amount: getAmount("Printing & Stationery Charges"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To Suspense",
      Amount: getAmount("Suspense Account"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: "To TDS",
      Amount: getAmount("TDS"),
      Income: "",
      IncomeAmount: "",
    },
    {
      Expenditure: otherAmount?.ExpenseAdjust
        ? "By Excess of Income Over Expenditure"
        : "",
      Amount: otherAmount?.ExpenseAdjust ? otherAmount?.ExpenseAdjust : "",
      Income: otherAmount?.IncomeAdjust
        ? "By Excess of Expenditure Over Income"
        : "",
      IncomeAmount: otherAmount?.IncomeAdjust ? otherAmount?.IncomeAdjust : "",
    },
    {
      Expenditure: "Total",
      Amount: otherAmount?.finalTotal,
      Income: "Total",
      IncomeAmount: otherAmount?.finalTotal,
    },
  ];

  const formatData = () => {
    return pandAccountData?.map((e, i) => {
      return {
        Expenditure: e.Expenditure,
        Amount: e.Amount,
        Income: e.Income,
        IncomeAmount: e.IncomeAmount,
      };
    });
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text(`Profit and Loss Account`, 14, 16);

    if (pandAccountData && pandAccountData.length > 0) {
      doc.autoTable({
        head: [["Expenditure", "Amount", "Income", "Amount"]],
        body: pandAccountData?.map((e) => [
          e.Expenditure,
          e.Amount,
          e.Income,
          e.IncomeAmount,
        ]),
        startY: 30,
      });
    }

    doc.save("PandAccount.pdf");
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Income & Expenditure Account
      </p>
      <CContainer style={{ minHeight: "85vh" }}>
        <CRow>
          <h5>Income & Expenditure Account </h5>
          <hr></hr>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="From_date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="To_date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={reqData.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white "
                  color="success"
                  onClick={onSubmitHandler}
                >
                  Get Details
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                  disabled={!data || data?.length === 0}
                >
                  Export To CSV
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={exportPDF}
                  disabled={!data || data?.length === 0}
                >
                  Print PDF
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <h5>Profit and Loss Account </h5>
        <hr></hr>

        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Expenditure
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Income{" "}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data && (
              <>
                <CTableRow>
                  <CTableDataCell>To Salaries, Allowances </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Salaries & Allowances")}
                  </CTableDataCell>
                  <CTableDataCell>
                    By Income from Business Partnership{" "}
                  </CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Employees Benefits</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Employee Benefits")}
                  </CTableDataCell>
                  <CTableDataCell>
                    By Income from Business Goods Loan{" "}
                  </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Income- Murabaha Business Loan")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Medical Expenses</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Medical Expenses")}
                  </CTableDataCell>
                  <CTableDataCell>
                    By Income from Consumer Goods Loan{" "}
                  </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Income- Murabaha Consumer Loan")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Office Expenses</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Office Expenses")}
                  </CTableDataCell>
                  <CTableDataCell>By Income from Demand Loan </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Income- Qard-e-Hasna")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Staff Accomodation</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Staff Accommodation")}
                  </CTableDataCell>
                  <CTableDataCell>By Annual Fee</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Income- Yearly Fee")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Telephone & Internet</CTableDataCell>
                  <CTableDataCell>
                    {getMissingAmount("Telephone And Internet ")}
                  </CTableDataCell>
                  <CTableDataCell>By MemberShip Fee </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Income- Membership Fee")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To TA, DA & Fuel</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("TA, DA & Fuel Charges")}
                  </CTableDataCell>
                  <CTableDataCell>By Income from Donation</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("INCOME FROM DONATION A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Electricity Charges</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Utility Charges")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Operating Expenses</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Operating Expenses")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell> To Staff Training </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell> To Bank Charges </CTableDataCell>
                  <CTableDataCell>{getAmount("Bank Charges")}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell> To Audit Fee </CTableDataCell>
                  <CTableDataCell>{getAmount("Audit Fee")}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>

                <CTableRow>
                  <CTableDataCell> To Management Fee </CTableDataCell>
                  <CTableDataCell>
                    {getMissingAmount("Management Fee")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>

                <CTableRow>
                  <CTableDataCell>To Office Rental</CTableDataCell>
                  <CTableDataCell>{getAmount("Office Rent")}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Misc. Expenses</CTableDataCell>
                  <CTableDataCell>
                    {getMissingAmount("Miscellaneous Expenses")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Printing & Stationery</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Printing & Stationery Charges")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To Suspense</CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Suspense Account")}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>To TDS</CTableDataCell>
                  <CTableDataCell>{getMissingAmount("TDS")}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>
                    {otherAmount.ExpenseAdjust
                      ? "By Excess of Income Over Expenditure"
                      : ""}
                  </CTableDataCell>
                  <CTableDataCell>
                    {otherAmount.ExpenseAdjust ? otherAmount.ExpenseAdjust : ""}
                  </CTableDataCell>
                  <CTableDataCell>
                    {otherAmount.IncomeAdjust
                      ? "By Excess of Expenditure Over Income"
                      : ""}
                  </CTableDataCell>
                  <CTableDataCell>
                    {otherAmount.IncomeAdjust ? otherAmount.IncomeAdjust : ""}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell>{otherAmount.finalTotal}</CTableDataCell>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell>{otherAmount.finalTotal}</CTableDataCell>
                </CTableRow>
              </>
            )}
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default PAndLAccounts;
