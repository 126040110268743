import {
  CCol,
  CContainer,
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButtonGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CFormSelect,
  CInputGroup , 
  CInputGroupText,
  CFormInput,
  CModalFooter, 
  CModalBody 
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext";

const PortalUsers = () => {
  const { showAlert } = useContext(globalContext);
  const [users, setUsers] = useState([])
  const [visible, setVisible] = useState(false)


  useEffect(() => {
    async function fetchUsers() {
      try {
        const res = await axios.get("api/superAdmin/getAllUsers", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        let { allUsers } = res.data
        setUsers(allUsers)
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3")
          });
        } else {
          showAlert(error.response.data.error, "3")
        }
      }
    }
    fetchUsers()
  }, [])


  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Portal Users Data
      </p>
      <CContainer style={{minHeight:"78vh"}}>
        <NewUserModal visible={visible} setVisible={setVisible} />
        <CCol sm={12} className="mt-5">
          <CContainer fluid className="d-flex justify-content-around">
            <CButtonGroup role="group">
              <CButton className="text-white " color="success" onClick={() => setVisible(!visible)}>

                Add New User
              </CButton>
            </CButtonGroup>
          </CContainer>
        </CCol>
        <h5>Users</h5>
        <hr></hr>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Username
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Role
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Status
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {users.map((ele, i) => {
              return (
                <CTableRow key={i}>
                  <CTableDataCell>{ele.username}</CTableDataCell>
                  <CTableDataCell>{ele.role}</CTableDataCell>
                  <CTableDataCell>{ele.status}</CTableDataCell>
                </CTableRow>
              )
            })}
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

function NewUserModal({ visible, setVisible }) {
  const navigate = useNavigate()
  const { showAlert } = useContext(globalContext);

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    role: "",
  });

  const onSubmitHandler = async (e) => {
    try {
        e.preventDefault();

        let res = await axios.post(
            "api/superAdmin/newUser",
            userData,
            {
                headers: {
                    "auth-token": JSON.parse(localStorage.getItem("token")).token,
                },
            }
        );
        await showAlert("Successfully Added new User", "2");
        setTimeout(()=>{
            navigate(0)
        },1000)
        
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach((ele) => {
                showAlert(ele.msg, "3")
            });
        } else {
            showAlert(error.response.data.error, "3")
        }
    }
};

  const onChangeHandler = (e) => {
      setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CModal visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader onClose={() => setVisible(false)}>
        <CModalTitle>Add New Portal User</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="form-1 m-3">
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Username
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Username"
              required
              type="text"
              name="username"
              value={userData.username}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Password
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter password here"
              required
              type="text"
              name="password"
              value={userData.password}
              onChange={onChangeHandler}
              
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Role
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="role"
              value={userData.role}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="manager">manager</option>
              <option value="committee">committee</option>
              <option value="branch">branch</option>
              <option value="management">management</option>
            </CFormSelect>
          </CInputGroup>
        </CContainer>

      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler}>Add</CButton>
      </CModalFooter>
    </CModal>
  )
}


export default PortalUsers;
