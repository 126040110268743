import { CPagination, CPaginationItem } from "@coreui/react";

const Paginate = ({
  itemsPerPage,
  totalItems,
  paginate,
  prevPage,
  nextPage,
}) => {
  const pageNumbers = [];
  // dynamically calculate number of pages in pagination component
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <CPagination align="center" style={{ cursor: "pointer" }}>
      <CPaginationItem onClick={() => prevPage()}>&laquo;</CPaginationItem>
      {pageNumbers.map((num) => {
        return (
          <CPaginationItem key={num} onClick={() => paginate(num)}>
            {num}
          </CPaginationItem>
        );
      })}
      <CPaginationItem onClick={() => nextPage()}>&raquo;</CPaginationItem>
    </CPagination>
  );
};

export default Paginate;
