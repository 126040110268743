let bookOpt =[
    {value:"all",label:"All"},
						
{value:"Demand Loan",label:"Demand Loan"},
{value:"Consumer Goods Loan",label:"Consumer Goods Loan"},
{value:"Business Goods Loan",label:"Business Goods Loan"},
// {value:"Business Partnership",label:"Business Partnership"},
// {value:"Special Qarz",label:"Special Qarz"},
{value:"Daily Deposit",label:"Daily Deposit"},
{value:"Savings Deposit",label:"Savings Deposit"},
{value:"Term Deposit",label:"Term Deposit"},
// {value:"Umrah Deposit",label:"Umrah Deposit"},
// {value:"Long Term Deposit",label:"Long Term Deposit"},
// {value:"Demand Loan Reserve",label:"Demand Loan Reserve"},
// {value:"General Reserve",label:"General Reserve"},
// {value:"BAD DEBTS RESERVE",label:"BAD DEBTS RESERVE"},
// {value:"Building Fund",label:"Building Fund"},
// {value:"Education Fund",label:"Education Fund"},
// {value:"Patronage Rebate Fund",label:"Patronage Rebate Fund"},
// {value:"Staff Welfare Reserve",label:"Staff Welfare Reserve"},
// {value:"Dividend Reserve",label:"Dividend Reserve"},
// {value:"Loans Payable",label:"Loans Payable"},
// {value:"DEFICIT RESERVE A/c",label:"DEFICIT RESERVE A/c"},
{value:"Main Cash",label:"Main Cash"},
{value:"CASH IN BANK",label:"Branch Bank Account"},
{value:"Petty Cash",label:"Petty Cash"},
{value:"Advance Salary",label:"Advance Salary"},
{value:"Cash Collector",label:"Cash Collector"},
{value:"LOANS RECEIVABLE A/c",label:"LOANS RECEIVABLE A/c"},
{value:"DEPOSIT FOR LOCKER A/c",label:"DEPOSIT FOR LOCKER A/c"},
{value:"RENTAL DEPOSIT A/c",label:"RENTAL DEPOSIT A/c"},
// {value:"donation from head office",label:"donation from head office"},
// {value:"loan received from head office",label:"loan received from head office"},
// {value:"loan repayment to head office",label:"loan repayment to head office"},
{value:"memberShipFee",label:"MemberShip Fee"},
{value:"annualFee",label:"Annual Fee"},
{value:"shareFee",label:"ShareCertificate Fee"},
// {value:"Associate Individual: ShareCertificate Fee",label:"Associate Individual: ShareCertificate Fee"},
// {value:"Associate Organization: ShareCertificate Fee",label:"Associate Organization: ShareCertificate Fee"},
// {value:"Nominal: ShareCertificate Fee",label:"Nominal: ShareCertificate Fee"},
{value:"INCOME FROM DONATION A/c",label:"INCOME FROM DONATION A/c"},
// {value:"INCOME FROM BRANCHES Bank A/c",label:"INCOME FROM BRANCHES Bank A/c"},
// {value:"INCOME FROM DONATION (ZAKAT) A/c",label:"INCOME FROM DONATION (ZAKAT) A/c"},
// {value:"Demand Loan Service Charge",label:"Demand Loan Service Charge"},
// {value:"Consumer Goods Loan Service Charge",label:"Consumer Goods Loan Service Charge"},
// {value:"Business Goods Loan Service Charge",label:"Business Goods Loan Service Charge"},
// {value:"Business Partnership Service Charge",label:"Business Partnership Service Charge"},
// {value:"Salaries, Allowances",label:"Salaries, Allowances"},
// {value:"Employees Benefits",label:"Employees Benefits"},
// {value:"Medical Expenses",label:"Medical Expenses"},
// {value:"Office Expenses",label:"Office Expenses"},
// {value:"Staff Accomodation",label:"Staff Accomodation"},
// {value:"Telephone &amp; Internet",label:"Telephone &amp; Internet"},
// {value:"TA, DA &amp; Fuel",label:"TA, DA &amp; Fuel"},
// {value:"Electricity Charges",label:"Electricity Charges"},
// {value:"Office Rental",label:"Office Rental"},
// {value:"Operating Expenses",label:"Operating Expenses"},
// {value:"Staff Training",label:"Staff Training"},
// {value:"Bank Charges",label:"Bank Charges"},
// {value:"Misc. Expenses",label:"Misc. Expenses"},
// {value:"Audit Fee",label:"Audit Fee"},
// {value:"Depreciation",label:"Depreciation"},
// {value:"Printing &amp; Stationery",label:"Printing &amp; Stationery"},
// {value:"Suspense",label:"Suspense"},
// {value:"Management Fee",label:"Management Fee"},
// {value:"TDS",label:"TDS"},
// {value:"DONATION A/c",label:"DONATION A/c"},
// {value:"TAX EXPENSES A/c",label:"TAX EXPENSES A/c"},
// {value:"FURNITURE &amp; FIXTURES A/c",label:"FURNITURE &amp; FIXTURES A/c"},
// {value:"OFFICE APPLIANCE  A/c",label:"OFFICE APPLIANCE  A/c"},
// {value:"OFFICE EQUIPMENT A/c",label:"OFFICE EQUIPMENT A/c"},
// {value:"COMPUTER A/c",label:"COMPUTER A/c"},
// {value:"INVERTER A/c",label:"INVERTER A/c"},
// {value:"ELECTRICAL GOODS A/c",label:"ELECTRICAL GOODS A/c"},
// {value:"LAND &amp; BUILDING A/c",label:"LAND &amp; BUILDING A/c"},
// {value:"PLANT &amp; MACHINERY A/c",label:"PLANT &amp; MACHINERY A/c"},


	{
		label:"FURNITURE & FIXTURES A/c",
		value:"FURNITURE & FIXTURES A/c",
	},
	{
		label: "OFFICE APPLIANCE A/c",
		value: "OFFICE APPLIANCE A/c",
	},
	{
		label: "OFFICE EQUIPMENT A/c",
		value: "OFFICE EQUIPMENT A/c",
	},
	{
		label: "COMPUTER A/c",
		value: "COMPUTER A/c",
	},
	{
		label: "INVERTER A/c",
		value: "INVERTER A/c",
	},
	{
		label: "ELECTRICAL GOODS A/c",
		value: "ELECTRICAL GOODS A/c",
	},
	{
		label: "LAND & BUILDING A/c",
		value: "LAND & BUILDING A/c",
	},
	{
		label: "PLANT & MACHINERY A/c",
		value: "PLANT & MACHINERY A/c",
	},
	{
		label: "Salaries & Allowances",
		value: "Salaries & Allowances",
	},
	{
		label: "Employee Benefits",
		value: "Employee Benefits",
	},
	{
		label: "Medical Expenses",
		value: "Medical Expenses",
	},
	{
		label: "Office Expenses",
		value: "Office Expenses",
	},
	{
		label: "Staff Accommodation",
		value: "Staff Accommodation",
	},
	{
		label: "Telephone And Internet ",
		value: "Telephone And Internet ",
	},
	{
		label: "TA, DA & Fuel Charges",
		value: "TA, DA & Fuel Charges",
	},
	{
		label: "Utility Charges",
		value: "Utility Charges",
	},
	{
		label: "Office Rent",
		value: "Office Rent",
	},
	{
		label: "Operating Expenses",
		value: "Operating Expenses",
	},
	{
		label: "Bank Charges",
		value: "Bank Charges",
	},
	{
		label: "Miscellaneous Expenses",
		value: "Miscellaneous Expenses",
	},
	{
		label: "Audit Fee",
		value: "Audit Fee",
	},
	{
		label: "Depreciation ",
		value: "Depreciation ",
	},
	{
		label: "Printing & Stationery Charges",
		value: "Printing & Stationery Charges",
	},
	{
		label: "Suspense Account",
		value: "Suspense Account",
	},
	{
		label: "Management Fee",
		value: "Management Fee",
	},
	{
		label: "TDS",
		value: "TDS",
	},
	{
		label: "Hypto Charges",
		value: "Hypto Charges",
	},
	{
		label: "DEPRECIATION EXPENSES A/c",
		value: "DEPRECIATION EXPENSES A/c",
	},
	{
		label: "DIVIDEND RESERVE A/c",
		value: "DIVIDEND RESERVE A/c",
	},

]

export default bookOpt;