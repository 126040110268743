import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../hooks/usePagination';
import '../custom.scss';
import { CPagination, CPaginationItem } from "@coreui/react";

const Paginating = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={classnames('pagination-container d-flex justify-content-center', { [className]: className })}
            style={{ cursor: "pointer" }}
        >
            <CPagination align="center" style={{ cursor: "pointer" }}>

                {/* Left navigation arrow */}
                <CPaginationItem
                    className={classnames({
                        disabled: currentPage === 1
                    })}
                    onClick={onPrevious}
                >
                    &laquo;
                    {/* <div className="arrow left" /> */}
                </CPaginationItem>
                {paginationRange.map(pageNumber => {

                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        return <CPaginationItem className="dots">&#8230;</CPaginationItem>;
                    }

                    // Render our Page Pills
                    return (
                        <CPaginationItem
                            className={classnames({
                                selected: pageNumber === currentPage
                            })}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </CPaginationItem>
                    );
                })}
                <CPaginationItem
                    className={classnames({
                        disabled: currentPage === lastPage
                    })}
                    onClick={onNext}
                >&raquo;
                    {/* <div className="arrow right" /> */}
                </CPaginationItem>

            </CPagination>
        </ul>
    );
};

export default Paginating;