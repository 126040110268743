import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CFormSelect,
  CButtonGroup,
} from "@coreui/react";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

const FieldTeamActivity = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [cashCollector, setCashCollector] = useState([]);
  const [display, setDisplay] = useState(false);
  const [input, setInput] = useState({
    cashCollectorId: "",
    date: new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    )
      .toLocaleString("sv", {
        timeZone: "Asia/Kolkata",
        dateStyle: "short",
        timeStyle: "medium",
      })
      .split(" ")[0],
  });

  useEffect(() => {
    async function fetchCashCollectors() {
      try {
        const res = await axios.get(
          `/api/cashCollectors/getCashCollectorsBranch`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        return setCashCollector(res.data.Success);
      } catch (e) {
        console.log(e);
      }
    }
    fetchCashCollectors();
  }, []);

  async function fetchData() {
    setIsSubmitting(true);
    try {
      const res = await axios.get(
        `/api/cashCollectors/fieldActivity/${input.date}`,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      setData(res.data.success);
      setIsSubmitting(false);
      setDisplay(true);
      return;
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  }

  const handleClick = () => {
    let newData = formatData();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `FieldTeamActivity.csv`);
  };

  const formatData = () => {
    return data.activeCollectorNames.map((item, index) => {
      return {
        "Cash Collector": item,
        Mobile:
          data.activeCollectormobileNo[
            data.activeCollectorNames.findIndex((e) => e === item)
          ],
        "Members Covered": data.coveredMembers[item],
        "Minimum Members":
          data.minimumMembers[
            data.activeCollectorNames.findIndex((e) => e === item)
          ],
        "Total Activities": data.activitiesLogged[item],
        Cash: data.cashCollection[item],
        "Total Collected": data.totalCollected[item],
        "Total Withdrawal": data.withdrawalCollection[item],
      };
    });
  };
  return (
    <CContainer
      fluid
      className="p-0 m-0"
      style={{
        minHeight: "88vh",
      }}
    >
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Field Team Activity
      </p>
      <CContainer>
        <CRow>
          <h5>Field Team Activity</h5>
          <hr></hr>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                Date
                <span className="text-danger">*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                max={
                  new Date(
                    new Date().toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    })
                  )
                    .toLocaleString("sv", {
                      timeZone: "Asia/Kolkata",
                      dateStyle: "short",
                      timeStyle: "medium",
                    })
                    .split(" ")[0]
                }
                value={input.date}
                onChange={(e) => setInput({ ...input, date: e.target.value })}
              />
            </CInputGroup>
          </CCol>

          {/* //TODO: variable data in caterogy  */}
          {/* <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="fieldteamactivity">
                Category
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormSelect
                aria-label="Category"
                options={["Select", ...cashCollector]}
                value={input.cashCollectorId}
                onChange={(e) => {
                  setInput({ ...input, cashCollectorId: e.target.value });
                }}
              />
            </CInputGroup>
          </CCol> */}

          <CCol sm={3}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButton
                className="text-white fw-bold"
                disabled={isSubmitting}
                color="success"
                onClick={() => fetchData()}
              >
                {isSubmitting ? "Fetching..." : "Show"}
              </CButton>
              <CButton
                className="text-white fw-bold"
                color="success"
                onClick={handleClick}
              >
                Export to CSV
              </CButton>
            </CContainer>
          </CCol>
        </CRow>
        {display && (
          <>
            <h5>Team Summary</h5>
            <hr></hr>
            <CRow>
              <CCol sm={4}>
                <CTable bordered striped className="shadow">
                  <CTableBody className=" align-middle">
                    <CTableRow>
                      <CTableDataCell>Total Cash Collectors</CTableDataCell>
                      <CTableDataCell>{cashCollector.length}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Cash Collectors Logged</CTableDataCell>
                      <CTableDataCell>
                        {data.teamSummary.cashCollectorlogged}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Total Members in Beats</CTableDataCell>
                      <CTableDataCell>{data.minimumMembers}</CTableDataCell>
                    </CTableRow>
                    {/* Date selected must come here */}
                    {/* <CTableRow>
                      <CTableDataCell>Members covered on date </CTableDataCell>
                      <CTableDataCell>{}</CTableDataCell>
                    </CTableRow> */}
                    <CTableRow>
                      <CTableDataCell>Activities Logged </CTableDataCell>
                      <CTableDataCell>
                        {data.teamSummary.totalActivitiesLogged}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Avg Time </CTableDataCell>
                      <CTableDataCell>{}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Total Collections (Cash)</CTableDataCell>
                      <CTableDataCell>
                        {data.teamSummary.totalCashCollection.toFixed(2)}
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCol>
              {/* <CCol sm={8}>
                <h6>Payment OutComes</h6> */}
              {/* <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    OutCome
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Members
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Collection
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Withdrawal
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text-center align-middle">
                <CTableRow>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable> */}
              {/* </CCol> */}
            </CRow>
            <h5>
              Team Activity Details as on {new Date(input.date).toDateString()}
            </h5>
            <hr></hr>
            {/* Commented because of static data */}
            {/* <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    Cash Collector
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Mobile
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Members Covered
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Minimum Members
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Total Activities
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Cash
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Cheque
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Total Collected
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Total Withdrawal
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Details
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Map
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text-center align-middle">
                <CTableRow>
                  <CTableDataCell>Abdul Hafeez </CTableDataCell>
                  <CTableDataCell> 9989786117 </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>100</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      type="submit"
                      disabled={isSubmitting}
                      color="link"
                      shape="rounded-0"
                    >
                      Details
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      type="submit"
                      disabled={isSubmitting}
                      color="link"
                      shape="rounded-0"
                    >
                      Show Map
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable> */}

            <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    Cash Collector
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Mobile
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Members Covered
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Minimum Members
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Total Activities
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Cash
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Total Collected
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Total Withdrawal
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Details
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Map
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className="text-center align-middle">
                {data.activeCollectorNames.map((item, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{item}</CTableDataCell>
                    <CTableDataCell>
                      {
                        data.activeCollectormobileNo[
                          data.activeCollectorNames.findIndex((e) => {
                            return e === item;
                          })
                        ]
                      }
                    </CTableDataCell>
                    <CTableDataCell>{data.coveredMembers[item]}</CTableDataCell>
                    <CTableDataCell>
                      {
                        data.minimumMembers[
                          data.activeCollectorNames.findIndex((e) => {
                            return e === item;
                          })
                        ]
                      }
                    </CTableDataCell>
                    <CTableDataCell>
                      {data.activitiesLogged[item]}
                    </CTableDataCell>
                    <CTableDataCell>{data.cashCollection[item]}</CTableDataCell>
                    <CTableDataCell>{data.totalCollected[item]}</CTableDataCell>
                    <CTableDataCell>
                      {data.withdrawalCollection[item]}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        type="submit"
                        disabled={isSubmitting}
                        color="link"
                        shape="rounded-0"
                        onClick={() => navigate("/MemberActivity")}
                      >
                        Details
                      </CButton>
                    </CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        type="submit"
                        disabled={isSubmitting}
                        color="link"
                        shape="rounded-0"
                        onClick={() => navigate("/cashCollectorActivity/maps")}
                      >
                        Show Map
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </>
        )}
      </CContainer>
    </CContainer>
  );
};

export default FieldTeamActivity;
