import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  CContainer,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import BeatModal from "./Modal/BeatModal";

// const TableSearch = ({ filterValue, currentValue }) => {
//   return (
//     <CContainer fluid className="w-75">
//       <CInputGroup>
//         <CInputGroupText className="bg-info text-white">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="20"
//             height="20"
//             fill="currentColor"
//             className="bi bi-search"
//             viewBox="0 0 16 16"
//           >
//             <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
//           </svg>
//         </CInputGroupText>
//         <CFormInput
//           placeholder="Search"
//           // value={currentValue}
//           // onChange={filterValue}
//         />
//       </CInputGroup>
//     </CContainer>
//   );
// };

function NewBeat() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(
          "/api/cashCollectors/allbeats",
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        setData(res.data.everyBeats);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <CContainer
      fluid
      className="p-0 m-0"
      style={{
        minHeight: "90vh",
      }}
    >
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        New Beats
      </p>
      <BeatModal visible={visible} setVisible={setVisible} />
      <CContainer fluid className="d-flex justify-content-around">
        {/* <TableSearch filterValue={{}} currentValue={{}} /> */}
        <CButton
          color="warning"
          className="text-white fw-bold shadow mt-3"
          onClick={() => setVisible(!visible)}
        >
          + Add New Beat
        </CButton>
      </CContainer>
      <hr />
      <CContainer className="d-flex justify-content-center ">
        {data.length > 0 ? (
          <CTable bordered striped className="shadow w-50">
            <CTableHead className="bg-dark text-center text-white">
              <CTableRow>
                <CTableHeaderCell scope="col" className="w-50">Beat</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="w-50">Beat Type</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data.map((beat, index) => {
                return (
                  <CTableRow className="text-center " key={index}>
                    <CTableHeaderCell scope="row">
                      {beat.beats.beatName}
                    </CTableHeaderCell>
                    <CTableDataCell>Normal</CTableDataCell>
                  </CTableRow>
                );
              })}
            </CTableBody>
          </CTable>
        ) : (
          <p className="text-center">No Beats</p>
        )}
      </CContainer>
    </CContainer>
  );
}

export default NewBeat;
