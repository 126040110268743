// * Search function for the search bar

// * Parameters:
// * 1.data: array of objects, contains the data to be searched
// * 2.filter: string, contains the search term

// * Return Value:
// * array of objects

const searchFunction = (data, filter) => {
  return data.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(filter.toLowerCase())
    );
  });
};

export default searchFunction;
