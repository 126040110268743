import React from "react"; //???
import {
  CModal,
  CFormInput,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTableBody,
  CTable,
  CTableRow,
  CTableHeaderCell,
  CTableHead,
  CTableDataCell,
} from "@coreui/react";
import globalContext from "../../contexts/Global/globalContext";
import { useState, useContext } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const Smodal = ({ setsModalOpen, SmodalOpen, membershipNo }) => {
  const navigate = useNavigate();
  const { showAlert } = useContext(globalContext);
  const [shareData, setshareData] = useState({
    Fee: "",
    date: new Date().toISOString().split("T")[0],
  });
  const [WmodalOpen, setwModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    if (e.target.value < 0) {
      return;
    }
    setshareData({
      ...shareData,
      [e.target.name]: e.target.value,
    });
  };
  async function shareFeeBuy() {
    setIsLoading(true);
    try {
      let res = await axios.post(
        `/api/member/shareCertificate/${membershipNo}`,
        shareData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      showAlert("Payment Successful", "2");
      setwModalOpen(false);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  return (
    <>
      <CModal
        className="modal-lg"
        visible={SmodalOpen}
        onClose={() => setsModalOpen(false)}
      >
        <CModalHeader>
          <CModalTitle>Fee Details</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CTable bordered className="">
            <CTableHead className="text-center">
              <CTableRow>
                <CTableHeaderCell scope="col">Type</CTableHeaderCell>
                <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                <CTableHeaderCell scope="col">Pay Now</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow className="text-center align-middle">
                <CTableDataCell className="p-5">
                  Share Certificate Fees
                </CTableDataCell>
                <CTableDataCell>
                  <CFormInput
                    type="number"
                    placeholder="Number of Shares"
                    aria-label="Number of Shares"
                    aria-describedby="basic-addon1"
                    required
                    name="Fee"
                    className="text-center"
                    value={shareData.Fee}
                    min="0"
                    onChange={onChangeHandler}
                  />
                  <CFormInput
                    placeholder="xx/xx/xxxx"
                    type="date"
                    aria-label="Number"
                    aria-describedby="basic-addon1"
                    required
                    name="date"
                    value={shareData.date}
                    onChange={onChangeHandler}
                    className="mb-3 mt-3 text-center"
                  />
                </CTableDataCell>
                <CTableDataCell>
                  Share Price X Qty (10 X {shareData.Fee})= {shareData.Fee * 10}
                  <br />
                  <CButton
                    className="fs-5 p-1 w-75"
                    color="primary"
                    onClick={() => shareFeeBuy()}
                    disabled={isLoading}
                  >
                    PAY
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CModalBody>
      </CModal>
    </>
  );
};

export default Smodal;
