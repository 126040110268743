import React from "react";
import {
  CButton,
  CContainer,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import globalContext from "../contexts/Global/globalContext";
// import Paginate from "./Pagination";
// import DropDownPagination from "./DropDownPaginate";

import { useState, useEffect,useContext } from "react";
import axios from "axios";

function CashCollectorBeat() {
  const [input, setInput] = useState({
    cashCollector: "",
    beatName: "",
  });
  const { alert, showAlert , loginComplete} = useContext(globalContext);
  const [cashCollector, setCashCollector] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [beatName, setBeatName] = useState("");

  useEffect(() => {
    async function fetchCashCollectors() {
      try {
       
        const res1 = await axios.get(
          `/api/cashCollectors/getCashCollectorsBranch`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
  
        setCashCollector(res1.data.Success);
        const res2 = await axios.get("/api/cashCollectors/allbeats", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        let dropDownBeat = res2.data.everyBeats.map((e) => {
          return {
            label: e.beats.beatName,
            value: e._id,
          };
        });
       
        setBeatName(dropDownBeat);
        return;
      } catch (e) {
        console.log(e);
      }
    }
    fetchCashCollectors();
  }, []);
  async function handleSubmit() {
    setIsSubmitting(true);
    try {
      const res = await axios.put(
        `/api/cashCollectors/assignbeat/${input.cashCollector}/${input.beatName}`,
        {},
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      showAlert(res.data.success,"2")
      setIsSubmitting(false);
      return;
    } catch (e) {
      console.log(e);
      showAlert(e.message,"3")
    }
    setIsSubmitting(false);
  }
  return (
    <CContainer fluid className="p-0 m-0" style={{ minHeight: "87vh" }}>
      <p
        style={{ background: "#2364aa" }}
        className="fw-bold text-white fs-2 text-center p-1"
      >
        Cash Collector Beat
      </p>
      <CContainer fluid className="d-flex flex-column p-0 m-0">
        <CContainer
          fluid
          className="d-flex justify-content-around align-items-center p-3 border border-info rounded shadow w-75"
        >
          <CInputGroup className="w-25">
            <CInputGroupText className="fw-bold text-info">
              Cash Collector:
            </CInputGroupText>
            <CFormSelect
              aria-label="Category"
              options={["Select", ...cashCollector]}
              value={input.cashCollector}
              onChange={(e) => {
                setInput({ ...input, cashCollector: e.target.value });
              }}
              className="w-25"
            ></CFormSelect>
          </CInputGroup>
          <CInputGroup className="w-25">
            <CInputGroupText className="fw-bold text-info">
              Beat Name
            </CInputGroupText>
            <CFormSelect
              aria-label="Category"
              options={["Select", ...beatName]}
              value={input.beatName}
              onChange={(e) => {
                setInput({ ...input, beatName: e.target.value });
              }}
              className="w-25"
            ></CFormSelect>
          </CInputGroup>
        </CContainer>
        <CContainer
          fluid
          className="d-flex justify-content-center align-items-center mt-5"
        >
          <CButton
            color="success"
            className="m-2 w-25 fw-bold text-white shadow"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Adding..." : "Add"}
          </CButton>
        </CContainer>
      </CContainer>
    </CContainer>
  );
}

export default CashCollectorBeat;
