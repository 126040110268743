import { CSpinner } from "@coreui/react";
const LoadingSpinner = () => {
  return (
    <div
      className="d-flex justify-content-center m-auto"
      style={{ height: "90vh"}}
    >
      <CSpinner color="primary" style={{ width: "4rem", height: "4rem" }} />
    </div>
  );
};

export default LoadingSpinner;
