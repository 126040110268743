import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CContainer,
  CButton,
} from "@coreui/react";

import { ReactComponent as Eye } from "../../assets/eye.svg";
import CreditorListGroup from "./CreditorListGroup";
import LoanList from "./LoanList";
import LastActivity from "./LastActivity";

  const creditorScroll = ({ data, setFlag, flag }) => {
  return (
    // TODO: Remove alwaysOpen later ... just for testing
    <CAccordion alwaysOpen activeItemKey={5}>
      {data.creditorType == "Individual" ? <CAccordionItem itemKey={1}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>View Details</strong>
        </CAccordionHeader>
        <CAccordionBody style={{ backgroundColor: "#f5f5f5f5" }}>
          <CreditorListGroup data={data} />
          <CContainer className="w-100 d-flex justify-content-between align-content-center px-4 mt-2">
            <CButton style={{ width: "16%" }}>Image#1</CButton>
          </CContainer>
        </CAccordionBody>
      </CAccordionItem> : <></>}
      <CAccordionItem itemKey={2}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>Loans Payable</strong>
        </CAccordionHeader>
        <CAccordionBody
          className="p-0"
        >
          <LoanList data={data} flag={flag} setFlag={setFlag}/>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={3}>
        <CAccordionHeader>
          <Eye
            style={{ width: "20px", height: "24px" }}
            className="text-secondary me-2"
          />
          <strong>Details</strong>
        </CAccordionHeader>
        <CAccordionBody style={{ backgroundColor: "#f5f5f5f5" }}>
          <LastActivity data={data} />
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  );
};

export default creditorScroll;
