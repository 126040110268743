import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import React, { useState , useContext} from "react";
import axios from "axios";
import globalContext from "../../contexts/Global/globalContext";

const CashCollector = () => {
  const {showAlert} = useContext(globalContext)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  let onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let res = await axios.get(`/api/branchActivity/cashCollector/${date}`, {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
          // "Content-Type": "application/json",
        },
      });
      setData(res.data.success);

      showAlert("Fetch Successfull",'2');
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
    setIsSubmitting(false);
  };

  return (
    <CContainer fluid className="p-0 m-0" style={{ minHeight: "90vh" }}>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Cash Collector
      </p>
      <CContainer>
        <CRow>
          <h5>Cash Collector Details</h5>
          <hr></hr>
          <CCol sm={6}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="select date">
                Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                // max={new Date().toISOString().split("T")[0]}
                required
                name="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={2}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButton
                type="submit"
                onClick={onSubmitHandler}
                disabled={isSubmitting}
                color="success"
                className="text-white  "
              >
                {isSubmitting ? "Submitting..." : "Get Details"}
              </CButton>
            </CContainer>
          </CCol>
        </CRow>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Cash Collector Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Advance
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Collection
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Collection Adjustment
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Withdrawal
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Withdrawal Adjustment
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount Returned
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data
              ? data.map((e, i) => (
                  <>
                    <CTableRow key={i}>
                      <CTableDataCell>{e.name}</CTableDataCell>
                      <CTableDataCell>{e.advance.toFixed(2)}</CTableDataCell>
                      <CTableDataCell>{e.collection.toFixed(2)}</CTableDataCell>
                      <CTableDataCell>
                        {e.collectionAdjustment.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell>{e.withdrawal.toFixed(2)}</CTableDataCell>
                      <CTableDataCell>
                        {e.withdrawalAdjustment.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell>
                        {e.amountReturned.toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell>
                        {e.balanceAmount.toFixed(2)}
                      </CTableDataCell>
                    </CTableRow>
                  </>
                ))
              : ""}
          </CTableBody>
        </CTable>
      </CContainer>
    </CContainer>
  );
};

export default CashCollector;
