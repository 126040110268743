import {
  CContainer,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CRow,
  CCol,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import globalContext from "../contexts/Global/globalContext";
import { useEffect, useState, useContext, useMemo } from "react";
import PendingLoansModal from "./PendingLoansModal";
import DropDownPagination from "./DropDownPaginate";
import Paginate from "./Pagination";

import Paginating from "./Paginating";

const TableSearch = ({ filterValue, currentValue }) => {
  return (
    <CContainer fluid className="w-75">
      <CInputGroup>
        <CInputGroupText className="bg-info text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </CInputGroupText>
        <CFormInput
          placeholder="Search"
          value={currentValue}
          onChange={filterValue}
        />
      </CInputGroup>
    </CContainer>
  );
};

const PendingLoanList = () => {
  const { showAlert } = useContext(globalContext);
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);

  const [accept, setAccept] = useState("");
  const [filter, setFilter] = useState("");

  const [pageSize, setPageSize] = useState(10)
	const [currentPage, setCurrentPage] = useState(1);

  let filterData = [...data];

  const filterString = filter.toLowerCase();

  filterData = filterData.filter((item) => {
    return [
      item.membershipNo,
      item.memberName,
      item.loanName,
      item.ammount,
      item.applicationDate,
      item.maxNoOfDays,
      item.frequency,
    ].map((e) => {
      if (typeof e == String) {
        return e.toLowerCase();
      } else return e.toString();
    }).some((property) => property.includes(filterString))

  });

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (filterData) {
      return filterData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, filterData]);


  function filterValue(e) {
    setFilter(e.target.value);
  }

  useEffect(() => {
    try {
      async function fetchData() {
        const response = await axios.get(`/api/loans/pending`, {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(response.data.pendingLoans);
      }
      fetchData();
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3");
        });
      } else {
        showAlert(error.response.data.error, "3");
      }
    }
  }, []);

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Pending Loans List
      </p>
      <CContainer fluid className="p-4 mb-5" style={{ height: "90vh" }}>
        <CContainer
          fluid
          className="d-flex align-items-center justify-content-around my-4"
        >
          <TableSearch filterValue={filterValue} currentValue={filter} />
          <DropDownPagination
            handleItemsPerPage={setPageSize}
            num={pageSize}
          />
        </CContainer>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Membership No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Membership Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Loan Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Application Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Pending Days
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Max No. of Days
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Frequency
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Action
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle ">
            {currentTableData.map((item, index) => {
              return (
              <>
                  <CTableRow key={index} className="text-center">
                  <CTableDataCell>{item.membershipNo}</CTableDataCell>
                  <CTableDataCell>{item.memberName}</CTableDataCell>
                  <CTableDataCell>{item.loanName}</CTableDataCell>
                  <CTableDataCell>{item.ammount}</CTableDataCell>
                  <CTableDataCell>
                    {new Date(item.applicationDate).toLocaleDateString()}
                  </CTableDataCell>
                  <CTableDataCell>0</CTableDataCell>
                  <CTableDataCell>{item.maxNoOfDays}</CTableDataCell>
                  <CTableDataCell>{item.frequency}</CTableDataCell>
                  <CTableDataCell>
                    <CRow className="d-grid align-items-center gy-2">
                      <CCol>
                        <CButton
                          className="btn btn-success w-75 text-white"
                          onClick={() => {
                            setAccept("accept");
                            setVisible(!visible);
                            setShowData(currentTableData[index]);
                          }}
                        >
                          Approve
                        </CButton>
                      </CCol>
                      <CCol>
                        <CButton
                          className="btn border-danger text-danger w-75"
                          style={{ backgroundColor: "#f5f5f5" }}
                          onClick={() => {
                            setAccept("reject");
                            setVisible(!visible);
                            setShowData(currentTableData[index]);
                          }}
                        >
                          Reject
                        </CButton>
                      </CCol>
                    </CRow>
                  </CTableDataCell>
                </CTableRow>
              
              </>
              );
            })}
          </CTableBody>
        </CTable>
        <Paginating
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filterData ? filterData.length : ""}
          pageSize={pageSize}
          onPageChange={page => setCurrentPage(page)}
        />
        <PendingLoansModal
          accept={accept}
          setAccept={setAccept}
          setVisible={setVisible}
          visible={visible}
          showData={showData}
          setShowData={setShowData}
        />
     
      </CContainer>
    </>
  );
};

export default PendingLoanList;
