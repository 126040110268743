import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";
import axios from "axios";

const DepositsLoans = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get("api/superAdmin/depositLoans", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(res.data.data);
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Member Fee Details
      </p>

      <CContainer className="mt-4" style={{ minHeight: "80vh" }}>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Type
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                frequency
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Service Charge
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Collateral
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Number of Days
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Minimum Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Maximum Amount
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            <CTableRow>
              <CTableDataCell>Savings Deposit</CTableDataCell>
              <CTableDataCell>None</CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell>
                {data ? data.depositMinAmount : ""}
              </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Daily Deposit</CTableDataCell>
              <CTableDataCell>Daily</CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell>
                {data ? data.depositMinAmount : ""}
              </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Term Deposit</CTableDataCell>
              <CTableDataCell>Half Yearly</CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
              <CTableDataCell>0</CTableDataCell>
              <CTableDataCell> - </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Business Goods Loan</CTableDataCell>
              <CTableDataCell>Daily</CTableDataCell>
              <CTableDataCell>
                {data ? data.loanServiceCharge : ""}
              </CTableDataCell>
              <CTableDataCell>Cheque, Linked Account</CTableDataCell>
              <CTableDataCell>100</CTableDataCell>
              <CTableDataCell>
                {data ? data.loanMinimumAmount : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.loanMaximumAmount : ""}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Demand Loan</CTableDataCell>
              <CTableDataCell>Monthly</CTableDataCell>
              <CTableDataCell>
                {data ? data.demandLoanServiceCharge : ""}
              </CTableDataCell>
              <CTableDataCell>Cheque, Gold, Linked Account</CTableDataCell>
              <CTableDataCell>100</CTableDataCell>
              <CTableDataCell>
                {data ? data.demandLoanMinimumAmount : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.demandLoanMaximumAmount : ""}
              </CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Consumer Goods Loan</CTableDataCell>
              <CTableDataCell>Monthly</CTableDataCell>
              <CTableDataCell>
                {data ? data.consumerLoanServiceCharge : ""}
              </CTableDataCell>
              <CTableDataCell>Cheque, Linked Account</CTableDataCell>
              <CTableDataCell>300</CTableDataCell>
              <CTableDataCell>
                {data ? data.consumerLoanMinimumAmount : ""}
              </CTableDataCell>
              <CTableDataCell>
                {data ? data.consumerLoanMaximumAmount : ""}
              </CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CContainer>
    </>
  );
};

export default DepositsLoans;
