import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
} from "@coreui/react";
import { useState, useContext } from "react";
import LoanRequestModal from "../Modal/LoanRequestModal";

import MemberContext from "../../contexts/MemberContext/MemberContext";
import LoanDetails from "../Modal/LoanDetails";
const LoanList = () => {
  // let {memberData:data} = useContext(MemberContext);
  let { memberData: data } = useContext(MemberContext);

  const [visible, setVisible] = useState(false);

  return (
    <>
      <LoanRequestModal visible={visible} setVisible={setVisible} data={data} />

      <CContainer fluid className="p-4 mt-1">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-secondary text-center">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Loan Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Loan Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Repayment Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Disbursed Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                No. of Days
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Maturity Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Repaid Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Last Transaction
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Options
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data.loans.loans.map((ele, i) => {
              if(ele.loanRequestStatus == "rejected"){
                return (<></>)
              }
              let disburseDate = null;
              let maturityDate = null;
              if(ele.disbursedDate){
                disburseDate = new Date(ele.disbursedDate).toLocaleDateString()
              }
              if(ele.maturityDate){
                maturityDate = new Date(ele.maturityDate).toLocaleDateString()
              }

              return (
                <CTableRow key={i}>
                  <CTableDataCell>{ele.loanName}</CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.loanAmount.toFixed(2)}</b>
                  </CTableDataCell>
                  <CTableDataCell>
                    <b>{(ele.repaymentAmount).toFixed(2)}</b>
                  </CTableDataCell>
                  <CTableDataCell>{ disburseDate ? disburseDate : "-"}</CTableDataCell>
                  <CTableDataCell>{ele.maxNoOfDays}</CTableDataCell>
                  <CTableDataCell>{maturityDate}</CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.repaidAmount.toFixed(2)}</b>
                  </CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.balanceAmount ? ele.balanceAmount.toFixed(2) : "-"}</b>
                  </CTableDataCell>
                <CTableDataCell>{ele.details ? "-" : new Date(ele.details[ele.details.length-1].transactionDate).toDateString()}</CTableDataCell>
                  <CTableDataCell>
                    <LoanDetails data={data} indx={i} />
                  </CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
        <div className="d-flex justify-content-center">
          <CButton onClick={() => setVisible(!visible)}>
            New Loan Request
          </CButton>
        </div>
      </CContainer>
    </>
  );
};

export default LoanList;
