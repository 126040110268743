import React, { useState , useContext } from "react";
import axios from "axios";
import {
  CFormSelect,
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";

import MemberContext from "../../contexts/MemberContext/MemberContext";
import globalContext from "../../contexts/Global/globalContext";
import { useNavigate } from "react-router-dom";

const MemberStatusModal = ({ setMemberStatusModalOpen, memberStatusModalOpen,setIsStatusChanged,isStatusChanged }) => {
  let {memberData:data} = useContext(MemberContext);
  const {showAlert , } = useContext(globalContext);
  const navigate = useNavigate()

  const [updatedStatus, setUpdatedStatus] = useState(data.memberStatus);
  const [isdisabled, setIsDisabled] = useState(true);

  const updateStatus = (e) => {
    setIsDisabled(false);
    setUpdatedStatus(e.target.value);
  };

  const submitStatus = async () => {
    try {
      if(isdisabled){
        alert("Please Select the Option")
        return
      }
      await axios.put(
        `/api/member/memberStatus/${data.membershipNo}`,
        {
          newStatus: updatedStatus,
        },
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      showAlert("Status Updated" , "2");
      setMemberStatusModalOpen(false);
      setIsDisabled(true);
      setIsStatusChanged(!isStatusChanged);
      setTimeout(()=>{
        navigate(0)
      },1000)
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
  };
  return (
    <>
      <CModal
        className="modal-lg"
        visible={memberStatusModalOpen}
        onClose={() => setMemberStatusModalOpen(false)}
      >
        <CModalHeader>
          <CModalTitle>Member Status</CModalTitle>
        </CModalHeader>
        <CModalBody  className="mt-5 text-center">
          Are you sure?
        </CModalBody>
        <CFormSelect
          aria-label="Forms"
          className="ms-auto me-auto d-flex justify-content-around w-50 "
          onChange={(e) => updateStatus(e)}
        >
          <option disabled selected hidden value="">Status</option>
          {/* <option value="Pending">Pending</option> */}
          <option value="Active">Active</option>
          <option value="InActive">InActive</option>
          {/* <option value="Red">Red</option> */}
          <option value="Terminated">Terminated</option>
        </CFormSelect>
        <CModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CButton
            color="success"
            variant="outline"
            className="mb-5"
            onClick={submitStatus}
          >
            Update
          </CButton>
          <CButton
            style={{ marginLeft: "10px" }}
            className="mb-5"
            color="danger"
            variant="outline"
            onClick={() => setMemberStatusModalOpen(false)}
          >
            Cancel
          </CButton>
        </CModalBody>
      </CModal>
    </>
  );
};

export default MemberStatusModal;
