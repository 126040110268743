import {
  CRow,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButtonGroup,
} from "@coreui/react";
import * as XLSX from "xlsx";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext.js";

const BalanceSheet = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState(null);
  const [otherAmount, setOtherAmount] = useState(null);
  const [reqData, setReqData] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });

  function getAmount(name) {
    let found = data?.find((e) => e.name === name);
    return found?.amount || 0;
  }

  let onSubmitHandler = () => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `api/accounts/balanceSheet/${reqData.fromReqDate}/${reqData.toReqDate}`,
          {
            headers: {
              "auth-token": JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );

        let bal = res.data.balances;
        let otherAmount = {
          finalTotal: res.data.finalTotal,
          assetsTotal: res.data.assetsTotal,
          liabilityTotal: res.data.liabilityTotal,
          IncomeAdjust: res.data.IncomeAdjust,
          ExpenseAdjust: res.data.ExpenseAdjust,
        };
        setOtherAmount(otherAmount);
        setData(bal);
      } catch (error) {
        // console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  };

  let onChangeHandler = (e) => {
    // e.preventDefault();

    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    let newData = formatData();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `BalanceSheet.csv`);
  };

  const balanceData = [
    {
      liability: "Capital Account",
      liabilityAmount: "",
      asset: "Fixed Assets",
      assetAmount: "",
    },
    {
      liability: "Share Capital ",
      liabilityAmount: getAmount("Share Capital"),
      asset: "Furniture & Fixtures",
      assetAmount: getAmount("FURNITURE & FIXTURES A/c"),
    },
    {
      liability: "Add : Addition",
      liabilityAmount: "0.00",
      asset: "Office Appliance",
      assetAmount: getAmount("OFFICE APPLIANCE A/c"),
    },
    {
      liability: "Less : Drawings",
      liabilityAmount: "0.00",
      asset: "Office Equipment",
      assetAmount: getAmount("OFFICE EQUIPMENT A/c"),
    },
    {
      liability: " ",
      liabilityAmount: "",
      asset: "Computer",
      assetAmount: getAmount("COMPUTER A/c"),
    },
    {
      liability: "Reserves & Surplus",
      liabilityAmount: "",
      asset: "Invertor",
      assetAmount: "0.00",
    },
    {
      liability: "Excess of Expenditure over Income",
      liabilityAmount: "0.00",
      asset: "",
      assetAmount: otherAmount?.IncomeAdjust,
    },
    {
      liability: "Deficit Reserve",
      liabilityAmount: "0.00",
      asset: "Electrical Goods",
      assetAmount: getAmount("ELECTRICAL GOODS A/c"),
    },
    {
      liability: "General Reserve",
      liabilityAmount: "0.00",
      asset: "Land & Building",
      assetAmount: getAmount("LAND & BUILDING A/c"),
    },
    {
      liability: "Bad Debts Reserve ",
      liabilityAmount: "0.00",
      asset: "Plant & Machinery ",
      assetAmount: getAmount("PLANT & MACHINERY A/c"),
    },
    {
      liability: "Building Fund",
      liabilityAmount: "0.00",
      asset: "Loans & Advances",
      assetAmount: "",
    },
    {
      liability: "Education Fund",
      liabilityAmount: "0.00",
      asset: "Special Qarz",
      assetAmount: "0.00",
    },
    {
      liability: "Patronage Rebate Fund",
      liabilityAmount: "0.00",
      asset: "Mushareka Business",
      assetAmount: "0.00",
    },
    {
      liability: "Staff Welfare Reserve",
      liabilityAmount: "0.00",
      asset: "Murabaha Business Loan",
      assetAmount: getAmount("Murabaha Business Loan"),
    },
    {
      liability: "Dividend Reserve",
      liabilityAmount: "0.00",
      asset: "Murabaha Consumer Loan",
      assetAmount: getAmount("Murabaha Consumer Loan"),
    },
    {
      liability: "Demand Loan Reserve",
      liabilityAmount: "0.00",
      asset: "Demand Loan",
      assetAmount: getAmount("Qard-e-Hasna"),
    },
    {
      liability: "",
      liabilityAmount: "0.00",
      asset: "Deposit For Locker",
      assetAmount: "0.00",
    },
    {
      liability: "Non-current Liabilities",
      liabilityAmount: "",
      asset: "Advance Salary",
      assetAmount: getAmount("FURNITURE & FIXTURES A/c"),
    },
    {
      liability: "Loans Payable",
      liabilityAmount: "0.00 ",
      asset: "Rental Deposit",
      assetAmount: "0.00",
    },
    {
      liability: " ",
      liabilityAmount: "0.00",
      asset: "Loans Receivable",
      assetAmount: "0.00",
    },
    {
      liability: "Current Liabilities",
      liabilityAmount: "",
      asset: "Cash & Cash Equivalents",
      assetAmount: "",
    },
    {
      liability: "Daily Deposit",
      liabilityAmount: getAmount("Daily Deposit"),
      asset: "Cash Collector",
      assetAmount: getAmount("Cash Collector"),
    },
    {
      liability: "Savings Deposit",
      liabilityAmount: getAmount("Savings Deposit"),
      asset: "Main Cash",
      assetAmount: getAmount("Main Cash"),
    },
    {
      liability: "Term Deposit",
      liabilityAmount: getAmount("Term Deposit"),
      asset: "Petty Cash",
      assetAmount: getAmount("Petty Cash"),
    },
    {
      liability: "Umrah Deposit",
      liabilityAmount: "0.00",
      asset: "Bank",
      assetAmount: getAmount("CASH IN BANKS"),
    },
    {
      liability: "Long Term Deposit",
      liabilityAmount: "0.00",
      asset: "Virtual Bank Account",
      assetAmount: "0.00",
    },
    {
      liability: "Total",
      liabilityAmount: otherAmount?.assetsTotal,
      asset: "Total",
      assetAmount: otherAmount?.liabilityTotal,
    },
  ];

  const formatData = () => {
    return balanceData?.map((ele, i) => {
      return {
        Liabilities: ele.liability,
        Amount: `${ele.liabilityAmount}`,
        Assests: ele.asset,
        "Amount ": ele.assetAmount,
      };
    });
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Balance Sheet
      </p>
      <CContainer>
        <CRow>
          <h5>Balance Sheet </h5>
          <hr></hr>
          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="From_date">
                From Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="fromReqDate"
                value={reqData.fromReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={4}>
            <CInputGroup className="mb-3">
              <CInputGroupText id="To_date">
                To Date
                <span style={{ color: "red", fontWeight: "lighter" }}>*</span>
              </CInputGroupText>
              <CFormInput
                type="date"
                placeholder="xx/xx/xxxx"
                aria-label="select date"
                aria-describedby="select date"
                required
                name="toReqDate"
                value={reqData.toReqDate}
                onChange={onChangeHandler}
              />
            </CInputGroup>
          </CCol>

          <CCol sm={3}>
            <CContainer fluid className="d-flex justify-content-around">
              <CButtonGroup role="group">
                <CButton
                  className="text-white "
                  color="success"
                  onClick={onSubmitHandler}
                >
                  Get Details
                </CButton>
                <CButton
                  className="text-white "
                  color="success"
                  onClick={handleClick}
                >
                  Export To CSV
                </CButton>
              </CButtonGroup>
            </CContainer>
          </CCol>
        </CRow>

        <h5>Account Balances</h5>
        <hr></hr>

        {data && (
          <>
            <CTable bordered striped className="shadow">
              <CTableHead className="bg-dark text-center align-middle">
                <CTableRow>
                  <CTableHeaderCell scope="col" className="text-white">
                    Liabilities
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Amount
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Assests{" "}
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col" className="text-white">
                    Amount
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody className=" align-middle">
                <CTableRow>
                  <CTableDataCell scope="col">
                    <u>Capital Account</u>
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell scope="col">
                    {" "}
                    <u>Fixed Assets</u>
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Share Capital </CTableDataCell>
                  <CTableDataCell>{getAmount("Share Capital")}</CTableDataCell>
                  <CTableDataCell>Furniture & Fixtures </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("FURNITURE & FIXTURES A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Add : Addition </CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                  <CTableDataCell>Office Appliance </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("OFFICE APPLIANCE A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Less : Drawings </CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                  <CTableDataCell> Office Equipment </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("OFFICE EQUIPMENT A/c")}
                  </CTableDataCell>
                </CTableRow>

                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Computer</CTableDataCell>
                  <CTableDataCell>{getAmount("COMPUTER A/c")}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell scope="col">
                    <u>Reserves & Surplus</u>
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Invertor</CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>
                    Excess of Expenditure over Income{" "}
                  </CTableDataCell>
                  <CTableDataCell>{0.0}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>{otherAmount.IncomeAdjust}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Deficit Reserve </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Electrical Goods </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("ELECTRICAL GOODS A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>General Reserve </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Land & Building </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("LAND & BUILDING A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Bad Debts Reserve </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Plant & Machinery </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("PLANT & MACHINERY A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Building Fund </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell scope="col">
                    <u>Loans & Advances</u>
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Education Fund </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Special Qarz </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Patronage Rebate Fund </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Mushareka Business </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Staff Welfare Reserve</CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Murabaha Business Loan </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Murabaha Business Loan")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Dividend Reserve </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Murabaha Consumer Loan </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Murabaha Consumer Loan")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Demand Loan Reserve </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Demand Loan </CTableDataCell>
                  <CTableDataCell>{getAmount("Qard-e-Hasna")}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Deposit For Locker </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell scope="col">
                    <u>Non-current Liabilities</u>
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Advance Salary </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("FURNITURE & FIXTURES A/c")}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Loans Payable </CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                  <CTableDataCell>Rental Deposit </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Loans Receivable </CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell scope="col">
                    <u>Current Liabilities</u>
                  </CTableDataCell>
                  <CTableDataCell> </CTableDataCell>
                  <CTableDataCell scope="col">
                    <u>Cash & Cash Equivalents</u>{" "}
                  </CTableDataCell>
                  <CTableDataCell> </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Daily Deposit </CTableDataCell>
                  <CTableDataCell>{getAmount("Daily Deposit")}</CTableDataCell>
                  <CTableDataCell>Cash Collector </CTableDataCell>
                  <CTableDataCell>{getAmount("Cash Collector")}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Savings Deposit </CTableDataCell>
                  <CTableDataCell>
                    {getAmount("Savings Deposit")}
                  </CTableDataCell>
                  <CTableDataCell>Main Cash </CTableDataCell>
                  <CTableDataCell>{getAmount("Main Cash")}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Term Deposit </CTableDataCell>
                  <CTableDataCell>{getAmount("Term Deposit")}</CTableDataCell>
                  <CTableDataCell>Petty Cash </CTableDataCell>
                  <CTableDataCell>{getAmount("Petty Cash")}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Umrah Deposit </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Bank </CTableDataCell>
                  <CTableDataCell>{getAmount("CASH IN BANKS")}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>Long Term Deposit </CTableDataCell>
                  <CTableDataCell>0.00</CTableDataCell>
                  <CTableDataCell>Virtual Bank Account </CTableDataCell>
                  <CTableDataCell>0.00 </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell scope="col">
                    <u>Total</u>
                  </CTableDataCell>
                  <CTableDataCell>{otherAmount.assetsTotal}</CTableDataCell>
                  <CTableDataCell scope="col">
                    <u>Total</u>
                  </CTableDataCell>
                  <CTableDataCell>{otherAmount.liabilityTotal}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </>
        )}
      </CContainer>
    </>
  );
};

export default BalanceSheet;
