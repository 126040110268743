import { CListGroup, CListGroupItem, CContainer } from "@coreui/react";
import { useState, useEffect, useContext } from "react";
import MemberContext from "../../contexts/MemberContext/MemberContext";


const MemberListGroup = () => {
  const { memberData: data } = useContext(MemberContext);
  const [bussiness, setBusiness] = useState(false);
  useEffect(() => {
    if (data) {
      if (data.pDetails.occupation === "bussiness") setBusiness(true);
    }
  }, []);

  return (
    <>
      <CListGroup>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Paternal relation name</b>
            </div>
            <div>{data.pDetails.fatherHusbandName}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Gender</b>
            </div>
            <div>{data.pDetails.gender}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Data of Birth</b>
            </div>
            <div>{new Date(data.pDetails.dob).toLocaleDateString()}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Address</b>
            </div>
            <div>{`${data.pAddress.pHouseNo} , ${data.pAddress.pStreet} , ${data.pAddress.pCity} , ${data.pAddress.pState}`}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>caste</b>
            </div>
            <div>{data.pDetails.caste}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>religion</b>
            </div>
            <div>{data.pDetails.religion}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>occupation</b>
            </div>
            <div>{data.pDetails.occupation}</div>
          </CContainer>
        </CListGroupItem>
        {bussiness ? (
          <>
            <CListGroupItem>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <b>Bussiness Type</b>
                </div>

                <div>{data.bussinessType}</div>
              </CContainer>
            </CListGroupItem>

            <CListGroupItem>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <b>Goods / Services</b>
                </div>

                <div>{data.goodsService}</div>
              </CContainer>
            </CListGroupItem>

            <CListGroupItem>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <b>Location Type</b>
                </div>

                <div>{data.locationType}</div>
              </CContainer>
            </CListGroupItem>

            <CListGroupItem>
              <CContainer
                fluid
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <b>Bussiness Description</b>
                </div>

                <div>{data.bussinessDesc}</div>
              </CContainer>
            </CListGroupItem>
          </>
        ) : (
          ""
        )}

        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Annual Income</b>
            </div>
            <div>{data.annualIncome}</div>
          </CContainer>
        </CListGroupItem>
        <CListGroupItem>
          <CContainer
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>Requested Date</b>
            </div>
            <div>{data.requestedDate}</div>
          </CContainer>
        </CListGroupItem>
      </CListGroup>
    </>
  );
};

export default MemberListGroup;
