import React, { useState, useMemo } from "react";
import axios from "axios";
import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
// import { React, useState, useEffect } from "react";
import DropDownPagination from "./DropDownPaginate";
// import xlsx library to convert json to excel
import * as XLSX from "xlsx";

import Paginating from "./Paginating";

function Ledger() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [date, setDate] = useState({
    fromReqDate: new Date().toISOString().split("T")[0],
    toReqDate: new Date().toISOString().split("T")[0],
  });
  const [ledgerData, setLedgerData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  let onChangeHandler = (e) => {
    setDate({
      ...date,
      [e.target.name]: e.target.value,
    });
  };

  let onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let res = await axios.get(
        `/api/accounts/ledger/${date.fromReqDate}/${date.toReqDate}`,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
            "Content-Type": "application/json",
          },
        }
      );
      setLedgerData(res.data.result);
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };
  const formatData = (ledgerData) => {
    return ledgerData.map((e, i) => {
      const utcDate = new Date(e.Date);
      // Convert to IST (Indian Standard Time)
      const istOptions = {
        timeZone: "Asia/Kolkata",
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      const istFormattedDate = utcDate
        .toLocaleDateString("en-IN", istOptions)
        .replace(/\//g, "-");

      return {
        "S.No": i + 1,
        Date: istFormattedDate,
        // Date: new Date(e.Date).toLocaleString(),
        VoucherNo: e.VoucherNo,
        LoanId: e.LoanId,
        Particular: e.Particular,
        Debit: e.Debit,
        Credit: e.Credit,
        MembershipNo: `${e.MembershipNo}`,
        MemberName: e.MemberName,
        Narration: e.Narration,
      };
    });
  };
  const handleClick = () => {
    let newData = formatData(ledgerData);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Ledger.csv`);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (ledgerData) {
      return ledgerData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, ledgerData]);

  return (
    <div style={{ minHeight: "90vh" }}>
      <p
        style={{ background: "#2364aa" }}
        className="fw-bold text-white fs-2 text-center p-1"
      >
        Ledger
      </p>
      <CContainer className=" mt-2">
        <legend>Details</legend>
        <CForm className=" d-flex " onSubmit={onSubmitHandler}>
          <CInputGroup className=" w-75">
            <CInputGroupText className="fw-bold">Start Date</CInputGroupText>
            <CFormInput
              placeholder="xx/xx/xxxx"
              className="me-5"
              type="date"
              // required
              name="fromReqDate"
              value={date.fromReqDate}
              onChange={onChangeHandler}
              // max={new Date().toISOString().split("T")[0]}
            />

            <CInputGroupText className="fw-bold ms-4">End Date</CInputGroupText>
            <CFormInput
              placeholder="xx/xx/xxxx"
              type="date"
              // required
              name="toReqDate"
              value={date.toReqDate}
              onChange={onChangeHandler}
              // max={new Date().toISOString().split("T")[0]}
            />
          </CInputGroup>

          <CButton
            type="submit"
            disabled={isSubmitting}
            // size="sm"
            color="info"
            className="ms-4 text-white fw-bold"
          >
            {isSubmitting ? "Submitting..." : "Get Details"}
          </CButton>
          <CButton
            onClick={handleClick}
            color="success"
            className="ms-4 text-white fw-bold"
          >
            Export to CSV
          </CButton>
        </CForm>
      </CContainer>
      <hr />

      <DropDownPagination handleItemsPerPage={setPageSize} num={pageSize} />

      <CContainer fluid className="p-4 my-2">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              {/* <CTableHeaderCell scope="col" className="text-white">
              #
            </CTableHeaderCell> */}
              <CTableHeaderCell scope="col" className="text-white">
                Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                VoucherNo
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Loan Id
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Particulars
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Debit
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Credit
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Membership No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Member Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Narration
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {ledgerData &&
              currentTableData.map((ele, i) => {
                return (
                  <CTableRow key={i}>
                    <CTableDataCell>
                      {new Date(ele.Date).toLocaleDateString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </CTableDataCell>
                    <CTableDataCell>{ele.VoucherNo}</CTableDataCell>
                    <CTableDataCell>{ele.LoanId}</CTableDataCell>
                    <CTableDataCell>{ele.Particular}</CTableDataCell>
                    <CTableDataCell>{ele.Debit}</CTableDataCell>
                    <CTableDataCell>{ele.Credit}</CTableDataCell>
                    <CTableDataCell>{ele.MembershipNo}</CTableDataCell>
                    <CTableDataCell>{ele.MemberName}</CTableDataCell>
                    <CTableDataCell>{ele.Narration}</CTableDataCell>
                  </CTableRow>
                );
              })}
          </CTableBody>
        </CTable>
      </CContainer>
      <Paginating
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={ledgerData ? ledgerData.length : ""}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

export default Ledger;
