import {
  CButton,
  CContainer,
  CForm,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

function LoanTrack() {
  const location = useLocation();
  const passedData = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryData, setQueryData] = useState({
    loanName: passedData ? passedData.loanName : "All",
    issueDate: passedData ? passedData.issueDate : "All",
    lastPaymentDate: passedData ? passedData.lastPaymentDate : "All",
    repaidValue: passedData ? passedData.repaidValue : "All",
    overduePercentage: passedData ? passedData.overduePercentage : "All",
  });

  const [loanData, setLoanData] = useState([]);

  useEffect(() => {
    const onMount = () => {
      if (passedData) {
        onSubmitHandler();
      }
    };
    onMount();
  }, []);

  let onChangeHandler = (e) => {
    setQueryData({
      ...queryData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = async (e) => {
    try {
      if (e) e.preventDefault();
      let res = await axios.get("/api/loans/loanTracker", {
        params: queryData,
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")).token,
        },
      });
      setLoanData(res.data.incompleteLoans);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    let newData = formatData(loanData);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `LoanTracker.csv`);
  };

  const formatData = (loanData) => {
    return loanData.map((ele, i) => {
      let disbursedDate = new Date(ele.disbursedDate).toLocaleDateString();
      let maturityDate = new Date(ele.maturityDate).toLocaleDateString();
      let lastTransactionDate = new Date(
        ele.lastTransaction
      ).toLocaleDateString();

      return {
        "S No.": i + 1,
        "Issue Date": disbursedDate,
        "Member No.": `${ele.membershipNo}`,
        "Member Name": ele.memberName,
        "Loan Product": ele.loanName,
        Freq: ele.frequency,
        "Maturity Date": maturityDate,
        "Maturity Overdue Days": `${ele.maturityOverDueDate}`,
        "Last Pay": lastTransactionDate,
        "Days Since Last Pay": ele.lastPayDate,
        Disbursed: ele.loanAmount.toFixed(2),
        "Total Payable": ele.repaymentAmount.toFixed(2),
        Repaid: ele.repaidAmount.toFixed(2),
        "Balance Amount": ele.balanceAmount.toFixed(2),
        "Overdue Amount":
          ele.totalOverdue > 0 ? ele.totalOverdue.toFixed(2) : "-",
      };
    });
  };

  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1"
      >
        Loan Tracker
      </p>

      <CForm className="ms-5" onSubmit={onSubmitHandler}>
        <legend className="fw-bold ">Report</legend>
        <CContainer className="d-flex">
          <CInputGroup className="mb-3 ms-3 w-25">
            <CInputGroupText id="basic-addon1" className="fw-bold">
              Loan Name
              <span className="text-danger">*</span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="loanName"
              value={queryData.loanName}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="All">All</option>
              <option value="Demand Loan">Demand Loan</option>
              <option value="Consumer Goods Loan">Consumer Goods Loan</option>
              <option value="Business Goods Loan">Business Goods Loan</option>
            </CFormSelect>
          </CInputGroup>

          <CInputGroup className="mb-3 ms-4 w-25 ">
            <CInputGroupText id="basic-addon1" className="fw-bold">
              Issue Date
              <span className="text-danger">*</span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="issueDate"
              value={queryData.issueDate}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="All">All</option>
              <option value="new">New loan</option>
              <option value="matured">Mature(0 - 90 days)</option>
              <option value="old">Old (90+ days)</option>
            </CFormSelect>
          </CInputGroup>

          <CInputGroup className="mb-3 ms-4 w-25">
            <CInputGroupText id="basic-addon1" className="fw-bold">
              Last Payment Date
              <span className="text-danger">*</span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="lastPaymentDate"
              value={queryData.lastPaymentDate}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="All">All</option>
              <option value="active"> Active(0-10 days)</option>
              <option value="inactive">InactiveActive(10+ days)</option>
            </CFormSelect>
          </CInputGroup>

          {/* <CButton
            color="success"
            className="mb-3 ms-5 fw-bold text-white ps-4 pe-4"
          >
            Export to excel
          </CButton> */}
        </CContainer>

        <CContainer className="d-flex">
          <CInputGroup className="mb-3 ms-3 w-25">
            <CInputGroupText id="basic-addon1" className="fw-bold">
              Repaid vs. Payable Amt
              <span className="text-danger">*</span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="repaidValue"
              value={queryData.repaidValue}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="All">All</option>
              <option value="noPayment">No Payment</option>
              <option value="partialPayment">Partial Payment</option>
            </CFormSelect>
          </CInputGroup>

          <CInputGroup className="mb-3 ms-4 w-25 ">
            <CInputGroupText id="basic-addon1" className="fw-bold">
              Overdue vs. Payable Amt
              <span className="text-danger">*</span>
            </CInputGroupText>
            <CFormSelect
              required
              size="sm"
              name="overduePercentage"
              value={queryData.overduePercentage}
              onChange={onChangeHandler}
            >
              <option disabled selected hidden value="">
                Select
              </option>
              <option value="All">All</option>
              <option value="upto">Upto 25%</option>
              <option value="over">Above 25%</option>
            </CFormSelect>
          </CInputGroup>

          <CButton
            color="info"
            type="submit"
            disabled={isSubmitting}
            className="mb-3 ms-4 fw-bold text-white ps-5 pe-5 w-25"
          >
            {isSubmitting ? "Fetching..." : "Get Details"}
          </CButton>
        </CContainer>
      </CForm>
      <hr />
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <legend className="fw-bold ms-5" style={{ width: "fit-content;" }}>
          Details
        </legend>
        <CButton
          className="text-white me-5"
          color="success"
          style={{ minWidth: "fit-content" }}
          onClick={handleClick}
          // disabled={data && data.length === 0}
        >
          Export To CSV
        </CButton>
      </div>
      <CTable bordered striped className="shadow ms-2 ">
        <CTableHead className="bg-secondary text-center">
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-white">
              S no.
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Issue Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Member No.
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Member Name
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Loan Product
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Freq
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Maturity Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Maturity Overdue Days
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Last Pay
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Days Since Last Pay
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Disbursed
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Total Payable
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Repaid
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Balance Amount
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-white">
              Overdue Amount
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {loanData ? (
            loanData.map((ele, i) => {
              let disbursedDate = new Date(ele.disbursedDate).toDateString();
              let maturityDate = new Date(ele.maturityDate).toDateString();
              let lastTransactionDate = new Date(
                ele.lastTransaction
              ).toDateString();

              return (
                <>
                  <CTableRow>
                    <CTableDataCell>{i}</CTableDataCell>
                    <CTableDataCell>{disbursedDate}</CTableDataCell>
                    <CTableDataCell>{ele.membershipNo}</CTableDataCell>
                    <CTableDataCell>{ele.memberName}</CTableDataCell>
                    <CTableDataCell>{ele.loanName}</CTableDataCell>
                    <CTableDataCell>{ele.frequency}</CTableDataCell>
                    <CTableDataCell>{maturityDate}</CTableDataCell>
                    <CTableDataCell>{ele.maturityOverDueDate}</CTableDataCell>
                    <CTableDataCell>{lastTransactionDate}</CTableDataCell>
                    <CTableDataCell>{ele.lastPayDate}</CTableDataCell>
                    <CTableDataCell>{ele.loanAmount.toFixed(0)}</CTableDataCell>
                    <CTableDataCell>
                      {ele.repaymentAmount.toFixed(0)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {ele.repaidAmount.toFixed(0)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {ele.balanceAmount.toFixed(0)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {ele.totalOverdue.toFixed(0) > 0
                        ? ele.totalOverdue.toFixed(0)
                        : "-"}
                    </CTableDataCell>
                  </CTableRow>
                </>
              );
            })
          ) : (
            <></>
          )}
        </CTableBody>
      </CTable>
    </>
  );
}

export default LoanTrack;
