import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
} from "@coreui/react";
import Dmodal from "../Modal/Dmodal";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";

import WithdrawModal from "../../components/Modal/WithdrawModal";
import DepositModal from "../../components/Modal/DepositModal ";
import MemberContext from "../../contexts/MemberContext/MemberContext";

const DeposiList = ({ setFlag, flag }) => {
  // let {memberData:data} = useContext(MemberContext);
  let { memberData: data } = useContext(MemberContext);
  const [modalOpen, setModalOpen] = useState(false);
  let navigate = useNavigate();
  const [depositId, setDepositId] = useState("");

  const [WithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [DepositModalOpen, setDepositModalOpen] = useState(false);
  // console.log(data.deposits.deposits);
  return (
    <>
      <Dmodal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        data={data}
        setFlag={setFlag}
        flag={flag}
        depositId={depositId}
      />

      <DepositModal
        setDepositModalOpen={setDepositModalOpen}
        DepositModalOpen={DepositModalOpen}
        data={data}
        setFlag={setFlag}
        flag={flag}
        depositId={depositId}
      />
      <WithdrawModal
        setWithdrawModalOpen={setWithdrawModalOpen}
        WithdrawModalOpen={WithdrawModalOpen}
        data={data}
        setFlag={setFlag}
        flag={flag}
        depositId={depositId}
      />
      <CContainer fluid className="p-4 mt-1">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-secondary text-center">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                #
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Payment
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Deposit Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Frequency
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Deposit Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Withdrawal Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Blocked Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Balance Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Last Transaction
              </CTableHeaderCell>
              {/* Maybe a modal popup just redirects to home page in original site */}
              <CTableHeaderCell scope="col" className="text-white">
                Details
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data.deposits &&
              data.deposits.deposits.map((ele, i) => (
                <CTableRow key={i}>
                  <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                  {/* use conditional rendering to check whether to pay or paid */}

                  <CTableDataCell className="d-flex">
                    <CButton
                      onClick={() => {
                        setDepositModalOpen(true);
                        setDepositId(ele._id);
                      }}
                      color="success"
                      className="text-white"
                    >
                      Deposit
                    </CButton>
                    <CButton
                      onClick={() => {
                        setWithdrawModalOpen(true);
                        setDepositId(ele._id);
                      }}
                      color="warning"
                      className="text-white ms-2"
                    >
                      Withdraw
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>{ele.depositName}</CTableDataCell>
                  <CTableDataCell>{ele.frequency}</CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.depositAmount.toFixed(2)}</b>
                  </CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.withdrawalAmount.toFixed(2)}</b>
                  </CTableDataCell>
                  <CTableDataCell>
                    <b>{ele?.blockedAmount?.toFixed(2) || 0} </b>
                  </CTableDataCell>
                  <CTableDataCell>
                    <b>{ele.balanceAmount.toFixed(2)}</b>
                  </CTableDataCell>
                  <CTableDataCell>
                    {new Date(ele.lastTransaction).toLocaleString()}
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      onClick={() =>
                        navigate("/DepositDetails", {
                          state: {
                            ele,
                            memberDetails: [
                              data.membershipNo,
                              data.pDetails.memberName,
                            ],
                          },
                        })
                      }
                      color="info"
                      className="text-white"
                    >
                      View
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              ))}
          </CTableBody>
        </CTable>
        <div className="d-flex justify-content-center">
          <CButton onClick={() => setModalOpen(!modalOpen)}>
            New Deposit Account
          </CButton>
        </div>
      </CContainer>
    </>
  );
};

export default DeposiList;
