import axios from "axios";
import { useState, useEffect, useContext } from "react";
import {
	CContainer,
	CFormInput,
	CInputGroup,
	CInputGroupText,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHeaderCell,
    CTableHead,
    CTableRow,
    CButton,
} from "@coreui/react";
import globalContext from "../../contexts/Global/globalContext";
import MemberContext from "../../contexts/MemberContext/MemberContext";
import Paginate from "../Pagination";
import DropDownPagination from "../DropDownPaginate";
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom";

// Search Component
const TableSearch = ({ filterValue, currentValue }) => {
	return (
		<CContainer fluid className="w-75">
			<CInputGroup>
				<CInputGroupText className="bg-info text-white">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="currentColor"
						className="bi bi-search"
						viewBox="0 0 16 16"
					>
						<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
					</svg>
				</CInputGroupText>
				<CFormInput
					placeholder="Search"
					value={currentValue}
					onChange={filterValue}
				/>
			</CInputGroup>
		</CContainer>
	);
};

const DepositsList = () => {
	const { authCheck, showAlert } = useContext(globalContext);
	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [filter, setFilter] = useState("");

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await axios.get("api/member/allDeposits", {
					headers: {
						"auth-token": JSON.parse(localStorage.getItem("token")).token,
					},
				});
				setData(result.data.allDeposits);
			} catch (error) {
				console.log(error);
				if (error.response.data.errors) {
					error.response.data.errors.forEach((ele) => {
						showAlert(ele.msg, "3");
					});
				} else {
					showAlert(error.response.data.error, "3");
				}
			}
		}
		fetchData();
	}, []);

	let filterData = [...data];
	filterData = filterData.map((e) => {
		return {
			memberName: e.memberName,
			membershipNo: e.membershipNo,
			depoName: e.depoName,
			amount: e.amount,
			frequency: e.frequency,
		};
	});
	const filterString = filter.toLowerCase();
	filterData = filterData.filter((item) => {
		return [
			item.memberName,
			item.membershipNo.toString(),
			item.depoName,
			item.amount.toString(),
			item.frequency,
		].some((property) => property.includes(filterString));
	});

	// to get the current items to be displayed
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);

	// to set new page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// to set to previous page wont return anything beyond first page
	const prevPage = () =>
		setCurrentPage((nextPage) => {
			if (nextPage > 1) {
				return nextPage - 1;
			}
			return nextPage;
		});

	// to set to next page wont return anything after the final page
	const nextPage = () =>
		setCurrentPage((prevPage) => {
			if (prevPage < Math.ceil(data.length / itemsPerPage)) {
				return prevPage + 1;
			}
			return prevPage;
		});

	// to set the number of items per page
	function handleItemsPerPage(value) {
		setItemsPerPage(value);
		setCurrentPage(1);
	}

	// to filter the data
	function filterValue(e) {
		setFilter(e.target.value);
	}

	return (
		<>
			<p
				style={{
					background: "#2364aa",
				}}
				className="fw-bold text-white fs-2 text-center p-1 m-0"
			>
				All Deposits List
			</p>
			<CContainer fluid style={{ minHeight: "90vh" }}>
				<CContainer
					fluid
					className="d-flex align-items-center justify-content-around mt-4"
				>
					<TableSearch filterValue={filterValue} currentValue={filter} />
					<CContainer>total result : {filterData.length}</CContainer>
					<DropDownPagination
						handleItemsPerPage={handleItemsPerPage}
						num={itemsPerPage}
					/>
				</CContainer>
				<DepositsListTable data={currentItems} />
				<Paginate
					itemsPerPage={itemsPerPage}
					totalItems={data.length}
					paginate={paginate}
					prevPage={prevPage}
					nextPage={nextPage}
				/>
			</CContainer>
		</>
	);
};

const DepositsListTable = ({ data }) => {
    const { setMemberNo, setMemberData } = useContext(MemberContext);
    const { authCheck, showAlert , } = useContext(globalContext);
    let navigate = useNavigate();
  
    const onClickHandler = async (membershipNo) =>{
      try {
        Cookies.remove("memberNo");
        await setMemberNo(membershipNo);
        await setMemberData(membershipNo);
        Cookies.set("memberNo", membershipNo);
        navigate("/home");
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3")
          });
        } else {
          showAlert(error.response.data.error, "3")
        }
      }
    }
  
    return (
      <CContainer fluid className="p-4 my-2">
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              {/* <CTableHeaderCell scope="col" className="text-white">
                #
              </CTableHeaderCell> */}
              <CTableHeaderCell scope="col" className="text-white">
                Member Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Membership No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Deposit Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Amount
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Frequency
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {data.map((e, index) => {
              return (
                <CTableRow key={index}>
                  {/* <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell> */}
                  <CTableDataCell>{e.memberName}</CTableDataCell>
                  <CTableDataCell onClick={()=>onClickHandler(e.membershipNo)} style={{textDecoration: "underline" , cursor: "pointer"}}>{e.membershipNo}</CTableDataCell>
                  <CTableDataCell>{e.depoName} </CTableDataCell>
                  <CTableDataCell>{e.amount}</CTableDataCell>
                  <CTableDataCell>{e.frequency} </CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </CContainer>
    );
  };



export default DepositsList;
