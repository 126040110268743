import { SET_MEMBER, SET_MEMBERNO , RESET_MEMBER} from "../types";
const MemberReducer = (state, action) => {
  switch (action.type) {
    case SET_MEMBER:
      return {
        ...state,
        memberData: action.payload,
        loading: false,
      };
    case RESET_MEMBER:
      return {
        ...state, 
        memberData : null,
        memberNo : null,
      }
    case SET_MEMBERNO:
      return {
        ...state,
        memberNo: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default MemberReducer;
