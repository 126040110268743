import axios from "axios";
import { useState, useEffect, useContext, useMemo } from "react";
import {
  CButton,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import * as XLSX from "xlsx";
import globalContext from "../contexts/Global/globalContext";
import ViewAllMemberTable from "./ViewAllMembersTable";
import DropDownPagination from "./DropDownPaginate";
import Paginating from "./Paginating";

import searchFunction from "../utils/searchFunction";

const TableSearch = ({ filterValue, currentValue }) => {
  return (
    <CContainer fluid className="w-75">
      <CInputGroup>
        <CInputGroupText className="bg-info text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </CInputGroupText>
        <CFormInput
          placeholder="Search"
          value={currentValue}
          onChange={filterValue}
        />
      </CInputGroup>
    </CContainer>
  );
};

const ViewAllMember = () => {
  const { showAlert } = useContext(globalContext);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.get("api/member/allmembers", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        setData(result.data.everymember);
      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3");
          });
        } else {
          showAlert(error.response.data.error, "3");
        }
      }
    }
    fetchData();
  }, []);

  let filterData = [...data];
  // pDetails and address are objects which are still kept because we need them to render table.
  // But we are also adding their properties to the main object so that we can filter them easily.
  filterData = filterData.map((e) => {
    return {
      memberName: e.pDetails.memberName,
      membershipNo: e.membershipNo,
      _id: e._id,
      pDetails: e.pDetails,
      address: e.pAddress,

      houseNo: e.pAddress.pHouseNo,
      country: e.pAddress.pCountry,
      state: e.pAddress.pState,
      city: e.pAddress.pCity,
      street: e.pAddress.pStreet,
      gender: e.pDetails.gender,
      memberType: e.memberType,
      mobileNo: e.mobileNo,
      joiningDate: new Date(e.acceptedDate).toLocaleDateString(),
    };
  });

  filterData = searchFunction(filterData, filter);

  const filterValue = (e) => {
    setFilter(e.target.value);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (filterData) {
      return filterData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, filterData]);

  const handleClick = () => {
    let memberData = formatData(data);
    const ws = XLSX.utils.json_to_sheet(memberData);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "allMemberList.csv"; // Naming file with .csv extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatData = (data) => {
    return data.map((e, i) => {
      return {
        "S.No": i + 1,
        "Member Name": e.pDetails.memberName,
        "Membership No.": `${e.membershipNo}`,
        Location: `${e.pAddress.pHouseNo}, ${e.pAddress.pCity}, ${e.pAddress.pState}`,
        Gender: e.pDetails.gender,
        "Member Type": e.memberType,
        Mobile: e.mobileNo,
        "Joining Date": new Date(e.acceptedDate).toLocaleDateString(),
      };
    });
  };

  return (
    <>
      <header
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        All Members List
      </header>
      <CContainer fluid style={{ minHeight: "90vh" }}>
        <CContainer
          fluid
          className="d-flex align-items-center justify-content-around mt-4"
        >
          <TableSearch filterValue={filterValue} currentValue={filter} />
          <CContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              total result : {filterData.length}
              <CButton
                color="info"
                className="text-white fw-bold shadow"
                onClick={handleClick}
              >
                Download CSV
              </CButton>
            </div>
          </CContainer>

          <DropDownPagination handleItemsPerPage={setPageSize} num={pageSize} />
        </CContainer>
        <ViewAllMemberTable data={currentTableData} />
        <Paginating
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filterData ? filterData.length : ""}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </CContainer>
    </>
  );
};

export default ViewAllMember;
