import {
    CButton,
    CContainer,
    CForm,
    CFormInput,
    CFormSelect,
    CInputGroup,
    CInputGroupText,
} from "@coreui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../contexts/Global/globalContext";

function PasswordReset() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate()
    const { showAlert } = useContext(globalContext);
    const [userData, setUserData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });



    const onSubmitHandler = async (e) => {
        setIsSubmitting(true);
        try {
            e.preventDefault();

            let res = await axios.patch(
                "/api/passwordChange",
                userData,
                {
                    headers: {
                        "auth-token": JSON.parse(localStorage.getItem("token")).token,
                    },
                }
            );
            await showAlert("Successfully Changed Password", "2");
            setTimeout(() => {
                navigate(0)
            }, 1000)

        } catch (error) {
            console.log(error);
            if (error.response.data.errors) {
                error.response.data.errors.forEach((ele) => {
                    showAlert(ele.msg, "3")
                });
            } else {
                showAlert(error.response.data.error, "3")
            }
        }
        setIsSubmitting(false);
    };

    const onChangeHandler = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <> <p
            style={{
                background: "#2364aa",
            }}
            className="fw-bold text-white fs-2 text-center p-1"
        >
            User Password Change
        </p>
            <CForm className="add-card mb-5" onSubmit={onSubmitHandler} style={{ minHeight: "75vh" }}>

                <CContainer className="form-container d-flex p-3 w-50">
                    <CContainer className="form-1 m-2">
                        <CInputGroup className="mb-3">
                            <CInputGroupText id="basic-addon1">
                                Original Password
                                <span className="text-danger">*</span>
                            </CInputGroupText>
                            <CFormInput
                                placeholder="Enter Password here"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                required
                                type="password"
                                name="oldPassword"
                                value={userData.oldPassword}
                                onChange={onChangeHandler}
                            />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                            <CInputGroupText id="basic-addon1">
                                New Password
                                <span className="text-danger">*</span>
                            </CInputGroupText>
                            <CFormInput
                                placeholder="Enter Password here"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                required
                                type="password"
                                name="newPassword"
                                value={userData.newPassword}
                                onChange={onChangeHandler}
                            />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                            <CInputGroupText id="basic-addon1">
                                Confirm Password
                                <span className="text-danger">*</span>
                            </CInputGroupText>
                            <CFormInput
                                placeholder="Enter the same password again"
                                type="password"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                required
                                name="confirmPassword"
                                value={userData.confirmPassword}
                                onChange={onChangeHandler}
                            />
                        </CInputGroup>
                        <hr />
                        <CContainer fluid className="d-flex justify-content-around mt-1">
                            <CButton
                                type="submit"
                                disabled={isSubmitting}
                                size="lg"
                                color="success"
                                className="m-2 text-white fw-bold"
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </CButton>
                        </CContainer>
                    </CContainer>

                </CContainer>
            </CForm>
        </>
    );
}

export default PasswordReset;
