import {
  CCol,
  CContainer,
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButtonGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CModalFooter,
  CModalBody
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../../contexts/Global/globalContext";
import Cookies from "js-cookie";

const CashCollectorsList = () => {
  const { showAlert } = useContext(globalContext);
  const [collectors, setCollectors] = useState([])
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [showData, setShowData] = useState({})
  const [deleteId, setDeleteId] = useState(null)
  const role = Cookies.get("role");


  useEffect(() => {
    async function fetchCollectors() {
      try {
        const res = await axios.get("api/superAdmin/getCashCollectorsBranch", {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        });
        let { collectors } = res.data
        setCollectors(collectors)


      } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((ele) => {
            showAlert(ele.msg, "3")
          });
        } else {
          showAlert(error.response.data.error, "3")
        }
      }
    }
    fetchCollectors()
  }, [])


  return (
    <>
      <p
        style={{
          background: "#2364aa",
        }}
        className="fw-bold text-white fs-2 text-center p-1 m-0"
      >
        Cash Collectors Data
      </p>
      <CContainer style={{ minHeight: "78vh" }}>
        <NewCollectorModal visible={visible} setVisible={setVisible} />
        <EditCollectorModal visible1={visible1} setVisible1={setVisible1} showData={showData} setShowData={setShowData} />
        <DeleteCollectorModal visible2={visible2} setVisible2={setVisible2} deleteId={deleteId} setDeleteId={setDeleteId} />

        <CCol sm={12} className="mt-5">
          <CContainer fluid className="d-flex justify-content-around">
            <CButtonGroup role="group">
              <CButton className="text-white " color="success" onClick={() => setVisible(!visible)}>

                Add New Collector
              </CButton>
            </CButtonGroup>
          </CContainer>
        </CCol>
        <h5>Collectors</h5>
        <hr></hr>
        <CTable bordered striped className="shadow">
          <CTableHead className="bg-dark text-center align-middle">
            <CTableRow>
              <CTableHeaderCell scope="col" className="text-white">
                Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Mobile No
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                UID
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Address
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Reffered By
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Joining Date
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="text-white">
                Status
              </CTableHeaderCell> <CTableHeaderCell scope="col" className="text-white">
                Edit Collector
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="text-center align-middle">
            {collectors.map((ele, i) => {
              let date = new Date(ele.joiningDate).toLocaleDateString()
              return (
                <CTableRow key={i}>
                  <CTableDataCell>{ele.name}</CTableDataCell>
                  <CTableDataCell>{ele.mobileNo}</CTableDataCell>
                  <CTableDataCell>{ele.IMEI}</CTableDataCell>
                  <CTableDataCell>{ele.address}</CTableDataCell>
                  <CTableDataCell>{ele.referredBy}</CTableDataCell>
                  <CTableDataCell>{date}</CTableDataCell>
                  <CTableDataCell>{ele.status}</CTableDataCell>
                  <CTableDataCell> {role == "admin" ? <CButton
                    className="btn btn-danger w-40 text-white"
                    onClick={() => {
                      setVisible2(!visible2);
                      setDeleteId(ele._id)
                    }}
                  >
                    Terminate
                  </CButton> : <></>}  <CButton
                    className="btn btn-success w-40 text-white"
                    onClick={() => {
                      setVisible1(!visible1);
                      setShowData(collectors[i]);
                    }}
                  >
                      Edit
                    </CButton></CTableDataCell>
                </CTableRow>
              )
            })}

          </CTableBody>
        </CTable>

      </CContainer>
    </>
  );
};

function DeleteCollectorModal({ visible2, setVisible2, deleteId, setDeleteId }) {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showAlert } = useContext(globalContext);
  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true)
      let res = await axios.patch(
        `api/superAdmin/deleteCollector/${deleteId}`, {},
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      await showAlert("Successfully Terminated Collector", "2");

      setTimeout(() => {
        setDeleteId(null)
        navigate(0)
      }, 1000)

    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
      setDeleteId(null)
      navigate(0)
    }
    setIsSubmitting(false)

  };

  return (
    <CModal visible={visible2} onClose={() => setVisible2(false)}>
      <CModalHeader onClose={() => setVisible2(false)}>
        <CModalTitle>Are you sure you want to terminate this collector?</CModalTitle>
      </CModalHeader>
      <CModalFooter>
        <CButton color="secondary" onClick={() => {
          setDeleteId(null)
          setVisible2(false)
        }} disabled={isSubmitting}>
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler} disabled={isSubmitting}>Confirm Terminate</CButton>
      </CModalFooter>
    </CModal>
  )
}

function NewCollectorModal({ visible, setVisible }) {
  const navigate = useNavigate()
  const { showAlert } = useContext(globalContext);


  const [userData, setUserData] = useState({
    username: "",
    password: "",
    role: "",
  });

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();

      let res = await axios.post(
        "api/cashCollectors/",
        userData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      await showAlert("Successfully Added new Collector", "2");
      setTimeout(() => {
        navigate(0)
      }, 1000)

    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
  };

  const onChangeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CModal visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader onClose={() => setVisible(false)}>
        <CModalTitle>Add New Cash Collector</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="form-1 m-3">
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Name
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="enter your name"
              required
              type="text"
              name="name"
              value={userData.name}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Mobile Number
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter Mobile Number here"
              required
              type="text"
              name="mobileNo"
              value={userData.mobileNo}
              onChange={onChangeHandler}

            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              address
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter Address here"
              required
              type="text"
              name="address"
              value={userData.address}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              IMEI / Unique Number
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter your unique number value here"
              required
              type="text"
              name="IMEI"
              value={userData.IMEI}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              password
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter Collector Password here"
              required
              type="text"
              name="password"
              value={userData.password}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Reffered By
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="referredBy"
              required
              type="text"
              name="referredBy"
              value={userData.referredBy}
              onChange={onChangeHandler}
            />
          </CInputGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler}>Add</CButton>
      </CModalFooter>
    </CModal>
  )
}

function EditCollectorModal({ visible1, setVisible1, showData, setShowData }) {
  const navigate = useNavigate()
  const { showAlert } = useContext(globalContext);

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      let res = await axios.put(
        "api/superAdmin/editcollector",
        showData,
        {
          headers: {
            "auth-token": JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      await showAlert("Successfully Edited Collector", "2");
      setTimeout(() => {
        navigate(0)
      }, 1000)

    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        error.response.data.errors.forEach((ele) => {
          showAlert(ele.msg, "3")
        });
      } else {
        showAlert(error.response.data.error, "3")
      }
    }
  };

  const onChangeHandler = (e) => {
    setShowData({
      ...showData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CModal visible={visible1} onClose={() => {
      setShowData({})
      setVisible1(false)
    }
    }>
      <CModalHeader onClose={() => {
        setShowData({})
        setVisible1(false)
      }}>
        <CModalTitle>Edit Cash Collector</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="form-1 m-3">
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Name
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="enter your name"
              required
              type="text"
              name="name"
              value={showData.name}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Mobile Number
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter Mobile Number here"
              required
              type="text"
              name="mobileNo"
              value={showData.mobileNo}
              onChange={onChangeHandler}

            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              address
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter Address here"
              required
              type="text"
              name="address"
              value={showData.address}
              onChange={onChangeHandler}
            />
          </CInputGroup>

          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              IMEI / Unique Number
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter your unique number value here"
              required
              type="text"
              name="IMEI"
              value={showData.IMEI}
              onChange={onChangeHandler}
            />
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              password
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="Enter new Password or empty for old"
              required
              type="text"
              name="password"
              value={showData.password}
              onChange={onChangeHandler}
            />
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupText id="basic-addon1">
              Reffered By
              <span style={{ color: "red", fontWeight: "lighter" }}>
                *
              </span>
            </CInputGroupText>
            <CFormInput
              placeholder="referredBy"
              required
              type="text"
              name="referredBy"
              value={showData.referredBy}
              onChange={onChangeHandler}
            />
          </CInputGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => {
          setShowData({})
          setVisible1(false)

        }}>
          Close
        </CButton>
        <CButton color="primary" onClick={onSubmitHandler}>Add</CButton>
      </CModalFooter>
    </CModal>
  )
}


export default CashCollectorsList;
